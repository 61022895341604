import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { getEnvConfig } from '../../utils/config';


const DepasSeuilConsoCA48mBi24mEff250Q = ({ nbStep, depasSeuilConsoCA48mBi24mEff250Q, catJuridiqueNiv1, catJuridiqueNiv2, setDepasSeuilConsoCA48mBi24mEff250Q, moveToStep,
    goBack, raisonSociale, isMicroEntreprise, nbCandtionMicroValide,
    isSCI,
}) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep+1) : 25);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleConfirm = async () => {
        if (catJuridiqueNiv1 === '5' && catJuridiqueNiv2 !== "54") {
            moveToStep('interventionConvReglSASSCASA')
        } else if (catJuridiqueNiv2 === "54") {
            moveToStep('interventionConvReglSARL')
        } else {
            moveToStep('VehiculeDeSociete')
        }
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);

    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            La société a-t-elle dépassé, pour l'ensemble constitué par la société et les entreprises qu'elle contrôle ou sur lesquelles elle exerce une [[influence notable]], pendant deux exercices successifs sur la base des derniers comptes annuels arrêtés, deux des trois critères suivants :
                            Total du bilan : 24 000 000 €
                            Montant net du chiffre d'affaires : 48 000 000 €
                            Nombre moyen de salariés : 250
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            Has the company exceeded, for the group comprising the company and the undertakings it controls or over which it exercises significant influence, for two successive financial years on the basis of the latest annual accounts, two of the following three criteria: Balance sheet total: €24,000,000 Net sales: €48,000,000 Average number of employees: 250
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${depasSeuilConsoCA48mBi24mEff250Q === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setDepasSeuilConsoCA48mBi24mEff250Q(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${depasSeuilConsoCA48mBi24mEff250Q === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setDepasSeuilConsoCA48mBi24mEff250Q(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${depasSeuilConsoCA48mBi24mEff250Q === null && 'opacity-25'}`} disabled={depasSeuilConsoCA48mBi24mEff250Q === null} onClick={handleConfirm} >
                    {t('siret_page.button')}
                </button>
            </div>
        </div>
    );
};

export default DepasSeuilConsoCA48mBi24mEff250Q;
