import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';


const ContractantPrestationSup = ({ nbStep, isSCI, isComanyCommercial, nbCandtionMicroValide, cocontractantPrestationSup5k, setCocontractantPrestationSup5k, moveToStep, proprioBatimentImmoQ, goBack, raisonSociale, isMicroEntreprise }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep+1) : 25);
    const { t, i18n } = useTranslation();


    const handleConfirm = () => {
        if (isMicroEntreprise) {
            moveToStep('EmissionFactureSecteurPublic');
        } else if (isComanyCommercial) {
            moveToStep('versHonorairesComSup1200')
        } else {
            if (proprioBatimentImmoQ.includes("HABIT") ||
                proprioBatimentImmoQ.some(value => value.startsWith('BUR')) ||
                proprioBatimentImmoQ.some(value => value.startsWith('COM')) ||
                proprioBatimentImmoQ.includes("INDUSTRIAL")) {
                moveToStep('ClassementLogementDPE');
            } else {
                moveToStep('DetentionSiteInternet');
            }
        }
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);

    console.log('nbStep:', nbStep)





    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            {raisonSociale} fait appel à un cocontractant (fournisseur, entrepreneur) ou sous-traitant pour une prestation dont le montant global est supérieur ou égale 5000 € HT ?
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            {raisonSociale} uses a co-contractor (supplier, contractor) or sub-contractor for a service with a total value of €5,000 or more excluding VAT?
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${cocontractantPrestationSup5k === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setCocontractantPrestationSup5k(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${cocontractantPrestationSup5k === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setCocontractantPrestationSup5k(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${cocontractantPrestationSup5k === null && 'opacity-25'}`} disabled={cocontractantPrestationSup5k === null} onClick={handleConfirm} >
                    {t('siret_page.button')}
                </button>
            </div>
        </div>
    );
};

export default ContractantPrestationSup;
