import React from 'react';
import { getActivity } from '../../utils/anpi';
import { useTranslation } from 'react-i18next';

const ActivityConfirmation = ({ activity, onConfirm, stepHistory }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100">
            <div className='max-w-[800px] mb-8'>

                <h1 className='text-2xl text-[#1e4690] my-6'>{t('activity.title_secteur_1')} <a href='#' className='text-[#006acc] hover:border-b-2 hover:border-[#006acc]'> Infogreffe</a>  {t('activity.title_secteur_2')} </h1>
                <p className='text-[#aaaaaa]'>{t('activity.title2')} </p>
                <div className='flex bg-[#e9ecef] py-3 rounded-md items-center justify-center mt-8'>
                    <span className='self-center text-[#006acc] px-2 text-xl'>{getActivity(activity, i18n.language)}</span>

                </div>
            </div>

            <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-8 mt-8">
                    <button
                        className="w-full sm:w-auto bg-[#1e4690] text-white px-8 py-3 rounded-full hover:bg-[#1e469094] transition duration-300 text-sm sm:text-base"
                        onClick={onConfirm}
                    >
                        {t('activity.button_yes')}
                    </button>
                    <a
                        href="https://www.insee.fr/fr/information/2406147"
                        target="_blank"
                        rel="noreferrer"
                        className="w-full sm:w-auto bg-white border border-[#006acc] text-[#006acc] px-12 py-3 rounded-full hover:bg-[#006acc] hover:text-white transition duration-300 text-center text-sm sm:text-base"
                    >
                        {t('activity.button_non')}
                    </a>
                </div>
            </div>
        </div >
    );
};

export default ActivityConfirmation;



