import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';


const TypeContratsTravail = ({ nbStep, isSCI, typeContratsTravail, setTypeContratsTravail, nbCandtionMicroValide, moveToStep, goBack, raisonSociale, isMicroEntreprise }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep+1) : 25);
    const { t, i18n } = useTranslation();
    const [filter, setFilter] = useState('');


    const handleConfirm = () => {
        moveToStep('expositionTravailleurs');
    };

    const options = [
        { key: 'CDI', label: { fr: 'Contrat à durée indéterminée (CDI)', en: 'Open-ended contract (CDI)' } },
        { key: 'CDD', label: { fr: 'Contrat à durée déterminée (CDD)', en: 'Fixed-term contract (CDD)' } },
        { key: 'ALTERNANCE', label: { fr: 'Contrat d\'alternance', en: 'Work-linked training contract' } },
        { key: 'CTT', label: { fr: 'Contrat de travail temporaire (CTT) ou Intérim', en: 'Temporary employment contract (CTT) or temp work' } },
        { key: 'CDII', label: { fr: 'Contrat à durée indéterminée intermittent (CDII)', en: 'Intermittent open-ended contract (CDII)' } },
        { key: 'VIE', label: { fr: 'Volontariat international en entreprise (VIE)', en: 'International Corporate Volunteering (VIE)' } },
    ];

    const toggleSelectedValue = (key) => {
        if (typeContratsTravail.includes(key)) {
            setTypeContratsTravail(typeContratsTravail.filter((item) => item !== key));
        } else {
            setTypeContratsTravail([...typeContratsTravail, key]);
        }
    };



    const displayedOptions = options.filter(option =>
        option.label.en.toLowerCase().includes(filter.toLowerCase()) ||
        option.label.fr.toLowerCase().includes(filter.toLowerCase())
    );

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);






    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            Sous quels contrats de travail sont embauchés le(s) salarié(s) de {raisonSociale} ?
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            Under which employment contracts are the employee(s) of {raisonSociale} ?
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[500px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4 overflow-y-auto max-h-80">
                    {displayedOptions.map((option, index) => (
                        <button
                            key={index}
                            className={`w-full p-4 text-left font-bold rounded-xl ${typeContratsTravail.includes(option.key) ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                            onClick={() => toggleSelectedValue(option.key)}
                        >
                            {i18n.language === 'fr' ? option.label.fr : option.label.en}
                        </button>
                    ))}
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${typeContratsTravail.length === 0 && 'opacity-25'}`} disabled={typeContratsTravail.length === 0} onClick={handleConfirm} >
                    {t('siret_page.button')}
                </button>
            </div>
        </div>
    );
};

export default TypeContratsTravail;
