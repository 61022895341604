// NavigationBar.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import infogreffe_logo from '../assets/infogreffe_logo.svg';
import infogreffe_logo_claire from '../assets/logo_infogreffe.png';
import france_logo from '../assets/french.png';
import uk_logo from '../assets/uk_logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import useWindowSize from '../hooks/useWindowSize';
import { TiThMenu } from "react-icons/ti";
import { GrUserExpert } from "react-icons/gr";
import { MdOutlineConnectWithoutContact } from "react-icons/md";

const NavigationBar = ({
  background = '',
  isQuestionnaire = false,
  isPageExpert = false
}) => {
  const { t, i18n } = useTranslation();
  const [hasScrolled, setHasScrolled] = useState(false);

  const { width } = useWindowSize();
  const isMobile = width < 768;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  let navigate = useNavigate();

  const navigateToPage = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const location = useLocation();

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact-section');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleContactClick = () => {
    if (location.pathname === '/') {
      // Si on est sur la page d'accueil, scroller vers la section "Contact"
      scrollToContact();
    } else {
      navigate('/', { state: { scrollToContact: true } });
    }
  };

  const currentLanguage = i18n.language;
  const otherLanguage = currentLanguage === 'fr' ? 'en' : 'fr';
  const flagToShow = otherLanguage === 'fr' ? france_logo : uk_logo;
  const altText = otherLanguage === 'fr' ? 'FR' : 'EN';

  const my_class = background ? "font-bold text-[#372B6B] hover:border-b-2 hover:border-[#372B6B]" : "text-white font-bold hover:text-[#5FFE96] hover:border-b-2 hover:border-[#5FFE96]"
  return (
    <>
      <nav className={`fixed top-0 left-0 right-0 z-50 flex justify-between items-center py-4 px-4 sm:px-6 md:px-8 lg:px-12 transition-colors duration-1000 ${background === '' && hasScrolled ? 'bg-[#001046]' : `bg-[#0F6ACC]`} ${background ? `bg-${background}` : ''} `}>
        <div>
          <a href="/" aria-label="Home">
            {background ?
              <img src={infogreffe_logo_claire} alt="infogreffe_logo" className="h-10 cursor-pointer" />
              :
              <img src={infogreffe_logo} alt="infogreffe_logo" className="h-10 cursor-pointer" />
            }
          </a>
        </div>
        {!isMobile && (
          <div className="flex space-x-6">
            {!isQuestionnaire &&
              <>
                <button className={my_class} onClick={() => navigateToPage('/experts')}>
                  {t('experts')}
                </button>
                <button className={my_class} onClick={handleContactClick}>
                  {t('contact')}
                </button>
              </>
            }
          </div>
        )}
        <div className="flex space-x-4 items-center">
          <button onClick={() => changeLanguage(otherLanguage)} className="flex items-center">
            <img src={flagToShow} alt={altText} className="h-10 mx-2" />
          </button>
          {isMobile && !isQuestionnaire && (
            <button onClick={toggleMenu} className="text-white font-bold">
              <TiThMenu size={30} />
            </button>
          )}
        </div>
      </nav>

      {isMenuOpen && (
        <div className={`relative bg-white shadow-lg rounded-lg p-5 w-full ${isPageExpert && 'mt-14'}`}>
          {!isQuestionnaire &&
            <>
              <button className="flex items-center justify-start px-4 py-2 text-left text-[#1e4690] font-bold border-b w-full" onClick={() => navigateToPage('/experts')}>
                <GrUserExpert size={25} className="mr-2" />
                {t('experts')}
              </button>
              <button className="flex items-center justify-start px-4 py-2 text-left text-[#1e4690] font-bold border-b w-full" onClick={handleContactClick} >
                <MdOutlineConnectWithoutContact size={25} className="mr-2" />
                {t('contact')}</button>
            </>
          }
        </div>
      )}
    </>
  );
};

export default NavigationBar;