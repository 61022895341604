import React, { useEffect, useState } from "react";
import landingPage from "../assets/illustration_landing.svg";
import landingPage_mobile from "../assets/landingPage_mobile.svg";
import landingPage_2 from "../assets/illustration_landing_2.svg";
import landingPage_3 from "../assets/illustration_landing_3.svg";
import promo_4 from "../assets/promo-4.svg";
import promo_4_hover from "../assets/promo-4-hover.svg";
import promo_5 from "../assets/promo-5.svg";
import promo_5_hover from "../assets/promo-5-hover.svg";
import logo_legaware from "../assets/logo_legaware.png"
import promo_6 from "../assets/promo-6.svg";
import promo_6_hover from "../assets/promo-6-hover.svg";
import complianceIcon3 from "../assets/complianceIcon3.png";
import complianceIcon4 from "../assets/complianceIcon4.png";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import { useTranslation } from "react-i18next";
import { Carousel } from "flowbite-react";
import PartnerCard from "../components/PartnerCard";
import useWindowSize from "../hooks/useWindowSize";
import FAQContactSection from "./FAQContactSection";
import Footer from "./Footer";

const Home = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { width, height } = useWindowSize();

  const isMobile = width <= 780;

  const [marginClass, setMarginClass] = useState("h-10");

  useEffect(() => {
    if (height < 610) {
      setMarginClass("h-40");
    } else if (height > 740 && height < 1080 && isMobile) {
      setMarginClass("h-80");
    } else if (height > 500 && height <= 740 && isMobile) {
      setMarginClass("h-48");
    }
    else if (height > 610 && height < 900) {
      setMarginClass("h-10");
    }
  }, [height, isMobile]);

  function startQuestionnaire() {
    localStorage.removeItem('questionnaireState');
    navigate('/siret'); // Redirection vers la première page du questionnaire
  }
  const groupPartners = (partners) => {
    return partners.reduce((acc, partner, index) => {
      const groupIndex = Math.floor(index / (width < 420 ? 1 : Math.floor(width / 420)));
      if (!acc[groupIndex]) {
        acc[groupIndex] = []; // start a new group
      }
      acc[groupIndex].push(partner);
      return acc;
    }, []);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToContact) {
      // Utiliser un léger délai pour s'assurer que le DOM est prêt avant de scroller
      setTimeout(() => {
        const contactSection = document.getElementById('contact-section');
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Délai de 100ms pour laisser le temps au DOM de se charger
    }
  }, [location]);

  const partners = [
    {
      logo: "https://infogreffe.mesobligations.fr/repository/59e657827bd8cbdccad9ba1b7ada8484/Logo-AP.png",
      name: "Action Prévention",
      type_service: "Consultant",
      description: "Action Prévention est une plateforme entièrement dédiée à la prévention des entreprises en difficulté.Notre algorithme, conçu par des experts en la matière, permet de mieux outiller le chef d'entreprise en améliorant la détection et la prévention des difficultés.Même face à une situation obérée, de nombreux chefs d'entreprises hésitent encore à demander de l'aide faute d'informations sur les procédures existantes.Action Prévention est donc là pour tous ces chefs d'entreprise afin de leur proposer un diagnostic gratuit de leur situation et les aiguiller sur la solution juridique la plus adaptée à leurs besoins.Une fois ce diagnostic posé, celui-ci est expliqué afin d'apporter une vision claire et rassurante de la procédure par laquelle le dirigeant devra passer.Action Prévention met ensuite le dirigeant en relation avec des professionnels géographiquement proches de lui et spécialisés dans le traitement des difficultés des entreprises.",
      solutions: [{ name: 'Traitement des entreprises en difficulté', description: '' },
      { name: 'Procédure préventive', description: '' },
      { name: 'Procédure collective', description: '' }
      ]
    },
    {
      logo: "https://infogreffe.mesobligations.fr/repository/6f31cba19ab12f5ff9d6fe04e02f0f9b/atout-chimie.png",
      name: "ATOUT CHIMIE",
      type_service: "Cabinet d'audit et de conseil",
      description: "Intoxication règlementaire? Notre équipe d'experts vous accompagne dans la mise en conformité de vos produits chimiques",
      solutions: [{ name: 'Règlement REACH', description: '' },
      { name: 'Règlement des Produits biocides (BPR)', description: '' },
      { name: 'Classification, Etiquetage, Emballage (CLP) des substances et mélanges', description: '' },
      { name: 'Evaluation de risque environnemental des produits pharmaceutiques', description: '' }
      ]
    },
    {
      logo: "https://infogreffe.mesobligations.fr/repository/d2cf1280cc5b91dd7da998f0740eae9a/auxoAvocats3.jpg",
      name: "AUXO AVOCAT",
      type_service: "Avocat",
      description: "Le Cabinet AUXÔ AVOCATS réunit trois avocats en droit des affaires, experts dans le domaine du conseil aux entreprises. Les membres d'AUXÔ AVOCATS interviennent auprès des entreprises de tous secteurs d'activités et de toutes tailles : Start-up, sociétés d'investissements, PME, ETI, grands groupes, commerçants, professions libérale dans les domaines suivants :&nbsp;Corporate/Fusion acquisition&nbsp; Droit des sociétés et gouvernance&nbsp;Fiscalité des entreprises - restructurations - du patrimoine et contentieuxManagement packages&nbsp;Pacte Associés&nbsp;Start - up et capital risque&nbsp;Procédures collectives",
      solutions: [{ name: 'Droit des Affaires', description: '' },
      { name: 'Droit des sociétés et gouvernance', description: '' },
      { name: 'Droit fiscal', description: '' },
      { name: 'Contentieux', description: '' },
      ]
    },
    {
      logo: "https://infogreffe.mesobligations.fr/repository/c15e5c6244a9f62a80f912ee9ec611f1/axiocapvD.png",
      name: "Axiocap",
      type_service: "Société de services",
      description: "Gagnez en efficacité et en sécurité tout en allégeant vos processus papier lourds et chronophages ! Numéro 1 des solutions digitales dédiées à la gestion dématérialisée de l'actionnariat, Axiocap a été développé pour les entreprises non cotées et multi-actionnaires, ainsi que les professionnels du droit et du chiffre.",
      solutions: [{ name: 'Gestion des titres et parts sociales en ligne', description: '' },
      { name: 'Registre des mouvements de titres en ligne', description: '' },
      { name: 'Registres légaux obligatoires', description: '' },
      { name: "Registres d'entreprise dématérialisés", description: '' },
      { name: 'Gestion des assemblées et conseils en ligne', description: '' },
      { name: 'Coffre-fort numérique (CCFN)', description: '' },
      { name: 'Signature électronique légale', description: '' },
      { name: 'Blockchain', description: '' },
      ]
    },
    {
      logo: "https://infogreffe.mesobligations.fr/repository/13549fcc3c9519915acf227bb2833c28/axonaut-bleu-vertical.svg",
      name: "Axonaut",
      type_service: "Société de services",
      description: "Axonaut est la solution française qui aide les entrepreneurs et chefs d'entreprise dans toutes les tâches administratives et commerciales.A l'aide d'un logiciel simple & abordable, ils automatisent :La gestion commercialeL'édition des Devis & FacturesLe suivi de la TrésorerieLes relations avec le comptable grâce à un Portail dédiéet aussi les RH, les projets, le marketing et les stocks",
      solutions: [{ name: 'Gestion commerciale (CRM)', description: '' },
      { name: 'Edition des Devis & Factures électroniques', description: '' },
      { name: 'Rapprochement bancaire', description: '' },
      { name: 'Suivi de la Trésorerie', description: '' }
      ]
    },
    // {
    //     logo: "https://infogreffe.mesobligations.fr/repository/59e657827bd8cbdccad9ba1b7ada8484/Logo-AP.png",
    //     name: "Action Prévention",
    //     type_service: "Consultant",
    //     description: "Action Prévention est une plateforme entièrement dédiée à la prévention des entreprises en difficulté.Notre algorithme, conçu par des experts en la matière, permet de mieux outiller le chef d'entreprise en améliorant la détection et la prévention des difficultés.Même face à une situation obérée, de nombreux chefs d'entreprises hésitent encore à demander de l'aide faute d'informations sur les procédures existantes.Action Prévention est donc là pour tous ces chefs d'entreprise afin de leur proposer un diagnostic gratuit de leur situation et les aiguiller sur la solution juridique la plus adaptée à leurs besoins.Une fois ce diagnostic posé, celui-ci est expliqué afin d'apporter une vision claire et rassurante de la procédure par laquelle le dirigeant devra passer.Action Prévention met ensuite le dirigeant en relation avec des professionnels géographiquement proches de lui et spécialisés dans le traitement des difficultés des entreprises.",
    //     solutions: [{ name: 'Traitement des entreprises en difficulté', description: '' },
    //     { name: 'Procédure préventive', description: '' },
    //     { name: 'Procédure collective', description: '' }
    //     ]
    // },
  ];

  const partnerGroups = groupPartners(partners);

  const customTheme = {
    root: {
      base: "relative h-full w-full",
      leftControl: `absolute left-0 top-0 flex h-full items-center justify-center ${!isMobile && 'px-4'} focus:outline-none`,
      rightControl: `absolute right-0 top-0 flex h-full items-center justify-center ${!isMobile && 'px-4'} focus:outline-none`
    },
    indicators: {
      active: {
        off: "bg-[#001046] hover:bg-[#006ACC] dark:bg-[#001046] dark:hover:bg-[#006ACC]",
        on: "bg-[#006ACC] dark:bg-[#001046]"
      },
      base: "h-3 w-3 rounded-full",
      wrapper: "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3"
    },
    item: {
      base: "absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
      wrapper: {
        off: "w-full flex-shrink-0 transform cursor-default snap-center",
        on: "w-full flex-shrink-0 transform cursor-grab snap-center"
      }
    },
    control: {
      base: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-[#001046] group-hover:bg-[#006ACC] group-focus:outline-none group-focus:ring-4 group-focus:ring-[#006ACC] dark:bg-[#001046] dark:group-hover:bg-[#006ACC] dark:group-focus:ring-[#001046] sm:h-10 sm:w-10",
      icon: "h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6"
    },
    scrollContainer: {
      base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-lg",
      snap: "snap-x"
    }
  };
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  return (
    <div className="flex flex-col bg-white pt-[72px]">
      {/* Navigation Bar */}
      <NavigationBar />

      {/* Main Content */}
      <div className="relative flex-grow flex flex-col bg-gradient-to-b from-[#0F6ACC] to-[#fff] text-center min-h-screen">
        {isMobile ?

          <img src={landingPage_mobile} alt="Landing Illustration" className="absolute bg-transparent right-16 inset-0 w-full h-full object-cover" />
          :

          <img src={landingPage} alt="Landing Illustration" className="absolute bg-transparent right-16 inset-0 w-full h-full object-cover" />
        }
        <div className="z-10 px-4 mt-8">
          <h1 className="text-3xl md:text-4xl font-bold mt-4 text-white">« {t('NoOneSsupposedToIgnoreTheLaw')} »</h1>
          <div className="flex flex-col items-center justify-center md:mx-auto md:max-w-3xl">
            {isMobile ?
              <p className="text-white text-lg font-semibold mt-6">{t('home.p1')} {t('home.p2')} {t('home.p3')}</p>
              :
              <p className="text-white text-lg font-semibold mt-6 flex flex-col items-center">
                <span className="mb-1">{t('home.p1')}</span>
                <span className="mb-1">{t('home.p2')}</span>
                <span>{t('home.p3')}</span>
              </p>
            }


          </div>
          <div className={`${marginClass}`}></div>
          <button className="group bg-[#001046] mt-4 text-white px-12 py-4 rounded-full hover:bg-[#006ACC] transition duration-300 ease-in-out" onClick={startQuestionnaire}>
            <div className="flex items-center text-lg font-semibold">
              {t('startQuestionnaire')}
              <FaLongArrowAltRight className="ml-4 transition-transform duration-300 ease-in-out group-hover:translate-x-4" />
            </div>
          </button>

        </div>

      </div >

      <div className="flex flex-wrap justify-center items-center px-12 gap-12 my-12">
        <div className="flex-1 max-w-[330px]"
          onMouseEnter={() => setHover1(true)}
          onMouseLeave={() => setHover1(false)}>
          <div className="promo__box public">
            <h3 className="color__azure">
              <span className="color__green">1.</span> <span className="text-[#001046] font-bold">{t('home.p10')}</span>
            </h3>
            <div className="promo__body">
              <p>«<span>{t('home.p11')}</span>&nbsp;»</p>
            </div>
            <div className="promo__image relative">
              <img className={`absolute inset-0 w-full h-full ${hover1 ? '' : 'hidden'}`} src={promo_4} alt="Default Image" />
              <img className={`absolute inset-0 w-full h-full ${hover1 ? 'hidden' : ''}`} src={promo_4_hover} alt="Hover Image" />
            </div>
          </div>
        </div>

        <div className="flex-1 max-w-[330px]"
          onMouseEnter={() => setHover2(true)}
          onMouseLeave={() => setHover2(false)}>
          <div className="promo__box public">
            <h3 className="color__azure">
              <span className="color__green">2.</span> <span data-translate="" className="text-[#001046] font-bold">{t('home.p12')}</span>
            </h3>
            <div className="promo__body">
              <p>«<span data-translate="">{t('home.p13')}</span>&nbsp;»</p>
            </div>
            <div className="promo__image relative">
              <img className={`absolute inset-0 w-full h-full ${hover2 ? '' : 'hidden'}`} src={promo_5} alt="Default Image" />
              <img className={`absolute inset-0 w-full h-full ${hover2 ? 'hidden' : ''}`} src={promo_5_hover} alt="Hover Image" />
            </div>
          </div>
        </div>

        <div className="flex-1 max-w-[330px]"
          onMouseEnter={() => setHover3(true)}
          onMouseLeave={() => setHover3(false)}>
          <div className="promo__box public">
            <h3 className="color__azure">
              <span className="color__green">3.</span> <span data-translate="" className="text-[#001046] font-bold">{t('home.p14')}</span>
            </h3>
            <div className="promo__body">
              <p>« <span data-translate="">{t('home.p15')}</span> »</p>
            </div>
            <div className="promo__image relative">
              <img className={`absolute inset-0 w-full h-full ${hover3 ? '' : 'hidden'}`} src={promo_6} alt="Default Image" />
              <img className={`absolute inset-0 w-full h-full ${hover3 ? 'hidden' : ''}`} src={promo_6_hover} alt="Hover Image" />
            </div>

          </div>
        </div>
      </div>


      <div className="relative flex-grow flex flex-col text-center min-h-screen">
        {isMobile ?
          <img src={landingPage_3} alt="Landing Illustration" className="absolute inset-0 w-full h-full object-cover" />
          :
          <img src={landingPage_2} alt="Landing Illustration" className="absolute inset-0 w-full h-full object-cover" />
        }

        <div className="flex flex-wrap justify-center items-center px-12 pt-8 gap-12 mb-2 mt-12 z-10">
          <div className="w-full md:max-w-[33%]">
            <h1 className="text-xl md:text-xl font-bold my-4 text-[#001046]">{t('home.p4')}</h1>
            <img src={complianceIcon3} alt="Compliance Icon" className="w-24 h-24 mx-auto mb-4" />
            <p className="text-[#001046] text-lg">{t('home.p6')}</p>
            <p className="text-[#001046] mb-8 text-lg">{t('home.p7')}</p>

          </div>
          <div className="w-full md:max-w-[33%]">
            <h1 className="text-xl md:text-xl font-bold my-4 text-[#001046]">{t('home.p5')}</h1>
            <img src={complianceIcon4} alt="Compliance Icon" className="w-24 h-24 mx-auto mb-4" />
            <p className="text-[#001046] text-lg">{t('home.p8')}</p>
            <p className="mb-8 text-lg text-[#001046]">{t('home.p9')}</p>

          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center pb-8 z-10">
          <button className="group bg-[#001046] text-white px-12 py-4 rounded-full hover:bg-[#006ACC] transition duration-300 ease-in-out" onClick={startQuestionnaire}>
            <div className="flex items-center text-lg font-semibold">
              {t('startQuestionnaire')}
              <FaLongArrowAltRight className="ml-4 transition-transform duration-300 ease-in-out group-hover:translate-x-4" />
            </div>
          </button>
        </div>

        <div className="items-start mt-8 z-10">
          <h1 className="text-[#001046] font-bold text-3xl mt-16 mb-4">Nos experts partenaires</h1>
          <p className="text-[#001046] text-xl mb-4 px-4">
            Nous avons une communauté d'expert qui vous accompagne via notre plateforme dans la conformité
          </p>
        </div>

        <div className="h-[600px] px-12 mt-2 justify-center items-center ">
          <Carousel slide={false} theme={customTheme} className="justify-center">
            {partnerGroups.map((group, index) => (
              <div key={index} className="flex h-full items-center justify-center gap-10">
                {group.map(partner => (
                  <PartnerCard key={partner.id} partner={partner} />
                ))}
              </div>
            ))}
          </Carousel>
        </div>

      </div>

      <FAQContactSection />
      <Footer />
    </div >
  );
};

export default Home;
