import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';


const ProdDetDechets = ({ nbStep, isSCI, moveToStep, societeFilialeMereQ, setSocieteFilialeMereQ, nbCandtionMicroValide, goBack, raisonSociale, isMicroEntreprise, nbMoyenSalaries, chiffredAffairesTotalNet, totalBilanQ, catJuridiqueNiv1, catJuridiqueNiv2 }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep+1) : 25);
    const { t, i18n } = useTranslation();
    const [filter, setFilter] = useState('');


    const handleConfirm = async () => {
        if (societeFilialeMereQ.includes("MERE")) {
            moveToStep('typeControlExerceSociete');
        } else if ((nbMoyenSalaries > 250 && chiffredAffairesTotalNet > 48000000) ||
            (nbMoyenSalaries > 250 && totalBilanQ > 24000000) ||
            (chiffredAffairesTotalNet > 48000000 && totalBilanQ > 24000000)
        ) {
            moveToStep('depasSeuilConsoCA48mBi24mEff250Q');
        } else if (catJuridiqueNiv1 === '5' && catJuridiqueNiv2 !== "54") {
            moveToStep('interventionConvReglSASSCASA')
        } else if (catJuridiqueNiv2 === "54") {
            moveToStep('interventionConvReglSARL')
        } else {
            moveToStep('VehiculeDeSociete')
        }
    };

    const options = [
        { key: 'MERE', label: { fr: 'une société MERE, possèdant une ou plusieurs filiales', en: 'a parent company with one or more subsidiaries' } },
        { key: 'FILLE', label: { fr: 'une société FILLE, étant la filiale d\'une autre société', en: 'a subsidiary company, being the subsidiary of another company' } },
        { key: '*AO', label: { fr: '- aucune de ces options -', en: '- none of these options -' } },
    ];


    const displayedOptions = options.filter(option =>
        option.label.en.toLowerCase().includes(filter.toLowerCase()) ||
        option.label.fr.toLowerCase().includes(filter.toLowerCase())
    );

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);

    const toggleSelectedValue = (key) => {
        setSocieteFilialeMereQ(prevState => {
            if (key === "*AO") {
                if (prevState.includes("*AO")) {
                    return [];
                }
                return ["*AO"];
            }
            if (prevState.includes("*AO")) {
                return [key];
            }

            if (prevState.includes(key)) {
                return prevState.filter(item => item !== key);
            }
            return [...prevState, key];
        });
    };





    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            {raisonSociale} est :
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            {raisonSociale} is :
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[500px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4 overflow-y-auto max-h-80">
                    {displayedOptions.map((option, index) => (
                        <button
                            key={index}
                            className={`w-full p-4 text-left font-bold rounded-xl ${societeFilialeMereQ.includes(option.key) ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                            onClick={() => toggleSelectedValue(option.key)}
                        >
                            {i18n.language === 'fr' ? option.label.fr : option.label.en}
                        </button>
                    ))}
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${societeFilialeMereQ.length === 0 && 'opacity-25'}`} disabled={societeFilialeMereQ.length === 0} onClick={handleConfirm} >
                    {t('siret_page.button')}
                </button>
            </div>
        </div>
    );
};

export default ProdDetDechets;
