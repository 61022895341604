import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import france_logo from '../../assets/french.png';
import uk_logo from '../../assets/uk_logo.png';
import ThematicBlock from './ThematicBlock';
import ModalPayments from './ModalPayments';
import { FaBars, FaTimes } from 'react-icons/fa';

function MainRecapWorkspace({ nbObligations, thematiques, result,
    isMobile,
    isOpen,
    raisonSociale,

    setIsMobile,
    setIsOpen,
    toggleSidebar }) {

    const { t, i18n } = useTranslation();
    const [modalPayments, setModalPayments] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const currentLanguage = i18n.language;
    const otherLanguage = currentLanguage === 'fr' ? 'en' : 'fr';
    const flagToShow = otherLanguage === 'fr' ? france_logo : uk_logo;
    const altText = otherLanguage === 'fr' ? 'FR' : 'EN';

    return (
        <main className="flex-1 overflow-y-auto">
            <div className="max-w-7xl mx-auto py-6 sm:py-1 px-4 sm:px-6 lg:px-8">
                <div className="md:py-10 sm:py-0 w-full board">
                    <nav className="flex justify-between items-center mb-4">
                        <h1 className="text-3xl text-[#1e4690] font-semibold mb-4">{raisonSociale}</h1>
                        <div className="flex items-center space-x-4">
                            <button onClick={() => changeLanguage(otherLanguage)} className="flex items-center">
                                <img src={flagToShow} alt={altText} className="h-8 mr-2" />
                            </button>
                            {!isOpen &&
                                <button
                                    className="z-30 p-2 bg-white rounded-md shadow-lg"
                                    onClick={toggleSidebar}
                                    aria-label={isOpen && isMobile ? "Fermer le menu" : "Ouvrir le menu"}
                                >
                                    {isOpen && isMobile ? <FaTimes size={30} color='#006acc' /> : <FaBars size={30} color='#006acc' />}
                                </button>
                            }
                        </div>
                    </nav>
                    <div className="flex justify-between bg-white py-1 pl-6 pr-1 rounded-lg shadow mb-4">
                        <h3 className="text-lg text-[#1e4690] p-2">Mes <strong>{nbObligations}</strong>  obligations applicables</h3>
                        <button className="bg-blue-800 text-white hover:bg-[#006ACC] p-2 rounded"
                            onClick={() => setModalPayments(true)}
                        >Accéder au RAPPORT DETAILLE</button>
                    </div>
                    <h1 className="text-xl text-[#1e4690] my-6">Détails par thématiques </h1>

                    <div className="view view-grid d-block">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5" id="details">
                            {thematiques.map((thematique, index) => {
                                // Calculer la somme des counts pour la thematique courante
                                const obligations = thematique.details.reduce((acc, detail) => acc + detail.count, 0);

                                return (
                                    <ThematicBlock
                                        key={index} // Assure-toi d'ajouter une clé unique pour chaque enfant dans la liste
                                        index={index}
                                        title={thematique.title}
                                        obligations={obligations} // Passer la somme calculée
                                        details={thematique.details}
                                    />
                                );
                            })}

                        </div>
                    </div>
                    <footer className="flex  justify-end mt-auto">
                        <button className="mt-auto bg-blue-800 hover:bg-[#006ACC] text-white p-2 rounded"
                            onClick={() => setModalPayments(true)}
                        >Accéder au RAPPORT DETAILLE</button>

                    </footer>

                    <ModalPayments isOpen={modalPayments} onClose={() => setModalPayments(false)} result={result} />

                </div>
            </div>
        </main>
    )
}


export default MainRecapWorkspace;
