import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';


const ImpositionAssociesQ = ({ isSCI, setImpositionAssociesQ, nbCandtionMicroValide, impositionAssociesQ, nbStep, moveToStep, goBack, raisonSociale, isMicroEntreprise }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep+1) : 25);
    const { t, i18n } = useTranslation();


    const handleConfirm = () => {
        moveToStep('personnalDataSelection');
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);


    const toggleSelectedValue = (key) => {
        if (impositionAssociesQ.includes(key)) {
            setImpositionAssociesQ(impositionAssociesQ.filter((item) => item !== key));
        } else {
            setImpositionAssociesQ([...impositionAssociesQ, key]);
        }
    };

    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            Les associés de {raisonSociale}  sont imposés :
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            Partners of {raisonSociale} are taxed :
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${impositionAssociesQ.includes('IR') ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => toggleSelectedValue('IR')}
                    >
                        {i18n.language === 'fr' ? "à l'impôt sur le revenu (IR)" : "income tax (IR)"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${impositionAssociesQ.includes('IS') ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => toggleSelectedValue('IS')}
                    >
                        {i18n.language === 'fr' ? "à l'impôt sur les sociétés (IS)" : "corporation tax (IS)"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${impositionAssociesQ.length === 0 && 'opacity-25'}`} disabled={impositionAssociesQ.length === 0} onClick={handleConfirm} >
                    {t('siret_page.button')}
                </button>
            </div>
        </div>
    );
};

export default ImpositionAssociesQ;
