import React, { useState, useEffect } from 'react';

const DisclaimerModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <>
            <div className="fixed inset-0 bg-black bg-opacity-20 z-40" />
            <div className="fixed inset-x-0 bottom-0 z-50 pb-4 bg-yellow-100 shadow-lg">
                <div className="bg-yellow-200 py-2 mb-2 flex justify-between items-center">
                    <div className="flex-grow text-center font-bold">AVERTISSEMENT</div>
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
                        <div className="flex justify-end">
                            <button
                                onClick={onClose}
                                className="bg-green-500 hover:bg-green-700 text-white px-4 rounded flex items-center"
                            >
                                Ok, J'ai compris !
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </button>
                </div>
                <div className="text-sm text-gray-700 px-4">
                    <ul className="list-disc space-y-2 pl-5">
                        <li>La plateforme restitue les principaux droits et obligations issues de la réglementation (liste non-exhaustive) susceptibles de s'appliquer à une entreprise individuelle ou commerciale, ainsi que les solutions pour s'y conformer. Ceci n'est pas du conseil juridique et ne remplace pas l'intervention d'un expert-comptable.</li>
                        <li className="mt-2">Un travail continu est effectué sur la fiabilisation et la mise-à-jour des contenus restitués. Cela dit, ce travail n'est pas exempt d'un risque d'erreur et l'utilisateur est invité à vérifier lui-même la pertinence des informations restituées grâce notamment aux informations fournies via les Champs d'application de sources.</li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default DisclaimerModal;