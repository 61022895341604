import LoginForm from '../components/auth/LoginForm';
import { useState } from "react";
import Modal from "../components/Modal";
import Cgus from "../components/auth/Cgus";
import { useColorsContext } from "../context/GlobalContext";

const Login = () => {

  const { subdomain } = useColorsContext();

  const [modalCguOpen, setModalCguOpen] = useState("");
  const [modalTile, setModalTitle] = useState('');


  return (
    <>
      <div className="loginPage w-[100%] h-[100dvh]">

        <div className="loginInfosContainer ">
          <div className={`loginWords bg-dark-grey`}>
            {/* {
              subdomain == "infogreffe" ?
                <img className={"loginWordsLogo"} src={logoInfogreffe_mini} alt="logo mini" />
                :
                <img className={"loginWordsLogo"} src={logo_mini} alt="logo mini" />
            } */}

            {/* <img className={"loginWordsLogo"} src={logo_mini} alt="logo mini" /> */}
            {/* {subdomain == "infogreffe" ? */}
            {subdomain == "oliver" ?
              <>
                <p className="loginWordsW1">une réponse instantanée...*</p>
                <p className="loginWordsW2">...sur une question administrative</p>
                <p className="loginWordsW3">...ou un sujet juridique</p>
              </> :
              <>
                <p className="loginWordsW1">plus précis</p>
                <p className="loginWordsW2">plus fiable</p>
                <p className="loginWordsW3">plus exhaustif</p>
              </>
            }
            <p className="text-[0.25em] leading-3	 absolute bottom-1">* la réponse étant basée sur un moteur d'intelligence artificielle, une fiabilté à 100% n'est pas garantie. De plus, ce legalbot ne fournit pas de conseil juridique, réservé aux avocats.</p>
          </div>

          <div className={`loginInfos`}
          >
            <p>Oliver.legal est un agent conversationnel spécialisé dans le juridique.<br />
              Son nom s'inspire d'Oliver Wendell Holmes, considéré comme le plus fameux juriste américain.</p>
            <p>Oliver.legal est basé sur la dernière technologie d'intelligence artificielle générative (genAI) et possède pour base de connaissance
              tout le corpus légal et réglementaire francais (+200 000 articles et +2000 fiches techniques), ce qui
              lui permet de répondre à un large éventail de questions juridiques et administratives.</p>
            <div>
              <p>Sa particularité réside dans :</p>
              <ul>
                <li>• la possibilite de contextualiser la question avec les données de son entreprise,</li>
                <li>• l'étendue de sa base de connaissance qui ne cesse d'être mise à jour et enrichie,</li>
                <li>• sa capacité à citer de manière fiable les sources qu'il utilise dans ses réponses.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="loginContainer overflow-y-scroll">


          <div className="loginContainerConnexion">
            <p>Découvrez Oliver,<br />
              notre illustre assistant juridique
            </p>
            <div className='w-[90%]'>
              <LoginForm setCgus={setModalTitle} setModalCguOpen={setModalCguOpen} />
            </div>
          </div>

          <div>
            {/* <div className={`${subdomain !== "infogreffe" && "hidden"}`}>
              <img className={"w-24"} src={infogreffe_logo} alt="logo mini" />

            </div> */}
            {/* <div >
              <img className={"w-24"} src={openAI_logo} alt="logo mini" />


            </div> */}
          </div>
          <div className="w-[100%] flex justify-end">

            <div className={`loginContainerFooter bg-first-oliver`}>
              <p><a onClick={() => { setModalTitle("Mentions légales"); setModalCguOpen(true) }} className="cursor-pointer">Mentions légales</a></p>
              <p><a onClick={() => { setModalTitle("Politique de confidentialité"); setModalCguOpen(true) }} className="cursor-pointer">Politique de confidentialité</a></p>
            </div>
            {/* <p><a onClick={() => {setModalTitle("Politiques de GGU"); setModalCguOpen(true)}} className="cursor-pointer">Politiques de GGU</a></p> */}
          </div>
        </div>
      </div>
      <Modal title={modalTile} modalOpen={modalCguOpen} setModalOpen={setModalCguOpen} modalSize={"cgus"}>
        <Cgus content={modalTile}></Cgus>
      </Modal>
    </>
  );
};
export default Login;
