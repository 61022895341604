import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';


const VersHonorairesComSup1200 = ({ nbStep, isSCI, isComanyCommercial, nbCandtionMicroValide, versHonorairesComSup1200, setVersHonorairesComSup1200, moveToStep, proprioBatimentImmoQ, goBack, raisonSociale, isMicroEntreprise }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep+1) : 25);
    const { t, i18n } = useTranslation();


    const handleConfirm = () => {
        moveToStep('EmissionFactureSecteurPublic');
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);

    console.log('nbStep:', nbStep)





    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            Cette année, {raisonSociale} a versé (ou versera) des honoraires commissions, courtages, ristournes commerciales, vacations, gratifications et autres rémunérations à des tiers (ex: professionnel libéral ou expert) pour un montant supérieur à 1200€ / an pour un même bénéficiaire ?
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            This year, {raisonSociale} has paid (or will pay) fees, commissions, brokerage, commercial rebates, holidays, gratuities and other remuneration to third parties (e.g. independent professionals or experts) in an amount exceeding €1,200 / year for the same beneficiary?
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${versHonorairesComSup1200 === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setVersHonorairesComSup1200(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${versHonorairesComSup1200 === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setVersHonorairesComSup1200(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${versHonorairesComSup1200 === null && 'opacity-25'}`} disabled={versHonorairesComSup1200 === null} onClick={handleConfirm} >
                    {t('siret_page.button')}
                </button>
            </div>
        </div>
    );
};

export default VersHonorairesComSup1200;
