import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';


const ProdDetDechets5flux = ({ nbStep, isSCI, isComanyCommercial, nbCandtionMicroValide, prodDetDechets5flux, setProdDetDechets5flux, moveToStep, goBack, raisonSociale, isMicroEntreprise }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep+1) : 25);
    const { t, i18n } = useTranslation();
    const [filter, setFilter] = useState('');


    const handleConfirm = () => {
        if (isComanyCommercial) {
            moveToStep('prodDetenCollecteEliminDechetsQ')
        } else {
            moveToStep('ContractantPrestationSup');
        }
    };

    const options = [
        { key: 'COLLPRIVE', label: { fr: 'par un prestataire privé', en: 'by a private service provider' } },
        { key: 'COLLPUBLIC', label: { fr: 'par le service public des déchets', en: 'by the public waste service' } },
        { key: '*NA', label: { fr: '- aucun déchet produit -', en: '- no waste produced -' } },
    ];


    const displayedOptions = options.filter(option =>
        option.label.en.toLowerCase().includes(filter.toLowerCase()) ||
        option.label.fr.toLowerCase().includes(filter.toLowerCase())
    );

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);






    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            {raisonSociale} produit ou détient des déchets de papier, de métal, de plastique, de verre ou de bois qui sont collectés :
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            {raisonSociale} produces or holds paper, metal, plastic, glass or wood waste that is collected :
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[500px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4 overflow-y-auto max-h-80">
                    {displayedOptions.map((option, index) => (
                        <button
                            key={index}
                            className={`w-full p-4 text-left font-bold rounded-xl ${prodDetDechets5flux === option.key ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                            onClick={() => setProdDetDechets5flux(option.key)}
                        >
                            {i18n.language === 'fr' ? option.label.fr : option.label.en}
                        </button>
                    ))}
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${prodDetDechets5flux === '' && 'opacity-25'}`} disabled={prodDetDechets5flux === null} onClick={handleConfirm} >
                    {t('siret_page.button')}
                </button>
            </div>
        </div>
    );
};

export default ProdDetDechets5flux;
