import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { themePopOver, updateProgressColor } from '../../utils/utils';
import { Popover } from 'flowbite-react';

const PeriodiciteDeclarationCA = ({ nbStep, isSCI, moveToStep, nbCandtionMicroValide, goBack, raisonSociale, periodiciteDeclarationCA, isMicroEntreprise, setPeriodiciteDeclarationCA }) => {
    const [value, setValue] = useState(periodiciteDeclarationCA);
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? 30 : (nbStep >= 25) ? (nbStep + 1) : 25);
    const { t, i18n } = useTranslation();

    const [isValidated, setIsValidated] = useState(false);
    const handleConfirm = () => {
        setPeriodiciteDeclarationCA(value);
        moveToStep('revenuFiscalReference');
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);


    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] -mt-2'>

                <h1 className='text-2xl text-[#1e4690] my-3'>
                    {i18n.language === 'fr' &&
                        <>
                            {raisonSociale} a opté pour une déclaration de son chiffre d'affaires de manière :

                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            {raisonSociale} has opted to declare its turnover in the :
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${value === 'mensuelle' ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc]  hover:border hover:text-[#006acc]'}`}
                        onClick={() => setValue('mensuelle')}
                    >
                        {i18n.language === 'fr' ? "Mensuelle" : "monthly"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${value === 'Trimestrielle' ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc]  hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]'}`}
                        onClick={() => setValue('Trimestrielle')}
                    >
                        {i18n.language === 'fr' ? "Trimestrielle" : "Quarterly"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${value === null && 'opacity-25'}`} disabled={value === null} onClick={handleConfirm} >
                    {t('siret_page.button')}
                </button>
            </div>
        </div >
    );
};

export default PeriodiciteDeclarationCA;
