import React, { useRef, useState } from 'react';
import illustration_cahier from '../../assets/illustration_cahier.svg';
import illustration_dashboard from '../../assets/illustration_dashboard.svg';
import DownloadButton from './DownloadButton';

const ModalPayments = ({ isOpen, onClose, result }) => {
    const [activeTab, setActiveTab] = useState('compliance');
    const modalRef = useRef();

    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose();
        }
    };


    const ComplianceReport = () => (
        <div className="bg-white px-4 md:px-8 py-2 rounded-3xl">
            <div className='flex justify-center mb-4'>
                <div className='bg-[#F0F0F0] rounded-full p-8'>
                    <img src={illustration_cahier} alt='' className="w-16 h-16" />
                </div>
            </div>
            <h2 className="text-xl md:text-3xl font-semibold mb-2">Rapport de conformité</h2>
            <p className="mb-4">Téléchargez le rapport complet et détaillez sur vos obligations légales comprenant :</p>
            <ul className="space-y-2 mb-2">
                <li>✔️ La checklist de vos obligations</li>
                <li>✔️ Les conséquences de non-conformité</li>
                <li>✔️ L'indice de risque global d'exposition au risque de non-conformité</li>
                <li>✔️ Les solutions les plus pertinentes</li>
                <li>✔️ Les échéances, lorsqu'applicable</li>
            </ul>
            <DownloadButton result={result} />
        </div>
    );

    const PremiumOffer = () => (
        <div className="bg-[#161C67] text-white px-4 md:px-8 py-2 rounded-3xl ">
            <div className='flex justify-center mb-4'>
                <div className='bg-[#F0F0F0] rounded-full p-8'>
                    <img src={illustration_dashboard} alt='' className="w-16 h-16" />
                </div>
            </div>
            <h2 className="text-xl md:text-3xl font-semibold mb-2">Pack Expert</h2>
            <p className="mb-4">Accédez à 100 rapports de conformité comprenant chacun :</p>
            <ul className="space-y-2 mb-2">
                <li>✔️ La checklist des obligations de l'entreprise</li>
                <li>✔️ Les conséquences de non-conformité</li>
                <li>✔️ L'indice de risque global d'exposition au risque de non-conformité</li>
                <li>✔️ Les solutions les plus pertinentes</li>
                <li>✔️ Les échéances, lorsqu'applicable</li>
                <li>✔️ L'état de solvabilité de l'entreprise</li>
                <li>✔️ Un suivi en temps réel du taux de conformité</li>
            </ul>
            <p className="text-3xl md:text-4xl font-bold mb-3">980€ <span className='text-xl font-bold relative bottom-3'>HT</span></p>

            <div className="flex justify-center">
                <button className="bg-white font-bold text-[#161C67] px-16 py-2 rounded-full">Découvrir</button>
            </div>
        </div>
    );

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
            onClick={handleOverlayClick}
        >
            <div className="bg-white rounded-3xl shadow-lg w-full max-w-4xl relative  max-h-[95vh]"
                ref={modalRef}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="md:hidden flex border-b">
                    <button
                        className={`w-1/2 py-2 ${activeTab === 'compliance' ? 'bg-gray-200 border-b border-blue-600' : ''}`}
                        onClick={() => setActiveTab('compliance')}
                    >
                        Rapport de conformité
                    </button>
                    <button
                        className={`w-1/2 py-2 ${activeTab === 'premium' ? 'bg-gray-200 border-b border-blue-600' : ''}`}
                        onClick={() => setActiveTab('premium')}
                    >
                        Pack Expert
                    </button>
                </div>
                <div className="md:flex relative">
                    <div className={`w-full md:w-1/2 ${activeTab === 'compliance' ? '' : 'hidden md:block'}`}>
                        <ComplianceReport />
                    </div>
                    <div className={`w-full md:w-1/2 md:absolute md:right-0 md:-top-16 ${activeTab === 'premium' ? '' : 'hidden md:block'}`}>
                        <div className="h-full md:h-[calc(100%-20px)] bg-white rounded-3xl shadow-lg overflow-hidden">
                            <PremiumOffer />
                        </div>
                    </div>
                </div>
                <button
                    onClick={onClose}
                    className="absolute top-0 right-0 md:-top-16 font-bold mt-3 mr-4 text-[#161C67] md:text-white scale-125 hover:text-gray-800"
                >
                    ✕
                </button>
            </div>
        </div>
    );
};

export default ModalPayments;