import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';


const ProprioBatimentImmoQ = ({ isSCI, nbStep, moveToStep, nbCandtionMicroValide, goBack, raisonSociale, isMicroEntreprise, NAFgroupe, proprioBatimentImmoQ, setProprioBatimentImmoQ }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep+1) : 25);
    const { t, i18n } = useTranslation();
    const [filter, setFilter] = useState('');

    console.log("NAFgroupe: ", NAFgroupe)
    const handleConfirm = () => {
        if (isSCI) {
            moveToStep('EquipmentSelector');
        } else if (proprioBatimentImmoQ.length === 0 || (proprioBatimentImmoQ.length > 0 && proprioBatimentImmoQ.includes('*AO'))) {
            moveToStep('EquipmentSelector');
        } else {
            moveToStep('LoyerProprioBailleur')
        }
    }

    // Fonction pour ajouter/retirer une option sélectionnée
    const toggleSelectedValue = (key) => {
        setProprioBatimentImmoQ(prevState => {
            // Si on clique sur '*AO'
            if (key === "*AO") {
                // Si '*AO' est déjà sélectionné, on le désélectionne
                if (prevState.includes("*AO")) {
                    return [];
                }
                // Sinon, on sélectionne uniquement '*AO'
                return ["*AO"];
            }

            // Si on clique sur une autre option
            // Si '*AO' était sélectionné, on le retire et on sélectionne la nouvelle option
            if (prevState.includes("*AO")) {
                return [key];
            }

            // Si l'option cliquée était déjà sélectionnée, on la retire
            if (prevState.includes(key)) {
                return prevState.filter(item => item !== key);
            }

            // Sinon, on ajoute la nouvelle option
            return [...prevState, key];
        });
    };


    const options = [
        { key: 'AGRI', label: { fr: 'AGRICOLE', en: 'VAT basis franchise' } },
        { key: 'BURM100', label: { fr: 'de BUREAUX de moins de 100m2', en: 'Small offices < 100m2' } },
        { key: 'BURP100', label: { fr: 'de BUREAUX de plus de 100m2', en: 'Large offices > 100m2' } },
        { key: 'COMM400', label: { fr: 'COMMERCIAL de moins de 400m2', en: 'Commercial < 400m2' } },
        { key: 'COM400E2500', label: { fr: 'COMMERCIAL entre 400 et 2500m2', en: 'Commercial 400m2 to 2500m2' } },
        { key: 'COMP2500', label: { fr: 'COMMERCIAL de plus de 2500m2', en: 'Commercial > 2500m2' } },
        { key: 'HABIT', label: { fr: 'd\'HABITATION (maison, appartement, immeuble)', en: 'Residential (house, apartment, building)' } },
        { key: 'INDUSTRIAL', label: { fr: 'INDUSTRIEL', en: 'Industrial' } },
        { key: 'STOCKM5000', label: { fr: 'de STOCKAGE de moins de 5000m2', en: 'Storage < 5000m2' } },
        { key: 'STOCKP5000', label: { fr: 'de STOCKAGE de plus de 5000m2', en: 'Storage > 5000m2' } },
        { key: 'EXPOCONG', label: { fr: 'de parc d\'EXPOSITION et CONGRES', en: 'Exhibition and convention center' } },
        { key: 'PROLIB', label: { fr: 'PROFESSIONNEL, dans le cadre d\'une activité libérale', en: 'Professional, liberal activities' } },
        { key: 'PARKM500', label: { fr: 'de STATIONNEMENT de véhicule de moins de 500m2', en: 'Parking < 500m2' } },
        { key: 'PARKP500', label: { fr: 'de STATIONNEMENT de véhicule de plus de 500m2', en: 'Parking > 500m2' } },
        { key: '*AO', label: { fr: '- l\'entreprise n\'est pas propriétaire de bien immobilier -', en: 'Not a property owner' } }
    ];


    const displayedOptions = options.filter(option =>
        option.label.en.toLowerCase().includes(filter.toLowerCase()) ||
        option.label.fr.toLowerCase().includes(filter.toLowerCase())
    );

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);






    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            {raisonSociale} est propriétaire (en pleine propriété, copropriété ou indivision) de locaux à usage :
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            {raisonSociale} is the owner (freehold, co-ownership or joint ownership) of premises used for :
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[500px] p-4 rounded-3xl mb-8'>
                <div className="mb-4">
                    <input
                        type="text"
                        className="w-full p-3 border-2 border-gray-200 rounded-2xl"
                        placeholder={i18n.language === 'fr' ? "Titre ou mot-clé" : "Title or keyword"}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                </div>
                <div className="flex flex-col gap-4 overflow-y-auto max-h-80">
                    {displayedOptions.map((option, index) => (
                        <button
                            key={index}
                            className={`w-full p-4 text-left font-bold  rounded-xl ${proprioBatimentImmoQ.includes(option.key) ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc] '}`}
                            onClick={() => toggleSelectedValue(option.key)}
                        >
                            {i18n.language === 'fr' ? option.label.fr : option.label.en}
                        </button>
                    ))}
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${proprioBatimentImmoQ.length === 0 && 'opacity-25'}`} disabled={proprioBatimentImmoQ.length === 0} onClick={handleConfirm} >
                    {t('siret_page.button')}
                </button>
            </div>
        </div>
    );
};

export default ProprioBatimentImmoQ;
