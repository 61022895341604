export const updateProgressColor = (nb_step, maxValueQuestion, rangeRef) => {
    const percent = (nb_step / maxValueQuestion) * 100;
    if (rangeRef.current) {
        rangeRef.current.style.background = `linear-gradient(to right, #4CAF50 0%, #4CAF50 ${percent}%, #dbdbdb ${percent}%, #dbdbdb 100%)`;
    }
};

export const themePopOver = {
    "base": "absolute z-20 inline-block w-max max-w-[250px] bg-white outline-none border border-gray-200 rounded-lg shadow-sm dark:border-gray-600 dark:bg-gray-800",
    "content": "z-10 overflow-hidden rounded-[7px]",
    "arrow": {
        "base": "absolute h-2 w-2 z-0 rotate-45 mix-blend-lighten bg-white border border-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:mix-blend-color",
        "placement": "-4px"
    }
}