import React from 'react';

const RiskMeter = ({ riskLevel }) => {
    const calculateNeedlePosition = (riskLevel) => {
        // Convert risk level to angle, example calculation
        const maxAngle = 180; // Half circle
        return (riskLevel / 100) * maxAngle;
    };

    const needlePosition = calculateNeedlePosition(riskLevel);

    return (
        <div className="flex justify-center items-center">
            <span className='text-center'> Risque limité</span>
            <svg width="200" height="120" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
                {/* Background arc */}
                <path
                    d="M 10 90 A 80 80 0 0 1 190 90"
                    fill="none"
                    stroke="#ddd"
                    strokeWidth="20"
                />
                {/* Colored arc always showing full spectrum of risk */}
                <path
                    d="M 10 90 A 80 80 0 0 1 190 90"
                    fill="none"
                    stroke="url(#gradient)"
                    strokeWidth="18"
                />
                {/* Needle */}
                <g transform={`rotate(${needlePosition - 90} 100 90)`}>
                    <line x1="100" y1="90" x2="100" y2="0" stroke="white" strokeWidth="5" />
                </g>
                {/* Gradient definition */}
                <defs>
                    <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
                        <stop offset="0%" stopColor="#FFFF00" />   {/* Yellow */}
                        <stop offset="25%" stopColor="#FFD700" />  {/* Light Orange */}
                        <stop offset="50%" stopColor="#FFA500" />  {/* Orange */}
                        <stop offset="75%" stopColor="#FF6347" />  {/* Light Red */}
                        <stop offset="100%" stopColor="#FF4500" /> {/* Red */}
                    </linearGradient>
                </defs>
            </svg>
            <span className='text-center'> Risque élevé</span>
        </div>
    );
};

export default RiskMeter;
