import logo from "../assets/light.png";

const BientotDispo = () => {
  return (
    <div className="w-full h-screen bg-slate-100">
      <div className="flex flex-col space-y-10 justify-center items-center h-full w-full flex">
        <img src={logo} alt="logo" className="w-1/4" />
        <div className="flex flex-col items-center">
          <p>Oliver sera disponible prochainement !</p>
          <p>Pour toute question : <span className="font-semibold">contact@oliver.legal</span></p>

        </div>

      </div>
    </div>
  );
};

export default BientotDispo;
