import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import { AppContextProvider } from './context/AppContext';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>

    <BrowserRouter>
      <AppContextProvider>

        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </AppContextProvider>

    </BrowserRouter>
  </React.StrictMode>
);
