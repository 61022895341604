
export function getEnvConfig() {
    const hostname = window.location.hostname;
    if (hostname.includes('staging')) {
        return {
            apiUrl: 'https://staging.obligations.legal/',
            wsUrl: 'wss:/staging.obligations.legal/ws'
        };
    } else if (hostname.includes('localhost')) {
        return {
            apiUrl: 'http://localhost:8000/',
            wsUrl: 'ws:/localhost:8000/ws'
        };
    }
    else {
        return {
            apiUrl: 'https://mesobligations.legal/',
            wsUrl: 'wss:/mesobligations.legal/ws'
        };
    }
}
