import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { getEnvConfig } from '../../utils/config';


const InterventionEntrepriseExterieure = ({ nbStep, isSCI, moveToStep, nbCandtionMicroValide,
    setInterventionEntrepriseExterieure,
    goBack, raisonSociale,
    isMicroEntreprise,
    SIRET,
    codeNaf,
    NAFsection,
    catJuridiqueNiv1,
    catJuridiqueNiv2,
    catJuridiqueNiv3,

    chiffredAffairesTotalNetX,
    nbMoyenSalaries,
    regimeTVANormalSimplifieFranQ,
    optionImpotSocieteISQ,
    auMoins90pCAsoumisTVA,
    montantTVAdeclareSup15kQ,
    proprioBatimentImmoQ,
    loyerProprioBailleur,
    equipInstallationsSoumisVGPQ,
    classementLogementDPE,
    surfaceExploitationSup1000mC,
    traitementDonneesPerso,
    sousTraitantDonneesPersonnelles,
    collectionDonnesPerso,
    traitementListeAIPDoblig,
    criteresDesignationDPO,
    detentionSiteInternetQ,
    utilisationTraceurCookies,
    operateurPlateforme,
    prodDetDechets5flux,
    prodDetenCollecteEliminDechetsQ,
    etabliRecevantPublicERP,
    SiegeLocauxEnCommunX,
    dateDomiciliationLocauxCommun,
    cocontractantPrestationSup5k,
    versHonorairesComSup1200,
    emissionFactureSecteurPublicQ,
    datePremClotureX,
    bilan_totalBilanQ,
    totalCapitauxPropres,
    capitalSocial,
    societeFilialeMereQ,
    typeControlExerceSociete,
    depasSeuilConsoCA48mBi24mEff250Q,
    interventionConvReglSASSCASA,
    interventionConvReglSARL,
    natureConvRegleIntevenues,
    voitureVehiculeDeSocieteQ,
    versementPayeType,
    typeContratsTravail,
    expositionTravailleurs,
    nbSalarieExpositionPenibilite,
    controleRepoasHebdomadaire,
    interventionEntrepriseExterieure
}) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep) : 25);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const decomposeCodeNaf = (code) => {
        const parts = code.match(/^(\d{2})\.(\d{2})([A-Z])$/);
        if (!parts) return null;

        return {
            NAFdivision: parts[1],
            NAFgroupe: `${parts[1]}.${parts[2].charAt(0)}`,
            NAFclasse: `${parts[1]}.${parts[2]}`,
            NAFsousClasse: code
        };
    };

    const handleConfirm = async () => {
        setIsLoading(true);
        const postData = {
            scope: {
                types: ["Obligation"],
                categories: [
                    "Compliance", "Comptabilite - Gestion - Finances", "Environnement - Developpement durable",
                    "Fiscalite - Impots - Taxes", "Gouvernance - Engagements", "Ressources humaines",
                    "Santé de l'Homme et de son environnement de travail"
                ],
                laws: ["France", "Europe"],
                modules_only: false,
                module_id: null
            },
            variables: {
                SIRET: SIRET,
                catJuridiqueNiv1: catJuridiqueNiv1,
                catJuridiqueNiv2: catJuridiqueNiv2,
                catJuridiqueNiv3: catJuridiqueNiv3,
                NAFsection: NAFsection,
                ...decomposeCodeNaf(codeNaf),

                chiffredAffairesTotalNetX: Math.ceil(chiffredAffairesTotalNetX),
                nbMoyenSalaries_ExerciceN: Math.ceil(nbMoyenSalaries),
                regimeTVANormalSimplifieFranQ: regimeTVANormalSimplifieFranQ,
                optionImpotSocieteISQ: optionImpotSocieteISQ,
                auMoins90pCAsoumisTVA: auMoins90pCAsoumisTVA,
                montantTVAdeclareSup15kQ: montantTVAdeclareSup15kQ,
                proprioBatimentImmoQ: proprioBatimentImmoQ,
                loyerProprioBailleur: loyerProprioBailleur,
                equipInstallationsSoumisVGPQ: equipInstallationsSoumisVGPQ,
                classementLogementDPE: classementLogementDPE,
                surfaceExploitationSup1000mC: surfaceExploitationSup1000mC,
                traitementDonneesPerso: traitementDonneesPerso,
                sousTraitantDonneesPersonnelles: sousTraitantDonneesPersonnelles,
                collectionDonnesPerso: collectionDonnesPerso,
                traitementListeAIPDoblig: traitementListeAIPDoblig,
                criteresDesignationDPO: criteresDesignationDPO,
                detentionSiteInternetQ: detentionSiteInternetQ,
                utilisationTraceurCookies: utilisationTraceurCookies,
                operateurPlateforme: operateurPlateforme,
                prodDetDechets5flux: prodDetDechets5flux,
                prodDetenCollecteEliminDechetsQ: prodDetenCollecteEliminDechetsQ,
                etabliRecevantPublicERP: etabliRecevantPublicERP,
                SiegeLocauxEnCommunX: SiegeLocauxEnCommunX,
                dateDomiciliationLocauxCommun: dateDomiciliationLocauxCommun,
                cocontractantPrestationSup5k: cocontractantPrestationSup5k,
                versHonorairesComSup1200: versHonorairesComSup1200,
                emissionFactureSecteurPublicQ: emissionFactureSecteurPublicQ,
                // datePremClotureX: datePremClotureX,
                bilan_totalBilanQ: Math.ceil(bilan_totalBilanQ),
                totalCapitauxPropres: Math.ceil(totalCapitauxPropres),
                capitalSocial: Math.ceil(capitalSocial),
                societeFilialeMereQ: societeFilialeMereQ,
                typeControlExerceSociete: typeControlExerceSociete,
                depasSeuilConsoCA48mBi24mEff250Q: depasSeuilConsoCA48mBi24mEff250Q,
                interventionConvReglSASSCASA: interventionConvReglSASSCASA,
                interventionConvReglSARL: interventionConvReglSARL,
                natureConvRegleIntevenues: natureConvRegleIntevenues,
                voitureVehiculeDeSocieteQ: voitureVehiculeDeSocieteQ,
                // versementPayeType: versementPayeType,
                typeContratsTravail: typeContratsTravail,
                expositionTravailleurs: expositionTravailleurs,
                // nbSalarieExpositionPenibilite: Math.ceil(nbSalarieExpositionPenibilite),
                controleRepoasHebdomadaire: controleRepoasHebdomadaire,
                interventionEntrepriseExterieure: interventionEntrepriseExterieure
            }
        };
        console.log(postData);

        try {
            const { apiUrl } = getEnvConfig();
            console.log("apiUrl: ", apiUrl + 'run_simulator')
            const response = await fetch(apiUrl + 'run_simulator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(postData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result)
            if (result?.erros) {
                alert("Une erreur s'est produite lors du calcul des obligations.")
                navigate('/siret');
            }
            navigate('/workspace', { state: { result, raisonSociale } });

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            alert("Une erreur s'est produite lors du calcul des obligations.")
            navigate('/siret');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);

    const [isLoading, setIsLoading] = useState(false);

    const Loader = () => (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
    );





    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            {isLoading && <Loader />}
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            {raisonSociale} fait intervenir des travailleurs dans l'un de ses établissements, y compris dans ses dépendances ou chantiers, d'une entreprise extérieure pour exécuter ou participer à l'exécution d'une opération totalisant plus de 400 heures de travail sur une période inférieure ou égale à douze mois, ou à des travaux dangereux figurant dans l'arrêté du 10 mai 1994 ?
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            {raisonSociale} employs workers in one of its establishments, including its outbuildings or worksites, from an outside company to perform or participate in the performance of an operation totalling more than 400 working hours over a period of less than or equal to twelve months, or in dangerous work listed in the decree of 10 May 1994?
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${interventionEntrepriseExterieure === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setInterventionEntrepriseExterieure(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${interventionEntrepriseExterieure === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setInterventionEntrepriseExterieure(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${interventionEntrepriseExterieure === null && 'opacity-25'}`} disabled={interventionEntrepriseExterieure === null} onClick={handleConfirm} >
                    {t('revenu.button_save')}
                </button>
            </div>
        </div>
    );
};

export default InterventionEntrepriseExterieure;
