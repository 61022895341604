import React from 'react';
import { useTranslation } from 'react-i18next';

const FAQContactSection = () => {
    const { t } = useTranslation();

    return (
        <section className="py-12 bg-[#F7F7F7] px-4 md:px-12 pb-8 xl:px-12">
            <div className="container mx-auto flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-1/2 mb-8 md:mb-0 ps-6">
                    <h2 className="text-3xl font-bold text-[#001046] mb-6">FAQ</h2>
                    <div className="space-y-4">
                        <details className="cursor-pointer">
                            <summary className="text-[#00369A] font-semibold">{t('faq.question1')}</summary>
                            <p className="mt-2 text-sm text-gray-600">{t('faq.answer1')}</p>
                        </details>
                        <details className="cursor-pointer">
                            <summary className="text-[#00369A] font-semibold">{t('faq.question2')}</summary>
                            <p className="mt-2 text-sm text-gray-600">{t('faq.answer2')}</p>
                        </details>
                        <details className="cursor-pointer">
                            <summary className="text-[#00369A] font-semibold">{t('faq.question3')}</summary>
                            <p className="mt-2 text-sm text-gray-600">{t('faq.answer3')}</p>
                        </details>
                        <details className="cursor-pointer" >
                            <summary className="text-[#00369A] font-semibold">{t('faq.question4')}</summary>
                            <p className="mt-2 text-sm text-gray-600">{t('faq.answer4')}</p>
                        </details>
                        <details className="cursor-pointer" id='contact-section'>
                            <summary className="text-[#00369A] font-semibold">{t('faq.question5')}</summary>
                            <p className="mt-2 text-sm text-gray-600">{t('faq.answer5')}</p>
                        </details>
                        <details className="cursor-pointer">
                            <summary className="text-[#00369A] font-semibold">{t('faq.question6')}</summary>
                            <p className="mt-2 text-sm text-gray-600">{t('faq.answer6')}</p>
                        </details>
                        <details className="cursor-pointer">
                            <summary className="text-[#00369A] font-semibold">{t('faq.question7')}</summary>
                            <p className="mt-2 text-sm text-gray-600">{t('faq.answer7')}</p>
                        </details>
                    </div>
                </div>
                <div className="w-full md:w-1/2 md:pl-28 ps-2 pl-4">
                    <h2 className="text-3xl font-bold text-[#001046] mb-6" >{t('contactUs')}</h2>
                    <form className="space-y-4 pr-2">
                        <div className='w-full'>
                            <input type="text" id="name" placeholder={t('form.name')} name="name" className="mt-1 block rounded-full md:w-96 w-full border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-[#5FFE96] focus:ring-opacity-50" />
                        </div>
                        <div>
                            <input type="email" id="email" placeholder={t('form.email')} name="email" className="mt-1 block rounded-full md:w-96  w-full border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-[#5FFE96] focus:ring-opacity-50" />
                        </div>
                        <div>
                            <textarea id="message" name="message" placeholder={t('form.message')} rows="4" className="mt-1 block md:w-96  w-full rounded-2xl border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-[#5FFE96] focus:ring-opacity-50"></textarea>
                        </div>
                        <button type="submit" className="inline-flex justify-center py-2 px-8 border shadow-sm rounded-full text-[#5FFE96] border-[#5FFE96] font-bold bg-white hover:bg-[#5FFE96] hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5FFE96]">
                            {t('form.send')}
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default FAQContactSection;