import { useNavigate } from "react-router-dom";
import logo_legaware from "../assets/logo_legaware.png"

const Footer = () => {

    let navigate = useNavigate();
    return (<footer className="bg-[#1E4690] text-white py-8 rounded-ss-3xl xl:px-8 rounded-se-3xl">
        <div className="container mx-auto px-8">
            <div className="flex flex-col md:flex-row justify-between items-start">
                {/* Left side */}
                <div className="mb-6 md:mb-0">
                    <p className="text-lg">Un service proposé par</p>
                    <img src={logo_legaware} className="h-10" />
                    <nav className="mt-4">
                        <ul className="flex flex-col space-y-2">
                            <li><a href="#contact" className="text-sm hover:underline">Mentions légales</a></li>
                            <li><a href="#testimonials" className="text-sm hover:underline">Politique de confidentialité</a></li>
                            <li><a href="#terms" className="text-sm hover:underline">Qualité des personnes à déposer une offre</a></li>
                            <li><a href="#privacy" className="text-sm hover:underline">Conditions générales de ventes et d'utilisation</a></li>
                            <li><a href="#privacy" className="text-sm hover:underline">Modalités de référencement, déréférencement et de classement</a></li>
                            <li><a href="#privacy" className="text-sm hover:underline">Règles et recommandations concernant la rédaction d’une offre et d’un contenu</a></li>
                        </ul>
                    </nav>
                </div>

                {/* Right side - Partner Services */}
                <div>
                    <h3 className="text-lg font-semibold mb-4 cursor-pointer hover:underline" onClick={() => navigate('/experts')} >Services Partenaires</h3>
                    <ul className="space-y-2">
                        <li className="text-sm">
                            <a href="https://oliver.legal/" className="text-sm hover:underline" > Oliver - Votre assistant juridique boosté à l'IA</a>
                        </li>
                        <li className="text-sm">
                            <a href="https://mesformalites.legal/" className="text-sm hover:underline" > MesFormalités.legal - Le guichet qui vous accompagne dans vos formalités juridiques</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>)
}

export default Footer;