import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';


const NbSalarieExpositionPenibilite = ({ nbStep, isSCI, moveToStep, goBack, nbSalarieExpositionPenibilite, setNbSalarieExpositionPenibilite, nbCandtionMicroValide, raisonSociale, isMicroEntreprise }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep+1) : 25);
    const { t, i18n } = useTranslation();


    const handleConfirm = () => {
        moveToStep('controleRepoasHebdomadaire');
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);






    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-4 mt-6'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        `Nombre de salariés pour lesquels ${raisonSociale} déclare l’exposition à au moins un facteur de pénibilité :`
                    }

                    {i18n.language === 'en' &&
                        `Number of employees for whom ${raisonSociale} declares exposure to at least one arduous work factor:`
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex justify-center gap-4">
                    <div>
                        <style jsx>{`
        /* Chrome, Safari, Edge, Opera */
        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        
        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
        }
      `}</style>
                        <input type="number" className="w-full p-4 text-center font-bold rounded-xl bg-white text-[#006acc]" onChange={(e) => setNbSalarieExpositionPenibilite(e.target.value)} />
                    </div>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${nbSalarieExpositionPenibilite === '' && 'opacity-25'}`} disabled={nbSalarieExpositionPenibilite === ''} onClick={handleConfirm} >
                    {t('siret_page.button')}
                </button>
            </div>
        </div>
    );
};

export default NbSalarieExpositionPenibilite;
