import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';


const LocMeubleeOccasMoins10p = ({ nbStep, isSCI, setLocMeubleeOccasMoins10p, nbCandtionMicroValide, locMeubleeOccasMoins10p, locMeubleeNonMeubleeQ, moveToStep, goBack, raisonSociale, isMicroEntreprise, proprioBatimentImmoQ, NAFgroupe }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep+1) : 25);
    const { t, i18n } = useTranslation();


    const handleConfirm = () => {
        if (proprioBatimentImmoQ.includes("HABIT") && locMeubleeNonMeubleeQ.includes("MEUBLEE")) {
            moveToStep('locationMeubleDeTourismeQ');
        }
        else if (["55.1", "55.3"].includes(NAFgroupe)) {
            moveToStep('locHabitPersoProprioQ');
        } else {
            moveToStep('assujettissementLoyerProComTvaQ')
        }

    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);






    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            La location meublée constitue moins de 10% des recettes de {raisonSociale} ou est occasionnelle (de courte durée et pas chaque année) ?
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            Does the furnished rental represent less than 10% of the {raisonSociale} revenue or is it occasional (short term and not every year)?
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${locMeubleeOccasMoins10p === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setLocMeubleeOccasMoins10p(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${locMeubleeOccasMoins10p === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setLocMeubleeOccasMoins10p(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${locMeubleeOccasMoins10p === null && 'opacity-25'}`} disabled={locMeubleeOccasMoins10p === null} onClick={handleConfirm} >
                    {t('siret_page.button')}
                </button>
            </div>
        </div>
    );
};

export default LocMeubleeOccasMoins10p;
