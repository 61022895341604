import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { themePopOver, updateProgressColor } from '../../utils/utils';
import { Popover } from 'flowbite-react';

const VersementLiberatoireIR = ({ nbStep, isSCI, moveToStep, nbCandtionMicroValide, goBack, raisonSociale, isMicroEntreprise, versementLiberatoireIR, setVersementLiberatoireIR }) => {
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep+1) : 25);
    const rangeRef = useRef(null);
    const { t, i18n } = useTranslation();


    const handleConfirm = () => {
        moveToStep('SiegeLocauxEnCommunX');
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);



    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] -mt-2'>

                <h1 className='text-2xl text-center text-[#1e4690] my-3'>
                    {i18n.language === 'fr' &&
                        <>
                            {raisonSociale} a opté pour le{' '}
                            <Popover theme={themePopOver}
                                trigger="hover"
                                aria-labelledby="default-popover"
                                content={
                                    <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                                        <div className="px-3 py-2">
                                            <p>
                                                Le versement libératoire de l’impôt sur le revenu est un dispositif fiscal destiné aux micro-entrepreneurs. Il permet de payer l’impôt sur le revenu au fur et à mesure des encaissements en même temps que les cotisations sociales, de manière mensuelle ou trimestrielle.
                                            </p>
                                        </div>
                                    </div>
                                }
                            >
                                <a href="#" className="text-[#006acc] hover:text-[#006acc] focus:text-[#006acc]">
                                    versement libératoire de l'impôt sur le revenu
                                </a>
                            </Popover>{' '}
                            ?
                        </>

                    }

                    {i18n.language === 'en' &&

                        <>
                            {raisonSociale} has opted for{' '}
                            <Popover theme={themePopOver}
                                trigger="hover"
                                aria-labelledby="default-popover"
                                content={
                                    <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                                        <div className="px-3 py-2">
                                            <p>
                                                Payment in full discharge of income tax is a tax scheme for micro-entrepreneurs. It allows income tax to be paid as and when cash is received, at the same time as social security contributions, on a monthly or quarterly basis.
                                            </p>
                                        </div>
                                    </div>
                                }
                            >
                                <a href="#" className="text-[#006acc] hover:text-[#006acc] focus:text-[#006acc]">
                                    payment in full discharge of income tax
                                </a>
                            </Popover>
                            ?
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-6'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${versementLiberatoireIR === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc]  hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]'}`}
                        onClick={() => setVersementLiberatoireIR(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${versementLiberatoireIR === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc]  hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]'}`}
                        onClick={() => setVersementLiberatoireIR(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${versementLiberatoireIR === null && 'opacity-25'}`} disabled={versementLiberatoireIR === null} onClick={handleConfirm} >
                    {t('siret_page.button')}
                </button>
            </div>
        </div>
    );
};

export default VersementLiberatoireIR;
