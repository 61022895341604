import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { themePopOver, updateProgressColor } from '../../utils/utils';
import { Popover } from 'flowbite-react';

const EprSelector = ({ nbStep, isSCI, moveToStep, nbCandtionMicroValide, isComanyCommercial, goBack, raisonSociale, isMicroEntreprise, etabliRecevantPublicERP, setEtabliRecevantPublicERP }) => {
  const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep+1) : 25);
  const rangeRef = useRef(null);
  const { t, i18n } = useTranslation();


  const handleConfirm = () => {
    if (isComanyCommercial) {
      moveToStep('SiegeLocauxEnCommunX');
    } else {

      moveToStep('prodDetDechets5flux');
    }
  };

  useEffect(() => {
    updateProgressColor(nbStep, maxValueQuestion, rangeRef);
  }, []);



  return (
    <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
      <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


      <div className='max-w-[800px] -mt-2'>

        <h1 className='text-2xl text-center text-[#1e4690] my-3'>
          {i18n.language === 'fr' &&
            <>
              Hormis son personnel, {raisonSociale} reçoit des personnes du public et est qualifié d'
              <Popover theme={themePopOver}
                trigger="hover"
                aria-labelledby="default-popover"
                content={
                  <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                    <div className="px-3 py-2">
                      <p>
                        Les établissements recevant du public (ERP) sont des bâtiments, des locaux ou des enceintes dans lesquels sont admises des personnes extérieures.
                      </p>
                    </div>
                  </div>
                }
              >
                <a href="#" className="text-[#006acc] hover:text-[#006acc] focus:text-[#006acc]">
                  établissement recevant du public (ERP)
                </a>
              </Popover>{' '} ?
            </>

          }

          {i18n.language === 'en' &&

            <>
              Apart from its staff, {raisonSociale} receives people from the public and is described as a {' '}
              <Popover theme={themePopOver}
                trigger="hover"
                aria-labelledby="default-popover"
                content={
                  <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                    <div className="px-3 py-2">
                      <p>
                        Establishments open to the public (ERP) are buildings, premises or enclosures where outsiders are admitted.
                      </p>
                    </div>
                  </div>
                }
              >
                <a href="#" className="text-[#006acc] hover:text-[#006acc] focus:text-[#006acc]">
                  public access building
                </a>
              </Popover>{' '} ?
            </>
          }

        </h1>
      </div>

      <div className='w-full max-w-[350px] p-4 rounded-3xl mb-6'>
        <div className="flex flex-col gap-4">
          <button
            className={`w-full p-4 text-center hover:bg-[#f0f0f0] font-bold rounded-xl ${etabliRecevantPublicERP === 'OUI' ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
            onClick={() => setEtabliRecevantPublicERP('OUI')}
          >
            {i18n.language === 'fr' ? "Oui" : "Yes"}
          </button>
          <button
            className={`w-full p-4 text-center hover:bg-[#f0f0f0] font-bold rounded-xl ${etabliRecevantPublicERP === 'NON' ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
            onClick={() => setEtabliRecevantPublicERP('NON')}
          >
            {i18n.language === 'fr' ? "Non" : "No"}
          </button>
          <button
            className={`w-full p-4 text-center hover:bg-[#f0f0f0] font-bold rounded-xl ${etabliRecevantPublicERP === 'JECPAS' ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
            onClick={() => setEtabliRecevantPublicERP('JECPAS')}
          >
            {i18n.language === 'fr' ? "Je ne sais pas" : "I don't know"}
          </button>
        </div>
      </div>

      <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
        <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
          {t('revenu.button_back')}
        </button>
        <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${etabliRecevantPublicERP === null && 'opacity-25'}`} disabled={etabliRecevantPublicERP === null} onClick={handleConfirm} >
          {t('siret_page.button')}
        </button>
      </div>
    </div>
  );
};

export default EprSelector;
