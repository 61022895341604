import {format} from 'date-fns/format';
import {parseISO} from 'date-fns';

function format_date(original_date, formatString = 'dd/MM/yyyy') {
    try {
        const date = parseISO(original_date);
        return format(date, formatString);
    } catch (error) {
        return null;
    }
}

function get_nested_data(data, key_list, defaultValue = '') {
    let current_data = data;
    for (let key of key_list) {
        console.log(key);
        if (current_data[key] === undefined || current_data[key] === null) return defaultValue;
        current_data = current_data[key];
    }
    return current_data;
}

function format_address(adresse_dict) {
    if (!adresse_dict) {
        return '';
    }
    return `${adresse_dict.numVoie || ''} ${adresse_dict.typeVoie || ''} ${adresse_dict.voie || ''}, ${adresse_dict.codePostal || ''} ${adresse_dict.commune || ''}`.trim();
}

function capitalizeFirstName(name) {
    return name
        .split('-') // Séparer le prénom par les tirets
        .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()) // Mettre en majuscule la première lettre de chaque partie et le reste en minuscule
        .join('-'); // Rassembler les parties avec un tiret
}

function get_dirigeants(data) {
    const dirigeants = [];
    const pouvoirs = get_nested_data(data, ["formality", "content", "personneMorale", "composition", "pouvoirs"], []);
    for (let p of pouvoirs) {
        if (p.typeDePersonne === 'INDIVIDU') {
            const nom = get_nested_data(p, ["individu", "descriptionPersonne", "nom"], '');
            const prenoms = get_nested_data(p, ["individu", "descriptionPersonne", "prenoms"], []);
            const prenom = prenoms.length > 0 ? prenoms[0] : '';
            dirigeants.push(`${capitalizeFirstName(prenom)} ${nom.toUpperCase()}`);
        }
    }
    return dirigeants;
}

function formatInfos(data) {
    const is_personne_morale = get_nested_data(data, ["formality", "content", "personneMorale"], null) !== null;
    
    let r = "1. **Informations générales** : \n";
    // Address
    let key_list = is_personne_morale ? ["formality", "content", "personneMorale", "etablissementPrincipal", "adresse"]
                                      : ["formality", "content", "personnePhysique", "adresseEntreprise", "adresse"];
    let adresse_dict = get_nested_data(data, key_list);
    console.log(adresse_dict);
    let adresse = format_address(adresse_dict);
    r += adresse ? `\t- Adresse : ${adresse}\n` : '';

    // Code APE
    key_list = is_personne_morale ? ["formality", "content", "personneMorale", "identite", "entreprise", "codeApe"]
                                  : ["formality", "content", "personnePhysique", "identite", "entreprise", "codeApe"];
    const code_ape = get_nested_data(data, key_list);
    r += code_ape ? `\t- Code APE : ${code_ape} (${ape[code_ape]})\n` : '';

    // Main activity
    key_list = is_personne_morale ? ["formality", "content", "personneMorale", "etablissementPrincipal", "activites"]
                                  : ["formality", "content", "personnePhysique", "etablissementPrincipal", "activites"];
    const activite_principale = get_nested_data(data, key_list, [{}])[0].descriptionDetaillee || "";
    r += activite_principale ? `\t- Activité principale déclarée : ${activite_principale}\n` : '';

    // Creation date
    key_list = ["formality", "content", "natureCreation", "dateCreation"];
    let date_creation = get_nested_data(data, key_list);
    date_creation = format_date(date_creation);
    r += date_creation ? `\t- Date de création : ${date_creation}\n` : '';

    // First closure date
    if (is_personne_morale) {
        key_list = ["formality", "content", "personneMorale", "identite", "description", "datePremiereCloture"];
        let date_cloture = get_nested_data(data, key_list);
        date_cloture = format_date(date_cloture, 'dd/MM');
        r += date_cloture ? `\t- Date de clôture : ${date_cloture}\n` : '';

        const dirigeants = get_dirigeants(data);
        if (dirigeants.length) {
            r += `\t- ${dirigeants.length > 1 ? 'Dirigeants' : 'Dirigeant'} :\n`;
            dirigeants.forEach(d => {
                r += `\t\t- ${d}\n`;
            });
        }
    } else {
        key_list = ["formality", "content", "personnePhysique", "identite", "entrepreneur", "descriptionPersonne"];
        const dirigeant = get_nested_data(data, key_list);
        if (dirigeant) { 
            const dirigeantName = `${dirigeant.prenoms ? capitalizeFirstName(dirigeant.prenoms[0]) : ''} ${dirigeant.nom?.toUpperCase() || ''}`;
            r += `\t- dirigeant : ${dirigeantName}\n`;
        }
    }

    r += "\n2. **Informations Juridiques** : \n";
    // Siren
    key_list = ["formality", "siren"];
    const siren = get_nested_data(data, key_list);
    r += siren ? `\t- Siren : ${formatSIREN(siren)}\n` : '';

    // Siret
    key_list = is_personne_morale ? ["formality", "content", "personneMorale", "etablissementPrincipal", "descriptionEtablissement", "siret"]
                                  : ["formality", "content", "personnePhysique", "etablissementPrincipal", "descriptionEtablissement", "siret"];
    const siret = get_nested_data(data, key_list);
    r += siret ? `\t- Siret : ${formatSIRET(siret)}\n` : '';

    // Legal form
    key_list = ["formality", "content", "natureCreation", "formeJuridique"];
    const forme_juridique = get_nested_data(data, key_list);
    r += forme_juridique ? `\t- Forme juridique : ${f_j[forme_juridique]}\n` : '';

    // Capital
    if (is_personne_morale) {
        key_list = ["formality", "content", "personneMorale", "identite", "description", "montantCapital"];
        const capital_social = get_nested_data(data, key_list);
        r += capital_social ? `\t- Capital social : ${capital_social}€\n` : '';
    }

    return r;
}


// Function to format a SIREN number (9 digits) as "000 000 000"
function formatSIREN(siren) {
    // Ensure the input is treated as a string to apply regex
    const sirenAsString = String(siren);
    // Check if the input is exactly 9 digits
    if (sirenAsString.length === 9) {
        return sirenAsString.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
    } else {
        return "Invalid SIREN";
    }
}

// Function to format a SIRET number (14 digits) as "000 000 000 00000"
function formatSIRET(siret) {
    // Ensure the input is treated as a string to apply regex
    const siretAsString = String(siret);
    // Check if the input is exactly 14 digits
    if (siretAsString.length === 14) {
        return siretAsString.replace(/(\d{3})(\d{3})(\d{3})(\d{5})/, "$1 $2 $3 $4");
    } else {
        return "Invalid SIRET";
    }
}

export function getActivity(nafCode, lang) {
    // Trouver l'objet qui correspond au code NAF
    let activity = null;
    if (lang=== 'fr')
        activity = codes_naf_fr.find(code => code.id === nafCode);
    else 
        activity = codes_naf_en.find(code => code.id === nafCode);
    // Retourner le label si trouvé, sinon retourner une chaîne par défaut
    return activity ? activity.label : nafCode;
  }

const ape = {'0111Z': "Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses",
'0112Z': 'Culture du riz',
'0113Z': 'Culture de légumes, de melons, de racines et de tubercules',
'0114Z': 'Culture de la canne à sucre',
'0115Z': 'Culture du tabac',
'0116Z': 'Culture de plantes à fibres',
'0119Z': 'Autres cultures non permanentes',
'0121Z': 'Culture de la vigne',
'0122Z': 'Culture de fruits tropicaux et subtropicaux',
'0123Z': "Culture d'agrumes",
'0124Z': 'Culture de fruits à pépins et à noyau',
'0125Z': "Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque",
'0126Z': 'Culture de fruits oléagineux',
'0127Z': 'Culture de plantes à boissons',
'0128Z': 'Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques',
'0129Z': 'Autres cultures permanentes',
'0130Z': 'Reproduction de plantes',
'0141Z': 'Élevage de vaches laitières',
'0142Z': "Élevage d'autres bovins et de buffles",
'0143Z': "Élevage de chevaux et d'autres équidés",
'0144Z': "Élevage de chameaux et d'autres camélidés",
'0145Z': "Élevage d'ovins et de caprins",
'0146Z': 'Élevage de porcins',
'0147Z': 'Élevage de volailles',
'0149Z': "Élevage d'autres animaux",
'0150Z': 'Culture et élevage associés',
'0161Z': 'Activités de soutien aux cultures',
'0162Z': 'Activités de soutien à la production animale',
'0163Z': 'Traitement primaire des récoltes',
'0164Z': 'Traitement des semences',
'0170Z': 'Chasse, piégeage et services annexes',
'0210Z': 'Sylviculture et autres activités forestières',
'0220Z': 'Exploitation forestière',
'0230Z': "Récolte de produits forestiers non ligneux poussant à l'état sauvage",
'0240Z': "Services de soutien à l'exploitation forestière",
'0311Z': 'Pêche en mer',
'0312Z': 'Pêche en eau douce',
'0321Z': 'Aquaculture en mer',
'0322Z': 'Aquaculture en eau douce',
'0510Z': 'Extraction de houille',
'0520Z': 'Extraction de lignite',
'0610Z': 'Extraction de pétrole brut',
'0620Z': 'Extraction de gaz naturel',
'0710Z': 'Extraction de minerais de fer',
'0721Z': "Extraction de minerais d'uranium et de thorium",
'0729Z': "Extraction d'autres minerais de métaux non ferreux",
'0811Z': "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise",
'0812Z': "Exploitation de gravières et sablières, extraction d'argiles et de kaolin",
'0891Z': "Extraction des minéraux chimiques et d'engrais minéraux",
'0892Z': 'Extraction de tourbe',
'0893Z': 'Production de sel',
'0899Z': 'Autres activités extractives n.c.a.',
'0910Z': "Activités de soutien à l'extraction d'hydrocarbures",
'0990Z': 'Activités de soutien aux autres industries extractives',
'1011Z': 'Transformation et conservation de la viande de boucherie',
'1012Z': 'Transformation et conservation de la viande de volaille',
'1013A': 'Préparation industrielle de produits à base de viande',
'1013B': 'Charcuterie',
'1020Z': 'Transformation et conservation de poisson, de crustacés et de mollusques',
'1031Z': 'Transformation et conservation de pommes de terre',
'1032Z': 'Préparation de jus de fruits et légumes',
'1039A': 'Autre transformation et conservation de légumes',
'1039B': 'Transformation et conservation de fruits',
'1041A': "Fabrication d'huiles et graisses brutes",
'1041B': "Fabrication d'huiles et graisses raffinées",
'1042Z': 'Fabrication de margarine et graisses comestibles similaires',
'1051A': 'Fabrication de lait liquide et de produits frais',
'1051B': 'Fabrication de beurre',
'1051C': 'Fabrication de fromage',
'1051D': "Fabrication d'autres produits laitiers",
'1052Z': 'Fabrication de glaces et sorbets',
'1061A': 'Meunerie',
'1061B': 'Autres activités du travail des grains',
'1062Z': 'Fabrication de produits amylacés',
'1071A': 'Fabrication industrielle de pain et de pâtisserie fraîche',
'1071B': 'Cuisson de produits de boulangerie',
'1071C': 'Boulangerie et boulangerie-pâtisserie',
'1071D': 'Pâtisserie',
'1072Z': 'Fabrication de biscuits, biscottes et pâtisseries de conservation',
'1073Z': 'Fabrication de pâtes alimentaires',
'1081Z': 'Fabrication de sucre',
'1082Z': 'Fabrication de cacao, chocolat et de produits de confiserie',
'1083Z': 'Transformation du thé et du café',
'1084Z': 'Fabrication de condiments et assaisonnements',
'1085Z': 'Fabrication de plats préparés',
'1086Z': "Fabrication d'aliments homogénéisés et diététiques",
'1089Z': "Fabrication d'autres produits alimentaires n.c.a.",
'1091Z': "Fabrication d'aliments pour animaux de ferme",
'1092Z': "Fabrication d'aliments pour animaux de compagnie",
'1101Z': 'Production de boissons alcooliques distillées',
'1102A': 'Fabrication de vins effervescents',
'1102B': 'Vinification',
'1103Z': 'Fabrication de cidre et de vins de fruits',
'1104Z': "Production d'autres boissons fermentées non distillées",
'1105Z': 'Fabrication de bière',
'1106Z': 'Fabrication de malt',
'1107A': 'Industrie des eaux de table',
'1107B': 'Production de boissons rafraîchissantes',
'1200Z': 'Fabrication de produits à base de tabac',
'1310Z': 'Préparation de fibres textiles et filature',
'1320Z': 'Tissage',
'1330Z': 'Ennoblissement textile',
'1391Z': "Fabrication d'étoffes à mailles",
'1392Z': "Fabrication d'articles textiles, sauf habillement",
'1393Z': 'Fabrication de tapis et moquettes',
'1394Z': 'Fabrication de ficelles, cordes et filets',
'1395Z': 'Fabrication de non-tissés, sauf habillement',
'1396Z': "Fabrication d'autres textiles techniques et industriels",
'1399Z': "Fabrication d'autres textiles n.c.a.",
'1411Z': 'Fabrication de vêtements en cuir',
'1412Z': 'Fabrication de vêtements de travail',
'1413Z': 'Fabrication de vêtements de dessus',
'1414Z': 'Fabrication de vêtements de dessous',
'1419Z': "Fabrication d'autres vêtements et accessoires",
'1420Z': "Fabrication d'articles en fourrure",
'1431Z': "Fabrication d'articles chaussants à mailles",
'1439Z': "Fabrication d'autres articles à mailles",
'1511Z': 'Apprêt et tannage des cuirs ; préparation et teinture des fourrures',
'1512Z': "Fabrication d'articles de voyage, de maroquinerie et de sellerie",
'1520Z': 'Fabrication de chaussures',
'1610A': 'Sciage et rabotage du bois, hors imprégnation',
'1610B': 'Imprégnation du bois',
'1621Z': 'Fabrication de placage et de panneaux de bois',
'1622Z': 'Fabrication de parquets assemblés',
'1623Z': "Fabrication de charpentes et d'autres menuiseries",
'1624Z': "Fabrication d'emballages en bois",
'1629Z': "Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie",
'1711Z': 'Fabrication de pâte à papier',
'1712Z': 'Fabrication de papier et de carton',
'1721A': 'Fabrication de carton ondulé',
'1721B': 'Fabrication de cartonnages',
'1721C': "Fabrication d'emballages en papier",
'1722Z': "Fabrication d'articles en papier à usage sanitaire ou domestique",
'1723Z': "Fabrication d'articles de papeterie",
'1724Z': 'Fabrication de papiers peints',
'1729Z': "Fabrication d'autres articles en papier ou en carton",
'1811Z': 'Imprimerie de journaux',
'1812Z': 'Autre imprimerie (labeur)',
'1813Z': 'Activités de pré-presse',
'1814Z': 'Reliure et activités connexes',
'1820Z': "Reproduction d'enregistrements",
'1910Z': 'Cokéfaction',
'1920Z': 'Raffinage du pétrole',
'2011Z': 'Fabrication de gaz industriels',
'2012Z': 'Fabrication de colorants et de pigments',
'2013A': 'Enrichissement et retraitement de matières nucléaires',
'2013B': "Fabrication d'autres produits chimiques inorganiques de base n.c.a.",
'2014Z': "Fabrication d'autres produits chimiques organiques de base",
'2015Z': "Fabrication de produits azotés et d'engrais",
'2016Z': 'Fabrication de matières plastiques de base',
'2017Z': 'Fabrication de caoutchouc synthétique',
'2020Z': "Fabrication de pesticides et d'autres produits agrochimiques",
'2030Z': 'Fabrication de peintures, vernis, encres et mastics',
'2041Z': "Fabrication de savons, détergents et produits d'entretien",
'2042Z': 'Fabrication de parfums et de produits pour la toilette',
'2051Z': 'Fabrication de produits explosifs',
'2052Z': 'Fabrication de colles',
'2053Z': "Fabrication d'huiles essentielles",
'2059Z': "Fabrication d'autres produits chimiques n.c.a.",
'2060Z': 'Fabrication de fibres artificielles ou synthétiques',
'2110Z': 'Fabrication de produits pharmaceutiques de base',
'2120Z': 'Fabrication de préparations pharmaceutiques',
'2211Z': 'Fabrication et rechapage de pneumatiques',
'2219Z': "Fabrication d'autres articles en caoutchouc",
'2221Z': 'Fabrication de plaques, feuilles, tubes et profilés en matières plastiques',
'2222Z': "Fabrication d'emballages en matières plastiques",
'2223Z': "Fabrication d'éléments en matières plastiques pour la construction",
'2229A': 'Fabrication de pièces techniques à base de matières plastiques',
'2229B': 'Fabrication de produits de consommation courante en matières plastiques',
'2311Z': 'Fabrication de verre plat',
'2312Z': 'Façonnage et transformation du verre plat',
'2313Z': 'Fabrication de verre creux',
'2314Z': 'Fabrication de fibres de verre',
'2319Z': "Fabrication et façonnage d'autres articles en verre, y compris verre technique",
'2320Z': 'Fabrication de produits réfractaires',
'2331Z': 'Fabrication de carreaux en céramique',
'2332Z': 'Fabrication de briques, tuiles et produits de construction, en terre cuite',
'2341Z': "Fabrication d'articles céramiques à usage domestique ou ornemental",
'2342Z': "Fabrication d'appareils sanitaires en céramique",
'2343Z': "Fabrication d'isolateurs et pièces isolantes en céramique",
'2344Z': "Fabrication d'autres produits céramiques à usage technique",
'2349Z': "Fabrication d'autres produits céramiques",
'2351Z': 'Fabrication de ciment',
'2352Z': 'Fabrication de chaux et plâtre',
'2361Z': "Fabrication d'éléments en béton pour la construction",
'2362Z': "Fabrication d'éléments en plâtre pour la construction",
'2363Z': "Fabrication de béton prêt à l'emploi",
'2364Z': 'Fabrication de mortiers et bétons secs',
'2365Z': "Fabrication d'ouvrages en fibre-ciment",
'2369Z': "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre",
'2370Z': 'Taille, façonnage et finissage de pierres',
'2391Z': 'Fabrication de produits abrasifs',
'2399Z': "Fabrication d'autres produits minéraux non métalliques n.c.a.",
'2410Z': 'Sidérurgie',
'2420Z': 'Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier',
'2431Z': 'Étirage à froid de barres',
'2432Z': 'Laminage à froid de feuillards',
'2433Z': 'Profilage à froid par formage ou pliage',
'2434Z': 'Tréfilage à froid',
'2441Z': 'Production de métaux précieux',
'2442Z': "Métallurgie de l'aluminium",
'2443Z': "Métallurgie du plomb, du zinc ou de l'étain",
'2444Z': 'Métallurgie du cuivre',
'2445Z': 'Métallurgie des autres métaux non ferreux',
'2446Z': 'Élaboration et transformation de matières nucléaires',
'2451Z': 'Fonderie de fonte',
'2452Z': "Fonderie d'acier",
'2453Z': 'Fonderie de métaux légers',
'2454Z': "Fonderie d'autres métaux non ferreux",
'2511Z': 'Fabrication de structures métalliques et de parties de structures',
'2512Z': 'Fabrication de portes et fenêtres en métal',
'2521Z': 'Fabrication de radiateurs et de chaudières pour le chauffage central',
'2529Z': "Fabrication d'autres réservoirs, citernes et conteneurs métalliques",
'2530Z': "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central",
'2540Z': "Fabrication d'armes et de munitions",
'2550A': 'Forge, estampage, matriçage ; métallurgie des poudres',
'2550B': 'Découpage, emboutissage',
'2561Z': 'Traitement et revêtement des métaux',
'2562A': 'Décolletage',
'2562B': 'Mécanique industrielle',
'2571Z': 'Fabrication de coutellerie',
'2572Z': 'Fabrication de serrures et de ferrures',
'2573A': 'Fabrication de moules et modèles',
'2573B': "Fabrication d'autres outillages",
'2591Z': 'Fabrication de fûts et emballages métalliques similaires',
'2592Z': "Fabrication d'emballages métalliques légers",
'2593Z': "Fabrication d'articles en fils métalliques, de chaînes et de ressorts",
'2594Z': 'Fabrication de vis et de boulons',
'2599A': "Fabrication d'articles métalliques ménagers",
'2599B': "Fabrication d'autres articles métalliques",
'2611Z': 'Fabrication de composants électroniques',
'2612Z': 'Fabrication de cartes électroniques assemblées',
'2620Z': "Fabrication d'ordinateurs et d'équipements périphériques",
'2630Z': "Fabrication d'équipements de communication",
'2640Z': 'Fabrication de produits électroniques grand public',
'2651A': "Fabrication d'équipements d'aide à la navigation",
'2651B': "Fabrication d'instrumentation scientifique et technique",
'2652Z': 'Horlogerie',
'2660Z': "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques",
'2670Z': 'Fabrication de matériels optique et photographique',
'2680Z': 'Fabrication de supports magnétiques et optiques',
'2711Z': 'Fabrication de moteurs, génératrices et transformateurs électriques',
'2712Z': 'Fabrication de matériel de distribution et de commande électrique',
'2720Z': "Fabrication de piles et d'accumulateurs électriques",
'2731Z': 'Fabrication de câbles de fibres optiques',
'2732Z': "Fabrication d'autres fils et câbles électroniques ou électriques",
'2733Z': "Fabrication de matériel d'installation électrique",
'2740Z': "Fabrication d'appareils d'éclairage électrique",
'2751Z': "Fabrication d'appareils électroménagers",
'2752Z': "Fabrication d'appareils ménagers non électriques",
'2790Z': "Fabrication d'autres matériels électriques",
'2811Z': "Fabrication de moteurs et turbines, à l'exception des moteurs d'avions et de véhicules",
'2812Z': "Fabrication d'équipements hydrauliques et pneumatiques",
'2813Z': "Fabrication d'autres pompes et compresseurs",
'2814Z': "Fabrication d'autres articles de robinetterie",
'2815Z': "Fabrication d'engrenages et d'organes mécaniques de transmission",
'2821Z': 'Fabrication de fours et brûleurs',
'2822Z': 'Fabrication de matériel de levage et de manutention',
'2823Z': "Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)",
'2824Z': "Fabrication d'outillage portatif à moteur incorporé",
'2825Z': "Fabrication d'équipements aérauliques et frigorifiques industriels",
'2829A': "Fabrication d'équipements d'emballage, de conditionnement et de pesage",
'2829B': "Fabrication d'autres machines d'usage général",
'2830Z': 'Fabrication de machines agricoles et forestières',
'2841Z': 'Fabrication de machines-outils pour le travail des métaux',
'2849Z': "Fabrication d'autres machines-outils",
'2891Z': 'Fabrication de machines pour la métallurgie',
'2892Z': "Fabrication de machines pour l'extraction ou la construction",
'2893Z': "Fabrication de machines pour l'industrie agro-alimentaire",
'2894Z': 'Fabrication de machines pour les industries textiles',
'2895Z': 'Fabrication de machines pour les industries du papier et du carton',
'2896Z': 'Fabrication de machines pour le travail du caoutchouc ou des plastiques',
'2899A': "Fabrication de machines d'imprimerie",
'2899B': "Fabrication d'autres machines spécialisées",
'2910Z': 'Construction de véhicules automobiles',
'2920Z': 'Fabrication de carrosseries et remorques',
'2931Z': "Fabrication d'équipements électriques et électroniques automobiles",
'2932Z': "Fabrication d'autres équipements automobiles",
'3011Z': 'Construction de navires et de structures flottantes',
'3012Z': 'Construction de bateaux de plaisance',
'3020Z': "Construction de locomotives et d'autre matériel ferroviaire roulant",
'3030Z': 'Construction aéronautique et spatiale',
'3040Z': 'Construction de véhicules militaires de combat',
'3091Z': 'Fabrication de motocycles',
'3092Z': 'Fabrication de bicyclettes et de véhicules pour invalides',
'3099Z': "Fabrication d'autres équipements de transport n.c.a.",
'3101Z': 'Fabrication de meubles de bureau et de magasin',
'3102Z': 'Fabrication de meubles de cuisine',
'3103Z': 'Fabrication de matelas',
'3109A': "Fabrication de sièges d'ameublement d'intérieur",
'3109B': "Fabrication d'autres meubles et industries connexes de l'ameublement",
'3211Z': 'Frappe de monnaie',
'3212Z': "Fabrication d'articles de joaillerie et bijouterie",
'3213Z': "Fabrication d'articles de bijouterie fantaisie et articles similaires",
'3220Z': "Fabrication d'instruments de musique",
'3230Z': "Fabrication d'articles de sport",
'3240Z': 'Fabrication de jeux et jouets',
'3250A': 'Fabrication de matériel médico-chirurgical et dentaire',
'3250B': 'Fabrication de lunettes',
'3291Z': "Fabrication d'articles de brosserie",
'3299Z': 'Autres activités manufacturières n.c.a.',
'3311Z': "Réparation d'ouvrages en métaux",
'3312Z': 'Réparation de machines et équipements mécaniques',
'3313Z': 'Réparation de matériels électroniques et optiques',
'3314Z': "Réparation d'équipements électriques",
'3315Z': 'Réparation et maintenance navale',
'3316Z': "Réparation et maintenance d'aéronefs et d'engins spatiaux",
'3317Z': "Réparation et maintenance d'autres équipements de transport",
'3319Z': "Réparation d'autres équipements",
'3320A': 'Installation de structures métalliques, chaudronnées et de tuyauterie',
'3320B': 'Installation de machines et équipements mécaniques',
'3320C': "Conception d'ensemble et assemblage sur site industriel d'équipements de contrôle des processus industriels",
'3320D': "Installation d'équipements électriques, de matériels électroniques et optiques ou d'autres matériels",
'3511Z': "Production d'électricité",
'3512Z': "Transport d'électricité",
'3513Z': "Distribution d'électricité",
'3514Z': "Commerce d'électricité",
'3521Z': 'Production de combustibles gazeux',
'3522Z': 'Distribution de combustibles gazeux par conduites',
'3523Z': 'Commerce de combustibles gazeux par conduites',
'3530Z': "Production et distribution de vapeur et d'air conditionné",
'3600Z': "Captage, traitement et distribution d'eau",
'3700Z': 'Collecte et traitement des eaux usées',
'3811Z': 'Collecte des déchets non dangereux',
'3812Z': 'Collecte des déchets dangereux',
'3821Z': 'Traitement et élimination des déchets non dangereux',
'3822Z': 'Traitement et élimination des déchets dangereux',
'3831Z': "Démantèlement d'épaves",
'3832Z': 'Récupération de déchets triés',
'3900Z': 'Dépollution et autres services de gestion des déchets',
'4110A': 'Promotion immobilière de logements',
'4110B': 'Promotion immobilière de bureaux',
'4110C': "Promotion immobilière d'autres bâtiments",
'4110D': 'Supports juridiques de programmes',
'4120A': 'Construction de maisons individuelles',
'4120B': "Construction d'autres bâtiments",
'4211Z': 'Construction de routes et autoroutes',
'4212Z': 'Construction de voies ferrées de surface et souterraines',
'4213A': "Construction d'ouvrages d'art",
'4213B': 'Construction et entretien de tunnels',
'4221Z': 'Construction de réseaux pour fluides',
'4222Z': 'Construction de réseaux électriques et de télécommunications',
'4291Z': "Construction d'ouvrages maritimes et fluviaux",
'4299Z': "Construction d'autres ouvrages de génie civil n.c.a.",
'4311Z': 'Travaux de démolition',
'4312A': 'Travaux de terrassement courants et travaux préparatoires',
'4312B': 'Travaux de terrassement spécialisés ou de grande masse',
'4313Z': 'Forages et sondages',
'4321A': "Travaux d'installation électrique dans tous locaux",
'4321B': "Travaux d'installation électrique sur la voie publique",
'4322A': "Travaux d'installation d'eau et de gaz en tous locaux",
'4322B': "Travaux d'installation d'équipements thermiques et de climatisation",
'4329A': "Travaux d'isolation",
'4329B': "Autres travaux d'installation n.c.a.",
'4331Z': 'Travaux de plâtrerie',
'4332A': 'Travaux de menuiserie bois et PVC',
'4332B': 'Travaux de menuiserie métallique et serrurerie',
'4332C': 'Agencement de lieux de vente',
'4333Z': 'Travaux de revêtement des sols et des murs',
'4334Z': 'Travaux de peinture et vitrerie',
'4339Z': 'Autres travaux de finition',
'4391A': 'Travaux de charpente',
'4391B': 'Travaux de couverture par éléments',
'4399A': "Travaux d'étanchéification",
'4399B': 'Travaux de montage de structures métalliques',
'4399C': 'Travaux de maçonnerie générale et gros œuvre de bâtiment',
'4399D': 'Autres travaux spécialisés de construction',
'4399E': 'Location avec opérateur de matériel de construction',
'4511Z': 'Commerce de voitures et de véhicules automobiles légers',
'4519Z': "Commerce d'autres véhicules automobiles",
'4520A': 'Entretien et réparation de véhicules automobiles légers',
'4520B': "Entretien et réparation d'autres véhicules automobiles",
'4531Z': "Commerce de gros d'équipements automobiles",
'4532Z': "Commerce de détail d'équipements automobiles",
'4540Z': 'Commerce et réparation de motocycles',
'4611Z': 'Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis',
'4612A': "Centrales d'achat de carburant",
'4612B': 'Autres intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques',
'4613Z': 'Intermédiaires du commerce en bois et matériaux de construction',
'4614Z': 'Intermédiaires du commerce en machines, équipements industriels, navires et avions',
'4615Z': 'Intermédiaires du commerce en meubles, articles de ménage et quincaillerie',
'4616Z': 'Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir',
'4617A': "Centrales d'achat alimentaires",
'4617B': 'Autres intermédiaires du commerce en denrées, boissons et tabac',
'4618Z': "Intermédiaires spécialisés dans le commerce d'autres produits spécifiques",
'4619A': "Centrales d'achat non alimentaires",
'4619B': 'Autres intermédiaires du commerce en produits divers',
'4621Z': "Commerce de gros (commerce interentreprises) de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail",
'4622Z': 'Commerce de gros (commerce interentreprises) de fleurs et plantes',
'4623Z': "Commerce de gros (commerce interentreprises) d'animaux vivants",
'4624Z': 'Commerce de gros (commerce interentreprises) de cuirs et peaux',
'4631Z': 'Commerce de gros (commerce interentreprises) de fruits et légumes',
'4632A': 'Commerce de gros (commerce interentreprises) de viandes de boucherie',
'4632B': 'Commerce de gros (commerce interentreprises) de produits à base de viande',
'4632C': 'Commerce de gros (commerce interentreprises) de volailles et gibier',
'4633Z': 'Commerce de gros (commerce interentreprises) de produits laitiers, œufs, huiles et matières grasses comestibles',
'4634Z': 'Commerce de gros (commerce interentreprises) de boissons',
'4635Z': 'Commerce de gros (commerce interentreprises) de produits à base de tabac',
'4636Z': 'Commerce de gros (commerce interentreprises) de sucre, chocolat et confiserie',
'4637Z': 'Commerce de gros (commerce interentreprises) de café, thé, cacao et épices',
'4638A': 'Commerce de gros (commerce interentreprises) de poissons, crustacés et mollusques',
'4638B': 'Commerce de gros (commerce interentreprises) alimentaire spécialisé divers',
'4639A': 'Commerce de gros (commerce interentreprises) de produits surgelés',
'4639B': 'Commerce de gros (commerce interentreprises) alimentaire non spécialisé',
'4641Z': 'Commerce de gros (commerce interentreprises) de textiles',
'4642Z': "Commerce de gros (commerce interentreprises) d'habillement et de chaussures",
'4643Z': "Commerce de gros (commerce interentreprises) d'appareils électroménagers",
'4644Z': "Commerce de gros (commerce interentreprises) de vaisselle, verrerie et produits d'entretien",
'4645Z': 'Commerce de gros (commerce interentreprises) de parfumerie et de produits de beauté',
'4646Z': 'Commerce de gros (commerce interentreprises) de produits pharmaceutiques',
'4647Z': "Commerce de gros (commerce interentreprises) de meubles, de tapis et d'appareils d'éclairage",
'4648Z': "Commerce de gros (commerce interentreprises) d'articles d'horlogerie et de bijouterie",
'4649Z': "Commerce de gros (commerce interentreprises) d'autres biens domestiques",
'4651Z': "Commerce de gros (commerce interentreprises) d'ordinateurs, d'équipements informatiques périphériques et de logiciels",
'4652Z': "Commerce de gros (commerce interentreprises) de composants et d'équipements électroniques et de télécommunication",
'4661Z': 'Commerce de gros (commerce interentreprises) de matériel agricole',
'4662Z': 'Commerce de gros (commerce interentreprises) de machines-outils',
'4663Z': "Commerce de gros (commerce interentreprises) de machines pour l'extraction, la construction et le génie civil",
'4664Z': "Commerce de gros (commerce interentreprises) de machines pour l'industrie textile et l'habillement",
'4665Z': 'Commerce de gros (commerce interentreprises) de mobilier de bureau',
'4666Z': "Commerce de gros (commerce interentreprises) d'autres machines et équipements de bureau",
'4669A': 'Commerce de gros (commerce interentreprises) de matériel électrique',
'4669B': 'Commerce de gros (commerce interentreprises) de fournitures et équipements industriels divers',
'4669C': 'Commerce de gros (commerce interentreprises) de fournitures et équipements divers pour le commerce et les services',
'4671Z': 'Commerce de gros (commerce interentreprises) de combustibles et de produits annexes',
'4672Z': 'Commerce de gros (commerce interentreprises) de minerais et métaux',
'4673A': 'Commerce de gros (commerce interentreprises) de bois et de matériaux de construction',
'4673B': "Commerce de gros (commerce interentreprises) d'appareils sanitaires et de produits de décoration",
'4674A': 'Commerce de gros (commerce interentreprises) de quincaillerie',
'4674B': 'Commerce de gros (commerce interentreprises) de fournitures pour la plomberie et le chauffage',
'4675Z': 'Commerce de gros (commerce interentreprises) de produits chimiques',
'4676Z': "Commerce de gros (commerce interentreprises) d'autres produits intermédiaires",
'4677Z': 'Commerce de gros (commerce interentreprises) de déchets et débris',
'4690Z': 'Commerce de gros (commerce interentreprises) non spécialisé',
'4711A': 'Commerce de détail de produits surgelés',
'4711B': "Commerce d'alimentation générale",
'4711C': 'Supérettes',
'4711D': 'Supermarchés',
'4711E': 'Magasins multi-commerces',
'4711F': 'Hypermarchés',
'4719A': 'Grands magasins',
'4719B': 'Autres commerces de détail en magasin non spécialisé',
'4721Z': 'Commerce de détail de fruits et légumes en magasin spécialisé',
'4722Z': 'Commerce de détail de viandes et de produits à base de viande en magasin spécialisé',
'4723Z': 'Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé',
'4724Z': 'Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé',
'4725Z': 'Commerce de détail de boissons en magasin spécialisé',
'4726Z': 'Commerce de détail de produits à base de tabac en magasin spécialisé',
'4729Z': 'Autres commerces de détail alimentaires en magasin spécialisé',
'4730Z': 'Commerce de détail de carburants en magasin spécialisé',
'4741Z': "Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé",
'4742Z': 'Commerce de détail de matériels de télécommunication en magasin spécialisé',
'4743Z': 'Commerce de détail de matériels audio et vidéo en magasin spécialisé',
'4751Z': 'Commerce de détail de textiles en magasin spécialisé',
'4752A': 'Commerce de détail de quincaillerie, peintures et verres en petites surfaces (moins de 400 m²)',
'4752B': 'Commerce de détail de quincaillerie, peintures et verres en grandes surfaces (400 m² et plus)',
'4753Z': 'Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé',
'4754Z': "Commerce de détail d'appareils électroménagers en magasin spécialisé",
'4759A': 'Commerce de détail de meubles',
'4759B': "Commerce de détail d'autres équipements du foyer",
'4761Z': 'Commerce de détail de livres en magasin spécialisé',
'4762Z': 'Commerce de détail de journaux et papeterie en magasin spécialisé',
'4763Z': "Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé",
'4764Z': "Commerce de détail d'articles de sport en magasin spécialisé",
'4765Z': 'Commerce de détail de jeux et jouets en magasin spécialisé',
'4771Z': "Commerce de détail d'habillement en magasin spécialisé",
'4772A': 'Commerce de détail de la chaussure',
'4772B': "Commerce de détail de maroquinerie et d'articles de voyage",
'4773Z': 'Commerce de détail de produits pharmaceutiques en magasin spécialisé',
'4774Z': "Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé",
'4775Z': 'Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé',
'4776Z': 'Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé',
'4777Z': "Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé",
'4778A': "Commerces de détail d'optique",
'4778B': 'Commerces de détail de charbons et combustibles',
'4778C': 'Autres commerces de détail spécialisés divers',
'4779Z': "Commerce de détail de biens d'occasion en magasin",
'4781Z': 'Commerce de détail alimentaire sur éventaires et marchés',
'4782Z': "Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés",
'4789Z': 'Autres commerces de détail sur éventaires et marchés',
'4791A': 'Vente à distance sur catalogue général',
'4791B': 'Vente à distance sur catalogue spécialisé',
'4799A': 'Vente à domicile',
'4799B': 'Vente par automates et autres commerces de détail hors magasin, éventaires ou marchés n.c.a.',
'4910Z': 'Transport ferroviaire interurbain de voyageurs',
'4920Z': 'Transports ferroviaires de fret',
'4931Z': 'Transports urbains et suburbains de voyageurs',
'4932Z': 'Transports de voyageurs par taxis',
'4939A': 'Transports routiers réguliers de voyageurs',
'4939B': 'Autres transports routiers de voyageurs',
'4939C': 'Téléphériques et remontées mécaniques',
'4941A': 'Transports routiers de fret interurbains',
'4941B': 'Transports routiers de fret de proximité',
'4941C': 'Location de camions avec chauffeur',
'4942Z': 'Services de déménagement',
'4950Z': 'Transports par conduites',
'5010Z': 'Transports maritimes et côtiers de passagers',
'5020Z': 'Transports maritimes et côtiers de fret',
'5030Z': 'Transports fluviaux de passagers',
'5040Z': 'Transports fluviaux de fret',
'5110Z': 'Transports aériens de passagers',
'5121Z': 'Transports aériens de fret',
'5122Z': 'Transports spatiaux',
'5210A': 'Entreposage et stockage frigorifique',
'5210B': 'Entreposage et stockage non frigorifique',
'5221Z': 'Services auxiliaires des transports terrestres',
'5222Z': 'Services auxiliaires des transports par eau',
'5223Z': 'Services auxiliaires des transports aériens',
'5224A': 'Manutention portuaire',
'5224B': 'Manutention non portuaire',
'5229A': 'Messagerie, fret express',
'5229B': 'Affrètement et organisation des transports',
'5310Z': "Activités de poste dans le cadre d'une obligation de service universel",
'5320Z': 'Autres activités de poste et de courrier',
'5510Z': 'Hôtels et hébergement similaire',
'5520Z': 'Hébergement touristique et autre hébergement de courte durée',
'5530Z': 'Terrains de camping et parcs pour caravanes ou véhicules de loisirs',
'5590Z': 'Autres hébergements',
'5610A': 'Restauration traditionnelle',
'5610B': 'Cafétérias et autres libres-services',
'5610C': 'Restauration de type rapide',
'5621Z': 'Services des traiteurs',
'5629A': 'Restauration collective sous contrat',
'5629B': 'Autres services de restauration n.c.a.',
'5630Z': 'Débits de boissons',
'5811Z': 'Édition de livres',
'5812Z': "Édition de répertoires et de fichiers d'adresses",
'5813Z': 'Édition de journaux',
'5814Z': 'Édition de revues et périodiques',
'5819Z': "Autres activités d'édition",
'5821Z': 'Édition de jeux électroniques',
'5829A': 'Édition de logiciels système et de réseau',
'5829B': 'Édition de logiciels outils de développement et de langages',
'5829C': 'Édition de logiciels applicatifs',
'5911A': 'Production de films et de programmes pour la télévision',
'5911B': 'Production de films institutionnels et publicitaires',
'5911C': 'Production de films pour le cinéma',
'5912Z': 'Post-production de films cinématographiques, de vidéo et de programmes de télévision',
'5913A': 'Distribution de films cinématographiques',
'5913B': 'Édition et distribution vidéo',
'5914Z': 'Projection de films cinématographiques',
'5920Z': 'Enregistrement sonore et édition musicale',
'6010Z': 'Édition et diffusion de programmes radio',
'6020A': 'Édition de chaînes généralistes',
'6020B': 'Édition de chaînes thématiques',
'6110Z': 'Télécommunications filaires',
'6120Z': 'Télécommunications sans fil',
'6130Z': 'Télécommunications par satellite',
'6190Z': 'Autres activités de télécommunication',
'6201Z': 'Programmation informatique',
'6202A': 'Conseil en systèmes et logiciels informatiques',
'6202B': "Tierce maintenance de systèmes et d'applications informatiques",
'6203Z': "Gestion d'installations informatiques",
'6209Z': 'Autres activités informatiques',
'6311Z': 'Traitement de données, hébergement et activités connexes',
'6312Z': 'Portails Internet',
'6391Z': 'Activités des agences de presse',
'6399Z': "Autres services d'information n.c.a.",
'6411Z': 'Activités de banque centrale',
'6419Z': 'Autres intermédiations monétaires',
'6420Z': 'Activités des sociétés holding',
'6430Z': 'Fonds de placement et entités financières similaires',
'6491Z': 'Crédit-bail',
'6492Z': 'Autre distribution de crédit',
'6499Z': 'Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a.',
'6511Z': 'Assurance vie',
'6512Z': 'Autres assurances',
'6520Z': 'Réassurance',
'6530Z': 'Caisses de retraite',
'6611Z': 'Administration de marchés financiers',
'6612Z': 'Courtage de valeurs mobilières et de marchandises',
'6619A': 'Supports juridiques de gestion de patrimoine mobilier',
'6619B': 'Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite, n.c.a.',
'6621Z': 'Évaluation des risques et dommages',
'6622Z': "Activités des agents et courtiers d'assurances",
'6629Z': "Autres activités auxiliaires d'assurance et de caisses de retraite",
'6630Z': 'Gestion de fonds',
'6810Z': 'Activités des marchands de biens immobiliers',
'6820A': 'Location de logements',
'6820B': "Location de terrains et d'autres biens immobiliers",
'6831Z': 'Agences immobilières',
'6832A': "Administration d'immeubles et autres biens immobiliers",
'6832B': 'Supports juridiques de gestion de patrimoine immobilier',
'6910Z': 'Activités juridiques',
'6920Z': 'Activités comptables',
'7010Z': 'Activités des sièges sociaux',
'7021Z': 'Conseil en relations publiques et communication',
'7022Z': 'Conseil pour les affaires et autres conseils de gestion',
'7111Z': "Activités d'architecture",
'7112A': 'Activité des géomètres',
'7112B': 'Ingénierie, études techniques',
'7120A': 'Contrôle technique automobile',
'7120B': 'Analyses, essais et inspections techniques',
'7211Z': 'Recherche-développement en biotechnologie',
'7219Z': 'Recherche-développement en autres sciences physiques et naturelles',
'7220Z': 'Recherche-développement en sciences humaines et sociales',
'7311Z': 'Activités des agences de publicité',
'7312Z': 'Régie publicitaire de médias',
'7320Z': 'Études de marché et sondages',
'7410Z': 'Activités spécialisées de design',
'7420Z': 'Activités photographiques',
'7430Z': 'Traduction et interprétation',
'7490A': 'Activité des économistes de la construction',
'7490B': 'Activités spécialisées, scientifiques et techniques diverses',
'7500Z': 'Activités vétérinaires',
'7711A': 'Location de courte durée de voitures et de véhicules automobiles légers',
'7711B': 'Location de longue durée de voitures et de véhicules automobiles légers',
'7712Z': 'Location et location-bail de camions',
'7721Z': "Location et location-bail d'articles de loisirs et de sport",
'7722Z': 'Location de vidéocassettes et disques vidéo',
'7729Z': "Location et location-bail d'autres biens personnels et domestiques",
'7731Z': 'Location et location-bail de machines et équipements agricoles',
'7732Z': 'Location et location-bail de machines et équipements pour la construction',
'7733Z': 'Location et location-bail de machines de bureau et de matériel informatique',
'7734Z': 'Location et location-bail de matériels de transport par eau',
'7735Z': 'Location et location-bail de matériels de transport aérien',
'7739Z': "Location et location-bail d'autres machines, équipements et biens matériels n.c.a.",
'7740Z': "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
'7810Z': "Activités des agences de placement de main-d'œuvre",
'7820Z': 'Activités des agences de travail temporaire',
'7830Z': 'Autre mise à disposition de ressources humaines',
'7911Z': 'Activités des agences de voyage',
'7912Z': 'Activités des voyagistes',
'7990Z': 'Autres services de réservation et activités connexes',
'8010Z': 'Activités de sécurité privée',
'8020Z': 'Activités liées aux systèmes de sécurité',
'8030Z': "Activités d'enquête",
'8110Z': 'Activités combinées de soutien lié aux bâtiments',
'8121Z': 'Nettoyage courant des bâtiments',
'8122Z': 'Autres activités de nettoyage des bâtiments et nettoyage industriel',
'8129A': 'Désinfection, désinsectisation, dératisation',
'8129B': 'Autres activités de nettoyage n.c.a.',
'8130Z': "Services d'aménagement paysager",
'8211Z': 'Services administratifs combinés de bureau',
'8219Z': 'Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau',
'8220Z': "Activités de centres d'appels",
'8230Z': 'Organisation de foires, salons professionnels et congrès',
'8291Z': "Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle",
'8292Z': 'Activités de conditionnement',
'8299Z': 'Autres activités de soutien aux entreprises n.c.a.',
'8411Z': 'Administration publique générale',
'8412Z': 'Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale',
'8413Z': 'Administration publique (tutelle) des activités économiques',
'8421Z': 'Affaires étrangères',
'8422Z': 'Défense',
'8423Z': 'Justice',
'8424Z': "Activités d'ordre public et de sécurité",
'8425Z': 'Services du feu et de secours',
'8430A': 'Activités générales de sécurité sociale',
'8430B': 'Gestion des retraites complémentaires',
'8430C': 'Distribution sociale de revenus',
'8510Z': 'Enseignement pré-primaire',
'8520Z': 'Enseignement primaire',
'8531Z': 'Enseignement secondaire général',
'8532Z': 'Enseignement secondaire technique ou professionnel',
'8541Z': 'Enseignement post-secondaire non supérieur',
'8542Z': 'Enseignement supérieur',
'8551Z': "Enseignement de disciplines sportives et d'activités de loisirs",
'8552Z': 'Enseignement culturel',
'8553Z': 'Enseignement de la conduite',
'8559A': "Formation continue d'adultes",
'8559B': 'Autres enseignements',
'8560Z': "Activités de soutien à l'enseignement",
'8610Z': 'Activités hospitalières',
'8621Z': 'Activité des médecins généralistes',
'8622A': 'Activités de radiodiagnostic et de radiothérapie',
'8622B': 'Activités chirurgicales',
'8622C': 'Autres activités des médecins spécialistes',
'8623Z': 'Pratique dentaire',
'8690A': 'Ambulances',
'8690B': "Laboratoires d'analyses médicales",
'8690C': "Centres de collecte et banques d'organes",
'8690D': 'Activités des infirmiers et des sages-femmes',
'8690E': "Activités des professionnels de la rééducation, de l'appareillage et des pédicures-podologues",
'8690F': 'Activités de santé humaine non classées ailleurs',
'8710A': 'Hébergement médicalisé pour personnes âgées',
'8710B': 'Hébergement médicalisé pour enfants handicapés',
'8710C': 'Hébergement médicalisé pour adultes handicapés et autre hébergement médicalisé',
'8720A': 'Hébergement social pour handicapés mentaux et malades mentaux',
'8720B': 'Hébergement social pour toxicomanes',
'8730A': 'Hébergement social pour personnes âgées',
'8730B': 'Hébergement social pour handicapés physiques',
'8790A': 'Hébergement social pour enfants en difficultés',
'8790B': 'Hébergement social pour adultes et familles en difficultés et autre hébergement social',
'8810A': 'Aide à domicile',
'8810B': "Accueil ou accompagnement sans hébergement d'adultes handicapés ou de personnes âgées",
'8810C': 'Aide par le travail',
'8891A': 'Accueil de jeunes enfants',
'8891B': "Accueil ou accompagnement sans hébergement d'enfants handicapés",
'8899A': "Autre accueil ou accompagnement sans hébergement d'enfants et d'adolescents",
'8899B': 'Action sociale sans hébergement n.c.a.',
'9001Z': 'Arts du spectacle vivant',
'9002Z': 'Activités de soutien au spectacle vivant',
'9003A': 'Création artistique relevant des arts plastiques',
'9003B': 'Autre création artistique',
'9004Z': 'Gestion de salles de spectacles',
'9101Z': 'Gestion des bibliothèques et des archives',
'9102Z': 'Gestion des musées',
'9103Z': 'Gestion des sites et monuments historiques et des attractions touristiques similaires',
'9104Z': 'Gestion des jardins botaniques et zoologiques et des réserves naturelles',
'9200Z': "Organisation de jeux de hasard et d'argent",
'9311Z': "Gestion d'installations sportives",
'9312Z': 'Activités de clubs de sports',
'9313Z': 'Activités des centres de culture physique',
'9319Z': 'Autres activités liées au sport',
'9321Z': "Activités des parcs d'attractions et parcs à thèmes",
'9329Z': 'Autres activités récréatives et de loisirs',
'9411Z': 'Activités des organisations patronales et consulaires',
'9412Z': 'Activités des organisations professionnelles',
'9420Z': 'Activités des syndicats de salariés',
'9491Z': 'Activités des organisations religieuses',
'9492Z': 'Activités des organisations politiques',
'9499Z': 'Autres organisations fonctionnant par adhésion volontaire',
'9511Z': "Réparation d'ordinateurs et d'équipements périphériques",
'9512Z': "Réparation d'équipements de communication",
'9521Z': 'Réparation de produits électroniques grand public',
'9522Z': "Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin",
'9523Z': "Réparation de chaussures et d'articles en cuir",
'9524Z': "Réparation de meubles et d'équipements du foyer",
'9525Z': "Réparation d'articles d'horlogerie et de bijouterie",
'9529Z': "Réparation d'autres biens personnels et domestiques",
'9601A': 'Blanchisserie-teinturerie de gros',
'9601B': 'Blanchisserie-teinturerie de détail',
'9602A': 'Coiffure',
'9602B': 'Soins de beauté',
'9603Z': 'Services funéraires',
'9604Z': 'Entretien corporel',
'9609Z': 'Autres services personnels n.c.a.',
'9700Z': "Activités des ménages en tant qu'employeurs de personnel domestique",
'9810Z': 'Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre',
'9820Z': 'Activités indifférenciées des ménages en tant que producteurs de services pour usage propre',
'9900Z': 'Activités des organisations et organismes extraterritoriaux'}

const f_j = {'0000': 'Organisme de placement collectif en valeurs mobilières sans personnalité morale',
'1000': 'Entrepreneur individuel',
'2110': 'Indivision entre personnes physiques',
'2120': 'Indivision avec personne morale',
'2210': 'Société créée de fait entre personnes physiques',
'2220': 'Société créée de fait avec personne morale',
'2310': 'Société en participation entre personnes physiques',
'2320': 'Société en participation avec personne morale',
'2385': 'Société en participation de professions libérales',
'2400': 'Fiducie',
'2700': 'Paroisse hors zone concordataire',
'2800': 'Assujetti unique à la TVA',
'2900': 'Autre groupement de droit privé non doté de la personnalité morale',
'3110': "Représentation ou agence commerciale d'état ou organisme public étranger immatriculé au RCS",
'3120': 'Société commerciale étrangère immatriculée au RCS',
'3205': 'Organisation internationale',
'3210': 'État, collectivité ou établissement public étranger',
'3220': 'Société étrangère non immatriculée au RCS',
'3290': 'Autre personne morale de droit étranger',
'4110': "Établissement public national à caractère industriel ou commercial doté d'un comptable public",
'4120': "Établissement public national à caractère industriel ou commercial non doté d'un comptable public",
'4130': 'Exploitant public',
'4140': 'Établissement public local à caractère industriel ou commercial',
'4150': "Régie d'une collectivité locale à caractère industriel ou commercial",
'4160': 'Institution Banque de France',
'5191': 'Société de caution mutuelle',
'5192': 'Société coopérative de banque populaire',
'5193': 'Caisse de crédit maritime mutuel',
'5194': 'Caisse (fédérale) de crédit mutuel',
'5195': 'Association coopérative inscrite (droit local Alsace Moselle)',
'5196': "Caisse d'épargne et de prévoyance à forme coopérative",
'5202': 'Société en nom collectif',
'5203': 'Société en nom collectif coopérative',
'5306': 'Société en commandite simple',
'5307': 'Société en commandite simple coopérative',
'5308': 'Société en commandite par actions',
'5309': 'Société en commandite par actions coopérative',
'5310': 'Société en libre partenariat (SLP)',
'5370': 'Société de Participations Financières de Profession Libérale Société en commandite par actions (SPFPL SCA)',
'5385': "Société d'exercice libéral en commandite par actions",
'5410': 'SARL nationale',
'5415': "SARL d'économie mixte",
'5422': "SARL immobilière pour le commerce et l'industrie (SICOMI)",
'5426': 'SARL immobilière de gestion',
'5430': "SARL d'aménagement foncier et d'équipement rural (SAFER)",
'5431': "SARL mixte d'intérêt agricole (SMIA)",
'5432': "SARL d'intérêt collectif agricole (SICA)",
'5442': "SARL d'attribution",
'5443': 'SARL coopérative de construction',
'5451': 'SARL coopérative de consommation',
'5453': 'SARL coopérative artisanale',
'5454': "SARL coopérative d'intérêt maritime",
'5455': 'SARL coopérative de transport',
'5458': 'SARL coopérative de production (SCOP)',
'5459': 'SARL union de sociétés coopératives',
'5460': 'Autre SARL coopérative',
'5470': 'Société de Participations Financières de Profession Libérale Société à responsabilité limitée (SPFPL SARL)',
'5485': "Société d'exercice libéral à responsabilité limitée",
'5499': 'Société à responsabilité limitée (sans autre indication)',
'5505': "SA à participation ouvrière à conseil d'administration",
'5510': "SA nationale à conseil d'administration",
'5515': "SA d'économie mixte à conseil d'administration",
'5520': "Fonds à forme sociétale à conseil d'administration",
'5522': "SA immobilière pour le commerce et l'industrie (SICOMI) à conseil d'administration",
'5525': "SA immobilière d'investissement à conseil d'administration",
'5530': "SA d'aménagement foncier et d'équipement rural (SAFER) à conseil d'administration",
'5531': "Société anonyme mixte d'intérêt agricole (SMIA) à conseil d'administration",
'5532': "SA d'intérêt collectif agricole (SICA) à conseil d'administration",
'5542': "SA d'attribution à conseil d'administration",
'5543': "SA coopérative de construction à conseil d'administration",
'5546': "SA de HLM à conseil d'administration",
'5547': "SA coopérative de production de HLM à conseil d'administration",
'5548': "SA de crédit immobilier à conseil d'administration",
'5551': "SA coopérative de consommation à conseil d'administration",
'5552': "SA coopérative de commerçants-détaillants à conseil d'administration",
'5553': "SA coopérative artisanale à conseil d'administration",
'5554': "SA coopérative (d'intérêt) maritime à conseil d'administration",
'5555': "SA coopérative de transport à conseil d'administration",
'5558': "SA coopérative de production (SCOP) à conseil d'administration",
'5559': "SA union de sociétés coopératives à conseil d'administration",
'5560': "Autre SA coopérative à conseil d'administration",
'5570': "Société de Participations Financières de Profession Libérale Société anonyme à conseil d'administration (SPFPL SA à conseil d'administration)",
'5585': "Société d'exercice libéral à forme anonyme à conseil d'administration",
'5599': "SA à conseil d'administration (s.a.i.)",
'5605': 'SA à participation ouvrière à directoire',
'5610': 'SA nationale à directoire',
'5615': "SA d'économie mixte à directoire",
'5620': 'Fonds à forme sociétale à directoire',
'5622': "SA immobilière pour le commerce et l'industrie (SICOMI) à directoire",
'5625': "SA immobilière d'investissement à directoire",
'5630': 'Safer anonyme à directoire',
'5631': "SA mixte d'intérêt agricole (SMIA)",
'5632': "SA d'intérêt collectif agricole (SICA)",
'5642': "SA d'attribution à directoire",
'5643': 'SA coopérative de construction à directoire',
'5646': 'SA de HLM à directoire',
'5647': 'Société coopérative de production de HLM anonyme à directoire',
'5648': 'SA de crédit immobilier à directoire',
'5651': 'SA coopérative de consommation à directoire',
'5652': 'SA coopérative de commerçants-détaillants à directoire',
'5653': 'SA coopérative artisanale à directoire',
'5654': "SA coopérative d'intérêt maritime à directoire",
'5655': 'SA coopérative de transport à directoire',
'5658': 'SA coopérative de production (SCOP) à directoire',
'5659': 'SA union de sociétés coopératives à directoire',
'5660': 'Autre SA coopérative à directoire',
'5670': 'Société de Participations Financières de Profession Libérale Société anonyme à Directoire (SPFPL SA à directoire)',
'5685': "Société d'exercice libéral à forme anonyme à directoire",
'5699': 'SA à directoire (s.a.i.)',
'5710': 'SAS, société par actions simplifiée',
'5770': 'Société de Participations Financières de Profession Libérale Société par actions simplifiée (SPFPL SAS)',
'5785': "Société d'exercice libéral par action simplifiée",
'5800': 'Société européenne',
'6100': "Caisse d'Épargne et de Prévoyance",
'6210': "Groupement européen d'intérêt économique (GEIE)",
'6220': "Groupement d'intérêt économique (GIE)",
'6316': "Coopérative d'utilisation de matériel agricole en commun (CUMA)",
'6317': 'Société coopérative agricole',
'6318': 'Union de sociétés coopératives agricoles',
'6411': "Société d'assurance à forme mutuelle",
'6511': 'Sociétés Interprofessionnelles de Soins Ambulatoires',
'6521': 'Société civile de placement collectif immobilier (SCPI)',
'6532': "Société civile d'intérêt collectif agricole (SICA)",
'6533': "Groupement agricole d'exploitation en commun (GAEC)",
'6534': 'Groupement foncier agricole',
'6535': 'Groupement agricole foncier',
'6536': 'Groupement forestier',
'6537': 'Groupement pastoral',
'6538': 'Groupement foncier et rural',
'6539': 'Société civile foncière',
'6540': 'Société civile immobilière',
'6541': 'Société civile immobilière de construction-vente',
'6542': "Société civile d'attribution",
'6543': 'Société civile coopérative de construction',
'6544': "Société civile immobilière d' accession progressive à la propriété",
'6551': 'Société civile coopérative de consommation',
'6554': "Société civile coopérative d'intérêt maritime",
'6558': 'Société civile coopérative entre médecins',
'6560': 'Autre société civile coopérative',
'6561': "SCP d'avocats",
'6562': "SCP d'avocats aux conseils",
'6563': "SCP d'avoués d'appel",
'6564': "SCP d'huissiers",
'6565': 'SCP de notaires',
'6566': 'SCP de commissaires-priseurs',
'6567': 'SCP de greffiers de tribunal de commerce',
'6568': 'SCP de conseils juridiques',
'6569': 'SCP de commissaires aux comptes',
'6571': 'SCP de médecins',
'6572': 'SCP de dentistes',
'6573': "SCP d'infirmiers",
'6574': 'SCP de masseurs-kinésithérapeutes',
'6575': "SCP de directeurs de laboratoire d'analyse médicale",
'6576': 'SCP de vétérinaires',
'6577': 'SCP de géomètres experts',
'6578': "SCP d'architectes",
'6585': 'Autre société civile professionnelle',
'6589': 'Société civile de moyens',
'6595': 'Caisse locale de crédit mutuel',
'6596': 'Caisse de crédit agricole mutuel',
'6597': "Société civile d'exploitation agricole",
'6598': 'Exploitation agricole à responsabilité limitée',
'6599': 'Autre société civile',
'6901': 'Autre personne de droit privé inscrite au registre du commerce et des sociétés',
'7111': 'Autorité constitutionnelle',
'7112': 'Autorité administrative ou publique indépendante',
'7113': 'Ministère',
'7120': "Service central d'un ministère",
'7150': 'Service du ministère de la Défense',
'7160': "Service déconcentré à compétence nationale d'un ministère (hors Défense)",
'7171': "Service déconcentré de l'État à compétence (inter) régionale",
'7172': "Service déconcentré de l'État à compétence (inter) départementale",
'7179': "(Autre) Service déconcentré de l'État à compétence territoriale",
'7190': 'Ecole nationale non dotée de la personnalité morale',
'7210': 'Commune et commune nouvelle',
'7220': 'Département',
'7225': "Collectivité et territoire d'Outre Mer",
'7229': '(Autre) Collectivité territoriale',
'7230': 'Région',
'7312': 'Commune associée et commune déléguée',
'7313': 'Section de commune',
'7314': 'Ensemble urbain',
'7321': 'Association syndicale autorisée',
'7322': 'Association foncière urbaine',
'7323': 'Association foncière de remembrement',
'7331': "Établissement public local d'enseignement",
'7340': 'Pôle métropolitain',
'7341': 'Secteur de commune',
'7342': 'District urbain',
'7343': 'Communauté urbaine',
'7344': 'Métropole',
'7345': 'Syndicat intercommunal à vocation multiple (SIVOM)',
'7346': 'Communauté de communes',
'7347': 'Communauté de villes',
'7348': "Communauté d'agglomération",
'7349': 'Autre établissement public local de coopération non spécialisé ou entente',
'7351': 'Institution interdépartementale ou entente',
'7352': 'Institution interrégionale ou entente',
'7353': 'Syndicat intercommunal à vocation unique (SIVU)',
'7354': 'Syndicat mixte fermé',
'7355': 'Syndicat mixte ouvert',
'7356': 'Commission syndicale pour la gestion des biens indivis des communes',
'7357': "Pôle d'équilibre territorial et rural (PETR)",
'7361': "Centre communal d'action sociale",
'7362': 'Caisse des écoles',
'7363': 'Caisse de crédit municipal',
'7364': "Établissement d'hospitalisation",
'7365': 'Syndicat inter hospitalier',
'7366': 'Établissement public local social et médico-social',
'7367': "Centre Intercommunal d'action sociale (CIAS)",
'7371': "Office public d'habitation à loyer modéré (OPHLM)",
'7372': "Service départemental d'incendie et de secours (SDIS)",
'7373': 'Établissement public local culturel',
'7378': "Régie d'une collectivité locale à caractère administratif",
'7379': '(Autre) Établissement public administratif local',
'7381': 'Organisme consulaire',
'7382': "Établissement public national ayant fonction d'administration centrale",
'7383': 'Établissement public national à caractère scientifique culturel et professionnel',
'7384': "Autre établissement public national d'enseignement",
'7385': 'Autre établissement public national administratif à compétence territoriale limitée',
'7389': 'Établissement public national à caractère administratif',
'7410': "Groupement d'intérêt public (GIP)",
'7430': "Établissement public des cultes d'Alsace-Lorraine",
'7450': 'Etablissement public administratif, cercle et foyer dans les armées',
'7470': 'Groupement de coopération sanitaire à gestion publique',
'7490': 'Autre personne morale de droit administratif',
'8110': 'Régime général de la Sécurité Sociale',
'8120': 'Régime spécial de Sécurité Sociale',
'8130': 'Institution de retraite complémentaire',
'8140': 'Mutualité sociale agricole',
'8150': 'Régime maladie des non-salariés non agricoles',
'8160': 'Régime vieillesse ne dépendant pas du régime général de la Sécurité Sociale',
'8170': "Régime d'assurance chômage",
'8190': 'Autre régime de prévoyance sociale',
'8210': 'Mutuelle',
'8250': 'Assurance mutuelle agricole',
'8290': 'Autre organisme mutualiste',
'8310': 'Comité social économique d’entreprise',
'8311': "Comité social économique d'établissement",
'8410': 'Syndicat de salariés',
'8420': 'Syndicat patronal',
'8450': 'Ordre professionnel ou assimilé',
'8470': 'Centre technique industriel ou comité professionnel du développement économique',
'8490': 'Autre organisme professionnel',
'8510': 'Institution de prévoyance',
'8520': 'Institution de retraite supplémentaire',
'9110': 'Syndicat de copropriété',
'9150': 'Association syndicale libre',
'9210': 'Association non déclarée',
'9220': 'Association déclarée',
'9221': "Association déclarée d'insertion par l'économique",
'9222': 'Association intermédiaire',
'9223': "Groupement d'employeurs",
'9224': "Association d'avocats à responsabilité professionnelle individuelle",
'9230': "Association déclarée, reconnue d'utilité publique",
'9240': 'Congrégation',
'9260': 'Association de droit local (Bas-Rhin, Haut-Rhin et Moselle)',
'9300': 'Fondation',
'9900': 'Autre personne morale de droit privé',
'9970': 'Groupement de coopération sanitaire à gestion privée'}

const codes_naf_fr = [
    {
      "id": "01",
      "label": "Culture et production animale, chasse et services annexes"
    },
    {
      "id": "01.1",
      "label": "Cultures non permanentes"
    },
    {
      "id": "01.11",
      "label": "Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses"
    },
    {
      "id": "01.11Z",
      "label": "Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses"
    },
    {
      "id": "01.12",
      "label": "Culture du riz"
    },
    {
      "id": "01.12Z",
      "label": "Culture du riz"
    },
    {
      "id": "01.13",
      "label": "Culture de légumes, de melons, de racines et de tubercules"
    },
    {
      "id": "01.13Z",
      "label": "Culture de légumes, de melons, de racines et de tubercules"
    },
    {
      "id": "01.14",
      "label": "Culture de la canne à sucre"
    },
    {
      "id": "01.14Z",
      "label": "Culture de la canne à sucre"
    },
    {
      "id": "01.15",
      "label": "Culture du tabac"
    },
    {
      "id": "01.15Z",
      "label": "Culture du tabac"
    },
    {
      "id": "01.16",
      "label": "Culture de plantes à fibres"
    },
    {
      "id": "01.16Z",
      "label": "Culture de plantes à fibres"
    },
    {
      "id": "01.19",
      "label": "Autres cultures non permanentes"
    },
    {
      "id": "01.19Z",
      "label": "Autres cultures non permanentes"
    },
    {
      "id": "01.2",
      "label": "Cultures permanentes"
    },
    {
      "id": "01.21",
      "label": "Culture de la vigne"
    },
    {
      "id": "01.21Z",
      "label": "Culture de la vigne"
    },
    {
      "id": "01.22",
      "label": "Culture de fruits tropicaux et subtropicaux"
    },
    {
      "id": "01.22Z",
      "label": "Culture de fruits tropicaux et subtropicaux"
    },
    {
      "id": "01.23",
      "label": "Culture d'agrumes"
    },
    {
      "id": "01.23Z",
      "label": "Culture d'agrumes"
    },
    {
      "id": "01.24",
      "label": "Culture de fruits à pépins et à noyau"
    },
    {
      "id": "01.24Z",
      "label": "Culture de fruits à pépins et à noyau"
    },
    {
      "id": "01.25",
      "label": "Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque"
    },
    {
      "id": "01.25Z",
      "label": "Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque"
    },
    {
      "id": "01.26",
      "label": "Culture de fruits oléagineux"
    },
    {
      "id": "01.26Z",
      "label": "Culture de fruits oléagineux"
    },
    {
      "id": "01.27",
      "label": "Culture de plantes à boissons"
    },
    {
      "id": "01.27Z",
      "label": "Culture de plantes à boissons"
    },
    {
      "id": "01.28",
      "label": "Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques"
    },
    {
      "id": "01.28Z",
      "label": "Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques"
    },
    {
      "id": "01.29",
      "label": "Autres cultures permanentes"
    },
    {
      "id": "01.29Z",
      "label": "Autres cultures permanentes"
    },
    {
      "id": "01.3",
      "label": "Reproduction de plantes"
    },
    {
      "id": "01.30",
      "label": "Reproduction de plantes"
    },
    {
      "id": "01.30Z",
      "label": "Reproduction de plantes"
    },
    {
      "id": "01.4",
      "label": "Production animale"
    },
    {
      "id": "01.41",
      "label": "Élevage de vaches laitières"
    },
    {
      "id": "01.41Z",
      "label": "Élevage de vaches laitières"
    },
    {
      "id": "01.42",
      "label": "Élevage d'autres bovins et de buffles"
    },
    {
      "id": "01.42Z",
      "label": "Élevage d'autres bovins et de buffles"
    },
    {
      "id": "01.43",
      "label": "Élevage de chevaux et d'autres équidés"
    },
    {
      "id": "01.43Z",
      "label": "Élevage de chevaux et d'autres équidés"
    },
    {
      "id": "01.44",
      "label": "Élevage de chameaux et d'autres camélidés"
    },
    {
      "id": "01.44Z",
      "label": "Élevage de chameaux et d'autres camélidés"
    },
    {
      "id": "01.45",
      "label": "Élevage d'ovins et de caprins"
    },
    {
      "id": "01.45Z",
      "label": "Élevage d'ovins et de caprins"
    },
    {
      "id": "01.46",
      "label": "Élevage de porcins"
    },
    {
      "id": "01.46Z",
      "label": "Élevage de porcins"
    },
    {
      "id": "01.47",
      "label": "Élevage de volailles"
    },
    {
      "id": "01.47Z",
      "label": "Élevage de volailles"
    },
    {
      "id": "01.49",
      "label": "Élevage d'autres animaux"
    },
    {
      "id": "01.49Z",
      "label": "Élevage d'autres animaux"
    },
    {
      "id": "01.5",
      "label": "Culture et élevage associés"
    },
    {
      "id": "01.50",
      "label": "Culture et élevage associés"
    },
    {
      "id": "01.50Z",
      "label": "Culture et élevage associés"
    },
    {
      "id": "01.6",
      "label": "Activités de soutien à l'agriculture et traitement primaire des récoltes"
    },
    {
      "id": "01.61",
      "label": "Activités de soutien aux cultures"
    },
    {
      "id": "01.61Z",
      "label": "Activités de soutien aux cultures"
    },
    {
      "id": "01.62",
      "label": "Activités de soutien à la production animale"
    },
    {
      "id": "01.62Z",
      "label": "Activités de soutien à la production animale"
    },
    {
      "id": "01.63",
      "label": "Traitement primaire des récoltes"
    },
    {
      "id": "01.63Z",
      "label": "Traitement primaire des récoltes"
    },
    {
      "id": "01.64",
      "label": "Traitement des semences"
    },
    {
      "id": "01.64Z",
      "label": "Traitement des semences"
    },
    {
      "id": "01.7",
      "label": "Chasse, piégeage et services annexes"
    },
    {
      "id": "01.70",
      "label": "Chasse, piégeage et services annexes"
    },
    {
      "id": "01.70Z",
      "label": "Chasse, piégeage et services annexes"
    },
    {
      "id": "02",
      "label": "Sylviculture et exploitation forestière"
    },
    {
      "id": "02.1",
      "label": "Sylviculture et autres activités forestières"
    },
    {
      "id": "02.10",
      "label": "Sylviculture et autres activités forestières"
    },
    {
      "id": "02.10Z",
      "label": "Sylviculture et autres activités forestières"
    },
    {
      "id": "02.2",
      "label": "Exploitation forestière"
    },
    {
      "id": "02.20",
      "label": "Exploitation forestière"
    },
    {
      "id": "02.20Z",
      "label": "Exploitation forestière"
    },
    {
      "id": "02.3",
      "label": "Récolte de produits forestiers non ligneux poussant à l'état sauvage"
    },
    {
      "id": "02.30",
      "label": "Récolte de produits forestiers non ligneux poussant à l'état sauvage"
    },
    {
      "id": "02.30Z",
      "label": "Récolte de produits forestiers non ligneux poussant à l'état sauvage"
    },
    {
      "id": "02.4",
      "label": "Services de soutien à l'exploitation forestière"
    },
    {
      "id": "02.40",
      "label": "Services de soutien à l'exploitation forestière"
    },
    {
      "id": "02.40Z",
      "label": "Services de soutien à l'exploitation forestière"
    },
    {
      "id": "03",
      "label": "Pêche et aquaculture"
    },
    {
      "id": "03.1",
      "label": "Pêche"
    },
    {
      "id": "03.11",
      "label": "Pêche en mer"
    },
    {
      "id": "03.11Z",
      "label": "Pêche en mer"
    },
    {
      "id": "03.12",
      "label": "Pêche en eau douce"
    },
    {
      "id": "03.12Z",
      "label": "Pêche en eau douce"
    },
    {
      "id": "03.2",
      "label": "Aquaculture"
    },
    {
      "id": "03.21",
      "label": "Aquaculture en mer"
    },
    {
      "id": "03.21Z",
      "label": "Aquaculture en mer"
    },
    {
      "id": "03.22",
      "label": "Aquaculture en eau douce"
    },
    {
      "id": "03.22Z",
      "label": "Aquaculture en eau douce"
    },
    {
      "id": "05",
      "label": "Extraction de houille et de lignite"
    },
    {
      "id": "05.1",
      "label": "Extraction de houille"
    },
    {
      "id": "05.10",
      "label": "Extraction de houille"
    },
    {
      "id": "05.10Z",
      "label": "Extraction de houille"
    },
    {
      "id": "05.2",
      "label": "Extraction de lignite"
    },
    {
      "id": "05.20",
      "label": "Extraction de lignite"
    },
    {
      "id": "05.20Z",
      "label": "Extraction de lignite"
    },
    {
      "id": "06",
      "label": "Extraction d'hydrocarbures"
    },
    {
      "id": "06.1",
      "label": "Extraction de pétrole brut"
    },
    {
      "id": "06.10",
      "label": "Extraction de pétrole brut"
    },
    {
      "id": "06.10Z",
      "label": "Extraction de pétrole brut"
    },
    {
      "id": "06.2",
      "label": "Extraction de gaz naturel"
    },
    {
      "id": "06.20",
      "label": "Extraction de gaz naturel"
    },
    {
      "id": "06.20Z",
      "label": "Extraction de gaz naturel"
    },
    {
      "id": "07",
      "label": "Extraction de minerais métalliques"
    },
    {
      "id": "07.1",
      "label": "Extraction de minerais de fer"
    },
    {
      "id": "07.10",
      "label": "Extraction de minerais de fer"
    },
    {
      "id": "07.10Z",
      "label": "Extraction de minerais de fer"
    },
    {
      "id": "07.2",
      "label": "Extraction de minerais de métaux non ferreux"
    },
    {
      "id": "07.21",
      "label": "Extraction de minerais d'uranium et de thorium"
    },
    {
      "id": "07.21Z",
      "label": "Extraction de minerais d'uranium et de thorium"
    },
    {
      "id": "07.29",
      "label": "Extraction d'autres minerais de métaux non ferreux"
    },
    {
      "id": "07.29Z",
      "label": "Extraction d'autres minerais de métaux non ferreux"
    },
    {
      "id": "08",
      "label": "Autres industries extractives"
    },
    {
      "id": "08.1",
      "label": "Extraction de pierres, de sables et d'argiles"
    },
    {
      "id": "08.11",
      "label": "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise"
    },
    {
      "id": "08.11Z",
      "label": "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise"
    },
    {
      "id": "08.12",
      "label": "Exploitation de gravières et sablières, extraction d’argiles et de kaolin"
    },
    {
      "id": "08.12Z",
      "label": "Exploitation de gravières et sablières, extraction d’argiles et de kaolin"
    },
    {
      "id": "08.9",
      "label": "Activités extractives n.c.a."
    },
    {
      "id": "08.91",
      "label": "Extraction des minéraux chimiques et d'engrais minéraux"
    },
    {
      "id": "08.91Z",
      "label": "Extraction des minéraux chimiques et d'engrais minéraux"
    },
    {
      "id": "08.92",
      "label": "Extraction de tourbe"
    },
    {
      "id": "08.92Z",
      "label": "Extraction de tourbe"
    },
    {
      "id": "08.93",
      "label": "Production de sel"
    },
    {
      "id": "08.93Z",
      "label": "Production de sel"
    },
    {
      "id": "08.99",
      "label": "Autres activités extractives n.c.a."
    },
    {
      "id": "08.99Z",
      "label": "Autres activités extractives n.c.a."
    },
    {
      "id": "09",
      "label": "Services de soutien aux industries extractives"
    },
    {
      "id": "09.1",
      "label": "Activités de soutien à l'extraction d'hydrocarbures"
    },
    {
      "id": "09.10",
      "label": "Activités de soutien à l'extraction d'hydrocarbures"
    },
    {
      "id": "09.10Z",
      "label": "Activités de soutien à l'extraction d'hydrocarbures"
    },
    {
      "id": "09.9",
      "label": "Activités de soutien aux autres industries extractives"
    },
    {
      "id": "09.90",
      "label": "Activités de soutien aux autres industries extractives"
    },
    {
      "id": "09.90Z",
      "label": "Activités de soutien aux autres industries extractives"
    },
    {
      "id": "10",
      "label": "Industries alimentaires"
    },
    {
      "id": "10.1",
      "label": "Transformation et conservation de la viande et préparation de produits à base de viande"
    },
    {
      "id": "10.11",
      "label": "Transformation et conservation de la viande de boucherie"
    },
    {
      "id": "10.11Z",
      "label": "Transformation et conservation de la viande de boucherie"
    },
    {
      "id": "10.12",
      "label": "Transformation et conservation de la viande de volaille"
    },
    {
      "id": "10.12Z",
      "label": "Transformation et conservation de la viande de volaille"
    },
    {
      "id": "10.13",
      "label": "Préparation de produits à base de viande"
    },
    {
      "id": "10.13A",
      "label": "Préparation industrielle de produits à base de viande"
    },
    {
      "id": "10.13B",
      "label": "Charcuterie"
    },
    {
      "id": "10.2",
      "label": "Transformation et conservation de poisson, de crustacés et de mollusques"
    },
    {
      "id": "10.20",
      "label": "Transformation et conservation de poisson, de crustacés et de mollusques"
    },
    {
      "id": "10.20Z",
      "label": "Transformation et conservation de poisson, de crustacés et de mollusques"
    },
    {
      "id": "10.3",
      "label": "Transformation et conservation de fruits et légumes"
    },
    {
      "id": "10.31",
      "label": "Transformation et conservation de pommes de terre"
    },
    {
      "id": "10.31Z",
      "label": "Transformation et conservation de pommes de terre"
    },
    {
      "id": "10.32",
      "label": "Préparation de jus de fruits et légumes"
    },
    {
      "id": "10.32Z",
      "label": "Préparation de jus de fruits et légumes"
    },
    {
      "id": "10.39",
      "label": "Autre transformation et conservation de fruits et légumes"
    },
    {
      "id": "10.39A",
      "label": "Autre transformation et conservation de légumes"
    },
    {
      "id": "10.39B",
      "label": "Transformation et conservation de fruits"
    },
    {
      "id": "10.4",
      "label": "Fabrication d’huiles et graisses végétales et animales"
    },
    {
      "id": "10.41",
      "label": "Fabrication d'huiles et graisses"
    },
    {
      "id": "10.41A",
      "label": "Fabrication d'huiles et graisses brutes"
    },
    {
      "id": "10.41B",
      "label": "Fabrication d'huiles et graisses raffinées"
    },
    {
      "id": "10.42",
      "label": "Fabrication de margarine et graisses comestibles similaires"
    },
    {
      "id": "10.42Z",
      "label": "Fabrication de margarine et graisses comestibles similaires"
    },
    {
      "id": "10.5",
      "label": "Fabrication de produits laitiers"
    },
    {
      "id": "10.51",
      "label": "Exploitation de laiteries et fabrication de fromage"
    },
    {
      "id": "10.51A",
      "label": "Fabrication de lait liquide et de produits frais"
    },
    {
      "id": "10.51B",
      "label": "Fabrication de beurre"
    },
    {
      "id": "10.51C",
      "label": "Fabrication de fromage"
    },
    {
      "id": "10.51D",
      "label": "Fabrication d'autres produits laitiers"
    },
    {
      "id": "10.52",
      "label": "Fabrication de glaces et sorbets"
    },
    {
      "id": "10.52Z",
      "label": "Fabrication de glaces et sorbets"
    },
    {
      "id": "10.6",
      "label": "Travail des grains ; fabrication de produits amylacés"
    },
    {
      "id": "10.61",
      "label": "Travail des grains"
    },
    {
      "id": "10.61A",
      "label": "Meunerie"
    },
    {
      "id": "10.61B",
      "label": "Autres activités du travail des grains"
    },
    {
      "id": "10.62",
      "label": "Fabrication de produits amylacés"
    },
    {
      "id": "10.62Z",
      "label": "Fabrication de produits amylacés"
    },
    {
      "id": "10.7",
      "label": "Fabrication de produits de boulangerie-pâtisserie et de pâtes alimentaires"
    },
    {
      "id": "10.71",
      "label": "Fabrication de pain et de pâtisserie fraîche"
    },
    {
      "id": "10.71A",
      "label": "Fabrication industrielle de pain et de pâtisserie fraîche"
    },
    {
      "id": "10.71B",
      "label": "Cuisson de produits de boulangerie"
    },
    {
      "id": "10.71C",
      "label": "Boulangerie et boulangerie-pâtisserie"
    },
    {
      "id": "10.71D",
      "label": "Pâtisserie"
    },
    {
      "id": "10.72",
      "label": "Fabrication de biscuits, biscottes et pâtisseries de conservation"
    },
    {
      "id": "10.72Z",
      "label": "Fabrication de biscuits, biscottes et pâtisseries de conservation"
    },
    {
      "id": "10.73",
      "label": "Fabrication de pâtes alimentaires"
    },
    {
      "id": "10.73Z",
      "label": "Fabrication de pâtes alimentaires"
    },
    {
      "id": "10.8",
      "label": "Fabrication d'autres produits alimentaires"
    },
    {
      "id": "10.81",
      "label": "Fabrication de sucre"
    },
    {
      "id": "10.81Z",
      "label": "Fabrication de sucre"
    },
    {
      "id": "10.82",
      "label": "Fabrication de cacao, chocolat et de produits de confiserie"
    },
    {
      "id": "10.82Z",
      "label": "Fabrication de cacao, chocolat et de produits de confiserie"
    },
    {
      "id": "10.83",
      "label": "Transformation du thé et du café"
    },
    {
      "id": "10.83Z",
      "label": "Transformation du thé et du café"
    },
    {
      "id": "10.84",
      "label": "Fabrication de condiments et assaisonnements"
    },
    {
      "id": "10.84Z",
      "label": "Fabrication de condiments et assaisonnements"
    },
    {
      "id": "10.85",
      "label": "Fabrication de plats préparés"
    },
    {
      "id": "10.85Z",
      "label": "Fabrication de plats préparés"
    },
    {
      "id": "10.86",
      "label": "Fabrication d'aliments homogénéisés et diététiques"
    },
    {
      "id": "10.86Z",
      "label": "Fabrication d'aliments homogénéisés et diététiques"
    },
    {
      "id": "10.89",
      "label": "Fabrication d'autres produits alimentaires n.c.a."
    },
    {
      "id": "10.89Z",
      "label": "Fabrication d'autres produits alimentaires n.c.a."
    },
    {
      "id": "10.9",
      "label": "Fabrication d'aliments pour animaux"
    },
    {
      "id": "10.91",
      "label": "Fabrication d'aliments pour animaux de ferme"
    },
    {
      "id": "10.91Z",
      "label": "Fabrication d'aliments pour animaux de ferme"
    },
    {
      "id": "10.92",
      "label": "Fabrication d'aliments pour animaux de compagnie"
    },
    {
      "id": "10.92Z",
      "label": "Fabrication d'aliments pour animaux de compagnie"
    },
    {
      "id": "11",
      "label": "Fabrication de boissons"
    },
    {
      "id": "11.0",
      "label": "Fabrication de boissons"
    },
    {
      "id": "11.01",
      "label": "Production de boissons alcooliques distillées"
    },
    {
      "id": "11.01Z",
      "label": "Production de boissons alcooliques distillées"
    },
    {
      "id": "11.02",
      "label": "Production de vin (de raisin)"
    },
    {
      "id": "11.02A",
      "label": "Fabrication de vins effervescents"
    },
    {
      "id": "11.02B",
      "label": "Vinification"
    },
    {
      "id": "11.03",
      "label": "Fabrication de cidre et de vins de fruits"
    },
    {
      "id": "11.03Z",
      "label": "Fabrication de cidre et de vins de fruits"
    },
    {
      "id": "11.04",
      "label": "Production d'autres boissons fermentées non distillées"
    },
    {
      "id": "11.04Z",
      "label": "Production d'autres boissons fermentées non distillées"
    },
    {
      "id": "11.05",
      "label": "Fabrication de bière"
    },
    {
      "id": "11.05Z",
      "label": "Fabrication de bière"
    },
    {
      "id": "11.06",
      "label": "Fabrication de malt"
    },
    {
      "id": "11.06Z",
      "label": "Fabrication de malt"
    },
    {
      "id": "11.07",
      "label": "Industrie des eaux minérales et autres eaux embouteillées et des boissons rafraîchissantes"
    },
    {
      "id": "11.07A",
      "label": "Industrie des eaux de table"
    },
    {
      "id": "11.07B",
      "label": "Production de boissons rafraîchissantes"
    },
    {
      "id": "12",
      "label": "Fabrication de produits à base de tabac"
    },
    {
      "id": "12.0",
      "label": "Fabrication de produits à base de tabac"
    },
    {
      "id": "12.00",
      "label": "Fabrication de produits à base de tabac"
    },
    {
      "id": "12.00Z",
      "label": "Fabrication de produits à base de tabac"
    },
    {
      "id": "13",
      "label": "Fabrication de textiles"
    },
    {
      "id": "13.1",
      "label": "Préparation de fibres textiles et filature"
    },
    {
      "id": "13.10",
      "label": "Préparation de fibres textiles et filature"
    },
    {
      "id": "13.10Z",
      "label": "Préparation de fibres textiles et filature"
    },
    {
      "id": "13.2",
      "label": "Tissage"
    },
    {
      "id": "13.20",
      "label": "Tissage"
    },
    {
      "id": "13.20Z",
      "label": "Tissage"
    },
    {
      "id": "13.3",
      "label": "Ennoblissement textile"
    },
    {
      "id": "13.30",
      "label": "Ennoblissement textile"
    },
    {
      "id": "13.30Z",
      "label": "Ennoblissement textile"
    },
    {
      "id": "13.9",
      "label": "Fabrication d'autres textiles"
    },
    {
      "id": "13.91",
      "label": "Fabrication d'étoffes à mailles"
    },
    {
      "id": "13.91Z",
      "label": "Fabrication d'étoffes à mailles"
    },
    {
      "id": "13.92",
      "label": "Fabrication d'articles textiles, sauf habillement"
    },
    {
      "id": "13.92Z",
      "label": "Fabrication d'articles textiles, sauf habillement"
    },
    {
      "id": "13.93",
      "label": "Fabrication de tapis et moquettes"
    },
    {
      "id": "13.93Z",
      "label": "Fabrication de tapis et moquettes"
    },
    {
      "id": "13.94",
      "label": "Fabrication de ficelles, cordes et filets"
    },
    {
      "id": "13.94Z",
      "label": "Fabrication de ficelles, cordes et filets"
    },
    {
      "id": "13.95",
      "label": "Fabrication de non-tissés, sauf habillement"
    },
    {
      "id": "13.95Z",
      "label": "Fabrication de non-tissés, sauf habillement"
    },
    {
      "id": "13.96",
      "label": "Fabrication d'autres textiles techniques et industriels"
    },
    {
      "id": "13.96Z",
      "label": "Fabrication d'autres textiles techniques et industriels"
    },
    {
      "id": "13.99",
      "label": "Fabrication d'autres textiles n.c.a."
    },
    {
      "id": "13.99Z",
      "label": "Fabrication d'autres textiles n.c.a."
    },
    {
      "id": "14",
      "label": "Industrie de l'habillement"
    },
    {
      "id": "14.1",
      "label": "Fabrication de vêtements, autres qu'en fourrure"
    },
    {
      "id": "14.11",
      "label": "Fabrication de vêtements en cuir"
    },
    {
      "id": "14.11Z",
      "label": "Fabrication de vêtements en cuir"
    },
    {
      "id": "14.12",
      "label": "Fabrication de vêtements de travail"
    },
    {
      "id": "14.12Z",
      "label": "Fabrication de vêtements de travail"
    },
    {
      "id": "14.13",
      "label": "Fabrication de vêtements de dessus"
    },
    {
      "id": "14.13Z",
      "label": "Fabrication de vêtements de dessus"
    },
    {
      "id": "14.14",
      "label": "Fabrication de vêtements de dessous"
    },
    {
      "id": "14.14Z",
      "label": "Fabrication de vêtements de dessous"
    },
    {
      "id": "14.19",
      "label": "Fabrication d'autres vêtements et accessoires"
    },
    {
      "id": "14.19Z",
      "label": "Fabrication d'autres vêtements et accessoires"
    },
    {
      "id": "14.2",
      "label": "Fabrication d'articles en fourrure"
    },
    {
      "id": "14.20",
      "label": "Fabrication d'articles en fourrure"
    },
    {
      "id": "14.20Z",
      "label": "Fabrication d'articles en fourrure"
    },
    {
      "id": "14.3",
      "label": "Fabrication d'articles à mailles"
    },
    {
      "id": "14.31",
      "label": "Fabrication d'articles chaussants à mailles"
    },
    {
      "id": "14.31Z",
      "label": "Fabrication d'articles chaussants à mailles"
    },
    {
      "id": "14.39",
      "label": "Fabrication d'autres articles à mailles"
    },
    {
      "id": "14.39Z",
      "label": "Fabrication d'autres articles à mailles"
    },
    {
      "id": "15",
      "label": "Industrie du cuir et de la chaussure"
    },
    {
      "id": "15.1",
      "label": "Apprêt et tannage des cuirs ; préparation et teinture des fourrures ; fabrication d'articles de voyage, de maroquinerie et de sellerie"
    },
    {
      "id": "15.11",
      "label": "Apprêt et tannage des cuirs ; préparation et teinture des fourrures"
    },
    {
      "id": "15.11Z",
      "label": "Apprêt et tannage des cuirs ; préparation et teinture des fourrures"
    },
    {
      "id": "15.12",
      "label": "Fabrication d'articles de voyage, de maroquinerie et de sellerie"
    },
    {
      "id": "15.12Z",
      "label": "Fabrication d'articles de voyage, de maroquinerie et de sellerie"
    },
    {
      "id": "15.2",
      "label": "Fabrication de chaussures"
    },
    {
      "id": "15.20",
      "label": "Fabrication de chaussures"
    },
    {
      "id": "15.20Z",
      "label": "Fabrication de chaussures"
    },
    {
      "id": "16",
      "label": "Travail du bois et fabrication d'articles en bois et en liège, à l’exception des meubles ; fabrication d’articles en vannerie et sparterie"
    },
    {
      "id": "16.1",
      "label": "Sciage et rabotage du bois"
    },
    {
      "id": "16.10",
      "label": "Sciage et rabotage du bois"
    },
    {
      "id": "16.10A",
      "label": "Sciage et rabotage du bois, hors imprégnation"
    },
    {
      "id": "16.10B",
      "label": "Imprégnation du bois"
    },
    {
      "id": "16.2",
      "label": "Fabrication d'articles en bois, liège, vannerie et sparterie"
    },
    {
      "id": "16.21",
      "label": "Fabrication de placage et de panneaux de bois"
    },
    {
      "id": "16.21Z",
      "label": "Fabrication de placage et de panneaux de bois"
    },
    {
      "id": "16.22",
      "label": "Fabrication de parquets assemblés"
    },
    {
      "id": "16.22Z",
      "label": "Fabrication de parquets assemblés"
    },
    {
      "id": "16.23",
      "label": "Fabrication de charpentes et d'autres menuiseries"
    },
    {
      "id": "16.23Z",
      "label": "Fabrication de charpentes et d'autres menuiseries"
    },
    {
      "id": "16.24",
      "label": "Fabrication d'emballages en bois"
    },
    {
      "id": "16.24Z",
      "label": "Fabrication d'emballages en bois"
    },
    {
      "id": "16.29",
      "label": "Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie"
    },
    {
      "id": "16.29Z",
      "label": "Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie"
    },
    {
      "id": "17",
      "label": "Industrie du papier et du carton"
    },
    {
      "id": "17.1",
      "label": "Fabrication de pâte à papier, de papier et de carton"
    },
    {
      "id": "17.11",
      "label": "Fabrication de pâte à papier"
    },
    {
      "id": "17.11Z",
      "label": "Fabrication de pâte à papier"
    },
    {
      "id": "17.12",
      "label": "Fabrication de papier et de carton"
    },
    {
      "id": "17.12Z",
      "label": "Fabrication de papier et de carton"
    },
    {
      "id": "17.2",
      "label": "Fabrication d'articles en papier ou en carton"
    },
    {
      "id": "17.21",
      "label": "Fabrication de papier et carton ondulés et d'emballages en papier ou en carton"
    },
    {
      "id": "17.21A",
      "label": "Fabrication de carton ondulé"
    },
    {
      "id": "17.21B",
      "label": "Fabrication de cartonnages"
    },
    {
      "id": "17.21C",
      "label": "Fabrication d'emballages en papier"
    },
    {
      "id": "17.22",
      "label": "Fabrication d'articles en papier à usage sanitaire ou domestique"
    },
    {
      "id": "17.22Z",
      "label": "Fabrication d'articles en papier à usage sanitaire ou domestique"
    },
    {
      "id": "17.23",
      "label": "Fabrication d'articles de papeterie"
    },
    {
      "id": "17.23Z",
      "label": "Fabrication d'articles de papeterie"
    },
    {
      "id": "17.24",
      "label": "Fabrication de papiers peints"
    },
    {
      "id": "17.24Z",
      "label": "Fabrication de papiers peints"
    },
    {
      "id": "17.29",
      "label": "Fabrication d'autres articles en papier ou en carton"
    },
    {
      "id": "17.29Z",
      "label": "Fabrication d'autres articles en papier ou en carton"
    },
    {
      "id": "18",
      "label": "Imprimerie et reproduction d'enregistrements"
    },
    {
      "id": "18.1",
      "label": "Imprimerie et services annexes"
    },
    {
      "id": "18.11",
      "label": "Imprimerie de journaux"
    },
    {
      "id": "18.11Z",
      "label": "Imprimerie de journaux"
    },
    {
      "id": "18.12",
      "label": "Autre imprimerie (labeur)"
    },
    {
      "id": "18.12Z",
      "label": "Autre imprimerie (labeur)"
    },
    {
      "id": "18.13",
      "label": "Activités de pré-presse"
    },
    {
      "id": "18.13Z",
      "label": "Activités de pré-presse"
    },
    {
      "id": "18.14",
      "label": "Reliure et activités connexes"
    },
    {
      "id": "18.14Z",
      "label": "Reliure et activités connexes"
    },
    {
      "id": "18.2",
      "label": "Reproduction d'enregistrements"
    },
    {
      "id": "18.20",
      "label": "Reproduction d'enregistrements"
    },
    {
      "id": "18.20Z",
      "label": "Reproduction d'enregistrements"
    },
    {
      "id": "19",
      "label": "Cokéfaction et raffinage"
    },
    {
      "id": "19.1",
      "label": "Cokéfaction"
    },
    {
      "id": "19.10",
      "label": "Cokéfaction"
    },
    {
      "id": "19.10Z",
      "label": "Cokéfaction"
    },
    {
      "id": "19.2",
      "label": "Raffinage du pétrole"
    },
    {
      "id": "19.20",
      "label": "Raffinage du pétrole"
    },
    {
      "id": "19.20Z",
      "label": "Raffinage du pétrole"
    },
    {
      "id": "20",
      "label": "Industrie chimique"
    },
    {
      "id": "20.1",
      "label": "Fabrication de produits chimiques de base, de produits azotés et d'engrais, de matières plastiques de base et de caoutchouc synthétique"
    },
    {
      "id": "20.11",
      "label": "Fabrication de gaz industriels"
    },
    {
      "id": "20.11Z",
      "label": "Fabrication de gaz industriels"
    },
    {
      "id": "20.12",
      "label": "Fabrication de colorants et de pigments"
    },
    {
      "id": "20.12Z",
      "label": "Fabrication de colorants et de pigments"
    },
    {
      "id": "20.13",
      "label": "Fabrication d'autres produits chimiques inorganiques de base"
    },
    {
      "id": "20.13A",
      "label": "Enrichissement et  retraitement de matières nucléaires"
    },
    {
      "id": "20.13B",
      "label": "Fabrication d'autres produits chimiques inorganiques de base n.c.a."
    },
    {
      "id": "20.14",
      "label": "Fabrication d'autres produits chimiques organiques de base"
    },
    {
      "id": "20.14Z",
      "label": "Fabrication d'autres produits chimiques organiques de base"
    },
    {
      "id": "20.15",
      "label": "Fabrication de produits azotés et d'engrais"
    },
    {
      "id": "20.15Z",
      "label": "Fabrication de produits azotés et d'engrais"
    },
    {
      "id": "20.16",
      "label": "Fabrication de matières plastiques de base"
    },
    {
      "id": "20.16Z",
      "label": "Fabrication de matières plastiques de base"
    },
    {
      "id": "20.17",
      "label": "Fabrication de caoutchouc synthétique"
    },
    {
      "id": "20.17Z",
      "label": "Fabrication de caoutchouc synthétique"
    },
    {
      "id": "20.2",
      "label": "Fabrication de pesticides et d’autres produits agrochimiques"
    },
    {
      "id": "20.20",
      "label": "Fabrication de pesticides et d’autres produits agrochimiques"
    },
    {
      "id": "20.20Z",
      "label": "Fabrication de pesticides et d’autres produits agrochimiques"
    },
    {
      "id": "20.3",
      "label": "Fabrication de peintures, vernis, encres et mastics"
    },
    {
      "id": "20.30",
      "label": "Fabrication de peintures, vernis, encres et mastics"
    },
    {
      "id": "20.30Z",
      "label": "Fabrication de peintures, vernis, encres et mastics"
    },
    {
      "id": "20.4",
      "label": "Fabrication de savons, de produits d'entretien et de parfums"
    },
    {
      "id": "20.41",
      "label": "Fabrication de savons, détergents et produits d'entretien"
    },
    {
      "id": "20.41Z",
      "label": "Fabrication de savons, détergents et produits d'entretien"
    },
    {
      "id": "20.42",
      "label": "Fabrication de parfums et de produits pour la toilette"
    },
    {
      "id": "20.42Z",
      "label": "Fabrication de parfums et de produits pour la toilette"
    },
    {
      "id": "20.5",
      "label": "Fabrication d'autres produits chimiques"
    },
    {
      "id": "20.51",
      "label": "Fabrication de produits explosifs"
    },
    {
      "id": "20.51Z",
      "label": "Fabrication de produits explosifs"
    },
    {
      "id": "20.52",
      "label": "Fabrication de colles"
    },
    {
      "id": "20.52Z",
      "label": "Fabrication de colles"
    },
    {
      "id": "20.53",
      "label": "Fabrication d'huiles essentielles"
    },
    {
      "id": "20.53Z",
      "label": "Fabrication d'huiles essentielles"
    },
    {
      "id": "20.59",
      "label": "Fabrication d'autres produits chimiques n.c.a."
    },
    {
      "id": "20.59Z",
      "label": "Fabrication d'autres produits chimiques n.c.a."
    },
    {
      "id": "20.6",
      "label": "Fabrication de fibres artificielles ou synthétiques"
    },
    {
      "id": "20.60",
      "label": "Fabrication de fibres artificielles ou synthétiques"
    },
    {
      "id": "20.60Z",
      "label": "Fabrication de fibres artificielles ou synthétiques"
    },
    {
      "id": "21",
      "label": "Industrie pharmaceutique"
    },
    {
      "id": "21.1",
      "label": "Fabrication de produits pharmaceutiques de base"
    },
    {
      "id": "21.10",
      "label": "Fabrication de produits pharmaceutiques de base"
    },
    {
      "id": "21.10Z",
      "label": "Fabrication de produits pharmaceutiques de base"
    },
    {
      "id": "21.2",
      "label": "Fabrication de préparations pharmaceutiques"
    },
    {
      "id": "21.20",
      "label": "Fabrication de préparations pharmaceutiques"
    },
    {
      "id": "21.20Z",
      "label": "Fabrication de préparations pharmaceutiques"
    },
    {
      "id": "22",
      "label": "Fabrication de produits en caoutchouc et en plastique"
    },
    {
      "id": "22.1",
      "label": "Fabrication de produits en caoutchouc"
    },
    {
      "id": "22.11",
      "label": "Fabrication et rechapage de pneumatiques"
    },
    {
      "id": "22.11Z",
      "label": "Fabrication et rechapage de pneumatiques"
    },
    {
      "id": "22.19",
      "label": "Fabrication d'autres articles en caoutchouc"
    },
    {
      "id": "22.19Z",
      "label": "Fabrication d'autres articles en caoutchouc"
    },
    {
      "id": "22.2",
      "label": "Fabrication  de produits en plastique"
    },
    {
      "id": "22.21",
      "label": "Fabrication de plaques, feuilles, tubes et profilés en matières plastiques"
    },
    {
      "id": "22.21Z",
      "label": "Fabrication de plaques, feuilles, tubes et profilés en matières plastiques"
    },
    {
      "id": "22.22",
      "label": "Fabrication d'emballages en matières plastiques"
    },
    {
      "id": "22.22Z",
      "label": "Fabrication d'emballages en matières plastiques"
    },
    {
      "id": "22.23",
      "label": "Fabrication d'éléments en matières plastiques pour la construction"
    },
    {
      "id": "22.23Z",
      "label": "Fabrication d'éléments en matières plastiques pour la construction"
    },
    {
      "id": "22.29",
      "label": "Fabrication d'autres articles en matières plastiques"
    },
    {
      "id": "22.29A",
      "label": "Fabrication de pièces techniques à base de matières plastiques"
    },
    {
      "id": "22.29B",
      "label": "Fabrication de produits de consommation courante en matières plastiques"
    },
    {
      "id": "23",
      "label": "Fabrication d'autres produits minéraux non métalliques"
    },
    {
      "id": "23.1",
      "label": "Fabrication de verre et d'articles en verre"
    },
    {
      "id": "23.11",
      "label": "Fabrication de verre plat"
    },
    {
      "id": "23.11Z",
      "label": "Fabrication de verre plat"
    },
    {
      "id": "23.12",
      "label": "Façonnage et transformation du verre plat"
    },
    {
      "id": "23.12Z",
      "label": "Façonnage et transformation du verre plat"
    },
    {
      "id": "23.13",
      "label": "Fabrication de verre creux"
    },
    {
      "id": "23.13Z",
      "label": "Fabrication de verre creux"
    },
    {
      "id": "23.14",
      "label": "Fabrication de fibres de verre"
    },
    {
      "id": "23.14Z",
      "label": "Fabrication de fibres de verre"
    },
    {
      "id": "23.19",
      "label": "Fabrication et façonnage d'autres articles en verre, y compris verre technique"
    },
    {
      "id": "23.19Z",
      "label": "Fabrication et façonnage d'autres articles en verre, y compris verre technique"
    },
    {
      "id": "23.2",
      "label": "Fabrication de produits réfractaires"
    },
    {
      "id": "23.20",
      "label": "Fabrication de produits réfractaires"
    },
    {
      "id": "23.20Z",
      "label": "Fabrication de produits réfractaires"
    },
    {
      "id": "23.3",
      "label": "Fabrication de matériaux de construction en terre cuite"
    },
    {
      "id": "23.31",
      "label": "Fabrication de carreaux en céramique"
    },
    {
      "id": "23.31Z",
      "label": "Fabrication de carreaux en céramique"
    },
    {
      "id": "23.32",
      "label": "Fabrication de briques, tuiles et produits de construction, en terre cuite"
    },
    {
      "id": "23.32Z",
      "label": "Fabrication de briques, tuiles et produits de construction, en terre cuite"
    },
    {
      "id": "23.4",
      "label": "Fabrication d'autres produits en céramique et en porcelaine"
    },
    {
      "id": "23.41",
      "label": "Fabrication d'articles céramiques à usage domestique ou ornemental"
    },
    {
      "id": "23.41Z",
      "label": "Fabrication d'articles céramiques à usage domestique ou ornemental"
    },
    {
      "id": "23.42",
      "label": "Fabrication d'appareils sanitaires en céramique"
    },
    {
      "id": "23.42Z",
      "label": "Fabrication d'appareils sanitaires en céramique"
    },
    {
      "id": "23.43",
      "label": "Fabrication d'isolateurs et pièces isolantes en céramique"
    },
    {
      "id": "23.43Z",
      "label": "Fabrication d'isolateurs et pièces isolantes en céramique"
    },
    {
      "id": "23.44",
      "label": "Fabrication d'autres produits céramiques à usage technique"
    },
    {
      "id": "23.44Z",
      "label": "Fabrication d'autres produits céramiques à usage technique"
    },
    {
      "id": "23.49",
      "label": "Fabrication d'autres produits céramiques"
    },
    {
      "id": "23.49Z",
      "label": "Fabrication d'autres produits céramiques"
    },
    {
      "id": "23.5",
      "label": "Fabrication de ciment, chaux et plâtre"
    },
    {
      "id": "23.51",
      "label": "Fabrication de ciment"
    },
    {
      "id": "23.51Z",
      "label": "Fabrication de ciment"
    },
    {
      "id": "23.52",
      "label": "Fabrication de chaux et plâtre"
    },
    {
      "id": "23.52Z",
      "label": "Fabrication de chaux et plâtre"
    },
    {
      "id": "23.6",
      "label": "Fabrication d'ouvrages en béton, en ciment ou en plâtre"
    },
    {
      "id": "23.61",
      "label": "Fabrication d'éléments en béton pour la construction"
    },
    {
      "id": "23.61Z",
      "label": "Fabrication d'éléments en béton pour la construction"
    },
    {
      "id": "23.62",
      "label": "Fabrication d'éléments en plâtre pour la construction"
    },
    {
      "id": "23.62Z",
      "label": "Fabrication d'éléments en plâtre pour la construction"
    },
    {
      "id": "23.63",
      "label": "Fabrication de béton prêt à l'emploi"
    },
    {
      "id": "23.63Z",
      "label": "Fabrication de béton prêt à l'emploi"
    },
    {
      "id": "23.64",
      "label": "Fabrication de mortiers et bétons secs"
    },
    {
      "id": "23.64Z",
      "label": "Fabrication de mortiers et bétons secs"
    },
    {
      "id": "23.65",
      "label": "Fabrication d'ouvrages en fibre-ciment"
    },
    {
      "id": "23.65Z",
      "label": "Fabrication d'ouvrages en fibre-ciment"
    },
    {
      "id": "23.69",
      "label": "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre"
    },
    {
      "id": "23.69Z",
      "label": "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre"
    },
    {
      "id": "23.7",
      "label": "Taille, façonnage et finissage de pierres"
    },
    {
      "id": "23.70",
      "label": "Taille, façonnage et finissage de pierres"
    },
    {
      "id": "23.70Z",
      "label": "Taille, façonnage et finissage de pierres"
    },
    {
      "id": "23.9",
      "label": "Fabrication de produits abrasifs et de produits minéraux non métalliques n.c.a."
    },
    {
      "id": "23.91",
      "label": "Fabrication de produits abrasifs"
    },
    {
      "id": "23.91Z",
      "label": "Fabrication de produits abrasifs"
    },
    {
      "id": "23.99",
      "label": "Fabrication d'autres produits minéraux non métalliques n.c.a."
    },
    {
      "id": "23.99Z",
      "label": "Fabrication d'autres produits minéraux non métalliques n.c.a."
    },
    {
      "id": "24",
      "label": "Métallurgie"
    },
    {
      "id": "24.1",
      "label": "Sidérurgie"
    },
    {
      "id": "24.10",
      "label": "Sidérurgie"
    },
    {
      "id": "24.10Z",
      "label": "Sidérurgie"
    },
    {
      "id": "24.2",
      "label": "Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier"
    },
    {
      "id": "24.20",
      "label": "Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier"
    },
    {
      "id": "24.20Z",
      "label": "Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier"
    },
    {
      "id": "24.3",
      "label": "Fabrication d'autres produits de première transformation de l'acier"
    },
    {
      "id": "24.31",
      "label": "Étirage à froid de barres"
    },
    {
      "id": "24.31Z",
      "label": "Étirage à froid de barres"
    },
    {
      "id": "24.32",
      "label": "Laminage à froid de feuillards"
    },
    {
      "id": "24.32Z",
      "label": "Laminage à froid de feuillards"
    },
    {
      "id": "24.33",
      "label": "Profilage à froid par formage ou pliage"
    },
    {
      "id": "24.33Z",
      "label": "Profilage à froid par formage ou pliage"
    },
    {
      "id": "24.34",
      "label": "Tréfilage à froid"
    },
    {
      "id": "24.34Z",
      "label": "Tréfilage à froid"
    },
    {
      "id": "24.4",
      "label": "Production de métaux précieux et d'autres métaux non ferreux"
    },
    {
      "id": "24.41",
      "label": "Production de métaux précieux"
    },
    {
      "id": "24.41Z",
      "label": "Production de métaux précieux"
    },
    {
      "id": "24.42",
      "label": "Métallurgie de l'aluminium"
    },
    {
      "id": "24.42Z",
      "label": "Métallurgie de l'aluminium"
    },
    {
      "id": "24.43",
      "label": "Métallurgie du plomb, du zinc ou de l'étain"
    },
    {
      "id": "24.43Z",
      "label": "Métallurgie du plomb, du zinc ou de l'étain"
    },
    {
      "id": "24.44",
      "label": "Métallurgie du cuivre"
    },
    {
      "id": "24.44Z",
      "label": "Métallurgie du cuivre"
    },
    {
      "id": "24.45",
      "label": "Métallurgie des autres métaux non ferreux"
    },
    {
      "id": "24.45Z",
      "label": "Métallurgie des autres métaux non ferreux"
    },
    {
      "id": "24.46",
      "label": "Élaboration et transformation de matières nucléaires"
    },
    {
      "id": "24.46Z",
      "label": "Élaboration et transformation de matières nucléaires"
    },
    {
      "id": "24.5",
      "label": "Fonderie"
    },
    {
      "id": "24.51",
      "label": "Fonderie de fonte"
    },
    {
      "id": "24.51Z",
      "label": "Fonderie de fonte"
    },
    {
      "id": "24.52",
      "label": "Fonderie d'acier"
    },
    {
      "id": "24.52Z",
      "label": "Fonderie d'acier"
    },
    {
      "id": "24.53",
      "label": "Fonderie de métaux légers"
    },
    {
      "id": "24.53Z",
      "label": "Fonderie de métaux légers"
    },
    {
      "id": "24.54",
      "label": "Fonderie d'autres métaux non ferreux"
    },
    {
      "id": "24.54Z",
      "label": "Fonderie d'autres métaux non ferreux"
    },
    {
      "id": "25",
      "label": "Fabrication de produits métalliques, à l’exception des machines et des équipements"
    },
    {
      "id": "25.1",
      "label": "Fabrication d'éléments en métal pour la construction"
    },
    {
      "id": "25.11",
      "label": "Fabrication de structures métalliques et de parties de structures"
    },
    {
      "id": "25.11Z",
      "label": "Fabrication de structures métalliques et de parties de structures"
    },
    {
      "id": "25.12",
      "label": "Fabrication de portes et fenêtres en métal"
    },
    {
      "id": "25.12Z",
      "label": "Fabrication de portes et fenêtres en métal"
    },
    {
      "id": "25.2",
      "label": "Fabrication de réservoirs, citernes et conteneurs métalliques"
    },
    {
      "id": "25.21",
      "label": "Fabrication de radiateurs et de chaudières pour le chauffage central"
    },
    {
      "id": "25.21Z",
      "label": "Fabrication de radiateurs et de chaudières pour le chauffage central"
    },
    {
      "id": "25.29",
      "label": "Fabrication d'autres réservoirs, citernes et conteneurs métalliques"
    },
    {
      "id": "25.29Z",
      "label": "Fabrication d'autres réservoirs, citernes et conteneurs métalliques"
    },
    {
      "id": "25.3",
      "label": "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central"
    },
    {
      "id": "25.30",
      "label": "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central"
    },
    {
      "id": "25.30Z",
      "label": "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central"
    },
    {
      "id": "25.4",
      "label": "Fabrication d'armes et de munitions"
    },
    {
      "id": "25.40",
      "label": "Fabrication d'armes et de munitions"
    },
    {
      "id": "25.40Z",
      "label": "Fabrication d'armes et de munitions"
    },
    {
      "id": "25.5",
      "label": "Forge, emboutissage, estampage ; métallurgie des poudres"
    },
    {
      "id": "25.50",
      "label": "Forge, emboutissage, estampage ; métallurgie des poudres"
    },
    {
      "id": "25.50A",
      "label": "Forge, estampage, matriçage ; métallurgie des poudres"
    },
    {
      "id": "25.50B",
      "label": "Découpage, emboutissage"
    },
    {
      "id": "25.6",
      "label": "Traitement et revêtement des métaux ; usinage"
    },
    {
      "id": "25.61",
      "label": "Traitement et revêtement des métaux"
    },
    {
      "id": "25.61Z",
      "label": "Traitement et revêtement des métaux"
    },
    {
      "id": "25.62",
      "label": "Usinage"
    },
    {
      "id": "25.62A",
      "label": "Décolletage"
    },
    {
      "id": "25.62B",
      "label": "Mécanique industrielle"
    },
    {
      "id": "25.7",
      "label": "Fabrication de coutellerie, d'outillage et de quincaillerie"
    },
    {
      "id": "25.71",
      "label": "Fabrication de coutellerie"
    },
    {
      "id": "25.71Z",
      "label": "Fabrication de coutellerie"
    },
    {
      "id": "25.72",
      "label": "Fabrication de serrures et de ferrures"
    },
    {
      "id": "25.72Z",
      "label": "Fabrication de serrures et de ferrures"
    },
    {
      "id": "25.73",
      "label": "Fabrication d'outillage"
    },
    {
      "id": "25.73A",
      "label": "Fabrication de moules et modèles"
    },
    {
      "id": "25.73B",
      "label": "Fabrication d'autres outillages"
    },
    {
      "id": "25.9",
      "label": "Fabrication d'autres ouvrages en métaux"
    },
    {
      "id": "25.91",
      "label": "Fabrication de fûts et emballages métalliques similaires"
    },
    {
      "id": "25.91Z",
      "label": "Fabrication de fûts et emballages métalliques similaires"
    },
    {
      "id": "25.92",
      "label": "Fabrication d'emballages métalliques légers"
    },
    {
      "id": "25.92Z",
      "label": "Fabrication d'emballages métalliques légers"
    },
    {
      "id": "25.93",
      "label": "Fabrication d'articles en fils métalliques, de chaînes et de ressorts"
    },
    {
      "id": "25.93Z",
      "label": "Fabrication d'articles en fils métalliques, de chaînes et de ressorts"
    },
    {
      "id": "25.94",
      "label": "Fabrication de vis et de boulons"
    },
    {
      "id": "25.94Z",
      "label": "Fabrication de vis et de boulons"
    },
    {
      "id": "25.99",
      "label": "Fabrication d'autres produits métalliques n.c.a."
    },
    {
      "id": "25.99A",
      "label": "Fabrication d'articles métalliques ménagers"
    },
    {
      "id": "25.99B",
      "label": "Fabrication d'autres articles métalliques"
    },
    {
      "id": "26",
      "label": "Fabrication de produits informatiques, électroniques et optiques"
    },
    {
      "id": "26.1",
      "label": "Fabrication de composants et cartes électroniques"
    },
    {
      "id": "26.11",
      "label": "Fabrication de composants électroniques"
    },
    {
      "id": "26.11Z",
      "label": "Fabrication de composants électroniques"
    },
    {
      "id": "26.12",
      "label": "Fabrication de cartes électroniques assemblées"
    },
    {
      "id": "26.12Z",
      "label": "Fabrication de cartes électroniques assemblées"
    },
    {
      "id": "26.2",
      "label": "Fabrication d'ordinateurs et d'équipements périphériques"
    },
    {
      "id": "26.20",
      "label": "Fabrication d'ordinateurs et d'équipements périphériques"
    },
    {
      "id": "26.20Z",
      "label": "Fabrication d'ordinateurs et d'équipements périphériques"
    },
    {
      "id": "26.3",
      "label": "Fabrication d'équipements de communication"
    },
    {
      "id": "26.30",
      "label": "Fabrication d'équipements de communication"
    },
    {
      "id": "26.30Z",
      "label": "Fabrication d'équipements de communication"
    },
    {
      "id": "26.4",
      "label": "Fabrication de produits électroniques grand public"
    },
    {
      "id": "26.40",
      "label": "Fabrication de produits électroniques grand public"
    },
    {
      "id": "26.40Z",
      "label": "Fabrication de produits électroniques grand public"
    },
    {
      "id": "26.5",
      "label": "Fabrication d'instruments et d'appareils de mesure, d'essai et de navigation ; horlogerie"
    },
    {
      "id": "26.51",
      "label": "Fabrication d'instruments et d'appareils de mesure, d'essai et de navigation"
    },
    {
      "id": "26.51A",
      "label": "Fabrication d'équipements d'aide à la navigation"
    },
    {
      "id": "26.51B",
      "label": "Fabrication d'instrumentation scientifique et technique"
    },
    {
      "id": "26.52",
      "label": "Horlogerie"
    },
    {
      "id": "26.52Z",
      "label": "Horlogerie"
    },
    {
      "id": "26.6",
      "label": "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques"
    },
    {
      "id": "26.60",
      "label": "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques"
    },
    {
      "id": "26.60Z",
      "label": "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques"
    },
    {
      "id": "26.7",
      "label": "Fabrication de matériels optique et photographique"
    },
    {
      "id": "26.70",
      "label": "Fabrication de matériels optique et photographique"
    },
    {
      "id": "26.70Z",
      "label": "Fabrication de matériels optique et photographique"
    },
    {
      "id": "26.8",
      "label": "Fabrication de supports magnétiques et optiques"
    },
    {
      "id": "26.80",
      "label": "Fabrication de supports magnétiques et optiques"
    },
    {
      "id": "26.80Z",
      "label": "Fabrication de supports magnétiques et optiques"
    },
    {
      "id": "27",
      "label": "Fabrication d'équipements électriques"
    },
    {
      "id": "27.1",
      "label": "Fabrication de moteurs, génératrices et transformateurs électriques et de matériel de distribution et de commande électrique"
    },
    {
      "id": "27.11",
      "label": "Fabrication de moteurs, génératrices et transformateurs électriques"
    },
    {
      "id": "27.11Z",
      "label": "Fabrication de moteurs, génératrices et transformateurs électriques"
    },
    {
      "id": "27.12",
      "label": "Fabrication de matériel de distribution et de commande électrique"
    },
    {
      "id": "27.12Z",
      "label": "Fabrication de matériel de distribution et de commande électrique"
    },
    {
      "id": "27.2",
      "label": "Fabrication de piles et d'accumulateurs électriques"
    },
    {
      "id": "27.20",
      "label": "Fabrication de piles et d'accumulateurs électriques"
    },
    {
      "id": "27.20Z",
      "label": "Fabrication de piles et d'accumulateurs électriques"
    },
    {
      "id": "27.3",
      "label": "Fabrication de fils et câbles et de matériel d'installation électrique"
    },
    {
      "id": "27.31",
      "label": "Fabrication de câbles de fibres optiques"
    },
    {
      "id": "27.31Z",
      "label": "Fabrication de câbles de fibres optiques"
    },
    {
      "id": "27.32",
      "label": "Fabrication d'autres fils et câbles électroniques ou électriques"
    },
    {
      "id": "27.32Z",
      "label": "Fabrication d'autres fils et câbles électroniques ou électriques"
    },
    {
      "id": "27.33",
      "label": "Fabrication de matériel d'installation électrique"
    },
    {
      "id": "27.33Z",
      "label": "Fabrication de matériel d'installation électrique"
    },
    {
      "id": "27.4",
      "label": "Fabrication d'appareils d'éclairage électrique"
    },
    {
      "id": "27.40",
      "label": "Fabrication d'appareils d'éclairage électrique"
    },
    {
      "id": "27.40Z",
      "label": "Fabrication d'appareils d'éclairage électrique"
    },
    {
      "id": "27.5",
      "label": "Fabrication d'appareils ménagers"
    },
    {
      "id": "27.51",
      "label": "Fabrication d'appareils électroménagers"
    },
    {
      "id": "27.51Z",
      "label": "Fabrication d'appareils électroménagers"
    },
    {
      "id": "27.52",
      "label": "Fabrication d'appareils ménagers non électriques"
    },
    {
      "id": "27.52Z",
      "label": "Fabrication d'appareils ménagers non électriques"
    },
    {
      "id": "27.9",
      "label": "Fabrication d'autres matériels électriques"
    },
    {
      "id": "27.90",
      "label": "Fabrication d'autres matériels électriques"
    },
    {
      "id": "27.90Z",
      "label": "Fabrication d'autres matériels électriques"
    },
    {
      "id": "28",
      "label": "Fabrication de machines et équipements n.c.a."
    },
    {
      "id": "28.1",
      "label": "Fabrication de machines d'usage général"
    },
    {
      "id": "28.11",
      "label": "Fabrication de moteurs et turbines, à l'exception des moteurs d’avions et de véhicules"
    },
    {
      "id": "28.11Z",
      "label": "Fabrication de moteurs et turbines, à l'exception des moteurs d’avions et de véhicules"
    },
    {
      "id": "28.12",
      "label": "Fabrication d'équipements hydrauliques et pneumatiques"
    },
    {
      "id": "28.12Z",
      "label": "Fabrication d'équipements hydrauliques et pneumatiques"
    },
    {
      "id": "28.13",
      "label": "Fabrication d'autres pompes et compresseurs"
    },
    {
      "id": "28.13Z",
      "label": "Fabrication d'autres pompes et compresseurs"
    },
    {
      "id": "28.14",
      "label": "Fabrication d'autres articles de robinetterie"
    },
    {
      "id": "28.14Z",
      "label": "Fabrication d'autres articles de robinetterie"
    },
    {
      "id": "28.15",
      "label": "Fabrication d'engrenages et d'organes mécaniques de transmission"
    },
    {
      "id": "28.15Z",
      "label": "Fabrication d'engrenages et d'organes mécaniques de transmission"
    },
    {
      "id": "28.2",
      "label": "Fabrication d'autres machines d'usage général"
    },
    {
      "id": "28.21",
      "label": "Fabrication de fours et brûleurs"
    },
    {
      "id": "28.21Z",
      "label": "Fabrication de fours et brûleurs"
    },
    {
      "id": "28.22",
      "label": "Fabrication de matériel de levage et de manutention"
    },
    {
      "id": "28.22Z",
      "label": "Fabrication de matériel de levage et de manutention"
    },
    {
      "id": "28.23",
      "label": "Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)"
    },
    {
      "id": "28.23Z",
      "label": "Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)"
    },
    {
      "id": "28.24",
      "label": "Fabrication d'outillage portatif à moteur incorporé"
    },
    {
      "id": "28.24Z",
      "label": "Fabrication d'outillage portatif à moteur incorporé"
    },
    {
      "id": "28.25",
      "label": "Fabrication d'équipements aérauliques et frigorifiques industriels"
    },
    {
      "id": "28.25Z",
      "label": "Fabrication d'équipements aérauliques et frigorifiques industriels"
    },
    {
      "id": "28.29",
      "label": "Fabrication de machines diverses d'usage général"
    },
    {
      "id": "28.29A",
      "label": "Fabrication d'équipements d'emballage, de conditionnement et de pesage"
    },
    {
      "id": "28.29B",
      "label": "Fabrication d'autres machines d'usage général"
    },
    {
      "id": "28.3",
      "label": "Fabrication de machines agricoles et forestières"
    },
    {
      "id": "28.30",
      "label": "Fabrication de machines agricoles et forestières"
    },
    {
      "id": "28.30Z",
      "label": "Fabrication de machines agricoles et forestières"
    },
    {
      "id": "28.4",
      "label": "Fabrication de machines de formage des métaux et de machines-outils"
    },
    {
      "id": "28.41",
      "label": "Fabrication de machines de formage des métaux"
    },
    {
      "id": "28.41Z",
      "label": "Fabrication de machines-outils pour le travail des métaux"
    },
    {
      "id": "28.49",
      "label": "Fabrication d'autres machines-outils"
    },
    {
      "id": "28.49Z",
      "label": "Fabrication d'autres machines-outils"
    },
    {
      "id": "28.9",
      "label": "Fabrication d'autres machines d'usage spécifique"
    },
    {
      "id": "28.91",
      "label": "Fabrication de machines pour la métallurgie"
    },
    {
      "id": "28.91Z",
      "label": "Fabrication de machines pour la métallurgie"
    },
    {
      "id": "28.92",
      "label": "Fabrication de machines pour l'extraction ou la construction"
    },
    {
      "id": "28.92Z",
      "label": "Fabrication de machines pour l'extraction ou la construction"
    },
    {
      "id": "28.93",
      "label": "Fabrication de machines pour l'industrie agro-alimentaire"
    },
    {
      "id": "28.93Z",
      "label": "Fabrication de machines pour l'industrie agro-alimentaire"
    },
    {
      "id": "28.94",
      "label": "Fabrication de machines pour les industries textiles"
    },
    {
      "id": "28.94Z",
      "label": "Fabrication de machines pour les industries textiles"
    },
    {
      "id": "28.95",
      "label": "Fabrication de machines pour les industries du papier et du carton"
    },
    {
      "id": "28.95Z",
      "label": "Fabrication de machines pour les industries du papier et du carton"
    },
    {
      "id": "28.96",
      "label": "Fabrication de machines pour le travail du caoutchouc ou des plastiques"
    },
    {
      "id": "28.96Z",
      "label": "Fabrication de machines pour le travail du caoutchouc ou des plastiques"
    },
    {
      "id": "28.99",
      "label": "Fabrication d'autres machines d'usage spécifique n.c.a."
    },
    {
      "id": "28.99A",
      "label": "Fabrication de machines d'imprimerie"
    },
    {
      "id": "28.99B",
      "label": "Fabrication d'autres machines spécialisées"
    },
    {
      "id": "29",
      "label": "Industrie automobile"
    },
    {
      "id": "29.1",
      "label": "Construction de véhicules automobiles"
    },
    {
      "id": "29.10",
      "label": "Construction de véhicules automobiles"
    },
    {
      "id": "29.10Z",
      "label": "Construction de véhicules automobiles"
    },
    {
      "id": "29.2",
      "label": "Fabrication de carrosseries et remorques"
    },
    {
      "id": "29.20",
      "label": "Fabrication de carrosseries et remorques"
    },
    {
      "id": "29.20Z",
      "label": "Fabrication de carrosseries et remorques"
    },
    {
      "id": "29.3",
      "label": "Fabrication d'équipements automobiles"
    },
    {
      "id": "29.31",
      "label": "Fabrication d'équipements électriques et électroniques automobiles"
    },
    {
      "id": "29.31Z",
      "label": "Fabrication d'équipements électriques et électroniques automobiles"
    },
    {
      "id": "29.32",
      "label": "Fabrication d'autres équipements automobiles"
    },
    {
      "id": "29.32Z",
      "label": "Fabrication d'autres équipements automobiles"
    },
    {
      "id": "30",
      "label": "Fabrication d'autres matériels de transport"
    },
    {
      "id": "30.1",
      "label": "Construction navale"
    },
    {
      "id": "30.11",
      "label": "Construction de navires et de structures flottantes"
    },
    {
      "id": "30.11Z",
      "label": "Construction de navires et de structures flottantes"
    },
    {
      "id": "30.12",
      "label": "Construction de bateaux de plaisance"
    },
    {
      "id": "30.12Z",
      "label": "Construction de bateaux de plaisance"
    },
    {
      "id": "30.2",
      "label": "Construction de locomotives et d'autre matériel ferroviaire roulant"
    },
    {
      "id": "30.20",
      "label": "Construction de locomotives et d'autre matériel ferroviaire roulant"
    },
    {
      "id": "30.20Z",
      "label": "Construction de locomotives et d'autre matériel ferroviaire roulant"
    },
    {
      "id": "30.3",
      "label": "Construction aéronautique et spatiale"
    },
    {
      "id": "30.30",
      "label": "Construction aéronautique et spatiale"
    },
    {
      "id": "30.30Z",
      "label": "Construction aéronautique et spatiale"
    },
    {
      "id": "30.4",
      "label": "Construction de véhicules militaires de combat"
    },
    {
      "id": "30.40",
      "label": "Construction de véhicules militaires de combat"
    },
    {
      "id": "30.40Z",
      "label": "Construction de véhicules militaires de combat"
    },
    {
      "id": "30.9",
      "label": "Fabrication de matériels de transport n.c.a."
    },
    {
      "id": "30.91",
      "label": "Fabrication de motocycles"
    },
    {
      "id": "30.91Z",
      "label": "Fabrication de motocycles"
    },
    {
      "id": "30.92",
      "label": "Fabrication de bicyclettes et de véhicules pour invalides"
    },
    {
      "id": "30.92Z",
      "label": "Fabrication de bicyclettes et de véhicules pour invalides"
    },
    {
      "id": "30.99",
      "label": "Fabrication d’autres équipements de transport n.c.a."
    },
    {
      "id": "30.99Z",
      "label": "Fabrication d’autres équipements de transport n.c.a."
    },
    {
      "id": "31",
      "label": "Fabrication de meubles"
    },
    {
      "id": "31.0",
      "label": "Fabrication de meubles"
    },
    {
      "id": "31.01",
      "label": "Fabrication de meubles de bureau et de magasin"
    },
    {
      "id": "31.01Z",
      "label": "Fabrication de meubles de bureau et de magasin"
    },
    {
      "id": "31.02",
      "label": "Fabrication de meubles de cuisine"
    },
    {
      "id": "31.02Z",
      "label": "Fabrication de meubles de cuisine"
    },
    {
      "id": "31.03",
      "label": "Fabrication de matelas"
    },
    {
      "id": "31.03Z",
      "label": "Fabrication de matelas"
    },
    {
      "id": "31.09",
      "label": "Fabrication d'autres meubles"
    },
    {
      "id": "31.09A",
      "label": "Fabrication de sièges d'ameublement d'intérieur"
    },
    {
      "id": "31.09B",
      "label": "Fabrication d’autres meubles et industries connexes de l’ameublement"
    },
    {
      "id": "32",
      "label": "Autres industries manufacturières"
    },
    {
      "id": "32.1",
      "label": "Fabrication d’articles de joaillerie, bijouterie et articles similaires"
    },
    {
      "id": "32.11",
      "label": "Frappe de monnaie"
    },
    {
      "id": "32.11Z",
      "label": "Frappe de monnaie"
    },
    {
      "id": "32.12",
      "label": "Fabrication d’articles de joaillerie et bijouterie"
    },
    {
      "id": "32.12Z",
      "label": "Fabrication d’articles de joaillerie et bijouterie"
    },
    {
      "id": "32.13",
      "label": "Fabrication d’articles de bijouterie fantaisie et articles similaires"
    },
    {
      "id": "32.13Z",
      "label": "Fabrication d’articles de bijouterie fantaisie et articles similaires"
    },
    {
      "id": "32.2",
      "label": "Fabrication d'instruments de musique"
    },
    {
      "id": "32.20",
      "label": "Fabrication d'instruments de musique"
    },
    {
      "id": "32.20Z",
      "label": "Fabrication d'instruments de musique"
    },
    {
      "id": "32.3",
      "label": "Fabrication d'articles de sport"
    },
    {
      "id": "32.30",
      "label": "Fabrication d'articles de sport"
    },
    {
      "id": "32.30Z",
      "label": "Fabrication d'articles de sport"
    },
    {
      "id": "32.4",
      "label": "Fabrication de jeux et jouets"
    },
    {
      "id": "32.40",
      "label": "Fabrication de jeux et jouets"
    },
    {
      "id": "32.40Z",
      "label": "Fabrication de jeux et jouets"
    },
    {
      "id": "32.5",
      "label": "Fabrication d'instruments et de fournitures à usage médical et dentaire"
    },
    {
      "id": "32.50",
      "label": "Fabrication d'instruments et de fournitures à usage médical et dentaire"
    },
    {
      "id": "32.50A",
      "label": "Fabrication de matériel médico-chirurgical et dentaire"
    },
    {
      "id": "32.50B",
      "label": "Fabrication de lunettes"
    },
    {
      "id": "32.9",
      "label": "Activités manufacturières n.c.a."
    },
    {
      "id": "32.91",
      "label": "Fabrication d’articles de brosserie"
    },
    {
      "id": "32.91Z",
      "label": "Fabrication d’articles de brosserie"
    },
    {
      "id": "32.99",
      "label": "Autres activités manufacturières n.c.a."
    },
    {
      "id": "32.99Z",
      "label": "Autres activités manufacturières n.c.a."
    },
    {
      "id": "33",
      "label": "Réparation et installation de machines et d'équipements"
    },
    {
      "id": "33.1",
      "label": "Réparation d'ouvrages en métaux, de machines et d'équipements"
    },
    {
      "id": "33.11",
      "label": "Réparation d'ouvrages en métaux"
    },
    {
      "id": "33.11Z",
      "label": "Réparation d'ouvrages en métaux"
    },
    {
      "id": "33.12",
      "label": "Réparation de machines et équipements mécaniques"
    },
    {
      "id": "33.12Z",
      "label": "Réparation de machines et équipements mécaniques"
    },
    {
      "id": "33.13",
      "label": "Réparation de matériels électroniques et optiques"
    },
    {
      "id": "33.13Z",
      "label": "Réparation de matériels électroniques et optiques"
    },
    {
      "id": "33.14",
      "label": "Réparation d'équipements électriques"
    },
    {
      "id": "33.14Z",
      "label": "Réparation d'équipements électriques"
    },
    {
      "id": "33.15",
      "label": "Réparation et maintenance navale"
    },
    {
      "id": "33.15Z",
      "label": "Réparation et maintenance navale"
    },
    {
      "id": "33.16",
      "label": "Réparation et maintenance d'aéronefs et d'engins spatiaux"
    },
    {
      "id": "33.16Z",
      "label": "Réparation et maintenance d'aéronefs et d'engins spatiaux"
    },
    {
      "id": "33.17",
      "label": "Réparation et maintenance d'autres équipements de transport"
    },
    {
      "id": "33.17Z",
      "label": "Réparation et maintenance d'autres équipements de transport"
    },
    {
      "id": "33.19",
      "label": "Réparation d'autres équipements"
    },
    {
      "id": "33.19Z",
      "label": "Réparation d'autres équipements"
    },
    {
      "id": "33.2",
      "label": "Installation de machines et d'équipements industriels"
    },
    {
      "id": "33.20",
      "label": "Installation de machines et d'équipements industriels"
    },
    {
      "id": "33.20A",
      "label": "Installation de structures métalliques, chaudronnées et de tuyauterie"
    },
    {
      "id": "33.20B",
      "label": "Installation de machines et équipements mécaniques"
    },
    {
      "id": "33.20C",
      "label": "Conception d'ensemble et assemblage sur site industriel d'équipements de contrôle des processus industriels"
    },
    {
      "id": "33.20D",
      "label": "Installation d'équipements électriques, de matériels électroniques et optiques ou d'autres matériels"
    },
    {
      "id": "35",
      "label": "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné"
    },
    {
      "id": "35.1",
      "label": "Production, transport et distribution d'électricité"
    },
    {
      "id": "35.11",
      "label": "Production d'électricité"
    },
    {
      "id": "35.11Z",
      "label": "Production d'électricité"
    },
    {
      "id": "35.12",
      "label": "Transport d'électricité"
    },
    {
      "id": "35.12Z",
      "label": "Transport d'électricité"
    },
    {
      "id": "35.13",
      "label": "Distribution d'électricité"
    },
    {
      "id": "35.13Z",
      "label": "Distribution d'électricité"
    },
    {
      "id": "35.14",
      "label": "Commerce d'électricité"
    },
    {
      "id": "35.14Z",
      "label": "Commerce d'électricité"
    },
    {
      "id": "35.2",
      "label": "Production et distribution de combustibles gazeux"
    },
    {
      "id": "35.21",
      "label": "Production de combustibles gazeux"
    },
    {
      "id": "35.21Z",
      "label": "Production de combustibles gazeux"
    },
    {
      "id": "35.22",
      "label": "Distribution de combustibles gazeux par conduites"
    },
    {
      "id": "35.22Z",
      "label": "Distribution de combustibles gazeux par conduites"
    },
    {
      "id": "35.23",
      "label": "Commerce de combustibles gazeux par conduites"
    },
    {
      "id": "35.23Z",
      "label": "Commerce de combustibles gazeux par conduites"
    },
    {
      "id": "35.3",
      "label": "Production et distribution de vapeur et d'air conditionné"
    },
    {
      "id": "35.30",
      "label": "Production et distribution de vapeur et d'air conditionné"
    },
    {
      "id": "35.30Z",
      "label": "Production et distribution de vapeur et d'air conditionné"
    },
    {
      "id": "36",
      "label": "Captage, traitement et distribution d'eau"
    },
    {
      "id": "36.0",
      "label": "Captage, traitement et distribution d'eau"
    },
    {
      "id": "36.00",
      "label": "Captage, traitement et distribution d'eau"
    },
    {
      "id": "36.00Z",
      "label": "Captage, traitement et distribution d'eau"
    },
    {
      "id": "37",
      "label": "Collecte et traitement des eaux usées"
    },
    {
      "id": "37.0",
      "label": "Collecte et traitement des eaux usées"
    },
    {
      "id": "37.00",
      "label": "Collecte et traitement des eaux usées"
    },
    {
      "id": "37.00Z",
      "label": "Collecte et traitement des eaux usées"
    },
    {
      "id": "38",
      "label": "Collecte, traitement et élimination des déchets ; récupération"
    },
    {
      "id": "38.1",
      "label": "Collecte des déchets"
    },
    {
      "id": "38.11",
      "label": "Collecte des déchets non dangereux"
    },
    {
      "id": "38.11Z",
      "label": "Collecte des déchets non dangereux"
    },
    {
      "id": "38.12",
      "label": "Collecte des déchets dangereux"
    },
    {
      "id": "38.12Z",
      "label": "Collecte des déchets dangereux"
    },
    {
      "id": "38.2",
      "label": "Traitement et élimination des déchets"
    },
    {
      "id": "38.21",
      "label": "Traitement et élimination des déchets non dangereux"
    },
    {
      "id": "38.21Z",
      "label": "Traitement et élimination des déchets non dangereux"
    },
    {
      "id": "38.22",
      "label": "Traitement et élimination des déchets dangereux"
    },
    {
      "id": "38.22Z",
      "label": "Traitement et élimination des déchets dangereux"
    },
    {
      "id": "38.3",
      "label": "Récupération"
    },
    {
      "id": "38.31",
      "label": "Démantèlement d'épaves"
    },
    {
      "id": "38.31Z",
      "label": "Démantèlement d'épaves"
    },
    {
      "id": "38.32",
      "label": "Récupération de déchets triés"
    },
    {
      "id": "38.32Z",
      "label": "Récupération de déchets triés"
    },
    {
      "id": "39",
      "label": "Dépollution et autres services de gestion des déchets"
    },
    {
      "id": "39.0",
      "label": "Dépollution et autres services de gestion des déchets"
    },
    {
      "id": "39.00",
      "label": "Dépollution et autres services de gestion des déchets"
    },
    {
      "id": "39.00Z",
      "label": "Dépollution et autres services de gestion des déchets"
    },
    {
      "id": "41",
      "label": "Construction de bâtiments"
    },
    {
      "id": "41.1",
      "label": "Promotion immobilière"
    },
    {
      "id": "41.10",
      "label": "Promotion immobilière"
    },
    {
      "id": "41.10A",
      "label": "Promotion immobilière de logements"
    },
    {
      "id": "41.10B",
      "label": "Promotion immobilière de bureaux"
    },
    {
      "id": "41.10C",
      "label": "Promotion immobilière d'autres bâtiments"
    },
    {
      "id": "41.10D",
      "label": "Supports juridiques de programmes"
    },
    {
      "id": "41.2",
      "label": "Construction de bâtiments résidentiels et non résidentiels"
    },
    {
      "id": "41.20",
      "label": "Construction de bâtiments résidentiels et non résidentiels"
    },
    {
      "id": "41.20A",
      "label": "Construction de maisons individuelles"
    },
    {
      "id": "41.20B",
      "label": "Construction d'autres bâtiments"
    },
    {
      "id": "42",
      "label": "Génie civil"
    },
    {
      "id": "42.1",
      "label": "Construction de routes et de voies ferrées"
    },
    {
      "id": "42.11",
      "label": "Construction de routes et autoroutes"
    },
    {
      "id": "42.11Z",
      "label": "Construction de routes et autoroutes"
    },
    {
      "id": "42.12",
      "label": "Construction de voies ferrées de surface et souterraines"
    },
    {
      "id": "42.12Z",
      "label": "Construction de voies ferrées de surface et souterraines"
    },
    {
      "id": "42.13",
      "label": "Construction de ponts et tunnels"
    },
    {
      "id": "42.13A",
      "label": "Construction d'ouvrages d'art"
    },
    {
      "id": "42.13B",
      "label": "Construction et entretien de tunnels"
    },
    {
      "id": "42.2",
      "label": "Construction de réseaux et de lignes"
    },
    {
      "id": "42.21",
      "label": "Construction de réseaux pour fluides"
    },
    {
      "id": "42.21Z",
      "label": "Construction de réseaux pour fluides"
    },
    {
      "id": "42.22",
      "label": "Construction de réseaux électriques et de télécommunications"
    },
    {
      "id": "42.22Z",
      "label": "Construction de réseaux électriques et de télécommunications"
    },
    {
      "id": "42.9",
      "label": "Construction d'autres ouvrages de génie civil"
    },
    {
      "id": "42.91",
      "label": "Construction d'ouvrages maritimes et fluviaux"
    },
    {
      "id": "42.91Z",
      "label": "Construction d'ouvrages maritimes et fluviaux"
    },
    {
      "id": "42.99",
      "label": "Construction d'autres ouvrages de génie civil n.c.a."
    },
    {
      "id": "42.99Z",
      "label": "Construction d'autres ouvrages de génie civil n.c.a."
    },
    {
      "id": "43",
      "label": "Travaux de construction spécialisés"
    },
    {
      "id": "43.1",
      "label": "Démolition et préparation des sites"
    },
    {
      "id": "43.11",
      "label": "Travaux de démolition"
    },
    {
      "id": "43.11Z",
      "label": "Travaux de démolition"
    },
    {
      "id": "43.12",
      "label": "Travaux de préparation des sites"
    },
    {
      "id": "43.12A",
      "label": "Travaux de terrassement courants et travaux préparatoires"
    },
    {
      "id": "43.12B",
      "label": "Travaux de terrassement spécialisés ou de grande masse"
    },
    {
      "id": "43.13",
      "label": "Forages et sondages"
    },
    {
      "id": "43.13Z",
      "label": "Forages et sondages"
    },
    {
      "id": "43.2",
      "label": "Travaux d'installation électrique, plomberie et autres travaux d'installation"
    },
    {
      "id": "43.21",
      "label": "Installation électrique"
    },
    {
      "id": "43.21A",
      "label": "Travaux d'installation électrique dans tous locaux"
    },
    {
      "id": "43.21B",
      "label": "Travaux d'installation électrique sur la voie publique"
    },
    {
      "id": "43.22",
      "label": "Travaux de plomberie et installation de chauffage et de conditionnement d'air"
    },
    {
      "id": "43.22A",
      "label": "Travaux d'installation d'eau et de gaz en tous locaux"
    },
    {
      "id": "43.22B",
      "label": "Travaux d'installation d'équipements thermiques et de climatisation"
    },
    {
      "id": "43.29",
      "label": "Autres travaux d'installation"
    },
    {
      "id": "43.29A",
      "label": "Travaux d'isolation"
    },
    {
      "id": "43.29B",
      "label": "Autres travaux d'installation n.c.a."
    },
    {
      "id": "43.3",
      "label": "Travaux de finition"
    },
    {
      "id": "43.31",
      "label": "Travaux de plâtrerie"
    },
    {
      "id": "43.31Z",
      "label": "Travaux de plâtrerie"
    },
    {
      "id": "43.32",
      "label": "Travaux de menuiserie"
    },
    {
      "id": "43.32A",
      "label": "Travaux de menuiserie bois et PVC"
    },
    {
      "id": "43.32B",
      "label": "Travaux de menuiserie métallique et serrurerie"
    },
    {
      "id": "43.32C",
      "label": "Agencement de lieux de vente"
    },
    {
      "id": "43.33",
      "label": "Travaux de revêtement des sols et des murs"
    },
    {
      "id": "43.33Z",
      "label": "Travaux de revêtement des sols et des murs"
    },
    {
      "id": "43.34",
      "label": "Travaux de peinture et vitrerie"
    },
    {
      "id": "43.34Z",
      "label": "Travaux de peinture et vitrerie"
    },
    {
      "id": "43.39",
      "label": "Autres travaux de finition"
    },
    {
      "id": "43.39Z",
      "label": "Autres travaux de finition"
    },
    {
      "id": "43.9",
      "label": "Autres travaux de construction spécialisés"
    },
    {
      "id": "43.91",
      "label": "Travaux de couverture"
    },
    {
      "id": "43.91A",
      "label": "Travaux de charpente"
    },
    {
      "id": "43.91B",
      "label": "Travaux de couverture par éléments"
    },
    {
      "id": "43.99",
      "label": "Autres travaux de construction spécialisés n.c.a."
    },
    {
      "id": "43.99A",
      "label": "Travaux d'étanchéification"
    },
    {
      "id": "43.99B",
      "label": "Travaux de montage de structures métalliques"
    },
    {
      "id": "43.99C",
      "label": "Travaux de maçonnerie générale et gros œuvre de bâtiment"
    },
    {
      "id": "43.99D",
      "label": "Autres travaux spécialisés de construction"
    },
    {
      "id": "43.99E",
      "label": "Location avec opérateur de matériel de construction"
    },
    {
      "id": "45",
      "label": "Commerce et réparation d'automobiles et de motocycles"
    },
    {
      "id": "45.1",
      "label": "Commerce de véhicules automobiles"
    },
    {
      "id": "45.11",
      "label": "Commerce de voitures et de véhicules automobiles légers"
    },
    {
      "id": "45.11Z",
      "label": "Commerce de voitures et de véhicules automobiles légers"
    },
    {
      "id": "45.19",
      "label": "Commerce d'autres véhicules automobiles"
    },
    {
      "id": "45.19Z",
      "label": "Commerce d'autres véhicules automobiles"
    },
    {
      "id": "45.2",
      "label": "Entretien et réparation de véhicules automobiles"
    },
    {
      "id": "45.20",
      "label": "Entretien et réparation de véhicules automobiles"
    },
    {
      "id": "45.20A",
      "label": "Entretien et réparation de véhicules automobiles légers"
    },
    {
      "id": "45.20B",
      "label": "Entretien et réparation d'autres véhicules automobiles"
    },
    {
      "id": "45.3",
      "label": "Commerce d'équipements automobiles"
    },
    {
      "id": "45.31",
      "label": "Commerce de gros d'équipements automobiles"
    },
    {
      "id": "45.31Z",
      "label": "Commerce de gros d'équipements automobiles"
    },
    {
      "id": "45.32",
      "label": "Commerce de détail d'équipements automobiles"
    },
    {
      "id": "45.32Z",
      "label": "Commerce de détail d'équipements automobiles"
    },
    {
      "id": "45.4",
      "label": "Commerce et réparation de motocycles"
    },
    {
      "id": "45.40",
      "label": "Commerce et réparation de motocycles"
    },
    {
      "id": "45.40Z",
      "label": "Commerce et réparation de motocycles"
    },
    {
      "id": "46",
      "label": "Commerce de gros, à l’exception des automobiles et des motocycles"
    },
    {
      "id": "46.1",
      "label": "Intermédiaires du commerce de gros"
    },
    {
      "id": "46.11",
      "label": "Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis"
    },
    {
      "id": "46.11Z",
      "label": "Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis"
    },
    {
      "id": "46.12",
      "label": "Intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques"
    },
    {
      "id": "46.12A",
      "label": "Centrales d'achat de carburant"
    },
    {
      "id": "46.12B",
      "label": "Autres intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques"
    },
    {
      "id": "46.13",
      "label": "Intermédiaires du commerce en bois et matériaux de construction"
    },
    {
      "id": "46.13Z",
      "label": "Intermédiaires du commerce en bois et matériaux de construction"
    },
    {
      "id": "46.14",
      "label": "Intermédiaires du commerce en machines, équipements industriels, navires et avions"
    },
    {
      "id": "46.14Z",
      "label": "Intermédiaires du commerce en machines, équipements industriels, navires et avions"
    },
    {
      "id": "46.15",
      "label": "Intermédiaires du commerce en meubles, articles de ménage et quincaillerie"
    },
    {
      "id": "46.15Z",
      "label": "Intermédiaires du commerce en meubles, articles de ménage et quincaillerie"
    },
    {
      "id": "46.16",
      "label": "Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir"
    },
    {
      "id": "46.16Z",
      "label": "Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir"
    },
    {
      "id": "46.17",
      "label": "Intermédiaires du commerce en denrées, boissons et tabac"
    },
    {
      "id": "46.17A",
      "label": "Centrales d'achat alimentaires"
    },
    {
      "id": "46.17B",
      "label": "Autres intermédiaires du commerce en denrées, boissons et tabac"
    },
    {
      "id": "46.18",
      "label": "Intermédiaires spécialisés dans le commerce d'autres produits spécifiques"
    },
    {
      "id": "46.18Z",
      "label": "Intermédiaires spécialisés dans le commerce d'autres produits spécifiques"
    },
    {
      "id": "46.19",
      "label": "Intermédiaires du commerce en produits divers"
    },
    {
      "id": "46.19A",
      "label": "Centrales d'achat non alimentaires"
    },
    {
      "id": "46.19B",
      "label": "Autres intermédiaires du commerce en produits divers"
    },
    {
      "id": "46.2",
      "label": "Commerce de gros de produits agricoles bruts et d'animaux vivants"
    },
    {
      "id": "46.21",
      "label": "Commerce de gros de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail"
    },
    {
      "id": "46.21Z",
      "label": "Commerce de gros (commerce interentreprises) de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail"
    },
    {
      "id": "46.22",
      "label": "Commerce de gros de fleurs et plantes"
    },
    {
      "id": "46.22Z",
      "label": "Commerce de gros (commerce interentreprises) de fleurs et plantes"
    },
    {
      "id": "46.23",
      "label": "Commerce de gros d'animaux vivants"
    },
    {
      "id": "46.23Z",
      "label": "Commerce de gros (commerce interentreprises) d'animaux vivants"
    },
    {
      "id": "46.24",
      "label": "Commerce de gros de cuirs et peaux"
    },
    {
      "id": "46.24Z",
      "label": "Commerce de gros (commerce interentreprises) de cuirs et peaux"
    },
    {
      "id": "46.3",
      "label": "Commerce de gros de produits alimentaires, de boissons et de tabac"
    },
    {
      "id": "46.31",
      "label": "Commerce de gros de fruits et légumes"
    },
    {
      "id": "46.31Z",
      "label": "Commerce de gros (commerce interentreprises) de fruits et légumes"
    },
    {
      "id": "46.32",
      "label": "Commerce de gros de viandes et de produits à base de viande"
    },
    {
      "id": "46.32A",
      "label": "Commerce de gros (commerce interentreprises) de viandes de boucherie"
    },
    {
      "id": "46.32B",
      "label": "Commerce de gros (commerce interentreprises) de produits à base de viande"
    },
    {
      "id": "46.32C",
      "label": "Commerce de gros (commerce interentreprises) de volailles et gibier"
    },
    {
      "id": "46.33",
      "label": "Commerce de gros de produits laitiers, œufs, huiles et matières grasses comestibles"
    },
    {
      "id": "46.33Z",
      "label": "Commerce de gros (commerce interentreprises) de produits laitiers, œufs, huiles et matières grasses comestibles"
    },
    {
      "id": "46.34",
      "label": "Commerce de gros de boissons"
    },
    {
      "id": "46.34Z",
      "label": "Commerce de gros (commerce interentreprises) de boissons"
    },
    {
      "id": "46.35",
      "label": "Commerce de gros de produits à base de tabac"
    },
    {
      "id": "46.35Z",
      "label": "Commerce de gros (commerce interentreprises) de produits à base de tabac"
    },
    {
      "id": "46.36",
      "label": "Commerce de gros de sucre, chocolat et confiserie"
    },
    {
      "id": "46.36Z",
      "label": "Commerce de gros (commerce interentreprises) de sucre, chocolat et confiserie"
    },
    {
      "id": "46.37",
      "label": "Commerce de gros de café, thé, cacao et épices"
    },
    {
      "id": "46.37Z",
      "label": "Commerce de gros (commerce interentreprises) de café, thé, cacao et épices"
    },
    {
      "id": "46.38",
      "label": "Commerce de gros d'autres produits alimentaires, y compris poissons, crustacés et mollusques"
    },
    {
      "id": "46.38A",
      "label": "Commerce de gros (commerce interentreprises) de poissons, crustacés et mollusques"
    },
    {
      "id": "46.38B",
      "label": "Commerce de gros (commerce interentreprises) alimentaire spécialisé divers"
    },
    {
      "id": "46.39",
      "label": "Commerce de gros non spécialisé de denrées, boissons et tabac"
    },
    {
      "id": "46.39A",
      "label": "Commerce de gros (commerce interentreprises) de produits surgelés"
    },
    {
      "id": "46.39B",
      "label": "Commerce de gros (commerce interentreprises) alimentaire non spécialisé"
    },
    {
      "id": "46.4",
      "label": "Commerce de gros de biens domestiques"
    },
    {
      "id": "46.41",
      "label": "Commerce de gros de textiles"
    },
    {
      "id": "46.41Z",
      "label": "Commerce de gros (commerce interentreprises) de textiles"
    },
    {
      "id": "46.42",
      "label": "Commerce de gros d'habillement et de chaussures"
    },
    {
      "id": "46.42Z",
      "label": "Commerce de gros (commerce interentreprises) d'habillement et de chaussures"
    },
    {
      "id": "46.43",
      "label": "Commerce de gros d'appareils électroménagers"
    },
    {
      "id": "46.43Z",
      "label": "Commerce de gros (commerce interentreprises) d'appareils électroménagers"
    },
    {
      "id": "46.44",
      "label": "Commerce de gros de vaisselle, verrerie et produits d'entretien"
    },
    {
      "id": "46.44Z",
      "label": "Commerce de gros (commerce interentreprises) de vaisselle, verrerie et produits d'entretien"
    },
    {
      "id": "46.45",
      "label": "Commerce de gros de parfumerie et de produits de beauté"
    },
    {
      "id": "46.45Z",
      "label": "Commerce de gros (commerce interentreprises) de parfumerie et de produits de beauté"
    },
    {
      "id": "46.46",
      "label": "Commerce de gros de produits pharmaceutiques"
    },
    {
      "id": "46.46Z",
      "label": "Commerce de gros (commerce interentreprises) de produits pharmaceutiques"
    },
    {
      "id": "46.47",
      "label": "Commerce de gros de meubles, de tapis et d'appareils d'éclairage"
    },
    {
      "id": "46.47Z",
      "label": "Commerce de gros (commerce interentreprises) de meubles, de tapis et d'appareils d'éclairage"
    },
    {
      "id": "46.48",
      "label": "Commerce de gros d'articles d'horlogerie et de bijouterie"
    },
    {
      "id": "46.48Z",
      "label": "Commerce de gros (commerce interentreprises) d'articles d'horlogerie et de bijouterie"
    },
    {
      "id": "46.49",
      "label": "Commerce de gros d'autres biens domestiques"
    },
    {
      "id": "46.49Z",
      "label": "Commerce de gros (commerce interentreprises) d'autres biens domestiques"
    },
    {
      "id": "46.5",
      "label": "Commerce de gros d'équipements de l'information et de la communication"
    },
    {
      "id": "46.51",
      "label": "Commerce de gros d'ordinateurs, d'équipements informatiques périphériques et de logiciels"
    },
    {
      "id": "46.51Z",
      "label": "Commerce de gros (commerce interentreprises) d'ordinateurs, d'équipements informatiques périphériques et de logiciels"
    },
    {
      "id": "46.52",
      "label": "Commerce de gros de composants et d'équipements électroniques et de télécommunication"
    },
    {
      "id": "46.52Z",
      "label": "Commerce de gros (commerce interentreprises) de composants et d'équipements électroniques et de télécommunication"
    },
    {
      "id": "46.6",
      "label": "Commerce de gros d'autres équipements industriels"
    },
    {
      "id": "46.61",
      "label": "Commerce de gros de matériel agricole"
    },
    {
      "id": "46.61Z",
      "label": "Commerce de gros (commerce interentreprises) de matériel agricole"
    },
    {
      "id": "46.62",
      "label": "Commerce de gros de machines-outils"
    },
    {
      "id": "46.62Z",
      "label": "Commerce de gros (commerce interentreprises) de machines-outils"
    },
    {
      "id": "46.63",
      "label": "Commerce de gros de machines pour l'extraction, la construction et le génie civil"
    },
    {
      "id": "46.63Z",
      "label": "Commerce de gros (commerce interentreprises) de machines pour l'extraction, la construction et le génie civil"
    },
    {
      "id": "46.64",
      "label": "Commerce de gros de machines pour l'industrie textile et l'habillement"
    },
    {
      "id": "46.64Z",
      "label": "Commerce de gros (commerce interentreprises) de machines pour l'industrie textile et l'habillement"
    },
    {
      "id": "46.65",
      "label": "Commerce de gros de mobilier de bureau"
    },
    {
      "id": "46.65Z",
      "label": "Commerce de gros (commerce interentreprises) de mobilier de bureau"
    },
    {
      "id": "46.66",
      "label": "Commerce de gros d'autres machines et équipements de bureau"
    },
    {
      "id": "46.66Z",
      "label": "Commerce de gros (commerce interentreprises) d'autres machines et équipements de bureau"
    },
    {
      "id": "46.69",
      "label": "Commerce de gros d'autres machines et équipements"
    },
    {
      "id": "46.69A",
      "label": "Commerce de gros (commerce interentreprises) de matériel électrique"
    },
    {
      "id": "46.69B",
      "label": "Commerce de gros (commerce interentreprises) de fournitures et équipements industriels divers"
    },
    {
      "id": "46.69C",
      "label": "Commerce de gros (commerce interentreprises) de fournitures et équipements divers pour le commerce et les services"
    },
    {
      "id": "46.7",
      "label": "Autres commerces de gros spécialisés"
    },
    {
      "id": "46.71",
      "label": "Commerce de gros de combustibles et de produits annexes"
    },
    {
      "id": "46.71Z",
      "label": "Commerce de gros (commerce interentreprises) de combustibles et de produits annexes"
    },
    {
      "id": "46.72",
      "label": "Commerce de gros de minerais et métaux"
    },
    {
      "id": "46.72Z",
      "label": "Commerce de gros (commerce interentreprises) de minerais et métaux"
    },
    {
      "id": "46.73",
      "label": "Commerce de gros de bois, de matériaux de construction et d'appareils sanitaires"
    },
    {
      "id": "46.73A",
      "label": "Commerce de gros (commerce interentreprises) de bois et de matériaux de construction"
    },
    {
      "id": "46.73B",
      "label": "Commerce de gros (commerce interentreprises) d'appareils sanitaires et de produits de décoration"
    },
    {
      "id": "46.74",
      "label": "Commerce de gros de quincaillerie et fournitures pour plomberie et chauffage"
    },
    {
      "id": "46.74A",
      "label": "Commerce de gros (commerce interentreprises) de quincaillerie"
    },
    {
      "id": "46.74B",
      "label": "Commerce de gros (commerce interentreprises) de fournitures pour la plomberie et le chauffage"
    },
    {
      "id": "46.75",
      "label": "Commerce de gros de produits chimiques"
    },
    {
      "id": "46.75Z",
      "label": "Commerce de gros (commerce interentreprises) de produits chimiques"
    },
    {
      "id": "46.76",
      "label": "Commerce de gros d'autres produits intermédiaires"
    },
    {
      "id": "46.76Z",
      "label": "Commerce de gros (commerce interentreprises) d'autres produits intermédiaires"
    },
    {
      "id": "46.77",
      "label": "Commerce de gros de déchets et débris"
    },
    {
      "id": "46.77Z",
      "label": "Commerce de gros (commerce interentreprises) de déchets et débris"
    },
    {
      "id": "46.9",
      "label": "Commerce de gros non spécialisé"
    },
    {
      "id": "46.90",
      "label": "Commerce de gros non spécialisé"
    },
    {
      "id": "46.90Z",
      "label": "Commerce de gros (commerce interentreprises) non spécialisé"
    },
    {
      "id": "47",
      "label": "Commerce de détail, à l’exception des automobiles et des motocycles"
    },
    {
      "id": "47.1",
      "label": "Commerce de détail en magasin non spécialisé"
    },
    {
      "id": "47.11",
      "label": "Commerce de détail en magasin non spécialisé à prédominance alimentaire"
    },
    {
      "id": "47.11A",
      "label": "Commerce de détail de produits surgelés"
    },
    {
      "id": "47.11B",
      "label": "Commerce d'alimentation générale"
    },
    {
      "id": "47.11C",
      "label": "Supérettes"
    },
    {
      "id": "47.11D",
      "label": "Supermarchés"
    },
    {
      "id": "47.11E",
      "label": "Magasins multi-commerces"
    },
    {
      "id": "47.11F",
      "label": "Hypermarchés"
    },
    {
      "id": "47.19",
      "label": "Autre commerce de détail en magasin non spécialisé"
    },
    {
      "id": "47.19A",
      "label": "Grands magasins"
    },
    {
      "id": "47.19B",
      "label": "Autres commerces de détail en magasin non spécialisé"
    },
    {
      "id": "47.2",
      "label": "Commerce de détail alimentaire en magasin spécialisé"
    },
    {
      "id": "47.21",
      "label": "Commerce de détail de fruits et légumes en magasin spécialisé"
    },
    {
      "id": "47.21Z",
      "label": "Commerce de détail de fruits et légumes en magasin spécialisé"
    },
    {
      "id": "47.22",
      "label": "Commerce de détail de viandes et de produits à base de viande en magasin spécialisé"
    },
    {
      "id": "47.22Z",
      "label": "Commerce de détail de viandes et de produits à base de viande en magasin spécialisé"
    },
    {
      "id": "47.23",
      "label": "Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé"
    },
    {
      "id": "47.23Z",
      "label": "Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé"
    },
    {
      "id": "47.24",
      "label": "Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé"
    },
    {
      "id": "47.24Z",
      "label": "Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé"
    },
    {
      "id": "47.25",
      "label": "Commerce de détail de boissons en magasin spécialisé"
    },
    {
      "id": "47.25Z",
      "label": "Commerce de détail de boissons en magasin spécialisé"
    },
    {
      "id": "47.26",
      "label": "Commerce de détail de produits à base de tabac en magasin spécialisé"
    },
    {
      "id": "47.26Z",
      "label": "Commerce de détail de produits à base de tabac en magasin spécialisé"
    },
    {
      "id": "47.29",
      "label": "Autres commerces de détail alimentaires en magasin spécialisé"
    },
    {
      "id": "47.29Z",
      "label": "Autres commerces de détail alimentaires en magasin spécialisé"
    },
    {
      "id": "47.3",
      "label": "Commerce de détail de carburants en magasin spécialisé"
    },
    {
      "id": "47.30",
      "label": "Commerce de détail de carburants en magasin spécialisé"
    },
    {
      "id": "47.30Z",
      "label": "Commerce de détail de carburants en magasin spécialisé"
    },
    {
      "id": "47.4",
      "label": "Commerce de détail d'équipements de l'information et de la communication en magasin spécialisé"
    },
    {
      "id": "47.41",
      "label": "Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé"
    },
    {
      "id": "47.41Z",
      "label": "Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé"
    },
    {
      "id": "47.42",
      "label": "Commerce de détail de matériels de télécommunication en magasin spécialisé"
    },
    {
      "id": "47.42Z",
      "label": "Commerce de détail de matériels de télécommunication en magasin spécialisé"
    },
    {
      "id": "47.43",
      "label": "Commerce de détail de matériels audio/vidéo en magasin spécialisé"
    },
    {
      "id": "47.43Z",
      "label": "Commerce de détail de matériels audio et vidéo en magasin spécialisé"
    },
    {
      "id": "47.5",
      "label": "Commerce de détail d'autres équipements du foyer en magasin spécialisé"
    },
    {
      "id": "47.51",
      "label": "Commerce de détail de textiles en magasin spécialisé"
    },
    {
      "id": "47.51Z",
      "label": "Commerce de détail de textiles en magasin spécialisé"
    },
    {
      "id": "47.52",
      "label": "Commerce de détail de quincaillerie, peintures et verres en magasin spécialisé"
    },
    {
      "id": "47.52A",
      "label": "Commerce de détail de quincaillerie, peintures et verres en petites surfaces (moins de 400 m2)"
    },
    {
      "id": "47.52B",
      "label": "Commerce de détail de quincaillerie, peintures et verres en grandes surfaces (400 m2et plus)"
    },
    {
      "id": "47.53",
      "label": "Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé"
    },
    {
      "id": "47.53Z",
      "label": "Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé"
    },
    {
      "id": "47.54",
      "label": "Commerce de détail d'appareils électroménagers en magasin spécialisé"
    },
    {
      "id": "47.54Z",
      "label": "Commerce de détail d'appareils électroménagers en magasin spécialisé"
    },
    {
      "id": "47.59",
      "label": "Commerce de détail de meubles, appareils d'éclairage et autres articles de ménage en magasin spécialisé"
    },
    {
      "id": "47.59A",
      "label": "Commerce de détail de meubles"
    },
    {
      "id": "47.59B",
      "label": "Commerce de détail d'autres équipements du foyer"
    },
    {
      "id": "47.6",
      "label": "Commerce de détail de biens culturels et de loisirs en magasin spécialisé"
    },
    {
      "id": "47.61",
      "label": "Commerce de détail de livres en magasin spécialisé"
    },
    {
      "id": "47.61Z",
      "label": "Commerce de détail de livres en magasin spécialisé"
    },
    {
      "id": "47.62",
      "label": "Commerce de détail de journaux et papeterie en magasin spécialisé"
    },
    {
      "id": "47.62Z",
      "label": "Commerce de détail de journaux et papeterie en magasin spécialisé"
    },
    {
      "id": "47.63",
      "label": "Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé"
    },
    {
      "id": "47.63Z",
      "label": "Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé"
    },
    {
      "id": "47.64",
      "label": "Commerce de détail d'articles de sport en magasin spécialisé"
    },
    {
      "id": "47.64Z",
      "label": "Commerce de détail d'articles de sport en magasin spécialisé"
    },
    {
      "id": "47.65",
      "label": "Commerce de détail de jeux et jouets en magasin spécialisé"
    },
    {
      "id": "47.65Z",
      "label": "Commerce de détail de jeux et jouets en magasin spécialisé"
    },
    {
      "id": "47.7",
      "label": "Autres commerces de détail en magasin spécialisé"
    },
    {
      "id": "47.71",
      "label": "Commerce de détail d'habillement en magasin spécialisé"
    },
    {
      "id": "47.71Z",
      "label": "Commerce de détail d'habillement en magasin spécialisé"
    },
    {
      "id": "47.72",
      "label": "Commerce de détail de chaussures et d'articles en cuir en magasin spécialisé"
    },
    {
      "id": "47.72A",
      "label": "Commerce de détail de la chaussure"
    },
    {
      "id": "47.72B",
      "label": "Commerce de détail de maroquinerie et d'articles de voyage"
    },
    {
      "id": "47.73",
      "label": "Commerce de détail de produits pharmaceutiques en magasin spécialisé"
    },
    {
      "id": "47.73Z",
      "label": "Commerce de détail de produits pharmaceutiques en magasin spécialisé"
    },
    {
      "id": "47.74",
      "label": "Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé"
    },
    {
      "id": "47.74Z",
      "label": "Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé"
    },
    {
      "id": "47.75",
      "label": "Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé"
    },
    {
      "id": "47.75Z",
      "label": "Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé"
    },
    {
      "id": "47.76",
      "label": "Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé"
    },
    {
      "id": "47.76Z",
      "label": "Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé"
    },
    {
      "id": "47.77",
      "label": "Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé"
    },
    {
      "id": "47.77Z",
      "label": "Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé"
    },
    {
      "id": "47.78",
      "label": "Autre commerce de détail de biens neufs en magasin spécialisé"
    },
    {
      "id": "47.78A",
      "label": "Commerces de détail d'optique"
    },
    {
      "id": "47.78B",
      "label": "Commerces de détail de charbons et combustibles"
    },
    {
      "id": "47.78C",
      "label": "Autres commerces de détail spécialisés divers"
    },
    {
      "id": "47.79",
      "label": "Commerce de détail de biens d'occasion en magasin"
    },
    {
      "id": "47.79Z",
      "label": "Commerce de détail de biens d'occasion en magasin"
    },
    {
      "id": "47.8",
      "label": "Commerce de détail sur éventaires et marchés"
    },
    {
      "id": "47.81",
      "label": "Commerce de détail alimentaire sur éventaires et marchés"
    },
    {
      "id": "47.81Z",
      "label": "Commerce de détail alimentaire sur éventaires et marchés"
    },
    {
      "id": "47.82",
      "label": "Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés"
    },
    {
      "id": "47.82Z",
      "label": "Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés"
    },
    {
      "id": "47.89",
      "label": "Autres commerces de détail sur éventaires et marchés"
    },
    {
      "id": "47.89Z",
      "label": "Autres commerces de détail sur éventaires et marchés"
    },
    {
      "id": "47.9",
      "label": "Commerce de détail hors magasin, éventaires ou marchés"
    },
    {
      "id": "47.91",
      "label": "Vente à distance"
    },
    {
      "id": "47.91A",
      "label": "Vente à distance sur catalogue général"
    },
    {
      "id": "47.91B",
      "label": "Vente à distance sur catalogue spécialisé"
    },
    {
      "id": "47.99",
      "label": "Autres commerces de détail hors magasin, éventaires ou marchés"
    },
    {
      "id": "47.99A",
      "label": "Vente à domicile"
    },
    {
      "id": "47.99B",
      "label": "Vente par automates et autres commerces de détail hors magasin, éventaires ou marchés n.c.a."
    },
    {
      "id": "49",
      "label": "Transports terrestres et transport par conduites"
    },
    {
      "id": "49.1",
      "label": "Transport ferroviaire interurbain de voyageurs"
    },
    {
      "id": "49.10",
      "label": "Transport ferroviaire interurbain de voyageurs"
    },
    {
      "id": "49.10Z",
      "label": "Transport ferroviaire interurbain de voyageurs"
    },
    {
      "id": "49.2",
      "label": "Transports ferroviaires de fret"
    },
    {
      "id": "49.20",
      "label": "Transports ferroviaires de fret"
    },
    {
      "id": "49.20Z",
      "label": "Transports ferroviaires de fret"
    },
    {
      "id": "49.3",
      "label": "Autres transports terrestres de voyageurs"
    },
    {
      "id": "49.31",
      "label": "Transports urbains et suburbains de voyageurs"
    },
    {
      "id": "49.31Z",
      "label": "Transports urbains et suburbains de voyageurs"
    },
    {
      "id": "49.32",
      "label": "Transports de voyageurs par taxis"
    },
    {
      "id": "49.32Z",
      "label": "Transports de voyageurs par taxis"
    },
    {
      "id": "49.39",
      "label": "Autres transports terrestres de voyageurs n.c.a."
    },
    {
      "id": "49.39A",
      "label": "Transports routiers réguliers de voyageurs"
    },
    {
      "id": "49.39B",
      "label": "Autres transports routiers de voyageurs"
    },
    {
      "id": "49.39C",
      "label": "Téléphériques et remontées mécaniques"
    },
    {
      "id": "49.4",
      "label": "Transports routiers de fret et services de déménagement"
    },
    {
      "id": "49.41",
      "label": "Transports routiers de fret"
    },
    {
      "id": "49.41A",
      "label": "Transports routiers de fret interurbains"
    },
    {
      "id": "49.41B",
      "label": "Transports routiers de fret de proximité"
    },
    {
      "id": "49.41C",
      "label": "Location de camions avec chauffeur"
    },
    {
      "id": "49.42",
      "label": "Services de déménagement"
    },
    {
      "id": "49.42Z",
      "label": "Services de déménagement"
    },
    {
      "id": "49.5",
      "label": "Transports par conduites"
    },
    {
      "id": "49.50",
      "label": "Transports par conduites"
    },
    {
      "id": "49.50Z",
      "label": "Transports par conduites"
    },
    {
      "id": "50",
      "label": "Transports par eau"
    },
    {
      "id": "50.1",
      "label": "Transports maritimes et côtiers de passagers"
    },
    {
      "id": "50.10",
      "label": "Transports maritimes et côtiers de passagers"
    },
    {
      "id": "50.10Z",
      "label": "Transports maritimes et côtiers de passagers"
    },
    {
      "id": "50.2",
      "label": "Transports maritimes et côtiers de fret"
    },
    {
      "id": "50.20",
      "label": "Transports maritimes et côtiers de fret"
    },
    {
      "id": "50.20Z",
      "label": "Transports maritimes et côtiers de fret"
    },
    {
      "id": "50.3",
      "label": "Transports fluviaux de passagers"
    },
    {
      "id": "50.30",
      "label": "Transports fluviaux de passagers"
    },
    {
      "id": "50.30Z",
      "label": "Transports fluviaux de passagers"
    },
    {
      "id": "50.4",
      "label": "Transports fluviaux de fret"
    },
    {
      "id": "50.40",
      "label": "Transports fluviaux de fret"
    },
    {
      "id": "50.40Z",
      "label": "Transports fluviaux de fret"
    },
    {
      "id": "51",
      "label": "Transports aériens"
    },
    {
      "id": "51.1",
      "label": "Transports aériens de passagers"
    },
    {
      "id": "51.10",
      "label": "Transports aériens de passagers"
    },
    {
      "id": "51.10Z",
      "label": "Transports aériens de passagers"
    },
    {
      "id": "51.2",
      "label": "Transports aériens de fret et transports spatiaux"
    },
    {
      "id": "51.21",
      "label": "Transports aériens de fret"
    },
    {
      "id": "51.21Z",
      "label": "Transports aériens de fret"
    },
    {
      "id": "51.22",
      "label": "Transports spatiaux"
    },
    {
      "id": "51.22Z",
      "label": "Transports spatiaux"
    },
    {
      "id": "52",
      "label": "Entreposage et services auxiliaires des transports"
    },
    {
      "id": "52.1",
      "label": "Entreposage et stockage"
    },
    {
      "id": "52.10",
      "label": "Entreposage et stockage"
    },
    {
      "id": "52.10A",
      "label": "Entreposage et stockage frigorifique"
    },
    {
      "id": "52.10B",
      "label": "Entreposage et stockage non frigorifique"
    },
    {
      "id": "52.2",
      "label": "Services auxiliaires des transports"
    },
    {
      "id": "52.21",
      "label": "Services auxiliaires des transports terrestres"
    },
    {
      "id": "52.21Z",
      "label": "Services auxiliaires des transports terrestres"
    },
    {
      "id": "52.22",
      "label": "Services auxiliaires des transports par eau"
    },
    {
      "id": "52.22Z",
      "label": "Services auxiliaires des transports par eau"
    },
    {
      "id": "52.23",
      "label": "Services auxiliaires des transports aériens"
    },
    {
      "id": "52.23Z",
      "label": "Services auxiliaires des transports aériens"
    },
    {
      "id": "52.24",
      "label": "Manutention"
    },
    {
      "id": "52.24A",
      "label": "Manutention portuaire"
    },
    {
      "id": "52.24B",
      "label": "Manutention non portuaire"
    },
    {
      "id": "52.29",
      "label": "Autres services auxiliaires des transports"
    },
    {
      "id": "52.29A",
      "label": "Messagerie, fret express"
    },
    {
      "id": "52.29B",
      "label": "Affrètement et organisation des transports"
    },
    {
      "id": "53",
      "label": "Activités de poste et de courrier"
    },
    {
      "id": "53.1",
      "label": "Activités de poste dans le cadre d'une obligation de service universel"
    },
    {
      "id": "53.10",
      "label": "Activités de poste dans le cadre d'une obligation de service universel"
    },
    {
      "id": "53.10Z",
      "label": "Activités de poste dans le cadre d'une obligation de service universel"
    },
    {
      "id": "53.2",
      "label": "Autres activités de poste et de courrier"
    },
    {
      "id": "53.20",
      "label": "Autres activités de poste et de courrier"
    },
    {
      "id": "53.20Z",
      "label": "Autres activités de poste et de courrier"
    },
    {
      "id": "55",
      "label": "Hébergement"
    },
    {
      "id": "55.1",
      "label": "Hôtels et hébergement similaire"
    },
    {
      "id": "55.10",
      "label": "Hôtels et hébergement similaire"
    },
    {
      "id": "55.10Z",
      "label": "Hôtels et hébergement similaire"
    },
    {
      "id": "55.2",
      "label": "Hébergement touristique et autre hébergement de courte durée"
    },
    {
      "id": "55.20",
      "label": "Hébergement touristique et autre hébergement de courte durée"
    },
    {
      "id": "55.20Z",
      "label": "Hébergement touristique et autre hébergement de courte durée"
    },
    {
      "id": "55.3",
      "label": "Terrains de camping et parcs pour caravanes ou véhicules de loisirs"
    },
    {
      "id": "55.30",
      "label": "Terrains de camping et parcs pour caravanes ou véhicules de loisirs"
    },
    {
      "id": "55.30Z",
      "label": "Terrains de camping et parcs pour caravanes ou véhicules de loisirs"
    },
    {
      "id": "55.9",
      "label": "Autres hébergements"
    },
    {
      "id": "55.90",
      "label": "Autres hébergements"
    },
    {
      "id": "55.90Z",
      "label": "Autres hébergements"
    },
    {
      "id": "56",
      "label": "Restauration"
    },
    {
      "id": "56.1",
      "label": "Restaurants et services de restauration mobile"
    },
    {
      "id": "56.10",
      "label": "Restaurants et services de restauration mobile"
    },
    {
      "id": "56.10A",
      "label": "Restauration traditionnelle"
    },
    {
      "id": "56.10B",
      "label": "Cafétérias et autres libres-services"
    },
    {
      "id": "56.10C",
      "label": "Restauration de type rapide"
    },
    {
      "id": "56.2",
      "label": "Traiteurs et autres services de restauration"
    },
    {
      "id": "56.21",
      "label": "Services des traiteurs"
    },
    {
      "id": "56.21Z",
      "label": "Services des traiteurs"
    },
    {
      "id": "56.29",
      "label": "Autres services de restauration"
    },
    {
      "id": "56.29A",
      "label": "Restauration collective sous contrat"
    },
    {
      "id": "56.29B",
      "label": "Autres services de restauration n.c.a."
    },
    {
      "id": "56.3",
      "label": "Débits de boissons"
    },
    {
      "id": "56.30",
      "label": "Débits de boissons"
    },
    {
      "id": "56.30Z",
      "label": "Débits de boissons"
    },
    {
      "id": "58",
      "label": "Édition"
    },
    {
      "id": "58.1",
      "label": "Édition de livres et périodiques et autres activités d'édition"
    },
    {
      "id": "58.11",
      "label": "Édition de livres"
    },
    {
      "id": "58.11Z",
      "label": "Édition de livres"
    },
    {
      "id": "58.12",
      "label": "Édition de répertoires et de fichiers d'adresses"
    },
    {
      "id": "58.12Z",
      "label": "Édition de répertoires et de fichiers d'adresses"
    },
    {
      "id": "58.13",
      "label": "Édition de journaux"
    },
    {
      "id": "58.13Z",
      "label": "Édition de journaux"
    },
    {
      "id": "58.14",
      "label": "Édition de revues et périodiques"
    },
    {
      "id": "58.14Z",
      "label": "Édition de revues et périodiques"
    },
    {
      "id": "58.19",
      "label": "Autres activités d'édition"
    },
    {
      "id": "58.19Z",
      "label": "Autres activités d'édition"
    },
    {
      "id": "58.2",
      "label": "Édition de logiciels"
    },
    {
      "id": "58.21",
      "label": "Édition de jeux électroniques"
    },
    {
      "id": "58.21Z",
      "label": "Édition de jeux électroniques"
    },
    {
      "id": "58.29",
      "label": "Édition d'autres logiciels"
    },
    {
      "id": "58.29A",
      "label": "Édition de logiciels système et de réseau"
    },
    {
      "id": "58.29B",
      "label": "Edition de logiciels outils de développement et de langages"
    },
    {
      "id": "58.29C",
      "label": "Edition de logiciels applicatifs"
    },
    {
      "id": "59",
      "label": "Production de films cinématographiques, de vidéo et de programmes de télévision ; enregistrement sonore et édition musicale"
    },
    {
      "id": "59.1",
      "label": "Activités cinématographiques, vidéo et de télévision"
    },
    {
      "id": "59.11",
      "label": "Production de films cinématographiques, de vidéo et de programmes de télévision"
    },
    {
      "id": "59.11A",
      "label": "Production de films et de programmes pour la télévision"
    },
    {
      "id": "59.11B",
      "label": "Production de films institutionnels et publicitaires"
    },
    {
      "id": "59.11C",
      "label": "Production de films pour le cinéma"
    },
    {
      "id": "59.12",
      "label": "Post-production de films cinématographiques, de vidéo et de programmes de télévision"
    },
    {
      "id": "59.12Z",
      "label": "Post-production de films cinématographiques, de vidéo et de programmes de télévision"
    },
    {
      "id": "59.13",
      "label": "Distribution de films cinématographiques, de vidéo et de programmes de télévision"
    },
    {
      "id": "59.13A",
      "label": "Distribution de films cinématographiques"
    },
    {
      "id": "59.13B",
      "label": "Edition et distribution vidéo"
    },
    {
      "id": "59.14",
      "label": "Projection de films cinématographiques"
    },
    {
      "id": "59.14Z",
      "label": "Projection de films cinématographiques"
    },
    {
      "id": "59.2",
      "label": "Enregistrement sonore et édition musicale"
    },
    {
      "id": "59.20",
      "label": "Enregistrement sonore et édition musicale"
    },
    {
      "id": "59.20Z",
      "label": "Enregistrement sonore et édition musicale"
    },
    {
      "id": "60",
      "label": "Programmation et diffusion"
    },
    {
      "id": "60.1",
      "label": "Édition et diffusion de programmes radio"
    },
    {
      "id": "60.10",
      "label": "Édition et diffusion de programmes radio"
    },
    {
      "id": "60.10Z",
      "label": "Édition et diffusion de programmes radio"
    },
    {
      "id": "60.2",
      "label": "Programmation de télévision et télédiffusion"
    },
    {
      "id": "60.20",
      "label": "Programmation de télévision et télédiffusion"
    },
    {
      "id": "60.20A",
      "label": "Edition de chaînes généralistes"
    },
    {
      "id": "60.20B",
      "label": "Edition de chaînes thématiques"
    },
    {
      "id": "61",
      "label": "Télécommunications"
    },
    {
      "id": "61.1",
      "label": "Télécommunications filaires"
    },
    {
      "id": "61.10",
      "label": "Télécommunications filaires"
    },
    {
      "id": "61.10Z",
      "label": "Télécommunications filaires"
    },
    {
      "id": "61.2",
      "label": "Télécommunications sans fil"
    },
    {
      "id": "61.20",
      "label": "Télécommunications sans fil"
    },
    {
      "id": "61.20Z",
      "label": "Télécommunications sans fil"
    },
    {
      "id": "61.3",
      "label": "Télécommunications par satellite"
    },
    {
      "id": "61.30",
      "label": "Télécommunications par satellite"
    },
    {
      "id": "61.30Z",
      "label": "Télécommunications par satellite"
    },
    {
      "id": "61.9",
      "label": "Autres activités de télécommunication"
    },
    {
      "id": "61.90",
      "label": "Autres activités de télécommunication"
    },
    {
      "id": "61.90Z",
      "label": "Autres activités de télécommunication"
    },
    {
      "id": "62",
      "label": "Programmation, conseil et autres activités informatiques"
    },
    {
      "id": "62.0",
      "label": "Programmation, conseil et autres activités informatiques"
    },
    {
      "id": "62.01",
      "label": "Programmation informatique"
    },
    {
      "id": "62.01Z",
      "label": "Programmation informatique"
    },
    {
      "id": "62.02",
      "label": "Conseil informatique"
    },
    {
      "id": "62.02A",
      "label": "Conseil en systèmes et logiciels informatiques"
    },
    {
      "id": "62.02B",
      "label": "Tierce maintenance de systèmes et d’applications informatiques"
    },
    {
      "id": "62.03",
      "label": "Gestion d'installations informatiques"
    },
    {
      "id": "62.03Z",
      "label": "Gestion d'installations informatiques"
    },
    {
      "id": "62.09",
      "label": "Autres activités informatiques"
    },
    {
      "id": "62.09Z",
      "label": "Autres activités informatiques"
    },
    {
      "id": "63",
      "label": "Services d'information"
    },
    {
      "id": "63.1",
      "label": "Traitement de données, hébergement et activités connexes ; portails Internet"
    },
    {
      "id": "63.11",
      "label": "Traitement de données, hébergement et activités connexes"
    },
    {
      "id": "63.11Z",
      "label": "Traitement de données, hébergement et activités connexes"
    },
    {
      "id": "63.12",
      "label": "Portails Internet"
    },
    {
      "id": "63.12Z",
      "label": "Portails Internet"
    },
    {
      "id": "63.9",
      "label": "Autres services d'information"
    },
    {
      "id": "63.91",
      "label": "Activités des agences de presse"
    },
    {
      "id": "63.91Z",
      "label": "Activités des agences de presse"
    },
    {
      "id": "63.99",
      "label": "Autres services d'information n.c.a."
    },
    {
      "id": "63.99Z",
      "label": "Autres services d'information n.c.a."
    },
    {
      "id": "64",
      "label": "Activités des services financiers, hors assurance et caisses de retraite"
    },
    {
      "id": "64.1",
      "label": "Intermédiation monétaire"
    },
    {
      "id": "64.11",
      "label": "Activités de banque centrale"
    },
    {
      "id": "64.11Z",
      "label": "Activités de banque centrale"
    },
    {
      "id": "64.19",
      "label": "Autres intermédiations monétaires"
    },
    {
      "id": "64.19Z",
      "label": "Autres intermédiations monétaires"
    },
    {
      "id": "64.2",
      "label": "Activités des sociétés holding"
    },
    {
      "id": "64.20",
      "label": "Activités des sociétés holding"
    },
    {
      "id": "64.20Z",
      "label": "Activités des sociétés holding"
    },
    {
      "id": "64.3",
      "label": "Fonds de placement et entités financières similaires"
    },
    {
      "id": "64.30",
      "label": "Fonds de placement et entités financières similaires"
    },
    {
      "id": "64.30Z",
      "label": "Fonds de placement et entités financières similaires"
    },
    {
      "id": "64.9",
      "label": "Autres activités des services financiers, hors assurance et caisses de retraite"
    },
    {
      "id": "64.91",
      "label": "Crédit-bail"
    },
    {
      "id": "64.91Z",
      "label": "Crédit-bail"
    },
    {
      "id": "64.92",
      "label": "Autre distribution de crédit"
    },
    {
      "id": "64.92Z",
      "label": "Autre distribution de crédit"
    },
    {
      "id": "64.99",
      "label": "Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a."
    },
    {
      "id": "64.99Z",
      "label": "Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a."
    },
    {
      "id": "65",
      "label": "Assurance"
    },
    {
      "id": "65.1",
      "label": "Assurance"
    },
    {
      "id": "65.11",
      "label": "Assurance vie"
    },
    {
      "id": "65.11Z",
      "label": "Assurance vie"
    },
    {
      "id": "65.12",
      "label": "Autres assurances"
    },
    {
      "id": "65.12Z",
      "label": "Autres assurances"
    },
    {
      "id": "65.2",
      "label": "Réassurance"
    },
    {
      "id": "65.20",
      "label": "Réassurance"
    },
    {
      "id": "65.20Z",
      "label": "Réassurance"
    },
    {
      "id": "65.3",
      "label": "Caisses de retraite"
    },
    {
      "id": "65.30",
      "label": "Caisses de retraite"
    },
    {
      "id": "65.30Z",
      "label": "Caisses de retraite"
    },
    {
      "id": "66",
      "label": "Activités auxiliaires de services financiers et d'assurance"
    },
    {
      "id": "66.1",
      "label": "Activités auxiliaires de services financiers, hors assurance et caisses de retraite"
    },
    {
      "id": "66.11",
      "label": "Administration de marchés financiers"
    },
    {
      "id": "66.11Z",
      "label": "Administration de marchés financiers"
    },
    {
      "id": "66.12",
      "label": "Courtage de valeurs mobilières et de marchandises"
    },
    {
      "id": "66.12Z",
      "label": "Courtage de valeurs mobilières et de marchandises"
    },
    {
      "id": "66.19",
      "label": "Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite"
    },
    {
      "id": "66.19A",
      "label": "Supports juridiques de gestion de patrimoine mobilier"
    },
    {
      "id": "66.19B",
      "label": "Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite, n.c.a."
    },
    {
      "id": "66.2",
      "label": "Activités auxiliaires d'assurance et de caisses de retraite"
    },
    {
      "id": "66.21",
      "label": "Évaluation des risques et dommages"
    },
    {
      "id": "66.21Z",
      "label": "Évaluation des risques et dommages"
    },
    {
      "id": "66.22",
      "label": "Activités des agents et courtiers d'assurances"
    },
    {
      "id": "66.22Z",
      "label": "Activités des agents et courtiers d'assurances"
    },
    {
      "id": "66.29",
      "label": "Autres activités auxiliaires d'assurance et de caisses de retraite"
    },
    {
      "id": "66.29Z",
      "label": "Autres activités auxiliaires d'assurance et de caisses de retraite"
    },
    {
      "id": "66.3",
      "label": "Gestion de fonds"
    },
    {
      "id": "66.30",
      "label": "Gestion de fonds"
    },
    {
      "id": "66.30Z",
      "label": "Gestion de fonds"
    },
    {
      "id": "68",
      "label": "Activités immobilières"
    },
    {
      "id": "68.1",
      "label": "Activités des marchands de biens immobiliers"
    },
    {
      "id": "68.10",
      "label": "Activités des marchands de biens immobiliers"
    },
    {
      "id": "68.10Z",
      "label": "Activités des marchands de biens immobiliers"
    },
    {
      "id": "68.2",
      "label": "Location et exploitation de biens immobiliers propres ou loués"
    },
    {
      "id": "68.20",
      "label": "Location et exploitation de biens immobiliers propres ou loués"
    },
    {
      "id": "68.20A",
      "label": "Location de logements"
    },
    {
      "id": "68.20B",
      "label": "Location de terrains et d'autres biens immobiliers"
    },
    {
      "id": "68.3",
      "label": "Activités immobilières pour compte de tiers"
    },
    {
      "id": "68.31",
      "label": "Agences immobilières"
    },
    {
      "id": "68.31Z",
      "label": "Agences immobilières"
    },
    {
      "id": "68.32",
      "label": "Administration de biens immobiliers"
    },
    {
      "id": "68.32A",
      "label": "Administration d'immeubles et autres biens immobiliers"
    },
    {
      "id": "68.32B",
      "label": "Supports juridiques de gestion de patrimoine immobilier"
    },
    {
      "id": "69",
      "label": "Activités juridiques et comptables"
    },
    {
      "id": "69.1",
      "label": "Activités juridiques"
    },
    {
      "id": "69.10",
      "label": "Activités juridiques"
    },
    {
      "id": "69.10Z",
      "label": "Activités juridiques"
    },
    {
      "id": "69.2",
      "label": "Activités comptables"
    },
    {
      "id": "69.20",
      "label": "Activités comptables"
    },
    {
      "id": "69.20Z",
      "label": "Activités comptables"
    },
    {
      "id": "70",
      "label": "Activités des sièges sociaux ; conseil de gestion"
    },
    {
      "id": "70.1",
      "label": "Activités des sièges sociaux"
    },
    {
      "id": "70.10",
      "label": "Activités des sièges sociaux"
    },
    {
      "id": "70.10Z",
      "label": "Activités des sièges sociaux"
    },
    {
      "id": "70.2",
      "label": "Conseil de gestion"
    },
    {
      "id": "70.21",
      "label": "Conseil en relations publiques et communication"
    },
    {
      "id": "70.21Z",
      "label": "Conseil en relations publiques et communication"
    },
    {
      "id": "70.22",
      "label": "Conseil pour les affaires et autres conseils de gestion"
    },
    {
      "id": "70.22Z",
      "label": "Conseil pour les affaires et autres conseils de gestion"
    },
    {
      "id": "71",
      "label": "Activités d'architecture et d'ingénierie ; activités de contrôle et analyses techniques"
    },
    {
      "id": "71.1",
      "label": "Activités d'architecture et d'ingénierie"
    },
    {
      "id": "71.11",
      "label": "Activités d'architecture"
    },
    {
      "id": "71.11Z",
      "label": "Activités d'architecture"
    },
    {
      "id": "71.12",
      "label": "Activités d'ingénierie"
    },
    {
      "id": "71.12A",
      "label": "Activité des géomètres"
    },
    {
      "id": "71.12B",
      "label": "Ingénierie, études techniques"
    },
    {
      "id": "71.2",
      "label": "Activités de contrôle et analyses techniques"
    },
    {
      "id": "71.20",
      "label": "Activités de contrôle et analyses techniques"
    },
    {
      "id": "71.20A",
      "label": "Contrôle technique automobile"
    },
    {
      "id": "71.20B",
      "label": "Analyses, essais et inspections techniques"
    },
    {
      "id": "72",
      "label": "Recherche-développement scientifique"
    },
    {
      "id": "72.1",
      "label": "Recherche-développement en sciences physiques et naturelles"
    },
    {
      "id": "72.11",
      "label": "Recherche-développement en biotechnologie"
    },
    {
      "id": "72.11Z",
      "label": "Recherche-développement en biotechnologie"
    },
    {
      "id": "72.19",
      "label": "Recherche-développement en autres sciences physiques et naturelles"
    },
    {
      "id": "72.19Z",
      "label": "Recherche-développement en autres sciences physiques et naturelles"
    },
    {
      "id": "72.2",
      "label": "Recherche-développement en sciences humaines et sociales"
    },
    {
      "id": "72.20",
      "label": "Recherche-développement en sciences humaines et sociales"
    },
    {
      "id": "72.20Z",
      "label": "Recherche-développement en sciences humaines et sociales"
    },
    {
      "id": "73",
      "label": "Publicité et études de marché"
    },
    {
      "id": "73.1",
      "label": "Publicité"
    },
    {
      "id": "73.11",
      "label": "Activités des agences de publicité"
    },
    {
      "id": "73.11Z",
      "label": "Activités des agences de publicité"
    },
    {
      "id": "73.12",
      "label": "Régie publicitaire de médias"
    },
    {
      "id": "73.12Z",
      "label": "Régie publicitaire de médias"
    },
    {
      "id": "73.2",
      "label": "Études de marché et sondages"
    },
    {
      "id": "73.20",
      "label": "Études de marché et sondages"
    },
    {
      "id": "73.20Z",
      "label": "Études de marché et sondages"
    },
    {
      "id": "74",
      "label": "Autres activités spécialisées, scientifiques et techniques"
    },
    {
      "id": "74.1",
      "label": "Activités spécialisées de design"
    },
    {
      "id": "74.10",
      "label": "Activités spécialisées de design"
    },
    {
      "id": "74.10Z",
      "label": "Activités spécialisées de design"
    },
    {
      "id": "74.2",
      "label": "Activités photographiques"
    },
    {
      "id": "74.20",
      "label": "Activités photographiques"
    },
    {
      "id": "74.20Z",
      "label": "Activités photographiques"
    },
    {
      "id": "74.3",
      "label": "Traduction et interprétation"
    },
    {
      "id": "74.30",
      "label": "Traduction et interprétation"
    },
    {
      "id": "74.30Z",
      "label": "Traduction et interprétation"
    },
    {
      "id": "74.9",
      "label": "Autres activités spécialisées, scientifiques et techniques n.c.a."
    },
    {
      "id": "74.90",
      "label": "Autres activités spécialisées, scientifiques et techniques n.c.a."
    },
    {
      "id": "74.90A",
      "label": "Activité des économistes de la construction"
    },
    {
      "id": "74.90B",
      "label": "Activités spécialisées, scientifiques et techniques diverses"
    },
    {
      "id": "75",
      "label": "Activités vétérinaires"
    },
    {
      "id": "75.0",
      "label": "Activités vétérinaires"
    },
    {
      "id": "75.00",
      "label": "Activités vétérinaires"
    },
    {
      "id": "75.00Z",
      "label": "Activités vétérinaires"
    },
    {
      "id": "77",
      "label": "Activités de location et location-bail"
    },
    {
      "id": "77.1",
      "label": "Location et location-bail de véhicules automobiles"
    },
    {
      "id": "77.11",
      "label": "Location et location-bail de voitures et de véhicules automobiles légers"
    },
    {
      "id": "77.11A",
      "label": "Location de courte durée de voitures et de véhicules automobiles légers"
    },
    {
      "id": "77.11B",
      "label": "Location de longue durée de voitures et de véhicules automobiles légers"
    },
    {
      "id": "77.12",
      "label": "Location et location-bail de camions"
    },
    {
      "id": "77.12Z",
      "label": "Location et location-bail de camions"
    },
    {
      "id": "77.2",
      "label": "Location et location-bail de biens personnels et domestiques"
    },
    {
      "id": "77.21",
      "label": "Location et location-bail d'articles de loisirs et de sport"
    },
    {
      "id": "77.21Z",
      "label": "Location et location-bail d'articles de loisirs et de sport"
    },
    {
      "id": "77.22",
      "label": "Location de vidéocassettes et disques vidéo"
    },
    {
      "id": "77.22Z",
      "label": "Location de vidéocassettes et disques vidéo"
    },
    {
      "id": "77.29",
      "label": "Location et location-bail d'autres biens personnels et domestiques"
    },
    {
      "id": "77.29Z",
      "label": "Location et location-bail d'autres biens personnels et domestiques"
    },
    {
      "id": "77.3",
      "label": "Location et location-bail d'autres machines, équipements et biens"
    },
    {
      "id": "77.31",
      "label": "Location et location-bail de machines et équipements agricoles"
    },
    {
      "id": "77.31Z",
      "label": "Location et location-bail de machines et équipements agricoles"
    },
    {
      "id": "77.32",
      "label": "Location et location-bail de machines et équipements pour la construction"
    },
    {
      "id": "77.32Z",
      "label": "Location et location-bail de machines et équipements pour la construction"
    },
    {
      "id": "77.33",
      "label": "Location et location-bail de machines de bureau et de matériel informatique"
    },
    {
      "id": "77.33Z",
      "label": "Location et location-bail de machines de bureau et de matériel informatique"
    },
    {
      "id": "77.34",
      "label": "Location et location-bail de matériels de transport par eau"
    },
    {
      "id": "77.34Z",
      "label": "Location et location-bail de matériels de transport par eau"
    },
    {
      "id": "77.35",
      "label": "Location et location-bail de matériels de transport aérien"
    },
    {
      "id": "77.35Z",
      "label": "Location et location-bail de matériels de transport aérien"
    },
    {
      "id": "77.39",
      "label": "Location et location-bail d'autres machines, équipements et biens matériels n.c.a."
    },
    {
      "id": "77.39Z",
      "label": "Location et location-bail d'autres machines, équipements et biens matériels n.c.a."
    },
    {
      "id": "77.4",
      "label": "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright"
    },
    {
      "id": "77.40",
      "label": "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright"
    },
    {
      "id": "77.40Z",
      "label": "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright"
    },
    {
      "id": "78",
      "label": "Activités liées à l'emploi"
    },
    {
      "id": "78.1",
      "label": "Activités des agences de placement de main-d'œuvre"
    },
    {
      "id": "78.10",
      "label": "Activités des agences de placement de main-d'œuvre"
    },
    {
      "id": "78.10Z",
      "label": "Activités des agences de placement de main-d'œuvre"
    },
    {
      "id": "78.2",
      "label": "Activités des agences de travail temporaire"
    },
    {
      "id": "78.20",
      "label": "Activités des agences de travail temporaire"
    },
    {
      "id": "78.20Z",
      "label": "Activités des agences de travail temporaire"
    },
    {
      "id": "78.3",
      "label": "Autre mise à disposition de ressources humaines"
    },
    {
      "id": "78.30",
      "label": "Autre mise à disposition de ressources humaines"
    },
    {
      "id": "78.30Z",
      "label": "Autre mise à disposition de ressources humaines"
    },
    {
      "id": "79",
      "label": "Activités des agences de voyage, voyagistes, services de réservation et activités connexes"
    },
    {
      "id": "79.1",
      "label": "Activités des agences de voyage et voyagistes"
    },
    {
      "id": "79.11",
      "label": "Activités des agences de voyage"
    },
    {
      "id": "79.11Z",
      "label": "Activités des agences de voyage"
    },
    {
      "id": "79.12",
      "label": "Activités des voyagistes"
    },
    {
      "id": "79.12Z",
      "label": "Activités des voyagistes"
    },
    {
      "id": "79.9",
      "label": "Autres services de réservation et activités connexes"
    },
    {
      "id": "79.90",
      "label": "Autres services de réservation et activités connexes"
    },
    {
      "id": "79.90Z",
      "label": "Autres services de réservation et activités connexes"
    },
    {
      "id": "80",
      "label": "Enquêtes et sécurité"
    },
    {
      "id": "80.1",
      "label": "Activités de sécurité privée"
    },
    {
      "id": "80.10",
      "label": "Activités de sécurité privée"
    },
    {
      "id": "80.10Z",
      "label": "Activités de sécurité privée"
    },
    {
      "id": "80.2",
      "label": "Activités liées aux systèmes de sécurité"
    },
    {
      "id": "80.20",
      "label": "Activités liées aux systèmes de sécurité"
    },
    {
      "id": "80.20Z",
      "label": "Activités liées aux systèmes de sécurité"
    },
    {
      "id": "80.3",
      "label": "Activités d'enquête"
    },
    {
      "id": "80.30",
      "label": "Activités d'enquête"
    },
    {
      "id": "80.30Z",
      "label": "Activités d'enquête"
    },
    {
      "id": "81",
      "label": "Services relatifs aux bâtiments et aménagement paysager"
    },
    {
      "id": "81.1",
      "label": "Activités combinées de soutien lié aux bâtiments"
    },
    {
      "id": "81.10",
      "label": "Activités combinées de soutien lié aux bâtiments"
    },
    {
      "id": "81.10Z",
      "label": "Activités combinées de soutien lié aux bâtiments"
    },
    {
      "id": "81.2",
      "label": "Activités de nettoyage"
    },
    {
      "id": "81.21",
      "label": "Nettoyage courant des bâtiments"
    },
    {
      "id": "81.21Z",
      "label": "Nettoyage courant des bâtiments"
    },
    {
      "id": "81.22",
      "label": "Autres activités de nettoyage des bâtiments et nettoyage industriel"
    },
    {
      "id": "81.22Z",
      "label": "Autres activités de nettoyage des bâtiments et nettoyage industriel"
    },
    {
      "id": "81.29",
      "label": "Autres activités de nettoyage"
    },
    {
      "id": "81.29A",
      "label": "Désinfection, désinsectisation, dératisation"
    },
    {
      "id": "81.29B",
      "label": "Autres activités de nettoyage n.c.a."
    },
    {
      "id": "81.3",
      "label": "Services d'aménagement paysager"
    },
    {
      "id": "81.30",
      "label": "Services d'aménagement paysager"
    },
    {
      "id": "81.30Z",
      "label": "Services d'aménagement paysager"
    },
    {
      "id": "82",
      "label": "Activités administratives et autres activités de soutien aux entreprises"
    },
    {
      "id": "82.1",
      "label": "Activités administratives"
    },
    {
      "id": "82.11",
      "label": "Services administratifs combinés de bureau"
    },
    {
      "id": "82.11Z",
      "label": "Services administratifs combinés de bureau"
    },
    {
      "id": "82.19",
      "label": "Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau"
    },
    {
      "id": "82.19Z",
      "label": "Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau"
    },
    {
      "id": "82.2",
      "label": "Activités de centres d'appels"
    },
    {
      "id": "82.20",
      "label": "Activités de centres d'appels"
    },
    {
      "id": "82.20Z",
      "label": "Activités de centres d'appels"
    },
    {
      "id": "82.3",
      "label": "Organisation de salons professionnels et congrès"
    },
    {
      "id": "82.30",
      "label": "Organisation de salons professionnels et congrès"
    },
    {
      "id": "82.30Z",
      "label": "Organisation de foires, salons professionnels et congrès"
    },
    {
      "id": "82.9",
      "label": "Activités de soutien aux entreprises n.c.a."
    },
    {
      "id": "82.91",
      "label": "Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle"
    },
    {
      "id": "82.91Z",
      "label": "Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle"
    },
    {
      "id": "82.92",
      "label": "Activités de conditionnement"
    },
    {
      "id": "82.92Z",
      "label": "Activités de conditionnement"
    },
    {
      "id": "82.99",
      "label": "Autres activités de soutien aux entreprises n.c.a."
    },
    {
      "id": "82.99Z",
      "label": "Autres activités de soutien aux entreprises n.c.a."
    },
    {
      "id": "84",
      "label": "Administration publique et défense ; sécurité sociale obligatoire"
    },
    {
      "id": "84.1",
      "label": "Administration générale, économique et sociale"
    },
    {
      "id": "84.11",
      "label": "Administration publique générale"
    },
    {
      "id": "84.11Z",
      "label": "Administration publique générale"
    },
    {
      "id": "84.12",
      "label": "Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale"
    },
    {
      "id": "84.12Z",
      "label": "Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale"
    },
    {
      "id": "84.13",
      "label": "Administration publique (tutelle) des activités économiques"
    },
    {
      "id": "84.13Z",
      "label": "Administration publique (tutelle) des activités économiques"
    },
    {
      "id": "84.2",
      "label": "Services de prérogative publique"
    },
    {
      "id": "84.21",
      "label": "Affaires étrangères"
    },
    {
      "id": "84.21Z",
      "label": "Affaires étrangères"
    },
    {
      "id": "84.22",
      "label": "Défense"
    },
    {
      "id": "84.22Z",
      "label": "Défense"
    },
    {
      "id": "84.23",
      "label": "Justice"
    },
    {
      "id": "84.23Z",
      "label": "Justice"
    },
    {
      "id": "84.24",
      "label": "Activités d’ordre public et de sécurité"
    },
    {
      "id": "84.24Z",
      "label": "Activités d’ordre public et de sécurité"
    },
    {
      "id": "84.25",
      "label": "Services du feu et de secours"
    },
    {
      "id": "84.25Z",
      "label": "Services du feu et de secours"
    },
    {
      "id": "84.3",
      "label": "Sécurité sociale obligatoire"
    },
    {
      "id": "84.30",
      "label": "Sécurité sociale obligatoire"
    },
    {
      "id": "84.30A",
      "label": "Activités générales de sécurité sociale"
    },
    {
      "id": "84.30B",
      "label": "Gestion des retraites complémentaires"
    },
    {
      "id": "84.30C",
      "label": "Distribution sociale de revenus"
    },
    {
      "id": "85",
      "label": "Enseignement"
    },
    {
      "id": "85.1",
      "label": "Enseignement pré-primaire"
    },
    {
      "id": "85.10",
      "label": "Enseignement pré-primaire"
    },
    {
      "id": "85.10Z",
      "label": "Enseignement pré-primaire"
    },
    {
      "id": "85.2",
      "label": "Enseignement primaire"
    },
    {
      "id": "85.20",
      "label": "Enseignement primaire"
    },
    {
      "id": "85.20Z",
      "label": "Enseignement primaire"
    },
    {
      "id": "85.3",
      "label": "Enseignement secondaire"
    },
    {
      "id": "85.31",
      "label": "Enseignement secondaire général"
    },
    {
      "id": "85.31Z",
      "label": "Enseignement secondaire général"
    },
    {
      "id": "85.32",
      "label": "Enseignement secondaire technique ou professionnel"
    },
    {
      "id": "85.32Z",
      "label": "Enseignement secondaire technique ou professionnel"
    },
    {
      "id": "85.4",
      "label": "Enseignement supérieur et post-secondaire non supérieur"
    },
    {
      "id": "85.41",
      "label": "Enseignement post-secondaire non supérieur"
    },
    {
      "id": "85.41Z",
      "label": "Enseignement post-secondaire non supérieur"
    },
    {
      "id": "85.42",
      "label": "Enseignement supérieur"
    },
    {
      "id": "85.42Z",
      "label": "Enseignement supérieur"
    },
    {
      "id": "85.5",
      "label": "Autres activités d'enseignement"
    },
    {
      "id": "85.51",
      "label": "Enseignement de disciplines sportives et d'activités de loisirs"
    },
    {
      "id": "85.51Z",
      "label": "Enseignement de disciplines sportives et d'activités de loisirs"
    },
    {
      "id": "85.52",
      "label": "Enseignement culturel"
    },
    {
      "id": "85.52Z",
      "label": "Enseignement culturel"
    },
    {
      "id": "85.53",
      "label": "Enseignement de la conduite"
    },
    {
      "id": "85.53Z",
      "label": "Enseignement de la conduite"
    },
    {
      "id": "85.59",
      "label": "Enseignements divers"
    },
    {
      "id": "85.59A",
      "label": "Formation continue d'adultes"
    },
    {
      "id": "85.59B",
      "label": "Autres enseignements"
    },
    {
      "id": "85.6",
      "label": "Activités de soutien à l'enseignement"
    },
    {
      "id": "85.60",
      "label": "Activités de soutien à l'enseignement"
    },
    {
      "id": "85.60Z",
      "label": "Activités de soutien à l'enseignement"
    },
    {
      "id": "86",
      "label": "Activités pour la santé humaine"
    },
    {
      "id": "86.1",
      "label": "Activités hospitalières"
    },
    {
      "id": "86.10",
      "label": "Activités hospitalières"
    },
    {
      "id": "86.10Z",
      "label": "Activités hospitalières"
    },
    {
      "id": "86.2",
      "label": "Activité des médecins et des dentistes"
    },
    {
      "id": "86.21",
      "label": "Activité des médecins généralistes"
    },
    {
      "id": "86.21Z",
      "label": "Activité des médecins généralistes"
    },
    {
      "id": "86.22",
      "label": "Activité des médecins spécialistes"
    },
    {
      "id": "86.22A",
      "label": "Activités de radiodiagnostic et de radiothérapie"
    },
    {
      "id": "86.22B",
      "label": "Activités chirurgicales"
    },
    {
      "id": "86.22C",
      "label": "Autres activités des médecins spécialistes"
    },
    {
      "id": "86.23",
      "label": "Pratique dentaire"
    },
    {
      "id": "86.23Z",
      "label": "Pratique dentaire"
    },
    {
      "id": "86.9",
      "label": "Autres activités pour la santé humaine"
    },
    {
      "id": "86.90",
      "label": "Autres activités pour la santé humaine"
    },
    {
      "id": "86.90A",
      "label": "Ambulances"
    },
    {
      "id": "86.90B",
      "label": "Laboratoires d'analyses médicales"
    },
    {
      "id": "86.90C",
      "label": "Centres de collecte et banques d'organes"
    },
    {
      "id": "86.90D",
      "label": "Activités des infirmiers et des sages-femmes"
    },
    {
      "id": "86.90E",
      "label": "Activités des professionnels de la rééducation, de l’appareillage et des pédicures-podologues"
    },
    {
      "id": "86.90F",
      "label": "Activités de santé humaine non classées ailleurs"
    },
    {
      "id": "87",
      "label": "Hébergement médico-social et social"
    },
    {
      "id": "87.1",
      "label": "Hébergement médicalisé"
    },
    {
      "id": "87.10",
      "label": "Hébergement médicalisé"
    },
    {
      "id": "87.10A",
      "label": "Hébergement médicalisé pour personnes âgées"
    },
    {
      "id": "87.10B",
      "label": "Hébergement médicalisé pour enfants handicapés"
    },
    {
      "id": "87.10C",
      "label": "Hébergement médicalisé pour adultes handicapés et autre hébergement médicalisé"
    },
    {
      "id": "87.2",
      "label": "Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes"
    },
    {
      "id": "87.20",
      "label": "Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes"
    },
    {
      "id": "87.20A",
      "label": "Hébergement social pour handicapés mentaux et malades mentaux"
    },
    {
      "id": "87.20B",
      "label": "Hébergement social pour toxicomanes"
    },
    {
      "id": "87.3",
      "label": "Hébergement social pour personnes âgées ou handicapées physiques"
    },
    {
      "id": "87.30",
      "label": "Hébergement social pour personnes âgées ou handicapées physiques"
    },
    {
      "id": "87.30A",
      "label": "Hébergement social pour personnes âgées"
    },
    {
      "id": "87.30B",
      "label": "Hébergement social pour handicapés  physiques"
    },
    {
      "id": "87.9",
      "label": "Autres activités d’hébergement social"
    },
    {
      "id": "87.90",
      "label": "Autres activités d’hébergement social"
    },
    {
      "id": "87.90A",
      "label": "Hébergement social pour enfants en difficultés"
    },
    {
      "id": "87.90B",
      "label": "Hébergement social pour adultes et familles en difficultés et autre hébergement social"
    },
    {
      "id": "88",
      "label": "Action sociale sans hébergement"
    },
    {
      "id": "88.1",
      "label": "Action sociale sans hébergement pour personnes âgées et pour personnes handicapées"
    },
    {
      "id": "88.10",
      "label": "Action sociale sans hébergement pour personnes âgées et pour personnes handicapées"
    },
    {
      "id": "88.10A",
      "label": "Aide à domicile"
    },
    {
      "id": "88.10B",
      "label": "Accueil ou accompagnement sans hébergement d’adultes handicapés ou de  personnes âgées"
    },
    {
      "id": "88.10C",
      "label": "Aide par le travail"
    },
    {
      "id": "88.9",
      "label": "Autre action sociale sans hébergement"
    },
    {
      "id": "88.91",
      "label": "Action sociale sans hébergement pour jeunes enfants"
    },
    {
      "id": "88.91A",
      "label": "Accueil de jeunes enfants"
    },
    {
      "id": "88.91B",
      "label": "Accueil ou accompagnement sans hébergement d’enfants handicapés"
    },
    {
      "id": "88.99",
      "label": "Autre action sociale sans hébergement n.c.a."
    },
    {
      "id": "88.99A",
      "label": "Autre accueil ou accompagnement sans hébergement d’enfants et d’adolescents"
    },
    {
      "id": "88.99B",
      "label": "Action sociale sans hébergement n.c.a."
    },
    {
      "id": "90",
      "label": "Activités créatives, artistiques et de spectacle"
    },
    {
      "id": "90.0",
      "label": "Activités créatives, artistiques et de spectacle"
    },
    {
      "id": "90.01",
      "label": "Arts du spectacle vivant"
    },
    {
      "id": "90.01Z",
      "label": "Arts du spectacle vivant"
    },
    {
      "id": "90.02",
      "label": "Activités de soutien au spectacle vivant"
    },
    {
      "id": "90.02Z",
      "label": "Activités de soutien au spectacle vivant"
    },
    {
      "id": "90.03",
      "label": "Création artistique"
    },
    {
      "id": "90.03A",
      "label": "Création artistique relevant des arts plastiques"
    },
    {
      "id": "90.03B",
      "label": "Autre création artistique"
    },
    {
      "id": "90.04",
      "label": "Gestion de salles de spectacles"
    },
    {
      "id": "90.04Z",
      "label": "Gestion de salles de spectacles"
    },
    {
      "id": "91",
      "label": "Bibliothèques, archives, musées et autres activités culturelles"
    },
    {
      "id": "91.0",
      "label": "Bibliothèques, archives, musées et autres activités culturelles"
    },
    {
      "id": "91.01",
      "label": "Gestion des bibliothèques et des archives"
    },
    {
      "id": "91.01Z",
      "label": "Gestion des bibliothèques et des archives"
    },
    {
      "id": "91.02",
      "label": "Gestion des musées"
    },
    {
      "id": "91.02Z",
      "label": "Gestion des musées"
    },
    {
      "id": "91.03",
      "label": "Gestion des sites et monuments historiques et des attractions touristiques similaires"
    },
    {
      "id": "91.03Z",
      "label": "Gestion des sites et monuments historiques et des attractions touristiques similaires"
    },
    {
      "id": "91.04",
      "label": "Gestion des jardins botaniques et zoologiques et des réserves naturelles"
    },
    {
      "id": "91.04Z",
      "label": "Gestion des jardins botaniques et zoologiques et des réserves naturelles"
    },
    {
      "id": "92",
      "label": "Organisation de jeux de hasard et d'argent"
    },
    {
      "id": "92.0",
      "label": "Organisation de jeux de hasard et d'argent"
    },
    {
      "id": "92.00",
      "label": "Organisation de jeux de hasard et d'argent"
    },
    {
      "id": "92.00Z",
      "label": "Organisation de jeux de hasard et d'argent"
    },
    {
      "id": "93",
      "label": "Activités sportives, récréatives et de loisirs"
    },
    {
      "id": "93.1",
      "label": "Activités liées au sport"
    },
    {
      "id": "93.11",
      "label": "Gestion d'installations sportives"
    },
    {
      "id": "93.11Z",
      "label": "Gestion d'installations sportives"
    },
    {
      "id": "93.12",
      "label": "Activités de clubs de sports"
    },
    {
      "id": "93.12Z",
      "label": "Activités de clubs de sports"
    },
    {
      "id": "93.13",
      "label": "Activités des centres de culture physique"
    },
    {
      "id": "93.13Z",
      "label": "Activités des centres de culture physique"
    },
    {
      "id": "93.19",
      "label": "Autres activités liées au sport"
    },
    {
      "id": "93.19Z",
      "label": "Autres activités liées au sport"
    },
    {
      "id": "93.2",
      "label": "Activités récréatives et de loisirs"
    },
    {
      "id": "93.21",
      "label": "Activités des parcs d'attractions et parcs à thèmes"
    },
    {
      "id": "93.21Z",
      "label": "Activités des parcs d'attractions et parcs à thèmes"
    },
    {
      "id": "93.29",
      "label": "Autres activités récréatives et de loisirs"
    },
    {
      "id": "93.29Z",
      "label": "Autres activités récréatives et de loisirs"
    },
    {
      "id": "94",
      "label": "Activités des organisations associatives"
    },
    {
      "id": "94.1",
      "label": "Activités des organisations économiques, patronales et professionnelles"
    },
    {
      "id": "94.11",
      "label": "Activités des organisations patronales et consulaires"
    },
    {
      "id": "94.11Z",
      "label": "Activités des organisations patronales et consulaires"
    },
    {
      "id": "94.12",
      "label": "Activités des organisations professionnelles"
    },
    {
      "id": "94.12Z",
      "label": "Activités des organisations professionnelles"
    },
    {
      "id": "94.2",
      "label": "Activités des syndicats de salariés"
    },
    {
      "id": "94.20",
      "label": "Activités des syndicats de salariés"
    },
    {
      "id": "94.20Z",
      "label": "Activités des syndicats de salariés"
    },
    {
      "id": "94.9",
      "label": "Activités des autres organisations associatives"
    },
    {
      "id": "94.91",
      "label": "Activités des organisations religieuses"
    },
    {
      "id": "94.91Z",
      "label": "Activités des organisations religieuses"
    },
    {
      "id": "94.92",
      "label": "Activités des organisations politiques"
    },
    {
      "id": "94.92Z",
      "label": "Activités des organisations politiques"
    },
    {
      "id": "94.99",
      "label": "Activités des organisations associatives n.c.a."
    },
    {
      "id": "94.99Z",
      "label": "Autres organisations fonctionnant par adhésion volontaire"
    },
    {
      "id": "95",
      "label": "Réparation d'ordinateurs et de biens personnels et domestiques"
    },
    {
      "id": "95.1",
      "label": "Réparation d'ordinateurs et d'équipements de communication"
    },
    {
      "id": "95.11",
      "label": "Réparation d'ordinateurs et d'équipements périphériques"
    },
    {
      "id": "95.11Z",
      "label": "Réparation d'ordinateurs et d'équipements périphériques"
    },
    {
      "id": "95.12",
      "label": "Réparation d'équipements de communication"
    },
    {
      "id": "95.12Z",
      "label": "Réparation d'équipements de communication"
    },
    {
      "id": "95.2",
      "label": "Réparation de biens personnels et domestiques"
    },
    {
      "id": "95.21",
      "label": "Réparation de produits électroniques grand public"
    },
    {
      "id": "95.21Z",
      "label": "Réparation de produits électroniques grand public"
    },
    {
      "id": "95.22",
      "label": "Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin"
    },
    {
      "id": "95.22Z",
      "label": "Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin"
    },
    {
      "id": "95.23",
      "label": "Réparation de chaussures et d'articles en cuir"
    },
    {
      "id": "95.23Z",
      "label": "Réparation de chaussures et d'articles en cuir"
    },
    {
      "id": "95.24",
      "label": "Réparation de meubles et d'équipements du foyer"
    },
    {
      "id": "95.24Z",
      "label": "Réparation de meubles et d'équipements du foyer"
    },
    {
      "id": "95.25",
      "label": "Réparation d'articles d'horlogerie et de bijouterie"
    },
    {
      "id": "95.25Z",
      "label": "Réparation d'articles d'horlogerie et de bijouterie"
    },
    {
      "id": "95.29",
      "label": "Réparation d'autres biens personnels et domestiques"
    },
    {
      "id": "95.29Z",
      "label": "Réparation d'autres biens personnels et domestiques"
    },
    {
      "id": "96",
      "label": "Autres services personnels"
    },
    {
      "id": "96.0",
      "label": "Autres services personnels"
    },
    {
      "id": "96.01",
      "label": "Blanchisserie-teinturerie"
    },
    {
      "id": "96.01A",
      "label": "Blanchisserie-teinturerie de gros"
    },
    {
      "id": "96.01B",
      "label": "Blanchisserie-teinturerie de détail"
    },
    {
      "id": "96.02",
      "label": "Coiffure et soins de beauté"
    },
    {
      "id": "96.02A",
      "label": "Coiffure"
    },
    {
      "id": "96.02B",
      "label": "Soins de beauté"
    },
    {
      "id": "96.03",
      "label": "Services funéraires"
    },
    {
      "id": "96.03Z",
      "label": "Services funéraires"
    },
    {
      "id": "96.04",
      "label": "Entretien corporel"
    },
    {
      "id": "96.04Z",
      "label": "Entretien corporel"
    },
    {
      "id": "96.09",
      "label": "Autres services personnels n.c.a."
    },
    {
      "id": "96.09Z",
      "label": "Autres services personnels n.c.a."
    },
    {
      "id": "97",
      "label": "Activités des ménages en tant qu'employeurs de personnel domestique"
    },
    {
      "id": "97.0",
      "label": "Activités des ménages en tant qu'employeurs de personnel domestique"
    },
    {
      "id": "97.00",
      "label": "Activités des ménages en tant qu'employeurs de personnel domestique"
    },
    {
      "id": "97.00Z",
      "label": "Activités des ménages en tant qu'employeurs de personnel domestique"
    },
    {
      "id": "98",
      "label": "Activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre"
    },
    {
      "id": "98.1",
      "label": "Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre"
    },
    {
      "id": "98.10",
      "label": "Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre"
    },
    {
      "id": "98.10Z",
      "label": "Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre"
    },
    {
      "id": "98.2",
      "label": "Activités indifférenciées des ménages en tant que producteurs de services pour usage propre"
    },
    {
      "id": "98.20",
      "label": "Activités indifférenciées des ménages en tant que producteurs de services pour usage propre"
    },
    {
      "id": "98.20Z",
      "label": "Activités indifférenciées des ménages en tant que producteurs de services pour usage propre"
    },
    {
      "id": "99",
      "label": "Activités des organisations et organismes extraterritoriaux"
    },
    {
      "id": "99.0",
      "label": "Activités des organisations et organismes extraterritoriaux"
    },
    {
      "id": "99.00",
      "label": "Activités des organisations et organismes extraterritoriaux"
    },
    {
      "id": "99.00Z",
      "label": "Activités des organisations et organismes extraterritoriaux"
    }
  ]

const codes_naf_en = [
  {
      "id": "01",
      "label": "Crop and animal production, hunting and related service activities"
  },
  {
      "id": "01.1",
      "label": "Growing of non-perennial crops"
  },
  {
      "id": "01.11",
      "label": "Growing of cereals (except rice), leguminous crops and oil seeds"
  },
  {
      "id": "01.11Z",
      "label": "Growing of cereals (except rice), leguminous crops and oil seeds"
  },
  {
      "id": "01.12",
      "label": "Growing of rice"
  },
  {
      "id": "01.12Z",
      "label": "Growing of rice"
  },
  {
      "id": "01.13",
      "label": "Growing of vegetables and melons, roots and tubers"
  },
  {
      "id": "01.13Z",
      "label": "Growing of vegetables and melons, roots and tubers"
  },
  {
      "id": "01.14",
      "label": "Growing of sugar cane"
  },
  {
      "id": "01.14Z",
      "label": "Growing of sugar cane"
  },
  {
      "id": "01.15",
      "label": "Growing of tobacco"
  },
  {
      "id": "01.15Z",
      "label": "Growing of tobacco"
  },
  {
      "id": "01.16",
      "label": "Growing of fibre crops"
  },
  {
      "id": "01.16Z",
      "label": "Growing of fibre crops"
  },
  {
      "id": "01.19",
      "label": "Growing of other non-perennial crops"
  },
  {
      "id": "01.19Z",
      "label": "Growing of other non-perennial crops"
  },
  {
      "id": "01.2",
      "label": "Growing of perennial crops"
  },
  {
      "id": "01.21",
      "label": "Growing of grapes"
  },
  {
      "id": "01.21Z",
      "label": "Growing of grapes"
  },
  {
      "id": "01.22",
      "label": "Growing of tropical and subtropical fruits"
  },
  {
      "id": "01.22Z",
      "label": "Growing of tropical and subtropical fruits"
  },
  {
      "id": "01.23",
      "label": "Growing of citrus fruits"
  },
  {
      "id": "01.23Z",
      "label": "Growing of citrus fruits"
  },
  {
      "id": "01.24",
      "label": "Growing of pome fruits and stone fruits"
  },
  {
      "id": "01.24Z",
      "label": "Growing of pome fruits and stone fruits"
  },
  {
      "id": "01.25",
      "label": "Growing of other tree and bush fruits and nuts"
  },
  {
      "id": "01.25Z",
      "label": "Growing of other tree and bush fruits and nuts"
  },
  {
      "id": "01.26",
      "label": "Growing of oleaginous fruits"
  },
  {
      "id": "01.26Z",
      "label": "Growing of oleaginous fruits"
  },
  {
      "id": "01.27",
      "label": "Growing of beverage crops"
  },
  {
      "id": "01.27Z",
      "label": "Growing of beverage crops"
  },
  {
      "id": "01.28",
      "label": "Growing of spices, aromatic, drug and pharmaceutical crops"
  },
  {
      "id": "01.28Z",
      "label": "Growing of spices, aromatic, drug and pharmaceutical crops"
  },
  {
      "id": "01.29",
      "label": "Growing of other perennial crops"
  },
  {
      "id": "01.29Z",
      "label": "Growing of other perennial crops"
  },
  {
      "id": "01.3",
      "label": "Plant propagation"
  },
  {
      "id": "01.30",
      "label": "Plant propagation"
  },
  {
      "id": "01.30Z",
      "label": "Plant propagation"
  },
  {
      "id": "01.4",
      "label": "Animal production"
  },
  {
      "id": "01.41",
      "label": "Raising of dairy cattle"
  },
  {
      "id": "01.41Z",
      "label": "Raising of dairy cattle"
  },
  {
      "id": "01.42",
      "label": "Raising of other cattle and buffaloes"
  },
  {
      "id": "01.42Z",
      "label": "Raising of other cattle and buffaloes"
  },
  {
      "id": "01.43",
      "label": "Raising of horses and other equines"
  },
  {
      "id": "01.43Z",
      "label": "Raising of horses and other equines"
  },
  {
      "id": "01.44",
      "label": "Raising of camels and camelids"
  },
  {
      "id": "01.44Z",
      "label": "Raising of camels and camelids"
  },
  {
      "id": "01.45",
      "label": "Raising of sheep and goats"
  },
  {
      "id": "01.45Z",
      "label": "Raising of sheep and goats"
  },
  {
      "id": "01.46",
      "label": "Raising of swine/pigs"
  },
  {
      "id": "01.46Z",
      "label": "Raising of swine/pigs"
  },
  {
      "id": "01.47",
      "label": "Raising of poultry"
  },
  {
      "id": "01.47Z",
      "label": "Raising of poultry"
  },
  {
      "id": "01.49",
      "label": "Raising of other animals"
  },
  {
      "id": "01.49Z",
      "label": "Raising of other animals"
  },
  {
      "id": "01.5",
      "label": "Mixed farming"
  },
  {
      "id": "01.50",
      "label": "Mixed farming"
  },
  {
      "id": "01.50Z",
      "label": "Mixed farming"
  },
  {
      "id": "01.6b",
      "label": "Support activities to agriculture and post-harvest crop activities"
  },
  {
      "id": "01.61",
      "label": "Support activities for crop production"
  },
  {
      "id": "01.61Z",
      "label": "Support activities for crop production"
  },
  {
      "id": "01.62",
      "label": "Support activities for animal production"
  },
  {
      "id": "01.62Z",
      "label": "Support activities for animal production"
  },
  {
      "id": "01.63",
      "label": "Post-harvest crop activities"
  },
  {
      "id": "01.63Z",
      "label": "Post-harvest crop activities"
  },
  {
      "id": "01.64",
      "label": "Seed processing for propagation"
  },
  {
      "id": "01.64Z",
      "label": "Seed processing for propagation"
  },
  {
      "id": "01.7",
      "label": "Hunting, trapping and related service activities"
  },
  {
      "id": "01.70",
      "label": "Hunting, trapping and related service activities"
  },
  {
      "id": "01.70Z",
      "label": "Hunting, trapping and related service activities"
  },
  {
      "id": "02",
      "label": "Forestry and logging"
  },
  {
      "id": "02.1",
      "label": "Silviculture and other forestry activities"
  },
  {
      "id": "02.10",
      "label": "Silviculture and other forestry activities"
  },
  {
      "id": "02.10Z",
      "label": "Silviculture and other forestry activities"
  },
  {
      "id": "02.2",
      "label": "Logging"
  },
  {
      "id": "02.20",
      "label": "Logging"
  },
  {
      "id": "02.20Z",
      "label": "Logging"
  },
  {
      "id": "02.3",
      "label": "Gathering of wild growing non-wood products"
  },
  {
      "id": "02.30",
      "label": "Gathering of wild growing non-wood products"
  },
  {
      "id": "02.30Z",
      "label": "Gathering of wild growing non-wood products"
  },
  {
      "id": "02.4",
      "label": "Support services to forestry"
  },
  {
      "id": "02.40",
      "label": "Support services to forestry"
  },
  {
      "id": "02.40Z",
      "label": "Support services to forestry"
  },
  {
      "id": "03",
      "label": "Fishing and aquaculture"
  },
  {
      "id": "03.1",
      "label": "Fishing"
  },
  {
      "id": "03.11",
      "label": "Marine fishing"
  },
  {
      "id": "03.11Z",
      "label": "Marine fishing"
  },
  {
      "id": "03.12",
      "label": "Freshwater fishing"
  },
  {
      "id": "03.12Z",
      "label": "Freshwater fishing"
  },
  {
      "id": "03.2",
      "label": "Aquaculture"
  },
  {
      "id": "03.21",
      "label": "Marine aquaculture"
  },
  {
      "id": "03.21Z",
      "label": "Marine aquaculture"
  },
  {
      "id": "03.22",
      "label": "Freshwater aquaculture"
  },
  {
      "id": "03.22Z",
      "label": "Freshwater aquaculture"
  },
  {
      "id": "05",
      "label": "Mining of coal and lignite"
  },
  {
      "id": "05.1",
      "label": "Mining of hard coal"
  },
  {
      "id": "05.10",
      "label": "Mining of hard coal"
  },
  {
      "id": "05.10Z",
      "label": "Mining of hard coal"
  },
  {
      "id": "05.2",
      "label": "Mining of lignite"
  },
  {
      "id": "05.20",
      "label": "Mining of lignite"
  },
  {
      "id": "05.20Z",
      "label": "Mining of lignite"
  },
  {
      "id": "06",
      "label": "Extraction of crude petroleum and natural gas"
  },
  {
      "id": "06.1",
      "label": "Extraction of crude petroleum"
  },
  {
      "id": "06.10",
      "label": "Extraction of crude petroleum"
  },
  {
      "id": "06.10Z",
      "label": "Extraction of crude petroleum"
  },
  {
      "id": "06.2",
      "label": "Extraction of natural gas"
  },
  {
      "id": "06.20",
      "label": "Extraction of natural gas"
  },
  {
      "id": "06.20Z",
      "label": "Extraction of natural gas"
  },
  {
      "id": "07",
      "label": "Mining of metal ores"
  },
  {
      "id": "07.1",
      "label": "Mining of iron ores"
  },
  {
      "id": "07.10",
      "label": "Mining of iron ores"
  },
  {
      "id": "07.10Z",
      "label": "Mining of iron ores"
  },
  {
      "id": "07.2",
      "label": "Mining of non-ferrous metal ores"
  },
  {
      "id": "07.21",
      "label": "Mining of uranium and thorium ores"
  },
  {
      "id": "07.21Z",
      "label": "Mining of uranium and thorium ores"
  },
  {
      "id": "07.29",
      "label": "Mining of other non-ferrous metal ores"
  },
  {
      "id": "07.29Z",
      "label": "Mining of other non-ferrous metal ores"
  },
  {
      "id": "08",
      "label": "Other mining and quarrying"
  },
  {
      "id": "08.1",
      "label": "Quarrying of stone, sand and clay"
  },
  {
      "id": "08.11",
      "label": "Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate"
  },
  {
      "id": "08.11Z",
      "label": "Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate"
  },
  {
      "id": "08.12",
      "label": "Operation of gravel and sand pits; mining of clays and kaolin"
  },
  {
      "id": "08.12Z",
      "label": "Operation of gravel and sand pits; mining of clays and kaolin"
  },
  {
      "id": "08.9",
      "label": "Mining and quarrying n.e.c."
  },
  {
      "id": "08.91",
      "label": "Mining of chemical and fertiliser minerals"
  },
  {
      "id": "08.91Z",
      "label": "Mining of chemical and fertiliser minerals"
  },
  {
      "id": "08.92",
      "label": "Extraction of peat"
  },
  {
      "id": "08.92Z",
      "label": "Extraction of peat"
  },
  {
      "id": "08.93",
      "label": "Extraction of salt"
  },
  {
      "id": "08.93Z",
      "label": "Extraction of salt"
  },
  {
      "id": "08.99",
      "label": "Other mining and quarrying n.e.c."
  },
  {
      "id": "08.99Z",
      "label": "Other mining and quarrying n.e.c."
  },
  {
      "id": "09",
      "label": "Mining support service activities"
  },
  {
      "id": "09.1",
      "label": "Support activities for petroleum and natural gas extraction"
  },
  {
      "id": "09.10",
      "label": "Support activities for petroleum and natural gas extraction"
  },
  {
      "id": "09.10Z",
      "label": "Support activities for petroleum and natural gas extraction"
  },
  {
      "id": "09.9",
      "label": "Support activities for other mining and quarrying"
  },
  {
      "id": "09.90",
      "label": "Support activities for other mining and quarrying"
  },
  {
      "id": "09.90Z",
      "label": "Support activities for other mining and quarrying"
  },
  {
      "id": "10",
      "label": "Manufacture of food products"
  },
  {
      "id": "10.1",
      "label": "Processing and preserving of meat and production of meat products"
  },
  {
      "id": "10.11",
      "label": "Processing and preserving of meat"
  },
  {
      "id": "10.11Z",
      "label": "Processing and preserving of meat"
  },
  {
      "id": "10.12",
      "label": "Processing and preserving of poultry meat"
  },
  {
      "id": "10.12Z",
      "label": "Processing and preserving of poultry meat"
  },
  {
      "id": "10.13",
      "label": "Production of meat and poultry meat products"
  },
  {
      "id": "10.13A",
      "label": "Industrial production of meat products"
  },
  {
      "id": "10.13B",
      "label": "Cooked meats production and trade"
  },
  {
      "id": "10.2",
      "label": "Processing and preserving of fish, crustaceans and molluscs"
  },
  {
      "id": "10.20",
      "label": "Processing and preserving of fish, crustaceans and molluscs"
  },
  {
      "id": "10.20Z",
      "label": "Processing and preserving of fish, crustaceans and molluscs"
  },
  {
      "id": "10.3",
      "label": "Processing and preserving of fruit and vegetables"
  },
  {
      "id": "10.31",
      "label": "Processing and preserving of potatoes"
  },
  {
      "id": "10.31Z",
      "label": "Processing and preserving of potatoes"
  },
  {
      "id": "10.32",
      "label": "Manufacture of fruit and vegetable juice"
  },
  {
      "id": "10.32Z",
      "label": "Manufacture of fruit and vegetable juice"
  },
  {
      "id": "10.39",
      "label": "Other processing and preserving of fruit and vegetables"
  },
  {
      "id": "10.39A",
      "label": "Other processing and preserving of vegetables"
  },
  {
      "id": "10.39B",
      "label": "Processing and preserving of fruit"
  },
  {
      "id": "10.4",
      "label": "Manufacture of vegetable and animal oils and fats"
  },
  {
      "id": "10.41",
      "label": "Manufacture of oils and fats"
  },
  {
      "id": "10.41A",
      "label": "Manufacture of crude oils and fats"
  },
  {
      "id": "10.41B",
      "label": "Manufacture of refined oils and fats"
  },
  {
      "id": "10.42",
      "label": "Manufacture of margarine and similar edible fats"
  },
  {
      "id": "10.42Z",
      "label": "Manufacture of margarine and similar edible fats"
  },
  {
      "id": "10.5",
      "label": "Manufacture of dairy products"
  },
  {
      "id": "10.51",
      "label": "Operation of dairies and cheese making"
  },
  {
      "id": "10.51A",
      "label": "Manufacture of liquid milk and of fresh dairy products"
  },
  {
      "id": "10.51B",
      "label": "Manufacture of butter"
  },
  {
      "id": "10.51C",
      "label": "Manufacture of cheese"
  },
  {
      "id": "10.51D",
      "label": "Manufacture of other dairy products"
  },
  {
      "id": "10.52",
      "label": "Manufacture of ice cream"
  },
  {
      "id": "10.52Z",
      "label": "Manufacture of ice cream"
  },
  {
      "id": "10.6",
      "label": "Manufacture of grain mill products, starches and starch products"
  },
  {
      "id": "10.61",
      "label": "Manufacture of grain mill products"
  },
  {
      "id": "10.61A",
      "label": "Flour milling"
  },
  {
      "id": "10.61B",
      "label": "Other manufacture of grain mill products"
  },
  {
      "id": "10.62",
      "label": "Manufacture of starches and starch products"
  },
  {
      "id": "10.62Z",
      "label": "Manufacture of starches and starch products"
  },
  {
      "id": "10.7",
      "label": "Manufacture of bakery and farinaceous products"
  },
  {
      "id": "10.71",
      "label": "Manufacture of bread; manufacture of fresh pastry goods and cakes"
  },
  {
      "id": "10.71A",
      "label": "Industrial manufacture of bread and fresh pastry"
  },
  {
      "id": "10.71B",
      "label": "Baking of bakery products"
  },
  {
      "id": "10.71C",
      "label": "Bakery and bakery confectionery"
  },
  {
      "id": "10.71D",
      "label": "Confectionery"
  },
  {
      "id": "10.72",
      "label": "Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes"
  },
  {
      "id": "10.72Z",
      "label": "Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes"
  },
  {
      "id": "10.73",
      "label": "Manufacture of macaroni, noodles, couscous and similar farinaceous products"
  },
  {
      "id": "10.73Z",
      "label": "Manufacture of macaroni, noodles, couscous and similar farinaceous products"
  },
  {
      "id": "10.8",
      "label": "Manufacture of other food products"
  },
  {
      "id": "10.81",
      "label": "Manufacture of sugar"
  },
  {
      "id": "10.81Z",
      "label": "Manufacture of sugar"
  },
  {
      "id": "10.82",
      "label": "Manufacture of cocoa, chocolate and sugar confectionery"
  },
  {
      "id": "10.82Z",
      "label": "Manufacture of cocoa, chocolate and sugar confectionery"
  },
  {
      "id": "10.83",
      "label": "Processing of tea and coffee"
  },
  {
      "id": "10.83Z",
      "label": "Processing of tea and coffee"
  },
  {
      "id": "10.84",
      "label": "Manufacture of condiments and seasonings"
  },
  {
      "id": "10.84Z",
      "label": "Manufacture of condiments and seasonings"
  },
  {
      "id": "10.85",
      "label": "Manufacture of prepared meals and dishes"
  },
  {
      "id": "10.85Z",
      "label": "Manufacture of prepared meals and dishes"
  },
  {
      "id": "10.86",
      "label": "Manufacture of homogenised food preparations and dietetic food"
  },
  {
      "id": "10.86Z",
      "label": "Manufacture of homogenised food preparations and dietetic food"
  },
  {
      "id": "10.89",
      "label": "Manufacture of other food products n.e.c."
  },
  {
      "id": "10.89Z",
      "label": "Manufacture of other food products n.e.c."
  },
  {
      "id": "10.9",
      "label": "Manufacture of prepared animal feeds"
  },
  {
      "id": "10.91",
      "label": "Manufacture of prepared feeds for farm animals"
  },
  {
      "id": "10.91Z",
      "label": "Manufacture of prepared feeds for farm animals"
  },
  {
      "id": "10.92",
      "label": "Manufacture of prepared pet foods"
  },
  {
      "id": "10.92Z",
      "label": "Manufacture of prepared pet foods"
  },
  {
      "id": "11",
      "label": "Manufacture of beverages"
  },
  {
      "id": "11.0",
      "label": "Manufacture of beverages"
  },
  {
      "id": "11.01",
      "label": "Distilling, rectifying and blending of spirits"
  },
  {
      "id": "11.01Z",
      "label": "Distilling, rectifying and blending of spirits"
  },
  {
      "id": "11.02",
      "label": "Manufacture of wine from grape"
  },
  {
      "id": "11.02A",
      "label": "Manufacture of sparkling wines"
  },
  {
      "id": "11.02B",
      "label": "Wine-making"
  },
  {
      "id": "11.03",
      "label": "Manufacture of cider and other fruit wines"
  },
  {
      "id": "11.03Z",
      "label": "Manufacture of cider and other fruit wines"
  },
  {
      "id": "11.04",
      "label": "Manufacture of other non-distilled fermented beverages"
  },
  {
      "id": "11.04Z",
      "label": "Manufacture of other non-distilled fermented beverages"
  },
  {
      "id": "11.05",
      "label": "Manufacture of beer"
  },
  {
      "id": "11.05Z",
      "label": "Manufacture of beer"
  },
  {
      "id": "11.06",
      "label": "Manufacture of malt"
  },
  {
      "id": "11.06Z",
      "label": "Manufacture of malt"
  },
  {
      "id": "11.07",
      "label": "Manufacture of soft drinks; production of mineral waters and other bottled waters"
  },
  {
      "id": "11.07A",
      "label": "Production of mineral water"
  },
  {
      "id": "11.07B",
      "label": "Production of soft drinks"
  },
  {
      "id": "12",
      "label": "Manufacture of tobacco products"
  },
  {
      "id": "12.0",
      "label": "Manufacture of tobacco products"
  },
  {
      "id": "12.00",
      "label": "Manufacture of tobacco products"
  },
  {
      "id": "12.00Z",
      "label": "Manufacture of tobacco products"
  },
  {
      "id": "13",
      "label": "Manufacture of textiles"
  },
  {
      "id": "13.1",
      "label": "Preparation and spinning of textile fibres"
  },
  {
      "id": "13.10",
      "label": "Preparation and spinning of textile fibres"
  },
  {
      "id": "13.10Z",
      "label": "Preparation and spinning of textile fibres"
  },
  {
      "id": "13.2",
      "label": "Weaving of textiles"
  },
  {
      "id": "13.20",
      "label": "Weaving of textiles"
  },
  {
      "id": "13.20Z",
      "label": "Weaving of textiles"
  },
  {
      "id": "13.3",
      "label": "Finishing of textiles"
  },
  {
      "id": "13.30",
      "label": "Finishing of textiles"
  },
  {
      "id": "13.30Z",
      "label": "Finishing of textiles"
  },
  {
      "id": "13.9",
      "label": "Manufacture of other textiles"
  },
  {
      "id": "13.91",
      "label": "Manufacture of knitted and crocheted fabrics"
  },
  {
      "id": "13.91Z",
      "label": "Manufacture of knitted and crocheted fabrics"
  },
  {
      "id": "13.92",
      "label": "Manufacture of made-up textile articles, except apparel"
  },
  {
      "id": "13.92Z",
      "label": "Manufacture of made-up textile articles, except apparel"
  },
  {
      "id": "13.93",
      "label": "Manufacture of carpets and rugs"
  },
  {
      "id": "13.93Z",
      "label": "Manufacture of carpets and rugs"
  },
  {
      "id": "13.94",
      "label": "Manufacture of cordage, rope, twine and netting"
  },
  {
      "id": "13.94Z",
      "label": "Manufacture of cordage, rope, twine and netting"
  },
  {
      "id": "13.95",
      "label": "Manufacture of non-wovens and articles made from non-wovens, except apparel"
  },
  {
      "id": "13.95Z",
      "label": "Manufacture of non-wovens and articles made from non-wovens, except apparel"
  },
  {
      "id": "13.96",
      "label": "Manufacture of other technical and industrial textiles"
  },
  {
      "id": "13.96Z",
      "label": "Manufacture of other technical and industrial textiles"
  },
  {
      "id": "13.99",
      "label": "Manufacture of other textiles n.e.c."
  },
  {
      "id": "13.99Z",
      "label": "Manufacture of other textiles n.e.c."
  },
  {
      "id": "14",
      "label": "Manufacture of wearing apparel"
  },
  {
      "id": "14.1",
      "label": "Manufacture of wearing apparel, except fur apparel"
  },
  {
      "id": "14.11",
      "label": "Manufacture of leather clothes"
  },
  {
      "id": "14.11Z",
      "label": "Manufacture of leather clothes"
  },
  {
      "id": "14.12",
      "label": "Manufacture of workwear"
  },
  {
      "id": "14.12Z",
      "label": "Manufacture of workwear"
  },
  {
      "id": "14.13",
      "label": "Manufacture of other outerwear"
  },
  {
      "id": "14.13Z",
      "label": "Manufacture of other outerwear"
  },
  {
      "id": "14.14",
      "label": "Manufacture of underwear"
  },
  {
      "id": "14.14Z",
      "label": "Manufacture of underwear"
  },
  {
      "id": "14.19",
      "label": "Manufacture of other wearing apparel and accessories"
  },
  {
      "id": "14.19Z",
      "label": "Manufacture of other wearing apparel and accessories"
  },
  {
      "id": "14.2",
      "label": "Manufacture of articles of fur"
  },
  {
      "id": "14.20",
      "label": "Manufacture of articles of fur"
  },
  {
      "id": "14.20Z",
      "label": "Manufacture of articles of fur"
  },
  {
      "id": "14.3",
      "label": "Manufacture of knitted and crocheted apparel"
  },
  {
      "id": "14.31",
      "label": "Manufacture of knitted and crocheted hosiery"
  },
  {
      "id": "14.31Z",
      "label": "Manufacture of knitted and crocheted hosiery"
  },
  {
      "id": "14.39",
      "label": "Manufacture of other knitted and crocheted apparel"
  },
  {
      "id": "14.39Z",
      "label": "Manufacture of other knitted and crocheted apparel"
  },
  {
      "id": "15",
      "label": "Manufacture of leather and related products"
  },
  {
      "id": "15.1",
      "label": "Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur"
  },
  {
      "id": "15.11",
      "label": "Tanning and dressing of leather; dressing and dyeing of fur"
  },
  {
      "id": "15.11Z",
      "label": "Tanning and dressing of leather; dressing and dyeing of fur"
  },
  {
      "id": "15.12",
      "label": "Manufacture of luggage, handbags and the like, saddlery and harness"
  },
  {
      "id": "15.12Z",
      "label": "Manufacture of luggage, handbags and the like, saddlery and harness"
  },
  {
      "id": "15.2",
      "label": "Manufacture of footwear"
  },
  {
      "id": "15.20",
      "label": "Manufacture of footwear"
  },
  {
      "id": "15.20Z",
      "label": "Manufacture of footwear"
  },
  {
      "id": "16",
      "label": "Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials"
  },
  {
      "id": "16.1",
      "label": "Sawmilling and planing of wood"
  },
  {
      "id": "16.10",
      "label": "Sawmilling and planing of wood"
  },
  {
      "id": "16.10A",
      "label": "Sawmilling and planing of wood, excluding impregnation"
  },
  {
      "id": "16.10B",
      "label": "Impregnation of wood"
  },
  {
      "id": "16.2",
      "label": "Manufacture of products of wood, cork, straw and plaiting materials"
  },
  {
      "id": "16.21",
      "label": "Manufacture of veneer sheets and wood-based panels"
  },
  {
      "id": "16.21Z",
      "label": "Manufacture of veneer sheets and wood-based panels"
  },
  {
      "id": "16.22",
      "label": "Manufacture of assembled parquet floors"
  },
  {
      "id": "16.22Z",
      "label": "Manufacture of assembled parquet floors"
  },
  {
      "id": "16.23",
      "label": "Manufacture of other builders' carpentry and joinery"
  },
  {
      "id": "16.23Z",
      "label": "Manufacture of other builders' carpentry and joinery"
  },
  {
      "id": "16.24",
      "label": "Manufacture of wooden containers"
  },
  {
      "id": "16.24Z",
      "label": "Manufacture of wooden containers"
  },
  {
      "id": "16.29",
      "label": "Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials"
  },
  {
      "id": "16.29Z",
      "label": "Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials"
  },
  {
      "id": "17",
      "label": "Manufacture of paper and paper products"
  },
  {
      "id": "17.1",
      "label": "Manufacture of pulp, paper and paperboard"
  },
  {
      "id": "17.11",
      "label": "Manufacture of pulp"
  },
  {
      "id": "17.11Z",
      "label": "Manufacture of pulp"
  },
  {
      "id": "17.12",
      "label": "Manufacture of paper and paperboard"
  },
  {
      "id": "17.12Z",
      "label": "Manufacture of paper and paperboard"
  },
  {
      "id": "17.2",
      "label": "Manufacture of articles of paper and paperboard"
  },
  {
      "id": "17.21",
      "label": "Manufacture of corrugated paper and paperboard and of containers of paper and paperboard"
  },
  {
      "id": "17.21A",
      "label": "Manufacture of corrugated paperboard"
  },
  {
      "id": "17.21B",
      "label": "Manufacture of paperboard and containers of paperboard"
  },
  {
      "id": "17.21C",
      "label": "Manufacture of containers of paper"
  },
  {
      "id": "17.22",
      "label": "Manufacture of household and sanitary goods and of toilet requisites"
  },
  {
      "id": "17.22Z",
      "label": "Manufacture of household and sanitary goods and of toilet requisites"
  },
  {
      "id": "17.23",
      "label": "Manufacture of paper stationery"
  },
  {
      "id": "17.23Z",
      "label": "Manufacture of paper stationery"
  },
  {
      "id": "17.24",
      "label": "Manufacture of wallpaper"
  },
  {
      "id": "17.24Z",
      "label": "Manufacture of wallpaper"
  },
  {
      "id": "17.29",
      "label": "Manufacture of other articles of paper and paperboard"
  },
  {
      "id": "17.29Z",
      "label": "Manufacture of other articles of paper and paperboard"
  },
  {
      "id": "18",
      "label": "Printing and reproduction of recorded media"
  },
  {
      "id": "18.1",
      "label": "Printing and service activities related to printing"
  },
  {
      "id": "18.11",
      "label": "Printing of newspapers"
  },
  {
      "id": "18.11Z",
      "label": "Printing of newspapers"
  },
  {
      "id": "18.12",
      "label": "Other printing"
  },
  {
      "id": "18.12Z",
      "label": "Other printing"
  },
  {
      "id": "18.13",
      "label": "Pre-press and pre-media services"
  },
  {
      "id": "18.13Z",
      "label": "Pre-press and pre-media services"
  },
  {
      "id": "18.14",
      "label": "Binding and related services"
  },
  {
      "id": "18.14Z",
      "label": "Binding and related services"
  },
  {
      "id": "18.2",
      "label": "Reproduction of recorded media"
  },
  {
      "id": "18.20",
      "label": "Reproduction of recorded media"
  },
  {
      "id": "18.20Z",
      "label": "Reproduction of recorded media"
  },
  {
      "id": "19",
      "label": "Manufacture of coke and refined petroleum products"
  },
  {
      "id": "19.1",
      "label": "Manufacture of coke oven products"
  },
  {
      "id": "19.10",
      "label": "Manufacture of coke oven products"
  },
  {
      "id": "19.10Z",
      "label": "Manufacture of coke oven products"
  },
  {
      "id": "19.2",
      "label": "Manufacture of refined petroleum products"
  },
  {
      "id": "19.20",
      "label": "Manufacture of refined petroleum products"
  },
  {
      "id": "19.20Z",
      "label": "Manufacture of refined petroleum products"
  },
  {
      "id": "20",
      "label": "Manufacture of chemicals and chemical products"
  },
  {
      "id": "20.1",
      "label": "Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms"
  },
  {
      "id": "20.11",
      "label": "Manufacture of industrial gases"
  },
  {
      "id": "20.11Z",
      "label": "Manufacture of industrial gases"
  },
  {
      "id": "20.12",
      "label": "Manufacture of dyes and pigments"
  },
  {
      "id": "20.12Z",
      "label": "Manufacture of dyes and pigments"
  },
  {
      "id": "20.13",
      "label": "Manufacture of other inorganic basic chemicals"
  },
  {
      "id": "20.13A",
      "label": "Enrichment and reprocessing of nuclear fuel"
  },
  {
      "id": "20.13B",
      "label": "Manufacture of other inorganic basic chemicals n.e.c."
  },
  {
      "id": "20.14",
      "label": "Manufacture of other organic basic chemicals"
  },
  {
      "id": "20.14Z",
      "label": "Manufacture of other organic basic chemicals"
  },
  {
      "id": "20.15",
      "label": "Manufacture of fertilisers and nitrogen compounds"
  },
  {
      "id": "20.15Z",
      "label": "Manufacture of fertilisers and nitrogen compounds"
  },
  {
      "id": "20.16",
      "label": "Manufacture of plastics in primary forms"
  },
  {
      "id": "20.16Z",
      "label": "Manufacture of plastics in primary forms"
  },
  {
      "id": "20.17",
      "label": "Manufacture of synthetic rubber in primary forms"
  },
  {
      "id": "20.17Z",
      "label": "Manufacture of synthetic rubber in primary forms"
  },
  {
      "id": "20.2",
      "label": "Manufacture of pesticides and other agrochemical products"
  },
  {
      "id": "20.20",
      "label": "Manufacture of pesticides and other agrochemical products"
  },
  {
      "id": "20.20Z",
      "label": "Manufacture of pesticides and other agrochemical products"
  },
  {
      "id": "20.3",
      "label": "Manufacture of paints, varnishes and similar coatings, printing ink and mastics"
  },
  {
      "id": "20.30",
      "label": "Manufacture of paints, varnishes and similar coatings, printing ink and mastics"
  },
  {
      "id": "20.30Z",
      "label": "Manufacture of paints, varnishes and similar coatings, printing ink and mastics"
  },
  {
      "id": "20.4",
      "label": "Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations"
  },
  {
      "id": "20.41",
      "label": "Manufacture of soap and detergents, cleaning and polishing preparations"
  },
  {
      "id": "20.41Z",
      "label": "Manufacture of soap and detergents, cleaning and polishing preparations"
  },
  {
      "id": "20.42",
      "label": "Manufacture of perfumes and toilet preparations"
  },
  {
      "id": "20.42Z",
      "label": "Manufacture of perfumes and toilet preparations"
  },
  {
      "id": "20.5",
      "label": "Manufacture of other chemical products"
  },
  {
      "id": "20.51",
      "label": "Manufacture of explosives"
  },
  {
      "id": "20.51Z",
      "label": "Manufacture of explosives"
  },
  {
      "id": "20.52",
      "label": "Manufacture of glues"
  },
  {
      "id": "20.52Z",
      "label": "Manufacture of glues"
  },
  {
      "id": "20.53",
      "label": "Manufacture of essential oils"
  },
  {
      "id": "20.53Z",
      "label": "Manufacture of essential oils"
  },
  {
      "id": "20.59",
      "label": "Manufacture of other chemical products n.e.c."
  },
  {
      "id": "20.59Z",
      "label": "Manufacture of other chemical products n.e.c."
  },
  {
      "id": "20.6",
      "label": "Manufacture of man-made fibres"
  },
  {
      "id": "20.60",
      "label": "Manufacture of man-made fibres"
  },
  {
      "id": "20.60Z",
      "label": "Manufacture of man-made fibres"
  },
  {
      "id": "21",
      "label": "Manufacture of basic pharmaceutical products and pharmaceutical preparations"
  },
  {
      "id": "21.1",
      "label": "Manufacture of basic pharmaceutical products"
  },
  {
      "id": "21.10",
      "label": "Manufacture of basic pharmaceutical products"
  },
  {
      "id": "21.10Z",
      "label": "Manufacture of basic pharmaceutical products"
  },
  {
      "id": "21.2",
      "label": "Manufacture of pharmaceutical preparations"
  },
  {
      "id": "21.20",
      "label": "Manufacture of pharmaceutical preparations"
  },
  {
      "id": "21.20Z",
      "label": "Manufacture of pharmaceutical preparations"
  },
  {
      "id": "22",
      "label": "Manufacture of rubber and plastic products"
  },
  {
      "id": "22.1",
      "label": "Manufacture of rubber products"
  },
  {
      "id": "22.11",
      "label": "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres"
  },
  {
      "id": "22.11Z",
      "label": "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres"
  },
  {
      "id": "22.19",
      "label": "Manufacture of other rubber products"
  },
  {
      "id": "22.19Z",
      "label": "Manufacture of other rubber products"
  },
  {
      "id": "22.2",
      "label": "Manufacture of plastics products"
  },
  {
      "id": "22.21",
      "label": "Manufacture of plastic plates, sheets, tubes and profiles"
  },
  {
      "id": "22.21Z",
      "label": "Manufacture of plastic plates, sheets, tubes and profiles"
  },
  {
      "id": "22.22",
      "label": "Manufacture of plastic packing goods"
  },
  {
      "id": "22.22Z",
      "label": "Manufacture of plastic packing goods"
  },
  {
      "id": "22.23",
      "label": "Manufacture of builders' ware of plastic"
  },
  {
      "id": "22.23Z",
      "label": "Manufacture of builders' ware of plastic"
  },
  {
      "id": "22.29",
      "label": "Manufacture of other plastic products"
  },
  {
      "id": "22.29A",
      "label": "Manufacture of plastic-based technical parts"
  },
  {
      "id": "22.29B",
      "label": "Manufacture of common consumer goods of plastic products"
  },
  {
      "id": "23",
      "label": "Manufacture of other non-metallic mineral products"
  },
  {
      "id": "23.1",
      "label": "Manufacture of glass and glass products"
  },
  {
      "id": "23.11",
      "label": "Manufacture of flat glass"
  },
  {
      "id": "23.11Z",
      "label": "Manufacture of flat glass"
  },
  {
      "id": "23.12",
      "label": "Shaping and processing of flat glass"
  },
  {
      "id": "23.12Z",
      "label": "Shaping and processing of flat glass"
  },
  {
      "id": "23.13",
      "label": "Manufacture of hollow glass"
  },
  {
      "id": "23.13Z",
      "label": "Manufacture of hollow glass"
  },
  {
      "id": "23.14",
      "label": "Manufacture of glass fibres"
  },
  {
      "id": "23.14Z",
      "label": "Manufacture of glass fibres"
  },
  {
      "id": "23.19",
      "label": "Manufacture and processing of other glass, including technical glassware"
  },
  {
      "id": "23.19Z",
      "label": "Manufacture and processing of other glass, including technical glassware"
  },
  {
      "id": "23.2",
      "label": "Manufacture of refractory products"
  },
  {
      "id": "23.20",
      "label": "Manufacture of refractory products"
  },
  {
      "id": "23.20Z",
      "label": "Manufacture of refractory products"
  },
  {
      "id": "23.3",
      "label": "Manufacture of clay building materials"
  },
  {
      "id": "23.31",
      "label": "Manufacture of ceramic tiles and flags"
  },
  {
      "id": "23.31Z",
      "label": "Manufacture of ceramic tiles and flags"
  },
  {
      "id": "23.32",
      "label": "Manufacture of bricks, tiles and construction products, in baked clay"
  },
  {
      "id": "23.32Z",
      "label": "Manufacture of bricks, tiles and construction products, in baked clay"
  },
  {
      "id": "23.4",
      "label": "Manufacture of other porcelain and ceramic products"
  },
  {
      "id": "23.41",
      "label": "Manufacture of ceramic household and ornamental articles"
  },
  {
      "id": "23.41Z",
      "label": "Manufacture of ceramic household and ornamental articles"
  },
  {
      "id": "23.42",
      "label": "Manufacture of ceramic sanitary fixtures"
  },
  {
      "id": "23.42Z",
      "label": "Manufacture of ceramic sanitary fixtures"
  },
  {
      "id": "23.43",
      "label": "Manufacture of ceramic insulators and insulating fittings"
  },
  {
      "id": "23.43Z",
      "label": "Manufacture of ceramic insulators and insulating fittings"
  },
  {
      "id": "23.44",
      "label": "Manufacture of other technical ceramic products"
  },
  {
      "id": "23.44Z",
      "label": "Manufacture of other technical ceramic products"
  },
  {
      "id": "23.49",
      "label": "Manufacture of other ceramic products"
  },
  {
      "id": "23.49Z",
      "label": "Manufacture of other ceramic products"
  },
  {
      "id": "23.5",
      "label": "Manufacture of cement, lime and plaster"
  },
  {
      "id": "23.51",
      "label": "Manufacture of cement"
  },
  {
      "id": "23.51Z",
      "label": "Manufacture of cement"
  },
  {
      "id": "23.52",
      "label": "Manufacture of lime and plaster"
  },
  {
      "id": "23.52Z",
      "label": "Manufacture of lime and plaster"
  },
  {
      "id": "23.6",
      "label": "Manufacture of articles of concrete, cement and plaster"
  },
  {
      "id": "23.61",
      "label": "Manufacture of concrete products for construction purposes"
  },
  {
      "id": "23.61Z",
      "label": "Manufacture of concrete products for construction purposes"
  },
  {
      "id": "23.62",
      "label": "Manufacture of plaster products for construction purposes"
  },
  {
      "id": "23.62Z",
      "label": "Manufacture of plaster products for construction purposes"
  },
  {
      "id": "23.63",
      "label": "Manufacture of ready-mixed concrete"
  },
  {
      "id": "23.63Z",
      "label": "Manufacture of ready-mixed concrete"
  },
  {
      "id": "23.64",
      "label": "Manufacture of mortars"
  },
  {
      "id": "23.64Z",
      "label": "Manufacture of mortars"
  },
  {
      "id": "23.65",
      "label": "Manufacture of fibre cement"
  },
  {
      "id": "23.65Z",
      "label": "Manufacture of fibre cement"
  },
  {
      "id": "23.69",
      "label": "Manufacture of other articles of concrete, plaster and cement"
  },
  {
      "id": "23.69Z",
      "label": "Manufacture of other articles of concrete, plaster and cement"
  },
  {
      "id": "23.7",
      "label": "Cutting, shaping and finishing of stone"
  },
  {
      "id": "23.70",
      "label": "Cutting, shaping and finishing of stone"
  },
  {
      "id": "23.70Z",
      "label": "Cutting, shaping and finishing of stone"
  },
  {
      "id": "23.9",
      "label": "Manufacture of abrasive products and non-metallic mineral products n.e.c."
  },
  {
      "id": "23.91",
      "label": "Production of abrasive products"
  },
  {
      "id": "23.91Z",
      "label": "Production of abrasive products"
  },
  {
      "id": "23.99",
      "label": "Manufacture of other non-metallic mineral products n.e.c."
  },
  {
      "id": "23.99Z",
      "label": "Manufacture of other non-metallic mineral products n.e.c."
  },
  {
      "id": "24",
      "label": "Manufacture of basic metals"
  },
  {
      "id": "24.1",
      "label": "Manufacture of basic iron and steel and of ferro-alloys"
  },
  {
      "id": "24.10",
      "label": "Manufacture of basic iron and steel and of ferro-alloys"
  },
  {
      "id": "24.10Z",
      "label": "Manufacture of basic iron and steel and of ferro-alloys"
  },
  {
      "id": "24.2",
      "label": "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel"
  },
  {
      "id": "24.20",
      "label": "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel"
  },
  {
      "id": "24.20Z",
      "label": "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel"
  },
  {
      "id": "24.3",
      "label": "Manufacture of other products of first processing of steel"
  },
  {
      "id": "24.31",
      "label": "Cold drawing of bars"
  },
  {
      "id": "24.31Z",
      "label": "Cold drawing of bars"
  },
  {
      "id": "24.32",
      "label": "Cold rolling of narrow strip"
  },
  {
      "id": "24.32Z",
      "label": "Cold rolling of narrow strip"
  },
  {
      "id": "24.33",
      "label": "Cold forming or folding"
  },
  {
      "id": "24.33Z",
      "label": "Cold forming or folding"
  },
  {
      "id": "24.34",
      "label": "Cold drawing of wire"
  },
  {
      "id": "24.34Z",
      "label": "Cold drawing of wire"
  },
  {
      "id": "24.4",
      "label": "Manufacture of basic precious and other non-ferrous metals"
  },
  {
      "id": "24.41",
      "label": "Precious metals production"
  },
  {
      "id": "24.41Z",
      "label": "Precious metals production"
  },
  {
      "id": "24.42",
      "label": "Aluminium production"
  },
  {
      "id": "24.42Z",
      "label": "Aluminium production"
  },
  {
      "id": "24.43",
      "label": "Lead, zinc and tin production"
  },
  {
      "id": "24.43Z",
      "label": "Lead, zinc and tin production"
  },
  {
      "id": "24.44",
      "label": "Copper production"
  },
  {
      "id": "24.44Z",
      "label": "Copper production"
  },
  {
      "id": "24.45",
      "label": "Other non-ferrous metal production"
  },
  {
      "id": "24.45Z",
      "label": "Other non-ferrous metal production"
  },
  {
      "id": "24.46",
      "label": "Processing of nuclear fuel"
  },
  {
      "id": "24.46Z",
      "label": "Processing of nuclear fuel"
  },
  {
      "id": "24.5",
      "label": "Casting of metals"
  },
  {
      "id": "24.51",
      "label": "Casting of iron"
  },
  {
      "id": "24.51Z",
      "label": "Casting of iron"
  },
  {
      "id": "24.52",
      "label": "Casting of steel"
  },
  {
      "id": "24.52Z",
      "label": "Casting of steel"
  },
  {
      "id": "24.53",
      "label": "Casting of light metals"
  },
  {
      "id": "24.53Z",
      "label": "Casting of light metals"
  },
  {
      "id": "24.54",
      "label": "Casting of other non-ferrous metals"
  },
  {
      "id": "24.54Z",
      "label": "Casting of other non-ferrous metals"
  },
  {
      "id": "25",
      "label": "Manufacture of fabricated metal products, except machinery and equipment"
  },
  {
      "id": "25.1",
      "label": "Manufacture of structural metal products"
  },
  {
      "id": "25.11",
      "label": "Manufacture of metal structures and parts of structures"
  },
  {
      "id": "font size=\"2\" face=\"Arial\">25.11Z",
      "label": "Manufacture of metal structures and parts of structures"
  },
  {
      "id": "25.12",
      "label": "Manufacture of doors and windows of metal"
  },
  {
      "id": "25.12Z",
      "label": "Manufacture of doors and windows of metal"
  },
  {
      "id": "25.2",
      "label": "Manufacture of tanks, reservoirs and containers of metal"
  },
  {
      "id": "25.21",
      "label": "Manufacture of central heating radiators and boilers"
  },
  {
      "id": "25.21Z",
      "label": "Manufacture of central heating radiators and boilers"
  },
  {
      "id": "25.29",
      "label": "Manufacture of other tanks, reservoirs and containers of metal"
  },
  {
      "id": "25.29Z",
      "label": "Manufacture of other tanks, reservoirs and containers of metal"
  },
  {
      "id": "25.3",
      "label": "Manufacture of steam generators, except central heating hot water boilers"
  },
  {
      "id": "25.30",
      "label": "Manufacture of steam generators, except central heating hot water boilers"
  },
  {
      "id": "25.30Z",
      "label": "Manufacture of steam generators, except central heating hot water boilers"
  },
  {
      "id": "25.4",
      "label": "Manufacture of weapons and ammunition"
  },
  {
      "id": "25.40",
      "label": "Manufacture of weapons and ammunition"
  },
  {
      "id": "25.40Z",
      "label": "Manufacture of weapons and ammunition"
  },
  {
      "id": "25.5",
      "label": "Forging, pressing, stamping and roll-forming of metal; powder metallurgy"
  },
  {
      "id": "25.50",
      "label": "Forging, pressing, stamping and roll-forming of metal; powder metallurgy"
  },
  {
      "id": "25.50A",
      "label": "Forging, pressing, stamping; powder metallurgy"
  },
  {
      "id": "25.50B",
      "label": "Cutting out, pressing"
  },
  {
      "id": "25.6",
      "label": "Treatment and coating of metals; machining"
  },
  {
      "id": "25.61",
      "label": "Treatment and coating of metals"
  },
  {
      "id": "25.61Z",
      "label": "Treatment and coating of metals"
  },
  {
      "id": "25.62",
      "label": "Machining"
  },
  {
      "id": "25.62A",
      "label": "Cutting-off"
  },
  {
      "id": "25.62B",
      "label": "Industrial mechanical engineering"
  },
  {
      "id": "25.7",
      "label": "Manufacture of cutlery, tools and general hardware"
  },
  {
      "id": "25.71",
      "label": "Manufacture of cutlery"
  },
  {
      "id": "25.71Z",
      "label": "Manufacture of cutlery"
  },
  {
      "id": "25.72",
      "label": "Manufacture of locks and hinges"
  },
  {
      "id": "25.72Z",
      "label": "Manufacture of locks and hinges"
  },
  {
      "id": "25.73",
      "label": "Manufacture of tools"
  },
  {
      "id": "25.73A",
      "label": "Manufacture of moulds and models"
  },
  {
      "id": "25.73B",
      "label": "Manufacture of other tools"
  },
  {
      "id": "25.9",
      "label": "Manufacture of other fabricated metal products"
  },
  {
      "id": "25.91",
      "label": "Manufacture of steel drums and similar containers"
  },
  {
      "id": "25.91Z",
      "label": "Manufacture of steel drums and similar containers"
  },
  {
      "id": "25.92",
      "label": "Manufacture of light metal packaging"
  },
  {
      "id": "25.92Z",
      "label": "Manufacture of light metal packaging"
  },
  {
      "id": "25.93",
      "label": "Manufacture of wire products, chain and springs"
  },
  {
      "id": "25.93Z",
      "label": "Manufacture of wire products, chain and springs"
  },
  {
      "id": "25.94",
      "label": "Manufacture of fasteners and screw machine products"
  },
  {
      "id": "25.94Z",
      "label": "Manufacture of fasteners and screw machine products"
  },
  {
      "id": "25.99",
      "label": "Manufacture of other fabricated metal products n.e.c."
  },
  {
      "id": "25.99A",
      "label": "Manufacture of household fabricated metal articles"
  },
  {
      "id": "25.99B",
      "label": "Manufacture of other fabricated metal articles"
  },
  {
      "id": "26",
      "label": "Manufacture of computer, electronic and optical products"
  },
  {
      "id": "26.1",
      "label": "Manufacture of electronic components and boards"
  },
  {
      "id": "26.11",
      "label": "Manufacture of electronic components"
  },
  {
      "id": "26.11Z",
      "label": "Manufacture of electronic components"
  },
  {
      "id": "26.12",
      "label": "Manufacture of loaded electronic boards"
  },
  {
      "id": "26.12Z",
      "label": "Manufacture of loaded electronic boards"
  },
  {
      "id": "26.2",
      "label": "Manufacture of computers and peripheral equipment"
  },
  {
      "id": "26.20",
      "label": "Manufacture of computers and peripheral equipment"
  },
  {
      "id": "26.20Z",
      "label": "Manufacture of computers and peripheral equipment"
  },
  {
      "id": "26.3",
      "label": "Manufacture of communication equipment"
  },
  {
      "id": "26.30",
      "label": "Manufacture of communication equipment"
  },
  {
      "id": "26.30Z",
      "label": "Manufacture of communication equipment"
  },
  {
      "id": "26.4",
      "label": "Manufacture of consumer electronics"
  },
  {
      "id": "26.40",
      "label": "Manufacture of consumer electronics"
  },
  {
      "id": "26.40Z",
      "label": "Manufacture of consumer electronics"
  },
  {
      "id": "26.5",
      "label": "Manufacture of instruments and appliances for measuring, testing and navigation; watches and clocks"
  },
  {
      "id": "26.51",
      "label": "Manufacture of instruments and appliances for measuring, testing and navigation"
  },
  {
      "id": "26.51A",
      "label": "Manufacture of aid to navigation equipment"
  },
  {
      "id": "26.51B",
      "label": "Manufacture of scientific and technical instruments"
  },
  {
      "id": "26.52",
      "label": "Manufacture of watches and clocks"
  },
  {
      "id": "26.52Z",
      "label": "Manufacture of watches and clocks"
  },
  {
      "id": "26.6",
      "label": "Manufacture of irradiation, electromedical and electrotherapeutic equipment"
  },
  {
      "id": "26.60",
      "label": "Manufacture of irradiation, electromedical and electrotherapeutic equipment"
  },
  {
      "id": "26.60Z",
      "label": "Manufacture of irradiation, electromedical and electrotherapeutic equipment"
  },
  {
      "id": "26.7",
      "label": "Manufacture of optical instruments and photographic equipment"
  },
  {
      "id": "26.70",
      "label": "Manufacture of optical instruments and photographic equipment"
  },
  {
      "id": "26.70Z",
      "label": "Manufacture of optical instruments and photographic equipment"
  },
  {
      "id": "26.8",
      "label": "Manufacture of magnetic and optical media"
  },
  {
      "id": "26.80",
      "label": "Manufacture of magnetic and optical media"
  },
  {
      "id": "26.80Z",
      "label": "Manufacture of magnetic and optical media"
  },
  {
      "id": "27",
      "label": "Manufacture of electrical equipment"
  },
  {
      "id": "27.1",
      "label": "Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus"
  },
  {
      "id": "27.11",
      "label": "Manufacture of electric motors, generators and transformers"
  },
  {
      "id": "27.11Z",
      "label": "Manufacture of electric motors, generators and transformers"
  },
  {
      "id": "27.12",
      "label": "Manufacture of electricity distribution and control apparatus"
  },
  {
      "id": "27.12Z",
      "label": "Manufacture of electricity distribution and control apparatus"
  },
  {
      "id": "27.2",
      "label": "Manufacture of batteries and accumulators"
  },
  {
      "id": "27.20",
      "label": "Manufacture of batteries and accumulators"
  },
  {
      "id": "27.20Z",
      "label": "Manufacture of batteries and accumulators"
  },
  {
      "id": "27.3",
      "label": "Manufacture of wiring and wiring devices"
  },
  {
      "id": "27.31",
      "label": "Manufacture of fibre optic cables"
  },
  {
      "id": "27.31Z",
      "label": "Manufacture of fibre optic cables"
  },
  {
      "id": "27.32",
      "label": "Manufacture of other electronic and electric wires and cables"
  },
  {
      "id": "27.32Z",
      "label": "Manufacture of other electronic and electric wires and cables"
  },
  {
      "id": "27.33",
      "label": "Manufacture of wiring devices"
  },
  {
      "id": "27.33Z",
      "label": "Manufacture of wiring devices"
  },
  {
      "id": "27.4",
      "label": "Manufacture of electric lighting equipment"
  },
  {
      "id": "27.40",
      "label": "Manufacture of electric lighting equipment"
  },
  {
      "id": "27.40Z",
      "label": "Manufacture of electric lighting equipment"
  },
  {
      "id": "27.5",
      "label": "Manufacture of domestic appliances"
  },
  {
      "id": "27.51",
      "label": "Manufacture of electric domestic appliances"
  },
  {
      "id": "27.51Z",
      "label": "Manufacture of electric domestic appliances"
  },
  {
      "id": "27.52",
      "label": "Manufacture of non-electric domestic appliances"
  },
  {
      "id": "27.52Z",
      "label": "Manufacture of non-electric domestic appliances"
  },
  {
      "id": "27.9",
      "label": "Manufacture of other electrical equipment"
  },
  {
      "id": "27.90",
      "label": "Manufacture of other electrical equipment"
  },
  {
      "id": "27.90Z",
      "label": "Manufacture of other electrical equipment"
  },
  {
      "id": "28",
      "label": "Manufacture of machinery and equipment n.e.c."
  },
  {
      "id": "28.1",
      "label": "Manufacture of general-purpose machinery"
  },
  {
      "id": "28.11",
      "label": "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines"
  },
  {
      "id": "28.11Z",
      "label": "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines"
  },
  {
      "id": "28.12",
      "label": "Manufacture of fluid power equipment"
  },
  {
      "id": "28.12Z",
      "label": "Manufacture of fluid power equipment"
  },
  {
      "id": "28.13",
      "label": "Manufacture of other pumps and compressors"
  },
  {
      "id": "28.13Z",
      "label": "Manufacture of other pumps and compressors"
  },
  {
      "id": "28.14",
      "label": "Manufacture of other taps and valves"
  },
  {
      "id": "28.14Z",
      "label": "Manufacture of other taps and valves"
  },
  {
      "id": "28.15",
      "label": "Manufacture of bearings, gears, gearing and driving elements"
  },
  {
      "id": "28.15Z",
      "label": "Manufacture of bearings, gears, gearing and driving elements"
  },
  {
      "id": "28.2",
      "label": "Manufacture of other general-purpose machinery"
  },
  {
      "id": "28.21",
      "label": "Manufacture of ovens, furnaces and furnace burners"
  },
  {
      "id": "28.21Z",
      "label": "Manufacture of ovens, furnaces and furnace burners"
  },
  {
      "id": "28.22",
      "label": "Manufacture of lifting and handling equipment"
  },
  {
      "id": "28.22Z",
      "label": "Manufacture of lifting and handling equipment"
  },
  {
      "id": "28.23",
      "label": "Manufacture of office machinery and equipment (except computers and peripheral equipment)"
  },
  {
      "id": "28.23Z",
      "label": "Manufacture of office machinery and equipment (except computers and peripheral equipment)"
  },
  {
      "id": "28.24",
      "label": "Manufacture of power-driven hand tools"
  },
  {
      "id": "28.24Z",
      "label": "Manufacture of power-driven hand tools"
  },
  {
      "id": "28.25",
      "label": "Manufacture of non-domestic cooling and ventilation equipment"
  },
  {
      "id": "28.25Z",
      "label": "Manufacture of non-domestic cooling and ventilation equipment"
  },
  {
      "id": "28.29",
      "label": "Manufacture of other general-purpose machinery n.e.c."
  },
  {
      "id": "28.29A",
      "label": "Manufacture of packing, packaging and weighing equipment"
  },
  {
      "id": "28.29B",
      "label": "Manufacture of other general purpose machinery"
  },
  {
      "id": "28.3",
      "label": "Manufacture of agricultural and forestry machinery"
  },
  {
      "id": "28.30",
      "label": "Manufacture of agricultural and forestry machinery"
  },
  {
      "id": "28.30Z",
      "label": "Manufacture of agricultural and forestry machinery"
  },
  {
      "id": "28.4",
      "label": "Manufacture of metal forming machinery and machine tools"
  },
  {
      "id": "28.41",
      "label": "Manufacture of metal forming machinery"
  },
  {
      "id": "28.41Z",
      "label": "Manufacture of machine-tools for metal work"
  },
  {
      "id": "28.49",
      "label": "Manufacture of other machine tools"
  },
  {
      "id": "28.49Z",
      "label": "Manufacture of other machine tools"
  },
  {
      "id": "28.9",
      "label": "Manufacture of other special-purpose machinery"
  },
  {
      "id": "28.91",
      "label": "Manufacture of machinery for metallurgy"
  },
  {
      "id": "28.91Z",
      "label": "Manufacture of machinery for metallurgy"
  },
  {
      "id": "28.92",
      "label": "Manufacture of machinery for mining, quarrying and construction"
  },
  {
      "id": "28.92Z",
      "label": "Manufacture of machinery for mining, quarrying and construction"
  },
  {
      "id": "28.93",
      "label": "Manufacture of machinery for food, beverage and tobacco processing"
  },
  {
      "id": "28.93Z",
      "label": "Manufacture of machinery for food, beverage and tobacco processing"
  },
  {
      "id": "28.94",
      "label": "Manufacture of machinery for textile, apparel and leather production"
  },
  {
      "id": "28.94Z",
      "label": "Manufacture of machinery for textile, apparel and leather production"
  },
  {
      "id": "28.95",
      "label": "Manufacture of machinery for paper and paperboard production"
  },
  {
      "id": "28.95Z",
      "label": "Manufacture of machinery for paper and paperboard production"
  },
  {
      "id": "28.96",
      "label": "Manufacture of plastics and rubber machinery"
  },
  {
      "id": "28.96Z",
      "label": "Manufacture of plastics and rubber machinery"
  },
  {
      "id": "28.99",
      "label": "Manufacture of other special-purpose machinery n.e.c."
  },
  {
      "id": "28.99A",
      "label": "Manufacture of printing machinery"
  },
  {
      "id": "28.99B",
      "label": "Manufacture of other special purpose machinery"
  },
  {
      "id": "29",
      "label": "Manufacture of motor vehicles, trailers and semi-trailers"
  },
  {
      "id": "29.1",
      "label": "Manufacture of motor vehicles"
  },
  {
      "id": "29.10",
      "label": "Manufacture of motor vehicles"
  },
  {
      "id": "29.10Z",
      "label": "Manufacture of motor vehicles"
  },
  {
      "id": "29.2",
      "label": "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers"
  },
  {
      "id": "29.20",
      "label": "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers"
  },
  {
      "id": "29.20Z",
      "label": "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers"
  },
  {
      "id": "29.3",
      "label": "Manufacture of parts and accessories for motor vehicles"
  },
  {
      "id": "29.31",
      "label": "Manufacture of electrical and electronic equipment for motor vehicles"
  },
  {
      "id": "29.31Z",
      "label": "Manufacture of electrical and electronic equipment for motor vehicles"
  },
  {
      "id": "29.32",
      "label": "Manufacture of other parts and accessories for motor vehicles"
  },
  {
      "id": "29.32Z",
      "label": "Manufacture of other parts and accessories for motor vehicles"
  },
  {
      "id": "30",
      "label": "Manufacture of other transport equipment"
  },
  {
      "id": "30.1",
      "label": "Building of ships and boats"
  },
  {
      "id": "30.11",
      "label": "Building of ships and floating structures"
  },
  {
      "id": "30.11Z",
      "label": "Building of ships and floating structures"
  },
  {
      "id": "30.12",
      "label": "Building of pleasure and sporting boats"
  },
  {
      "id": "30.12Z",
      "label": "Building of pleasure and sporting boats"
  },
  {
      "id": "30.2",
      "label": "Manufacture of railway locomotives and rolling stock"
  },
  {
      "id": "30.20",
      "label": "Manufacture of railway locomotives and rolling stock"
  },
  {
      "id": "30.20Z",
      "label": "Manufacture of railway locomotives and rolling stock"
  },
  {
      "id": "30.3",
      "label": "Manufacture of air and spacecraft and related machinery"
  },
  {
      "id": "30.30",
      "label": "Manufacture of air and spacecraft and related machinery"
  },
  {
      "id": "30.30Z",
      "label": "Manufacture of air and spacecraft and related machinery"
  },
  {
      "id": "30.4",
      "label": "Manufacture of military fighting vehicles"
  },
  {
      "id": "30.40",
      "label": "Manufacture of military fighting vehicles"
  },
  {
      "id": "30.40Z",
      "label": "Manufacture of military fighting vehicles"
  },
  {
      "id": "30.9",
      "label": "Manufacture of transport equipment n.e.c."
  },
  {
      "id": "30.91",
      "label": "Manufacture of motorcycles"
  },
  {
      "id": "30.91Z",
      "label": "Manufacture of motorcycles"
  },
  {
      "id": "30.92",
      "label": "Manufacture of bicycles and invalid carriages"
  },
  {
      "id": "30.92Z",
      "label": "Manufacture of bicycles and invalid carriages"
  },
  {
      "id": "30.99",
      "label": "Manufacture of other transport equipment n.e.c."
  },
  {
      "id": "30.99Z",
      "label": "Manufacture of other transport equipment n.e.c."
  },
  {
      "id": "31",
      "label": "Manufacture of furniture"
  },
  {
      "id": "31.0",
      "label": "Manufacture of furniture"
  },
  {
      "id": "31.01",
      "label": "Manufacture of office and shop furniture"
  },
  {
      "id": "31.01Z",
      "label": "Manufacture of office and shop furniture"
  },
  {
      "id": "31.02",
      "label": "Manufacture of kitchen furniture"
  },
  {
      "id": "31.02Z",
      "label": "Manufacture of kitchen furniture"
  },
  {
      "id": "31.03",
      "label": "Manufacture of mattresses"
  },
  {
      "id": "31.03Z",
      "label": "Manufacture of mattresses"
  },
  {
      "id": "31.09",
      "label": "Manufacture of other furniture"
  },
  {
      "id": "31.09A",
      "label": "Manufacture of home furnishing chairs and seats"
  },
  {
      "id": "31.09B",
      "label": "Manufacture of other furniture and industry closely related to furnishing"
  },
  {
      "id": "32",
      "label": "Other manufacturing"
  },
  {
      "id": "32.1",
      "label": "Manufacture of jewellery, bijouterie and related articles"
  },
  {
      "id": "32.11",
      "label": "Striking of coins"
  },
  {
      "id": "32.11Z",
      "label": "Striking of coins"
  },
  {
      "id": "32.12",
      "label": "Manufacture of jewellery and related articles"
  },
  {
      "id": "32.12Z",
      "label": "Manufacture of jewellery and related articles"
  },
  {
      "id": "32.13",
      "label": "Manufacture of imitation jewellery and related articles"
  },
  {
      "id": "32.13Z",
      "label": "Manufacture of imitation jewellery and related articles"
  },
  {
      "id": "32.2",
      "label": "Manufacture of musical instruments"
  },
  {
      "id": "32.20",
      "label": "Manufacture of musical instruments"
  },
  {
      "id": "32.20Z",
      "label": "Manufacture of musical instruments"
  },
  {
      "id": "32.3",
      "label": "Manufacture of sports goods"
  },
  {
      "id": "32.30",
      "label": "Manufacture of sports goods"
  },
  {
      "id": "32.30Z",
      "label": "Manufacture of sports goods"
  },
  {
      "id": "32.4",
      "label": "Manufacture of games and toys"
  },
  {
      "id": "32.40",
      "label": "Manufacture of games and toys"
  },
  {
      "id": "32.40Z",
      "label": "Manufacture of games and toys"
  },
  {
      "id": "32.5",
      "label": "Manufacture of medical and dental instruments and supplies"
  },
  {
      "id": "32.50",
      "label": "Manufacture of medical and dental instruments and supplies"
  },
  {
      "id": "32.50A",
      "label": "Manufacture of medical, surgical and dental equipment"
  },
  {
      "id": "32.50B",
      "label": "Manufacture of glasses"
  },
  {
      "id": "32.9",
      "label": "Manufacturing n.e.c."
  },
  {
      "id": "32.91",
      "label": "Manufacture of brooms and brushes"
  },
  {
      "id": "32.91Z",
      "label": "Manufacture of brooms and brushes"
  },
  {
      "id": "32.99",
      "label": "Other manufacturing n.e.c."
  },
  {
      "id": "32.99Z",
      "label": "Other manufacturing n.e.c."
  },
  {
      "id": "33",
      "label": "Repair and installation of machinery and equipment"
  },
  {
      "id": "33.1",
      "label": "Repair of fabricated metal products, machinery and equipment"
  },
  {
      "id": "33.11",
      "label": "Repair of fabricated metal products"
  },
  {
      "id": "33.11Z",
      "label": "Repair of fabricated metal products"
  },
  {
      "id": "33.12",
      "label": "Repair of machinery"
  },
  {
      "id": "33.12Z",
      "label": "Repair of machinery"
  },
  {
      "id": "33.13",
      "label": "Repair of electronic and optical equipment"
  },
  {
      "id": "33.13Z",
      "label": "Repair of electronic and optical equipment"
  },
  {
      "id": "33.14",
      "label": "Repair of electrical equipment"
  },
  {
      "id": "33.14Z",
      "label": "Repair of electrical equipment"
  },
  {
      "id": "33.15",
      "label": "Repair and maintenance of ships and boats"
  },
  {
      "id": "33.15Z",
      "label": "Repair and maintenance of ships and boats"
  },
  {
      "id": "33.16",
      "label": "Repair and maintenance of aircraft and spacecraft"
  },
  {
      "id": "33.16Z",
      "label": "Repair and maintenance of aircraft and spacecraft"
  },
  {
      "id": "33.17",
      "label": "Repair and maintenance of other transport equipment"
  },
  {
      "id": "33.17Z",
      "label": "Repair and maintenance of other transport equipment"
  },
  {
      "id": "33.19",
      "label": "Repair of other equipment"
  },
  {
      "id": "33.19Z",
      "label": "Repair of other equipment"
  },
  {
      "id": "33.2",
      "label": "Installation of industrial machinery and equipment"
  },
  {
      "id": "33.20",
      "label": "Installation of industrial machinery and equipment"
  },
  {
      "id": "33.20A",
      "label": "Installation of metallic, boiler structures and pipes"
  },
  {
      "id": "33.20B",
      "label": "Installation of machinery and mechanical equipment"
  },
  {
      "id": "33.20C",
      "label": "Overall conception and assembly of industrial process control equipment in industrial plant"
  },
  {
      "id": "33.20D",
      "label": "Installation of electrical equipment, electronic and optical equipment or other equipment"
  },
  {
      "id": "35",
      "label": "Electricity, gas, steam and air conditioning supply"
  },
  {
      "id": "35.1",
      "label": "Electric power generation, transmission and distribution"
  },
  {
      "id": "35.11",
      "label": "Production of electricity"
  },
  {
      "id": "35.11Z",
      "label": "Production of electricity"
  },
  {
      "id": "35.12",
      "label": "Transmission of electricity"
  },
  {
      "id": "35.12Z",
      "label": "Transmission of electricity"
  },
  {
      "id": "35.13",
      "label": "Distribution of electricity"
  },
  {
      "id": "35.13Z",
      "label": "Distribution of electricity"
  },
  {
      "id": "35.14",
      "label": "Trade of electricity"
  },
  {
      "id": "35.14Z",
      "label": "Trade of electricity"
  },
  {
      "id": "35.2",
      "label": "Manufacture of gas; distribution of gaseous fuels through mains"
  },
  {
      "id": "35.21",
      "label": "Manufacture of gas"
  },
  {
      "id": "35.21Z",
      "label": "Manufacture of gas"
  },
  {
      "id": "35.22",
      "label": "Distribution of gaseous fuels through mains"
  },
  {
      "id": "35.22Z",
      "label": "Distribution of gaseous fuels through mains"
  },
  {
      "id": "35.23",
      "label": "Trade of gas through mains"
  },
  {
      "id": "35.23Z",
      "label": "Trade of gas through mains"
  },
  {
      "id": "35.3",
      "label": "Steam and air conditioning supply"
  },
  {
      "id": "35.30",
      "label": "Steam and air conditioning supply"
  },
  {
      "id": "35.30Z",
      "label": "Steam and air conditioning supply"
  },
  {
      "id": "36",
      "label": "Water collection, treatment and supply"
  },
  {
      "id": "36.0",
      "label": "Water collection, treatment and supply"
  },
  {
      "id": "36.00",
      "label": "Water collection, treatment and supply"
  },
  {
      "id": "36.00Z",
      "label": "Water collection, treatment and supply"
  },
  {
      "id": "37",
      "label": "Sewerage"
  },
  {
      "id": "37.0",
      "label": "Sewerage"
  },
  {
      "id": "37.00",
      "label": "Sewerage"
  },
  {
      "id": "37.00Z",
      "label": "Sewerage"
  },
  {
      "id": "38",
      "label": "Waste collection, treatment and disposal activities; materials recovery"
  },
  {
      "id": "38.1",
      "label": "Waste collection"
  },
  {
      "id": "38.11",
      "label": "Collection of non-hazardous waste"
  },
  {
      "id": "38.11Z",
      "label": "Collection of non-hazardous waste"
  },
  {
      "id": "38.12",
      "label": "Collection of hazardous waste"
  },
  {
      "id": "38.12Z",
      "label": "Collection of hazardous waste"
  },
  {
      "id": "38.2",
      "label": "Waste treatment and disposal"
  },
  {
      "id": "38.21",
      "label": "Treatment and disposal of non-hazardous waste"
  },
  {
      "id": "38.21Z",
      "label": "Treatment and disposal of non-hazardous waste"
  },
  {
      "id": "38.22",
      "label": "Treatment and disposal of hazardous waste"
  },
  {
      "id": "38.22Z",
      "label": "Treatment and disposal of hazardous waste"
  },
  {
      "id": "38.3",
      "label": "Materials recovery"
  },
  {
      "id": "38.31",
      "label": "Dismantling of wrecks"
  },
  {
      "id": "38.31Z",
      "label": "Dismantling of wrecks"
  },
  {
      "id": "38.32",
      "label": "Recovery of sorted materials"
  },
  {
      "id": "38.32Z",
      "label": "Recovery of sorted materials"
  },
  {
      "id": "39",
      "label": "Remediation activities and other waste management services"
  },
  {
      "id": "39.0",
      "label": "Remediation activities and other waste management services"
  },
  {
      "id": "39.00",
      "label": "Remediation activities and other waste management services"
  },
  {
      "id": "39.00Z",
      "label": "Remediation activities and other waste management services"
  },
  {
      "id": "41",
      "label": "Construction of buildings"
  },
  {
      "id": "41.1",
      "label": "Development of building projects"
  },
  {
      "id": "41.10",
      "label": "Development of building projects"
  },
  {
      "id": "41.10A",
      "label": "Development and selling of dwellings"
  },
  {
      "id": "41.10B",
      "label": "Development and selling of offices"
  },
  {
      "id": "41.10C",
      "label": "Development and selling of other buildings"
  },
  {
      "id": "41.10D",
      "label": "Juridical compartmentalization of property programmes"
  },
  {
      "id": "41.2",
      "label": "Construction of residential and non-residential buildings"
  },
  {
      "id": "41.20",
      "label": "Construction of residential and non-residential buildings"
  },
  {
      "id": "41.20A",
      "label": "Construction of detached and semi-detached houses"
  },
  {
      "id": "41.20B",
      "label": "Construction of other buildings"
  },
  {
      "id": "42",
      "label": "Civil engineering"
  },
  {
      "id": "42.1",
      "label": "Construction of roads and railways"
  },
  {
      "id": "42.11",
      "label": "Construction of roads and motorways"
  },
  {
      "id": "42.11Z",
      "label": "Construction of roads and motorways"
  },
  {
      "id": "42.12",
      "label": "Construction of railways and underground railways"
  },
  {
      "id": "42.12Z",
      "label": "Construction of railways and underground railways"
  },
  {
      "id": "42.13",
      "label": "Construction of bridges and tunnels"
  },
  {
      "id": "42.13A",
      "label": "Construction of civil engineering structures"
  },
  {
      "id": "42.13B",
      "label": "Construction and maintenance of tunnels"
  },
  {
      "id": "42.2",
      "label": "Construction of utility projects"
  },
  {
      "id": "42.21",
      "label": "Construction of utility projects for fluids"
  },
  {
      "id": "42.21Z",
      "label": "Construction of utility projects for fluids"
  },
  {
      "id": "42.22",
      "label": "Construction of utility projects for electricity and telecommunications"
  },
  {
      "id": "42.22Z",
      "label": "Construction of utility projects for electricity and telecommunications"
  },
  {
      "id": "42.9",
      "label": "Construction of other civil engineering projects"
  },
  {
      "id": "42.91",
      "label": "Construction of water projects"
  },
  {
      "id": "42.91Z",
      "label": "Construction of water projects"
  },
  {
      "id": "42.99",
      "label": "Construction of other civil engineering projects n.e.c."
  },
  {
      "id": "42.99Z",
      "label": "Construction of other civil engineering projects n.e.c."
  },
  {
      "id": "43",
      "label": "Specialised construction activities"
  },
  {
      "id": "43.1",
      "label": "Demolition and site preparation"
  },
  {
      "id": "43.11",
      "label": "Demolition"
  },
  {
      "id": "43.11Z",
      "label": "Demolition"
  },
  {
      "id": "43.12",
      "label": "Site preparation"
  },
  {
      "id": "43.12A",
      "label": "Standard earth moving and preparation works"
  },
  {
      "id": "43.12B",
      "label": "Specialized earth moving or in great mass"
  },
  {
      "id": "43.13",
      "label": "Test drilling and boring"
  },
  {
      "id": "43.13Z",
      "label": "Test drilling and boring"
  },
  {
      "id": "43.2",
      "label": "Electrical, plumbing and other construction installation activities"
  },
  {
      "id": "43.21",
      "label": "Electrical installation"
  },
  {
      "id": "43.21A",
      "label": "Installation works of electrical wiring and fittings in all kind of buildings"
  },
  {
      "id": "43.21B",
      "label": "Installation works of electrical wiring and fittings on public thoroughfare"
  },
  {
      "id": "43.22",
      "label": "Plumbing, heat and air-conditioning installation"
  },
  {
      "id": "43.22A",
      "label": "Water and gas installation works in all kind of premises"
  },
  {
      "id": "43.22B",
      "label": "Installation works of thermic and air conditioning equipment"
  },
  {
      "id": "43.29",
      "label": "Other construction installation"
  },
  {
      "id": "43.29A",
      "label": "Insulation work activities"
  },
  {
      "id": "43.29B",
      "label": "Other building installation works n.e.c."
  },
  {
      "id": "43.3",
      "label": "Building completion and finishing"
  },
  {
      "id": "43.31",
      "label": "Plastering"
  },
  {
      "id": "43.31Z",
      "label": "Plastering"
  },
  {
      "id": "43.32",
      "label": "Joinery installation"
  },
  {
      "id": "43.32A",
      "label": "Wood and PVC joinery works"
  },
  {
      "id": "43.32B",
      "label": "Metal joinery works and ironwork"
  },
  {
      "id": "43.32C",
      "label": "Finishing of sale premises"
  },
  {
      "id": "43.33",
      "label": "Floor and wall covering"
  },
  {
      "id": "43.33Z",
      "label": "Floor and wall covering"
  },
  {
      "id": "43.34",
      "label": "Painting and glazing"
  },
  {
      "id": "43.34Z",
      "label": "Painting and glazing"
  },
  {
      "id": "43.39",
      "label": "Other building completion and finishing"
  },
  {
      "id": "43.39Z",
      "label": "Other building completion and finishing"
  },
  {
      "id": "43.9",
      "label": "Other specialised construction activities"
  },
  {
      "id": "43.91",
      "label": "Roofing activities"
  },
  {
      "id": "43.91A",
      "label": "Roof frames works"
  },
  {
      "id": "43.91B",
      "label": "Roof covering activities by elements"
  },
  {
      "id": "43.99",
      "label": "Other specialised construction activities n.e.c."
  },
  {
      "id": "43.99A",
      "label": "Waterproofness works"
  },
  {
      "id": "43.99B",
      "label": "Assembly works of metal structures"
  },
  {
      "id": "43.99C",
      "label": "Masonery works and building structural works"
  },
  {
      "id": "43.99D",
      "label": "Other construction works involving special trades"
  },
  {
      "id": "43.99E",
      "label": "Renting of construction equipment with operator"
  },
  {
      "id": "45",
      "label": "Wholesale and retail trade and repair of motor vehicles and motorcycles"
  },
  {
      "id": "45.1",
      "label": "Sale of motor vehicles"
  },
  {
      "id": "45.11",
      "label": "Sale of cars and light motor vehicles"
  },
  {
      "id": "45.11Z",
      "label": "Sale of cars and light motor vehicles"
  },
  {
      "id": "45.19",
      "label": "Sale of other motor vehicles"
  },
  {
      "id": "45.19Z",
      "label": "Sale of other motor vehicles"
  },
  {
      "id": "45.2",
      "label": "Maintenance and repair of motor vehicles"
  },
  {
      "id": "45.20",
      "label": "Maintenance and repair of motor vehicles"
  },
  {
      "id": "45.20A",
      "label": "Maintenance and repair of light motor vehicles"
  },
  {
      "id": "45.20B",
      "label": "Maintenance and repair of other motor vehicles"
  },
  {
      "id": "45.3",
      "label": "Sale of motor vehicle parts and accessories"
  },
  {
      "id": "45.31",
      "label": "Wholesale trade of motor vehicle parts and accessories"
  },
  {
      "id": "45.31Z",
      "label": "Wholesale trade of motor vehicle parts and accessories"
  },
  {
      "id": "45.32",
      "label": "Retail trade of motor vehicle parts and accessories"
  },
  {
      "id": "45.32Z",
      "label": "Retail trade of motor vehicle parts and accessories"
  },
  {
      "id": "45.4",
      "label": "Sale, maintenance and repair of motorcycles and related parts and accessories"
  },
  {
      "id": "45.40",
      "label": "Sale, maintenance and repair of motorcycles and related parts and accessories"
  },
  {
      "id": "45.40Z",
      "label": "Sale, maintenance and repair of motorcycles and related parts and accessories"
  },
  {
      "id": "46",
      "label": "Wholesale trade, except of motor vehicles and motorcycles"
  },
  {
      "id": "46.1",
      "label": "Wholesale on a fee or contract basis"
  },
  {
      "id": "46.11",
      "label": "Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods"
  },
  {
      "id": "46.11Z",
      "label": "Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods"
  },
  {
      "id": "46.12",
      "label": "Agents involved in the sale of fuels, ores, metals and industrial chemicals"
  },
  {
      "id": "46.12A",
      "label": "Automotive fuel buying groups"
  },
  {
      "id": "46.12B",
      "label": "Other agents involved in the sale of fuels, ores, metals and industrial chemicals"
  },
  {
      "id": "46.13",
      "label": "Agents involved in the sale of timber and building materials"
  },
  {
      "id": "46.13Z",
      "label": "Agents involved in the sale of timber and building materials"
  },
  {
      "id": "46.14",
      "label": "Agents involved in the sale of machinery, industrial equipment, ships and aircraft"
  },
  {
      "id": "46.14Z",
      "label": "Agents involved in the sale of machinery, industrial equipment, ships and aircraft"
  },
  {
      "id": "46.15",
      "label": "Agents involved in the sale of furniture, household goods, hardware and ironmongery"
  },
  {
      "id": "46.15Z",
      "label": "Agents involved in the sale of furniture, household goods, hardware and ironmongery"
  },
  {
      "id": "46.16",
      "label": "Agents involved in the sale of textiles, clothing, fur, footwear and leather goods"
  },
  {
      "id": "46.16Z",
      "label": "Agents involved in the sale of textiles, clothing, fur, footwear and leather goods"
  },
  {
      "id": "46.17",
      "label": "Agents involved in the sale of food, beverages and tobacco"
  },
  {
      "id": "46.17A",
      "label": "Food buying groups"
  },
  {
      "id": "46.17B",
      "label": "Other agents involved in the sale of food, beverages and tobacco"
  },
  {
      "id": "46.18",
      "label": "Agents specialised in the sale of other particular products"
  },
  {
      "id": "46.18Z",
      "label": "Agents specialised in the sale of other particular products"
  },
  {
      "id": "46.19",
      "label": "Agents involved in the sale of a variety of goods"
  },
  {
      "id": "46.19A",
      "label": "Non food buying groups"
  },
  {
      "id": "46.19B",
      "label": "Other agents involved in the sale of a variety of goods"
  },
  {
      "id": "46.2",
      "label": "Wholesale of agricultural raw materials and live animals"
  },
  {
      "id": "46.21",
      "label": "Wholesale of grain, unmanufactured tobacco, seeds and animal feeds"
  },
  {
      "id": "46.21Z",
      "label": "Wholesale (intercompany trade) of grain, unmanufactured tobacco, seeds and animal feeds"
  },
  {
      "id": "46.22",
      "label": "Wholesale of flowers and plants"
  },
  {
      "id": "46.22Z",
      "label": "Wholesale (intercompany trade) of flowers and plants"
  },
  {
      "id": "46.23",
      "label": "Wholesale of live animals"
  },
  {
      "id": "46.23Z",
      "label": "Wholesale (intercompany trade) of live animals"
  },
  {
      "id": "46.24",
      "label": "Wholesale of hides, skins and leather"
  },
  {
      "id": "46.24Z",
      "label": "Wholesale (intercompany trade) of hides, skins and leather"
  },
  {
      "id": "46.3",
      "label": "Wholesale of food, beverages and tobacco"
  },
  {
      "id": "46.31",
      "label": "Wholesale of fruit and vegetables"
  },
  {
      "id": "46.31Z",
      "label": "Wholesale (intercompany trade) of fruit and vegetables"
  },
  {
      "id": "46.32",
      "label": "Wholesale of meat and meat products"
  },
  {
      "id": "46.32A",
      "label": "Wholesale (intercompany trade) of fresh meat"
  },
  {
      "id": "46.32B",
      "label": "Wholesale (intercompany trade) of meat products"
  },
  {
      "id": "46.32C",
      "label": "Wholesale (intercompany trade) of poultry and game"
  },
  {
      "id": "46.33",
      "label": "Wholesale of dairy products, eggs and edible oils and fats"
  },
  {
      "id": "46.33Z",
      "label": "Wholesale (intercompany trade) of dairy products, eggs and edible oils and fats"
  },
  {
      "id": "46.34",
      "label": "Wholesale of beverages"
  },
  {
      "id": "46.34Z",
      "label": "Wholesale (intercompany trade) of beverages"
  },
  {
      "id": "46.35",
      "label": "Wholesale of tobacco products"
  },
  {
      "id": "46.35Z",
      "label": "Wholesale (intercompany trade) of tobacco products"
  },
  {
      "id": "46.36",
      "label": "Wholesale of sugar and chocolate and sugar confectionery"
  },
  {
      "id": "46.36Z",
      "label": "Wholesale (intercompany trade) of sugar and chocolate and sugar confectionery"
  },
  {
      "id": "46.37",
      "label": "Wholesale of coffee, tea, cocoa and spices"
  },
  {
      "id": "46.37Z",
      "label": "Wholesale (intercompany trade) of coffee, tea, cocoa and spices"
  },
  {
      "id": "46.38",
      "label": "Wholesale of other food, including fish, crustaceans and molluscs"
  },
  {
      "id": "46.38A",
      "label": "Wholesale (intercompany trade) of fish, crustaceans and molluscs"
  },
  {
      "id": "46.38B",
      "label": "Wholesale (intercompany trade) of sundry food, specialized"
  },
  {
      "id": "46.39",
      "label": "Non-specialised wholesale of food, beverages and tobacco"
  },
  {
      "id": "46.39A",
      "label": "Wholesale (intercompany trade) of frozen products"
  },
  {
      "id": "46.39B",
      "label": "Wholesale (intercompany trade) of food, non specialized"
  },
  {
      "id": "46.4",
      "label": "Wholesale of household goods"
  },
  {
      "id": "46.41",
      "label": "Wholesale of textiles"
  },
  {
      "id": "46.41Z",
      "label": "Wholesale (intercompany trade) of textiles"
  },
  {
      "id": "46.42",
      "label": "Wholesale of clothing and footwear"
  },
  {
      "id": "46.42Z",
      "label": "Wholesale (intercompany trade) of clothing and footwear"
  },
  {
      "id": "46.43",
      "label": "Wholesale of electrical household appliances"
  },
  {
      "id": "46.43Z",
      "label": "Wholesale (intercompany trade) of electrical household appliances"
  },
  {
      "id": "46.44",
      "label": "Wholesale of china and glassware and cleaning materials"
  },
  {
      "id": "46.44Z",
      "label": "Wholesale (intercompany trade) of china and glassware and cleaning materials"
  },
  {
      "id": "46.45",
      "label": "Wholesale of perfume and cosmetics"
  },
  {
      "id": "46.45Z",
      "label": "Wholesale (intercompany trade) of perfume and cosmetics"
  },
  {
      "id": "46.46",
      "label": "Wholesale of pharmaceutical goods"
  },
  {
      "id": "46.46Z",
      "label": "Wholesale (intercompany trade) of pharmaceutical goods"
  },
  {
      "id": "46.47",
      "label": "Wholesale of furniture, carpets and lighting equipment"
  },
  {
      "id": "46.47Z",
      "label": "Wholesale (intercompany trade) of furniture, carpets and lighting equipment"
  },
  {
      "id": "46.48",
      "label": "Wholesale of watches and jewellery"
  },
  {
      "id": "46.48Z",
      "label": "Wholesale (intercompany trade) of watches and jewellery"
  },
  {
      "id": "46.49",
      "label": "Wholesale of other household goods"
  },
  {
      "id": "46.49Z",
      "label": "Wholesale (intercompany trade) of other household goods"
  },
  {
      "id": "46.5",
      "label": "Wholesale of information and communication equipment"
  },
  {
      "id": "46.51",
      "label": "Wholesale of computers, computer peripheral equipment and software"
  },
  {
      "id": "46.51Z",
      "label": "Wholesale (intercompany trade) of computers, computer peripheral equipment and software"
  },
  {
      "id": "46.52",
      "label": "Wholesale of electronic and telecommunications equipment and parts"
  },
  {
      "id": "46.52Z",
      "label": "Wholesale (intercompany trade) of electronic and telecommunications equipment and parts"
  },
  {
      "id": "46.6",
      "label": "Wholesale of other machinery, equipment and supplies"
  },
  {
      "id": "46.61",
      "label": "Wholesale of agricultural machinery, equipment and supplies"
  },
  {
      "id": "46.61Z",
      "label": "Wholesale (intercompany trade) of agricultural machinery, equipment and supplies"
  },
  {
      "id": "46.62",
      "label": "Wholesale of machine tools"
  },
  {
      "id": "46.62Z",
      "label": "Wholesale (intercompany trade) of machine tools"
  },
  {
      "id": "46.63",
      "label": "Wholesale of mining, construction and civil engineering machinery"
  },
  {
      "id": "46.63Z",
      "label": "Wholesale (intercompany trade) of mining, construction and civil engineering machinery"
  },
  {
      "id": "46.64",
      "label": "Wholesale of machinery for the textile industry and of sewing and knitting machines"
  },
  {
      "id": "46.64Z",
      "label": "Wholesale (intercompany trade) of machinery for the textile industry and of sewing and knitting machines"
  },
  {
      "id": "46.65",
      "label": "Wholesale of office furniture"
  },
  {
      "id": "46.65Z",
      "label": "Wholesale (intercompany trade) of office furniture"
  },
  {
      "id": "46.66",
      "label": "Wholesale of other office machinery and equipment"
  },
  {
      "id": "46.66Z",
      "label": "Wholesale (intercompany trade) of other office machinery and equipment"
  },
  {
      "id": "46.69",
      "label": "Wholesale of other machinery and equipment"
  },
  {
      "id": "46.69A",
      "label": "Wholesale (intercompany trade) of electric equipment"
  },
  {
      "id": "46.69B",
      "label": "Wholesale (intercompany trade) of sundry industrial supplies and equipment"
  },
  {
      "id": "46.69C",
      "label": "Wholesale (intercompany trade) of sundry supplies and equipment for trade and services"
  },
  {
      "id": "46.7",
      "label": "Other specialised wholesale"
  },
  {
      "id": "46.71",
      "label": "Wholesale of solid, liquid and gaseous fuels and related products"
  },
  {
      "id": "46.71Z",
      "label": "Wholesale (intercompany trade) of solid, liquid and gaseous fuels and related products"
  },
  {
      "id": "46.72",
      "label": "Wholesale of metals and metal ores"
  },
  {
      "id": "46.72Z",
      "label": "Wholesale (intercompany trade) of metals and metal ores"
  },
  {
      "id": "46.73",
      "label": "Wholesale of wood, construction materials and sanitary equipment"
  },
  {
      "id": "46.73A",
      "label": "Wholesale (intercompany trade) of wood and construction materials"
  },
  {
      "id": "46.73B",
      "label": "Wholesale (intercompany trade) of sanitary equipment and fitting out of housing materials"
  },
  {
      "id": "46.74",
      "label": "Wholesale of hardware, plumbing and heating equipment and supplies"
  },
  {
      "id": "46.74A",
      "label": "Wholesale (intercompany trade) of hardware"
  },
  {
      "id": "46.74B",
      "label": "Wholesale (intercompany trade) of plumbing and heating equipment and supplies"
  },
  {
      "id": "46.75",
      "label": "Wholesale of chemical products"
  },
  {
      "id": "46.75Z",
      "label": "Wholesale (intercompany trade) of chemical products"
  },
  {
      "id": "46.76",
      "label": "Wholesale of other intermediate products"
  },
  {
      "id": "46.76Z",
      "label": "Wholesale (intercompany trade) of other intermediate products"
  },
  {
      "id": "46.77",
      "label": "Wholesale of waste and scrap"
  },
  {
      "id": "46.77Z",
      "label": "Wholesale (intercompany trade) of waste and scrap"
  },
  {
      "id": "46.9",
      "label": "Non-specialised wholesale trade"
  },
  {
      "id": "46.90",
      "label": "Non-specialised wholesale trade"
  },
  {
      "id": "46.90Z",
      "label": "Non-specialised wholesale trade (intercompany trade)"
  },
  {
      "id": "47",
      "label": "Retail trade, except of motor vehicles and motorcycles"
  },
  {
      "id": "47.1",
      "label": "Retail sale in non-specialised stores"
  },
  {
      "id": "47.11",
      "label": "Retail sale in non-specialised stores with food, beverages or tobacco predominating"
  },
  {
      "id": "47.11A",
      "label": "Retail sale of frozen products"
  },
  {
      "id": "47.11B",
      "label": "General food retail stores"
  },
  {
      "id": "47.11C",
      "label": "Mini-markets"
  },
  {
      "id": "47.11D",
      "label": "Supermarkets"
  },
  {
      "id": "47.11E",
      "label": "Multi-trade stores"
  },
  {
      "id": "47.11F",
      "label": "Hypermarkets"
  },
  {
      "id": "47.19",
      "label": "Other retail sale in non-specialised stores"
  },
  {
      "id": "47.19A",
      "label": "Department stores"
  },
  {
      "id": "47.19B",
      "label": "Other retail sale in non specialized stores"
  },
  {
      "id": "47.2",
      "label": "Retail sale of food, beverages and tobacco in specialised stores"
  },
  {
      "id": "47.21",
      "label": "Retail sale of fruit and vegetables in specialised stores"
  },
  {
      "id": "47.21Z",
      "label": "Retail sale of fruit and vegetables in specialised stores"
  },
  {
      "id": "47.22",
      "label": "Retail sale of meat and meat products in specialised stores"
  },
  {
      "id": "47.22Z",
      "label": "Retail sale of meat and meat products in specialised stores"
  },
  {
      "id": "47.23",
      "label": "Retail sale of fish, crustaceans and molluscs in specialised stores"
  },
  {
      "id": "47.23Z",
      "label": "Retail sale of fish, crustaceans and molluscs in specialised stores"
  },
  {
      "id": "47.24",
      "label": "Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores"
  },
  {
      "id": "47.24Z",
      "label": "Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores"
  },
  {
      "id": "47.25",
      "label": "Retail sale of beverages in specialised stores"
  },
  {
      "id": "47.25Z",
      "label": "Retail sale of beverages in specialised stores"
  },
  {
      "id": "47.26",
      "label": "Retail sale of tobacco products in specialised stores"
  },
  {
      "id": "47.26Z",
      "label": "Retail sale of tobacco products in specialised stores"
  },
  {
      "id": "47.29",
      "label": "Other retail sale of food in specialised stores"
  },
  {
      "id": "47.29Z",
      "label": "Other retail sale of food in specialised stores"
  },
  {
      "id": "47.3",
      "label": "Retail sale of automotive fuel in specialised stores"
  },
  {
      "id": "47.30",
      "label": "Retail sale of automotive fuel in specialised stores"
  },
  {
      "id": "47.30Z",
      "label": "Retail sale of automotive fuel in specialised stores"
  },
  {
      "id": "47.4",
      "label": "Retail sale of information and communication equipment in specialised stores"
  },
  {
      "id": "47.41",
      "label": "Retail sale of computers, peripheral units and software in specialised stores"
  },
  {
      "id": "47.41Z",
      "label": "Retail sale of computers, peripheral units and software in specialised stores"
  },
  {
      "id": "47.42",
      "label": "Retail sale of telecommunications equipment in specialised stores"
  },
  {
      "id": "47.42Z",
      "label": "Retail sale of telecommunications equipment in specialised stores"
  },
  {
      "id": "47.43",
      "label": "Retail sale of audio and video equipment in specialised stores"
  },
  {
      "id": "47.43Z",
      "label": "Retail sale of audio and video equipment in specialised stores"
  },
  {
      "id": "47.5",
      "label": "Retail sale of other household equipment in specialised stores"
  },
  {
      "id": "47.51",
      "label": "Retail sale of textiles in specialised stores"
  },
  {
      "id": "47.51Z",
      "label": "Retail sale of textiles in specialised stores"
  },
  {
      "id": "47.52",
      "label": "Retail sale of hardware, paints and glass in specialised stores"
  },
  {
      "id": "47.52A",
      "label": "Retail sale of hardware, paints and glass in small stores (less  than 400 m2)"
  },
  {
      "id": "47.52B",
      "label": "Retail sale of hardware, paints and glass in DIY superstores (400 m2 and more)"
  },
  {
      "id": "47.53",
      "label": "Retail sale of carpets, rugs, wall and floor coverings in specialised stores"
  },
  {
      "id": "47.53Z",
      "label": "Retail sale of carpets, rugs, wall and floor coverings in specialised stores"
  },
  {
      "id": "47.54",
      "label": "Retail sale of electrical household appliances in specialised stores"
  },
  {
      "id": "47.54Z",
      "label": "Retail sale of electrical household appliances in specialised stores"
  },
  {
      "id": "47.59",
      "label": "Retail sale of furniture, lighting equipment and other household articles in specialised stores"
  },
  {
      "id": "47.59A",
      "label": "Retail sale of furniture"
  },
  {
      "id": "47.59B",
      "label": "Retail sale of other household equipment and articles"
  },
  {
      "id": "47.6",
      "label": "Retail sale of cultural and recreation goods in specialised stores"
  },
  {
      "id": "47.61",
      "label": "Retail sale of books in specialised stores"
  },
  {
      "id": "47.61Z",
      "label": "Retail sale of books in specialised stores"
  },
  {
      "id": "47.62",
      "label": "Retail sale of newspapers and stationery in specialised stores"
  },
  {
      "id": "47.62Z",
      "label": "Retail sale of newspapers and stationery in specialised stores"
  },
  {
      "id": "47.63",
      "label": "Retail sale of music and video recordings in specialised stores"
  },
  {
      "id": "47.63Z",
      "label": "Retail sale of music and video recordings in specialised stores"
  },
  {
      "id": "47.64",
      "label": "Retail sale of sporting equipment in specialised stores"
  },
  {
      "id": "47.64Z",
      "label": "Retail sale of sporting equipment in specialised stores"
  },
  {
      "id": "47.65",
      "label": "Retail sale of games and toys in specialised stores"
  },
  {
      "id": "47.65Z",
      "label": "Retail sale of games and toys in specialised stores"
  },
  {
      "id": "47.7",
      "label": "Retail sale of other goods in specialised stores"
  },
  {
      "id": "47.71",
      "label": "Retail sale of clothing in specialised stores"
  },
  {
      "id": "47.71Z",
      "label": "Retail sale of clothing in specialised stores"
  },
  {
      "id": "47.72",
      "label": "Retail sale of footwear and leather goods in specialised stores"
  },
  {
      "id": "47.72A",
      "label": "Retail sale of footwear"
  },
  {
      "id": "47.72B",
      "label": "Retail sale of fine leather goods and of travel articles"
  },
  {
      "id": "47.73",
      "label": "Dispensing chemist in specialised stores"
  },
  {
      "id": "47.73Z",
      "label": "Dispensing chemist in specialised stores"
  },
  {
      "id": "47.74",
      "label": "Retail sale of medical and orthopaedic goods in specialised stores"
  },
  {
      "id": "47.74Z",
      "label": "Retail sale of medical and orthopaedic goods in specialised stores"
  },
  {
      "id": "47.75",
      "label": "Retail sale of cosmetic and toilet articles in specialised stores"
  },
  {
      "id": "47.75Z",
      "label": "Retail sale of cosmetic and toilet articles in specialised stores"
  },
  {
      "id": "47.76",
      "label": "Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores"
  },
  {
      "id": "47.76Z",
      "label": "Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores"
  },
  {
      "id": "47.77",
      "label": "Retail sale of watches and jewellery in specialised stores"
  },
  {
      "id": "47.77Z",
      "label": "Retail sale of watches and jewellery in specialised stores"
  },
  {
      "id": "47.78",
      "label": "Other retail sale of new goods in specialised stores"
  },
  {
      "id": "47.78A",
      "label": "Retail sale of optics"
  },
  {
      "id": "47.78B",
      "label": "Retail sale of coal and fuels"
  },
  {
      "id": "47.78C",
      "label": "Other sundry specialized retail sale"
  },
  {
      "id": "47.79",
      "label": "Retail sale of second-hand goods in stores"
  },
  {
      "id": "47.79Z",
      "label": "Retail sale of second-hand goods in stores"
  },
  {
      "id": "47.8",
      "label": "Retail sale via stalls and markets"
  },
  {
      "id": "47.81",
      "label": "Retail sale via stalls and markets of food, beverages and tobacco products"
  },
  {
      "id": "47.81Z",
      "label": "Retail sale via stalls and markets of food, beverages and tobacco products"
  },
  {
      "id": "47.82",
      "label": "Retail sale via stalls and markets of textiles, clothing and footwear"
  },
  {
      "id": "47.82Z",
      "label": "Retail sale via stalls and markets of textiles, clothing and footwear"
  },
  {
      "id": "47.89",
      "label": "Retail sale via stalls and markets of other goods"
  },
  {
      "id": "47.89Z",
      "label": "Retail sale via stalls and markets of other goods"
  },
  {
      "id": "47.9",
      "label": "Retail trade not in stores, stalls or markets"
  },
  {
      "id": "47.91",
      "label": "Retail sale via mail order houses or via Internet"
  },
  {
      "id": "47.91A",
      "label": "Retail sale via home-shopping by general catalogue"
  },
  {
      "id": "47.91B",
      "label": "Retail sale via home-shopping by specialized catalogue"
  },
  {
      "id": "47.99",
      "label": "Other retail sale not in stores, stalls or markets"
  },
  {
      "id": "47.99A",
      "label": "From door to door sale"
  },
  {
      "id": "47.99B",
      "label": "Vending machine sale and sundry retail trade not in stores, stalls or markets, n.e.c."
  },
  {
      "id": "49",
      "label": "Land transport and transport via pipelines"
  },
  {
      "id": "49.1",
      "label": "Passenger rail transport, interurban"
  },
  {
      "id": "49.10",
      "label": "Passenger rail transport, interurban"
  },
  {
      "id": "49.10Z",
      "label": "Passenger rail transport, interurban"
  },
  {
      "id": "49.2",
      "label": "Freight rail transport"
  },
  {
      "id": "49.20",
      "label": "Freight rail transport"
  },
  {
      "id": "49.20Z",
      "label": "Freight rail transport"
  },
  {
      "id": "49.3",
      "label": "Other passenger land transport"
  },
  {
      "id": "49.31",
      "label": "Urban and suburban passenger land transport"
  },
  {
      "id": "49.31Z",
      "label": "Urban and suburban passenger land transport"
  },
  {
      "id": "49.32",
      "label": "Taxi operation"
  },
  {
      "id": "49.32Z",
      "label": "Taxi operation"
  },
  {
      "id": "49.39",
      "label": "Other passenger land transport n.e.c."
  },
  {
      "id": "49.39A",
      "label": "Road scheduled passengers land transport"
  },
  {
      "id": "49.39B",
      "label": "Other land passenger transport"
  },
  {
      "id": "49.39C",
      "label": "Cableway and ski lifts"
  },
  {
      "id": "49.4",
      "label": "Freight transport by road and removal services"
  },
  {
      "id": "49.41",
      "label": "Freight transport by road"
  },
  {
      "id": "49.41A",
      "label": "Interurban freight transport by road"
  },
  {
      "id": "49.41B",
      "label": "Proximity freight transport by road"
  },
  {
      "id": "49.41C",
      "label": "Rent of lorries with driver"
  },
  {
      "id": "49.42",
      "label": "Removal services"
  },
  {
      "id": "49.42Z",
      "label": "Removal services"
  },
  {
      "id": "49.5",
      "label": "Transport via pipeline"
  },
  {
      "id": "49.50",
      "label": "Transport via pipeline"
  },
  {
      "id": "49.50Z",
      "label": "Transport via pipeline"
  },
  {
      "id": "50",
      "label": "Water transport"
  },
  {
      "id": "50.1",
      "label": "Sea and coastal passenger water transport"
  },
  {
      "id": "50.10",
      "label": "Sea and coastal passenger water transport"
  },
  {
      "id": "50.10Z",
      "label": "Sea and coastal passenger water transport"
  },
  {
      "id": "50.2",
      "label": "Sea and coastal freight water transport"
  },
  {
      "id": "50.20",
      "label": "Sea and coastal freight water transport"
  },
  {
      "id": "50.20Z",
      "label": "Sea and coastal freight water transport"
  },
  {
      "id": "50.3",
      "label": "Inland passenger water transport"
  },
  {
      "id": "50.30",
      "label": "Inland passenger water transport"
  },
  {
      "id": "50.30Z",
      "label": "Inland passenger water transport"
  },
  {
      "id": "50.4",
      "label": "Inland freight water transport"
  },
  {
      "id": "50.40",
      "label": "Inland freight water transport"
  },
  {
      "id": "50.40Z",
      "label": "Inland freight water transport"
  },
  {
      "id": "51",
      "label": "Air transport"
  },
  {
      "id": "51.1",
      "label": "Passenger air transport"
  },
  {
      "id": "51.10",
      "label": "Passenger air transport"
  },
  {
      "id": "51.10Z",
      "label": "Passenger air transport"
  },
  {
      "id": "51.2",
      "label": "Freight air transport and space transport"
  },
  {
      "id": "51.21",
      "label": "Freight air transport"
  },
  {
      "id": "51.21Z",
      "label": "Freight air transport"
  },
  {
      "id": "51.22",
      "label": "Space transport"
  },
  {
      "id": "51.22Z",
      "label": "Space transport"
  },
  {
      "id": "52",
      "label": "Warehousing and support activities for transportation"
  },
  {
      "id": "52.1",
      "label": "Warehousing and storage"
  },
  {
      "id": "52.10",
      "label": "Warehousing and storage"
  },
  {
      "id": "52.10A",
      "label": "Refrigerating warehousing and storage"
  },
  {
      "id": "52.10B",
      "label": "Non refrigerating warehousing and storage"
  },
  {
      "id": "52.2",
      "label": "Support activities for transportation"
  },
  {
      "id": "52.21",
      "label": "Service activities incidental to land transportation"
  },
  {
      "id": "52.21Z",
      "label": "Service activities incidental to land transportation"
  },
  {
      "id": "52.22",
      "label": "Service activities incidental to water transportation"
  },
  {
      "id": "52.22Z",
      "label": "Service activities incidental to water transportation"
  },
  {
      "id": "52.23",
      "label": "Service activities incidental to air transportation"
  },
  {
      "id": "52.23Z",
      "label": "Service activities incidental to air transportation"
  },
  {
      "id": "52.24",
      "label": "Cargo handling"
  },
  {
      "id": "52.24A",
      "label": "Harbour cargo handling"
  },
  {
      "id": "52.24B",
      "label": "Non harbour cargo handling"
  },
  {
      "id": "52.29",
      "label": "Other transportation support activities"
  },
  {
      "id": "52.29A",
      "label": "Freight services organization"
  },
  {
      "id": "52.29B",
      "label": "Chartering and transportation organisation"
  },
  {
      "id": "53",
      "label": "Postal and courier activities"
  },
  {
      "id": "53.1",
      "label": "Postal activities under universal service obligation"
  },
  {
      "id": "53.10",
      "label": "Postal activities under universal service obligation"
  },
  {
      "id": "53.10Z",
      "label": "Postal activities under universal service obligation"
  },
  {
      "id": "53.2",
      "label": "Other postal and courier activities"
  },
  {
      "id": "53.20",
      "label": "Other postal and courier activities"
  },
  {
      "id": "53.20Z",
      "label": "Other postal and courier activities"
  },
  {
      "id": "55",
      "label": "Accommodation"
  },
  {
      "id": "55.1",
      "label": "Hotels and similar accommodation"
  },
  {
      "id": "55.10",
      "label": "Hotels and similar accommodation"
  },
  {
      "id": "55.10Z",
      "label": "Hotels and similar accommodation"
  },
  {
      "id": "55.2",
      "label": "Holiday and other short-stay accommodation"
  },
  {
      "id": "55.20",
      "label": "Holiday and other short-stay accommodation"
  },
  {
      "id": "55.20Z",
      "label": "Holiday and other short-stay accommodation"
  },
  {
      "id": "55.3",
      "label": "Camping grounds, recreational vehicle parks and trailer parks"
  },
  {
      "id": "55.30",
      "label": "Camping grounds, recreational vehicle parks and trailer parks"
  },
  {
      "id": "55.30Z",
      "label": "Camping grounds, recreational vehicle parks and trailer parks"
  },
  {
      "id": "55.9",
      "label": "Other accommodation"
  },
  {
      "id": "55.90",
      "label": "Other accommodation"
  },
  {
      "id": "55.90Z",
      "label": "Other accommodation"
  },
  {
      "id": "56",
      "label": "Food and beverage service activities"
  },
  {
      "id": "56.1",
      "label": "Restaurants and mobile food service activities"
  },
  {
      "id": "56.10",
      "label": "Restaurants and mobile food service activities"
  },
  {
      "id": "56.10A",
      "label": "Traditional catering"
  },
  {
      "id": "56.10B",
      "label": "Cafeterias and other self-service catering"
  },
  {
      "id": "56.10C",
      "label": "Fast food restaurants"
  },
  {
      "id": "56.2",
      "label": "Event catering and other food service activities"
  },
  {
      "id": "56.21",
      "label": "Event catering activities"
  },
  {
      "id": "56.21Z",
      "label": "Event catering activities"
  },
  {
      "id": "56.29",
      "label": "Other food service activities"
  },
  {
      "id": "56.29A",
      "label": "Collective catering under contract"
  },
  {
      "id": "56.29B",
      "label": "Other catering n.e.c."
  },
  {
      "id": "56.3",
      "label": "Beverage serving activities"
  },
  {
      "id": "56.30",
      "label": "Beverage serving activities"
  },
  {
      "id": "56.30Z",
      "label": "Beverage serving activities"
  },
  {
      "id": "58",
      "label": "Publishing activities"
  },
  {
      "id": "58.1",
      "label": "Publishing of books, periodicals and other publishing activities"
  },
  {
      "id": "58.11",
      "label": "Book publishing"
  },
  {
      "id": "58.11Z",
      "label": "Book publishing"
  },
  {
      "id": "58.12",
      "label": "Publishing of directories and mailing lists"
  },
  {
      "id": "58.12Z",
      "label": "Publishing of directories and mailing lists"
  },
  {
      "id": "58.13",
      "label": "Publishing of newspapers"
  },
  {
      "id": "58.13Z",
      "label": "Publishing of newspapers"
  },
  {
      "id": "58.14",
      "label": "Publishing of journals and periodicals"
  },
  {
      "id": "58.14Z",
      "label": "Publishing of journals and periodicals"
  },
  {
      "id": "58.19",
      "label": "Other publishing activities"
  },
  {
      "id": "58.19Z",
      "label": "Other publishing activities"
  },
  {
      "id": "58.2",
      "label": "Software publishing"
  },
  {
      "id": "58.21",
      "label": "Publishing of computer games"
  },
  {
      "id": "58.21Z",
      "label": "Publishing of computer games"
  },
  {
      "id": "58.29",
      "label": "Other software publishing"
  },
  {
      "id": "58.29A",
      "label": "System and network software publishing"
  },
  {
      "id": "58.29B",
      "label": "Development tools and programming languages software publishing"
  },
  {
      "id": "58.29C",
      "label": "Application software publishing"
  },
  {
      "id": "59",
      "label": "Motion picture, video and television programme production, sound recording and music publishing activities"
  },
  {
      "id": "59.1",
      "label": "Motion picture, video and television programme activities"
  },
  {
      "id": "59.11",
      "label": "Motion picture, video and television programme production activities"
  },
  {
      "id": "59.11A",
      "label": "Production of motion pictures for television and television programmes"
  },
  {
      "id": "59.11B",
      "label": "Production of institutional and promotional motion pictures"
  },
  {
      "id": "59.11C",
      "label": "Production of motion pictures for cinema"
  },
  {
      "id": "59.12",
      "label": "Motion picture, video and television programme post-production activities"
  },
  {
      "id": "59.12Z",
      "label": "Motion picture, video and television programme post-production activities"
  },
  {
      "id": "59.13",
      "label": "Motion picture, video and television programme distribution activities"
  },
  {
      "id": "59.13A",
      "label": "Motion pictures for cinema distribution"
  },
  {
      "id": "59.13B",
      "label": "Video edition and distribution"
  },
  {
      "id": "59.14",
      "label": "Motion picture projection activities"
  },
  {
      "id": "59.14Z",
      "label": "Motion picture projection activities"
  },
  {
      "id": "59.2",
      "label": "Sound recording and music publishing activities"
  },
  {
      "id": "59.20",
      "label": "Sound recording and music publishing activities"
  },
  {
      "id": "59.20Z",
      "label": "Sound recording and music publishing activities"
  },
  {
      "id": "60",
      "label": "Programming and broadcasting activities"
  },
  {
      "id": "60.1",
      "label": "Radio broadcasting"
  },
  {
      "id": "60.10",
      "label": "Radio broadcasting"
  },
  {
      "id": "60.10Z",
      "label": "Radio broadcasting"
  },
  {
      "id": "60.2",
      "label": "Television programming and broadcasting activities"
  },
  {
      "id": "60.20",
      "label": "Television programming and broadcasting activities"
  },
  {
      "id": "60.20A",
      "label": "Broadcast of general-interest television programmes"
  },
  {
      "id": "60.20B",
      "label": "Broadcast of thematic television programmes"
  },
  {
      "id": "61",
      "label": "Telecommunications"
  },
  {
      "id": "61.1",
      "label": "Wired telecommunications activities"
  },
  {
      "id": "61.10",
      "label": "Wired telecommunications activities"
  },
  {
      "id": "61.10Z",
      "label": "Wired telecommunications activities"
  },
  {
      "id": "61.2",
      "label": "Wireless telecommunications activities"
  },
  {
      "id": "61.20",
      "label": "Wireless telecommunications activities"
  },
  {
      "id": "61.20Z",
      "label": "Wireless telecommunications activities"
  },
  {
      "id": "61.3",
      "label": "Satellite telecommunications activities"
  },
  {
      "id": "61.30",
      "label": "Satellite telecommunications activities"
  },
  {
      "id": "61.30Z",
      "label": "Satellite telecommunications activities"
  },
  {
      "id": "61.9",
      "label": "Other telecommunications activities"
  },
  {
      "id": "61.90",
      "label": "Other telecommunications activities"
  },
  {
      "id": "61.90Z",
      "label": "Other telecommunications activities"
  },
  {
      "id": "62",
      "label": "Computer programming, consultancy and related activities"
  },
  {
      "id": "62.0",
      "label": "Computer programming, consultancy and related activities"
  },
  {
      "id": "62.01",
      "label": "Computer programming activities"
  },
  {
      "id": "62.01Z",
      "label": "Computer programming activities"
  },
  {
      "id": "62.02",
      "label": "Computer consultancy activities"
  },
  {
      "id": "62.02A",
      "label": "Hardware and software consultancy"
  },
  {
      "id": "62.02B",
      "label": "Third party maintenance of computer sytems and applications"
  },
  {
      "id": "62.03",
      "label": "Computer facilities management activities"
  },
  {
      "id": "62.03Z",
      "label": "Computer facilities management activities"
  },
  {
      "id": "62.09",
      "label": "Other information technology and computer service activities"
  },
  {
      "id": "62.09Z",
      "label": "Other information technology and computer service activities"
  },
  {
      "id": "63",
      "label": "Information service activities"
  },
  {
      "id": "63.1",
      "label": "Data processing, hosting and related activities; web portals"
  },
  {
      "id": "63.11",
      "label": "Data processing, hosting and related activities"
  },
  {
      "id": "63.11Z",
      "label": "Data processing, hosting and related activities"
  },
  {
      "id": "63.12",
      "label": "Web portals"
  },
  {
      "id": "63.12Z",
      "label": "Web portals"
  },
  {
      "id": "63.9",
      "label": "Other information service activities"
  },
  {
      "id": "63.91",
      "label": "News agency activities"
  },
  {
      "id": "63.91Z",
      "label": "News agency activities"
  },
  {
      "id": "63.99",
      "label": "Other information service activities n.e.c."
  },
  {
      "id": "63.99Z",
      "label": "Other information service activities n.e.c."
  },
  {
      "id": "64",
      "label": "Financial service activities, except insurance and pension funding"
  },
  {
      "id": "64.1",
      "label": "Monetary intermediation"
  },
  {
      "id": "64.11",
      "label": "Central banking"
  },
  {
      "id": "64.11Z",
      "label": "Central banking"
  },
  {
      "id": "64.19",
      "label": "Other monetary intermediation"
  },
  {
      "id": "64.19Z",
      "label": "Other monetary intermediation"
  },
  {
      "id": "64.2",
      "label": "Activities of holding companies"
  },
  {
      "id": "64.20",
      "label": "Activities of holding companies"
  },
  {
      "id": "64.20Z",
      "label": "Activities of holding companies"
  },
  {
      "id": "64.3",
      "label": "Trusts, funds and similar financial entities"
  },
  {
      "id": "64.30",
      "label": "Trusts, funds and similar financial entities"
  },
  {
      "id": "64.30Z",
      "label": "Trusts, funds and similar financial entities"
  },
  {
      "id": "64.9",
      "label": "Other financial service activities, except insurance and pension funding"
  },
  {
      "id": "64.91",
      "label": "Financial leasing"
  },
  {
      "id": "64.91Z",
      "label": "Financial leasing"
  },
  {
      "id": "64.92",
      "label": "Other credit granting/font>"
  },
  {
      "id": "64.92Z",
      "label": "Other credit granting"
  },
  {
      "id": "64.99",
      "label": "Other financial service activities, except insurance and pension funding n.e.c."
  },
  {
      "id": "64.99Z",
      "label": "Other financial service activities, except insurance and pension funding n.e.c."
  },
  {
      "id": "65",
      "label": "Insurance, reinsurance and pension funding, except compulsory social security"
  },
  {
      "id": "65.1",
      "label": "Insurance"
  },
  {
      "id": "65.11",
      "label": "Life insurance"
  },
  {
      "id": "65.11Z",
      "label": "Life insurance"
  },
  {
      "id": "65.12",
      "label": "Non-life insurance"
  },
  {
      "id": "65.12Z",
      "label": "Non-life insurance"
  },
  {
      "id": "65.2",
      "label": "Reinsurance"
  },
  {
      "id": "65.20",
      "label": "Reinsurance"
  },
  {
      "id": "65.20Z",
      "label": "Reinsurance"
  },
  {
      "id": "65.3",
      "label": "Pension funding"
  },
  {
      "id": "65.30",
      "label": "Pension funding"
  },
  {
      "id": "65.30Z",
      "label": "Pension funding"
  },
  {
      "id": "66",
      "label": "Activities auxiliary to financial services and insurance activities"
  },
  {
      "id": "66.1",
      "label": "Activities auxiliary to financial services, except insurance and pension funding"
  },
  {
      "id": "66.11",
      "label": "Administration of financial markets"
  },
  {
      "id": "66.11Z",
      "label": "Administration of financial markets"
  },
  {
      "id": "66.12",
      "label": "Security and commodity contracts brokerage"
  },
  {
      "id": "66.12Z",
      "label": "Security and commodity contracts brokerage"
  },
  {
      "id": "66.19",
      "label": "Other activities auxiliary to financial services, except insurance and pension funding"
  },
  {
      "id": "66.19A",
      "label": "Juridical arrangement of movable property management"
  },
  {
      "id": "66.19B",
      "label": "Other activities auxiliary to financial services, except insurance and pension funding, n.e.c."
  },
  {
      "id": "66.2",
      "label": "Activities auxiliary to insurance and pension funding"
  },
  {
      "id": "66.21",
      "label": "Risk and damage evaluation"
  },
  {
      "id": "66.21Z",
      "label": "Risk and damage evaluation"
  },
  {
      "id": "66.22",
      "label": "Activities of insurance agents and brokers"
  },
  {
      "id": "66.22Z",
      "label": "Activities of insurance agents and brokers"
  },
  {
      "id": "66.29",
      "label": "Other activities auxiliary to insurance and pension funding"
  },
  {
      "id": "66.29Z",
      "label": "Other activities auxiliary to insurance and pension funding"
  },
  {
      "id": "66.3",
      "label": "Fund management activities"
  },
  {
      "id": "66.30",
      "label": "Fund management activities"
  },
  {
      "id": "66.30Z",
      "label": "Fund management activities"
  },
  {
      "id": "68",
      "label": "Real estate activities"
  },
  {
      "id": "68.1",
      "label": "Buying and selling of own real estate"
  },
  {
      "id": "68.10",
      "label": "Buying and selling of own real estate"
  },
  {
      "id": "68.10Z",
      "label": "Buying and selling of own real estate"
  },
  {
      "id": "68.2",
      "label": "Renting and operating of own or leased real estate"
  },
  {
      "id": "68.20",
      "label": "Renting and operating of own or leased real estate"
  },
  {
      "id": "68.20A",
      "label": "Letting of dwellings"
  },
  {
      "id": "68.20B",
      "label": "Letting of land and other own property"
  },
  {
      "id": "68.3",
      "label": "Real estate activities on a fee or contract basis"
  },
  {
      "id": "68.31",
      "label": "Real estate agencies"
  },
  {
      "id": "68.31Z",
      "label": "Real estate agencies"
  },
  {
      "id": "68.32",
      "label": "Management of real estate on a fee or contract basis"
  },
  {
      "id": "68.32A",
      "label": "Management of residential building and other real estate on a fee or contract basis"
  },
  {
      "id": "68.32B",
      "label": "Juridical arrangement of immovable property management"
  },
  {
      "id": "69",
      "label": "Legal and accounting activities"
  },
  {
      "id": "69.1",
      "label": "Legal activities"
  },
  {
      "id": "69.10",
      "label": "Legal activities"
  },
  {
      "id": "69.10Z",
      "label": "Legal activities"
  },
  {
      "id": "69.2",
      "label": "Accounting, bookkeeping and auditing activities; tax consultancy"
  },
  {
      "id": "69.20",
      "label": "Accounting, bookkeeping and auditing activities; tax consultancy"
  },
  {
      "id": "69.20Z",
      "label": "Accounting, bookkeeping and auditing activities; tax consultancy"
  },
  {
      "id": "70",
      "label": "Activities of head offices; management consultancy activities"
  },
  {
      "id": "70.1",
      "label": "Activities of head offices"
  },
  {
      "id": "70.10",
      "label": "Activities of head offices"
  },
  {
      "id": "70.10Z",
      "label": "Activities of head offices"
  },
  {
      "id": "70.2",
      "label": "Management consultancy activities"
  },
  {
      "id": "70.21",
      "label": "Public relations and communication activities"
  },
  {
      "id": "70.21Z",
      "label": "Public relations and communication activities"
  },
  {
      "id": "70.22",
      "label": "Business and other management consultancy activities"
  },
  {
      "id": "70.22Z",
      "label": "Business and other management consultancy activities"
  },
  {
      "id": "71",
      "label": "Architectural and engineering activities; technical testing and analysis"
  },
  {
      "id": "71.1",
      "label": "Architectural and engineering activities and related technical consultancy"
  },
  {
      "id": "71.11",
      "label": "Architectural activities"
  },
  {
      "id": "71.11Z",
      "label": "Architectural activities"
  },
  {
      "id": "71.12",
      "label": "Engineering activities and related technical consultancy"
  },
  {
      "id": "71.12A",
      "label": "Surveyor activities"
  },
  {
      "id": "71.12B",
      "label": "Engineering, technical studies"
  },
  {
      "id": "71.2",
      "label": "Technical testing and analysis"
  },
  {
      "id": "71.20",
      "label": "Technical testing and analysis"
  },
  {
      "id": "71.20A",
      "label": "Car technical testing"
  },
  {
      "id": "71.20B",
      "label": "Technical analyses, testing and inspections"
  },
  {
      "id": "72",
      "label": "Scientific research and development"
  },
  {
      "id": "72.1",
      "label": "Research and experimental development on natural sciences and engineering"
  },
  {
      "id": "72.11",
      "label": "Research and experimental development on biotechnology"
  },
  {
      "id": "72.11Z",
      "label": "Research and experimental development on biotechnology"
  },
  {
      "id": "72.19",
      "label": "Other research and experimental development on natural sciences and engineering"
  },
  {
      "id": "72.19Z",
      "label": "Other research and experimental development on natural sciences and engineering"
  },
  {
      "id": "72.2",
      "label": "Research and experimental development on social sciences and humanities"
  },
  {
      "id": "72.20",
      "label": "Research and experimental development on social sciences and humanities"
  },
  {
      "id": "72.20Z",
      "label": "Research and experimental development on social sciences and humanities"
  },
  {
      "id": "73",
      "label": "Advertising and market research"
  },
  {
      "id": "73.1",
      "label": "Advertising"
  },
  {
      "id": "73.11",
      "label": "Advertising agencies"
  },
  {
      "id": "73.11Z",
      "label": "Advertising agencies"
  },
  {
      "id": "73.12",
      "label": "Media representation"
  },
  {
      "id": "73.12Z",
      "label": "Media representation"
  },
  {
      "id": "73.2",
      "label": "Market research and public opinion polling"
  },
  {
      "id": "73.20",
      "label": "Market research and public opinion polling"
  },
  {
      "id": "73.20Z",
      "label": "Market research and public opinion polling"
  },
  {
      "id": "74",
      "label": "Other professional, scientific and technical activities"
  },
  {
      "id": "74.1",
      "label": "Specialised design activities"
  },
  {
      "id": "74.10",
      "label": "Specialised design activities"
  },
  {
      "id": "74.10Z",
      "label": "Specialised design activities"
  },
  {
      "id": "74.2",
      "label": "Photographic activities"
  },
  {
      "id": "74.20",
      "label": "Photographic activities"
  },
  {
      "id": "74.20Z",
      "label": "Photographic activities"
  },
  {
      "id": "74.3",
      "label": "Translation and interpretation activities"
  },
  {
      "id": "74.30",
      "label": "Translation and interpretation activities"
  },
  {
      "id": "74.30Z",
      "label": "Translation and interpretation activities"
  },
  {
      "id": "74.9",
      "label": "Other professional, scientific and technical activities n.e.c."
  },
  {
      "id": "74.90",
      "label": "Other professional, scientific and technical activities n.e.c."
  },
  {
      "id": "74.90A",
      "label": "Activities of quantity surveyors"
  },
  {
      "id": "74.90B",
      "label": "Sundry professional, scientific and technical activities"
  },
  {
      "id": "75",
      "label": "Veterinary activities"
  },
  {
      "id": "75.0",
      "label": "Veterinary activities"
  },
  {
      "id": "75.00",
      "label": "Veterinary activities"
  },
  {
      "id": "75.00Z",
      "label": "Veterinary activities"
  },
  {
      "id": "77",
      "label": "Rental and leasing activities"
  },
  {
      "id": "77.1",
      "label": "Renting and leasing of motor vehicles"
  },
  {
      "id": "77.11",
      "label": "Renting and leasing of cars and light motor vehicles"
  },
  {
      "id": "77.11A",
      "label": "Short term renting of cars and light motor vehicles"
  },
  {
      "id": "77.11B",
      "label": "Long term renting of cars and light motor vehicles"
  },
  {
      "id": "77.12",
      "label": "Renting and leasing of trucks"
  },
  {
      "id": "77.12Z",
      "label": "Renting and leasing of trucks"
  },
  {
      "id": "77.2",
      "label": "Renting and leasing of personal and household goods"
  },
  {
      "id": "77.21",
      "label": "Renting and leasing of recreational and sports goods"
  },
  {
      "id": "77.21Z",
      "label": "Renting and leasing of recreational and sports goods"
  },
  {
      "id": "77.22",
      "label": "Renting of video tapes and disks"
  },
  {
      "id": "77.22Z",
      "label": "Renting of video tapes and disks"
  },
  {
      "id": "77.29",
      "label": "Renting and leasing of other personal and household goods"
  },
  {
      "id": "77.29Z",
      "label": "Renting and leasing of other personal and household goods"
  },
  {
      "id": "77.3",
      "label": "Renting and leasing of other machinery, equipment and tangible goods"
  },
  {
      "id": "77.31",
      "label": "Renting and leasing of agricultural machinery and equipment"
  },
  {
      "id": "77.31Z",
      "label": "Renting and leasing of agricultural machinery and equipment"
  },
  {
      "id": "77.32",
      "label": "Renting and leasing of construction and civil engineering machinery and equipment"
  },
  {
      "id": "77.32Z",
      "label": "Renting and leasing of construction and civil engineering machinery and equipment"
  },
  {
      "id": "77.33",
      "label": "Renting and leasing of office machinery and equipment (including computers)"
  },
  {
      "id": "77.33Z",
      "label": "Renting and leasing of office machinery and equipment (including computers)"
  },
  {
      "id": "77.34",
      "label": "Renting and leasing of water transport equipment"
  },
  {
      "id": "77.34Z",
      "label": "Renting and leasing of water transport equipment"
  },
  {
      "id": "77.35",
      "label": "Renting and leasing of air transport equipment"
  },
  {
      "id": "77.35Z",
      "label": "Renting and leasing of air transport equipment"
  },
  {
      "id": "77.39",
      "label": "Renting and leasing of other machinery, equipment and tangible goods n.e.c."
  },
  {
      "id": "77.39Z",
      "label": "Renting and leasing of other machinery, equipment and tangible goods n.e.c."
  },
  {
      "id": "77.4",
      "label": "Leasing of intellectual property and similar products, except copyrighted works"
  },
  {
      "id": "77.40",
      "label": "Leasing of intellectual property and similar products, except copyrighted works"
  },
  {
      "id": "77.40Z",
      "label": "Leasing of intellectual property and similar products, except copyrighted works"
  },
  {
      "id": "78",
      "label": "Employment activities"
  },
  {
      "id": "78.1",
      "label": "Activities of employment placement agencies"
  },
  {
      "id": "78.10",
      "label": "Activities of employment placement agencies"
  },
  {
      "id": "78.10Z",
      "label": "Activities of employment placement agencies"
  },
  {
      "id": "78.2",
      "label": "Temporary employment agency activities"
  },
  {
      "id": "78.20",
      "label": "Temporary employment agency activities"
  },
  {
      "id": "78.20Z",
      "label": "Temporary employment agency activities"
  },
  {
      "id": "78.3",
      "label": "Other human resources provision"
  },
  {
      "id": "78.30",
      "label": "Other human resources provision"
  },
  {
      "id": "78.30Z",
      "label": "Other human resources provision"
  },
  {
      "id": "79",
      "label": "Travel agency, tour operator and other reservation service and related activities"
  },
  {
      "id": "79.1",
      "label": "Travel agency and tour operator activities"
  },
  {
      "id": "79.11",
      "label": "Travel agency activities"
  },
  {
      "id": "79.11Z",
      "label": "Travel agency activities"
  },
  {
      "id": "79.12",
      "label": "Tour operator activities"
  },
  {
      "id": "79.12Z",
      "label": "Tour operator activities"
  },
  {
      "id": "79.9",
      "label": "Other reservation service and related activities"
  },
  {
      "id": "79.90",
      "label": "Other reservation service and related activities"
  },
  {
      "id": "79.90Z",
      "label": "Other reservation service and related activities"
  },
  {
      "id": "80",
      "label": "Security and investigation activities"
  },
  {
      "id": "80.1",
      "label": "Private security activities"
  },
  {
      "id": "80.10",
      "label": "Private security activities"
  },
  {
      "id": "80.10Z",
      "label": "Private security activities"
  },
  {
      "id": "80.2",
      "label": "Security systems service activities"
  },
  {
      "id": "80.20",
      "label": "Security systems service activities"
  },
  {
      "id": "80.20Z",
      "label": "Security systems service activities"
  },
  {
      "id": "80.3",
      "label": "Investigation activities"
  },
  {
      "id": "80.30",
      "label": "Investigation activities"
  },
  {
      "id": "80.30Z",
      "label": "Investigation activities"
  },
  {
      "id": "81",
      "label": "Services to buildings and landscape activities"
  },
  {
      "id": "81.1",
      "label": "Combined facilities support activities"
  },
  {
      "id": "81.10",
      "label": "Combined facilities support activities"
  },
  {
      "id": "81.10Z",
      "label": "Combined facilities support activities"
  },
  {
      "id": "81.2",
      "label": "Cleaning activities"
  },
  {
      "id": "81.21",
      "label": "General cleaning of buildings"
  },
  {
      "id": "81.21Z",
      "label": "General cleaning of buildings"
  },
  {
      "id": "81.22",
      "label": "Other building and industrial cleaning activities"
  },
  {
      "id": "81.22Z",
      "label": "Other building and industrial cleaning activities"
  },
  {
      "id": "81.29",
      "label": "Other cleaning activities"
  },
  {
      "id": "81.29A",
      "label": "Desinfection, insect and rat extermination"
  },
  {
      "id": "81.29B",
      "label": "Other cleaning activities n.e.c."
  },
  {
      "id": "81.3",
      "label": "Landscape service activities"
  },
  {
      "id": "81.30",
      "label": "Landscape service activities"
  },
  {
      "id": "81.30Z",
      "label": "Landscape service activities"
  },
  {
      "id": "82",
      "label": "Office administrative, office support and other business support activities"
  },
  {
      "id": "82.1",
      "label": "Office administrative and support activities"
  },
  {
      "id": "82.11",
      "label": "Combined office administrative service activities"
  },
  {
      "id": "82.11Z",
      "label": "Combined office administrative service activities"
  },
  {
      "id": "82.19",
      "label": "Photocopying, document preparation and other specialised office support activities"
  },
  {
      "id": "82.19Z",
      "label": "Photocopying, document preparation and other specialised office support activities"
  },
  {
      "id": "82.2",
      "label": "Activities of call centres"
  },
  {
      "id": "82.20",
      "label": "Activities of call centres"
  },
  {
      "id": "82.20Z",
      "label": "Activities of call centres"
  },
  {
      "id": "82.3",
      "label": "Organisation of conventions and trade shows"
  },
  {
      "id": "82.30",
      "label": "Organisation of conventions and trade shows"
  },
  {
      "id": "82.30Z",
      "label": "Organisation of trade fairs, trade shows and conventions"
  },
  {
      "id": "82.9",
      "label": "Business support service activities n.e.c."
  },
  {
      "id": "82.91",
      "label": "Activities of collection agencies and credit bureaus"
  },
  {
      "id": "82.91Z",
      "label": "Activities of collection agencies and credit bureaus"
  },
  {
      "id": "82.92",
      "label": "Packaging activities"
  },
  {
      "id": "82.92Z",
      "label": "Packaging activities"
  },
  {
      "id": "82.99",
      "label": "Other business support service activities n.e.c."
  },
  {
      "id": "82.99Z",
      "label": "Other business support service activities n.e.c."
  },
  {
      "id": "84",
      "label": "Public administration and defence; compulsory social security"
  },
  {
      "id": "84.1",
      "label": "Administration of the State and the economic and social policy of the community"
  },
  {
      "id": "84.11",
      "label": "General public administration activities"
  },
  {
      "id": "84.11Z",
      "label": "General public administration activities"
  },
  {
      "id": "84.12",
      "label": "Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security"
  },
  {
      "id": "84.12Z",
      "label": "Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security"
  },
  {
      "id": "84.13",
      "label": "Regulation of and contribution to more efficient operation of businesses"
  },
  {
      "id": "84.13Z",
      "label": "Regulation of and contribution to more efficient operation of businesses"
  },
  {
      "id": "84.2",
      "label": "Provision of services to the community as a whole"
  },
  {
      "id": "84.21",
      "label": "Foreign affairs"
  },
  {
      "id": "84.21Z",
      "label": "Foreign affairs"
  },
  {
      "id": "84.22",
      "label": "Defence activities"
  },
  {
      "id": "84.22Z",
      "label": "Defence activities"
  },
  {
      "id": "84.23",
      "label": "Justice and judicial activities"
  },
  {
      "id": "84.23Z",
      "label": "Justice and judicial activities"
  },
  {
      "id": "84.24",
      "label": "Public order and safety activities"
  },
  {
      "id": "84.24Z",
      "label": "Public order and safety activities"
  },
  {
      "id": "84.25",
      "label": "Fire service activities"
  },
  {
      "id": "84.25Z",
      "label": "Fire service activities"
  },
  {
      "id": "84.3",
      "label": "Compulsory social security activities"
  },
  {
      "id": "84.30",
      "label": "Compulsory social security activities"
  },
  {
      "id": "84.30A",
      "label": "Social security general activities"
  },
  {
      "id": "84.30B",
      "label": "Management of supplementary pensions"
  },
  {
      "id": "84.30C",
      "label": "Social distribution of incomes"
  },
  {
      "id": "85",
      "label": "Education"
  },
  {
      "id": "85.1",
      "label": "Pre-primary education"
  },
  {
      "id": "85.10",
      "label": "Pre-primary education"
  },
  {
      "id": "85.10Z",
      "label": "Pre-primary education"
  },
  {
      "id": "85.2",
      "label": "Primary education"
  },
  {
      "id": "85.20",
      "label": "Primary education"
  },
  {
      "id": "85.20Z",
      "label": "Primary education"
  },
  {
      "id": "85.3",
      "label": "Secondary education"
  },
  {
      "id": "85.31",
      "label": "General secondary education"
  },
  {
      "id": "85.31Z",
      "label": "General secondary education"
  },
  {
      "id": "85.32",
      "label": "Technical and vocational secondary education"
  },
  {
      "id": "85.32Z",
      "label": "Technical and vocational secondary education"
  },
  {
      "id": "85.4",
      "label": "Higher education"
  },
  {
      "id": "85.41",
      "label": "Post-secondary non-tertiary education"
  },
  {
      "id": "85.41Z",
      "label": "Post-secondary non-tertiary education"
  },
  {
      "id": "85.42",
      "label": "Tertiary education"
  },
  {
      "id": "85.42Z",
      "label": "Tertiary education"
  },
  {
      "id": "85.5",
      "label": "Other education"
  },
  {
      "id": "85.51",
      "label": "Sports and recreation education"
  },
  {
      "id": "85.51Z",
      "label": "Sports and recreation education"
  },
  {
      "id": "85.52",
      "label": "Cultural education"
  },
  {
      "id": "85.52Z",
      "label": "Cultural education"
  },
  {
      "id": "85.53",
      "label": "Driving school activities"
  },
  {
      "id": "85.53Z",
      "label": "Driving school activities"
  },
  {
      "id": "85.59",
      "label": "Other education n.e.c."
  },
  {
      "id": "85.59A",
      "label": "Continuing education for adults"
  },
  {
      "id": "85.59B",
      "label": "Sundry education"
  },
  {
      "id": "85.6",
      "label": "Educational support activities"
  },
  {
      "id": "85.60",
      "label": "Educational support activities"
  },
  {
      "id": "85.60Z",
      "label": "Educational support activities"
  },
  {
      "id": "86",
      "label": "Human health activities"
  },
  {
      "id": "86.1",
      "label": "Hospital activities"
  },
  {
      "id": "86.10",
      "label": "Hospital activities"
  },
  {
      "id": "86.10Z",
      "label": "Hospital activities"
  },
  {
      "id": "86.2",
      "label": "Medical and dental practice activities"
  },
  {
      "id": "86.21",
      "label": "General medical practice activities"
  },
  {
      "id": "86.21Z",
      "label": "General medical practice activities"
  },
  {
      "id": "86.22",
      "label": "Specialist medical practice activities"
  },
  {
      "id": "86.22A",
      "label": "X-ray diagnosis and radiotherapy activities"
  },
  {
      "id": "86.22B",
      "label": "Surgery activities"
  },
  {
      "id": "86.22C",
      "label": "Other specialist medical practice activities"
  },
  {
      "id": "86.23",
      "label": "Dental practice activities"
  },
  {
      "id": "86.23Z",
      "label": "Dental practice activities"
  },
  {
      "id": "86.9",
      "label": "Other human health activities"
  },
  {
      "id": "86.90",
      "label": "Other human health activities"
  },
  {
      "id": "86.90A",
      "label": "Ambulances"
  },
  {
      "id": "86.90B",
      "label": "Medical analysis laboratory"
  },
  {
      "id": "86.90C",
      "label": "Collection centers and organ banks"
  },
  {
      "id": "86.90D",
      "label": "Activities of nurses and midwives"
  },
  {
      "id": "86.90E",
      "label": "Activities of professionnals in re-education, prosthesis or health devices and chiropodists"
  },
  {
      "id": "86.90F",
      "label": "Human health activities n.e.c."
  },
  {
      "id": "87",
      "label": "Residential care activities"
  },
  {
      "id": "87.1",
      "label": "Residential nursing care activities"
  },
  {
      "id": "87.10",
      "label": "Residential nursing care activities"
  },
  {
      "id": "87.10A",
      "label": "Residential nursing care activities for the elderly"
  },
  {
      "id": "87.10B",
      "label": "Residential nursing care activities for disabled children"
  },
  {
      "id": "87.10C",
      "label": "Residential nursing care activities for disabled adults and other residential nursing care"
  },
  {
      "id": "87.2",
      "label": "Residential care activities for mental retardation, mental health and substance abuse"
  },
  {
      "id": "87.20",
      "label": "Residential care activities for mental retardation, mental health and substance abuse"
  },
  {
      "id": "87.20A",
      "label": "Residential care activities for mental retardation and mental health"
  },
  {
      "id": "87.20B",
      "label": "Residential care activities for substance abuse"
  },
  {
      "id": "87.3",
      "label": "Residential care activities for the elderly and disabled"
  },
  {
      "id": "87.30",
      "label": "Residential care activities for the elderly and disabled"
  },
  {
      "id": "87.30A",
      "label": "Residential care activities for the elderly"
  },
  {
      "id": "87.30B",
      "label": "Residential care activities for the disabled"
  },
  {
      "id": "87.9",
      "label": "Other residential care activities"
  },
  {
      "id": "87.90",
      "label": "Other residential care activities"
  },
  {
      "id": "87.90A",
      "label": "Residential care activities for children with difficulties"
  },
  {
      "id": "87.90B",
      "label": "Residential care activities for adults and families with difficulties and other residential care activities"
  },
  {
      "id": "88",
      "label": "Social work activities without accommodation"
  },
  {
      "id": "88.1",
      "label": "Social work activities without accommodation for the elderly and disabled"
  },
  {
      "id": "88.10",
      "label": "Social work activities without accommodation for the elderly and disabled"
  },
  {
      "id": "88.10A",
      "label": "Home helps"
  },
  {
      "id": "88.10B",
      "label": "Other welcome or guidance without accomodation for disabled adults or the elderly"
  },
  {
      "id": "88.10C",
      "label": "Assistance by work"
  },
  {
      "id": "88.9",
      "label": "Other social work activities without accommodation"
  },
  {
      "id": "88.91",
      "label": "Child day-care activities"
  },
  {
      "id": "88.91A",
      "label": "Welcome facilities for young children"
  },
  {
      "id": "88.91B",
      "label": "Welcome or guidance without accomodation for disabled children"
  },
  {
      "id": "88.99",
      "label": "Other social work activities without accommodation n.e.c."
  },
  {
      "id": "88.99A",
      "label": "Other welcome or guidance without accomodation for children and teenagers"
  },
  {
      "id": "88.99B",
      "label": "Social work activities without accommodation n.e.c."
  },
  {
      "id": "90",
      "label": "Creative, arts and entertainment activities"
  },
  {
      "id": "90.0",
      "label": "Creative, arts and entertainment activities"
  },
  {
      "id": "90.01",
      "label": "Performing arts"
  },
  {
      "id": "90.01Z",
      "label": "Performing arts"
  },
  {
      "id": "90.02",
      "label": "Support activities to performing arts"
  },
  {
      "id": "90.02Z",
      "label": "Support activities to performing arts"
  },
  {
      "id": "90.03",
      "label": "Artistic creation"
  },
  {
      "id": "90.03A",
      "label": "Artistic creation related to fine arts"
  },
  {
      "id": "90.03B",
      "label": "Other artistic creation"
  },
  {
      "id": "90.04",
      "label": "Operation of arts facilities"
  },
  {
      "id": "90.04Z",
      "label": "Operation of arts facilities"
  },
  {
      "id": "91",
      "label": "Libraries, archives, museums and other cultural activities"
  },
  {
      "id": "91.0",
      "label": "Libraries, archives, museums and other cultural activities"
  },
  {
      "id": "91.01",
      "label": "Library and archives activities"
  },
  {
      "id": "91.01Z",
      "label": "Library and archives activities"
  },
  {
      "id": "91.02",
      "label": "Museums activities"
  },
  {
      "id": "91.02Z",
      "label": "Museums activities"
  },
  {
      "id": "91.03",
      "label": "Operation of historical sites and buildings and similar visitor attractions"
  },
  {
      "id": "91.03Z",
      "label": "Operation of historical sites and buildings and similar visitor attractions"
  },
  {
      "id": "91.04",
      "label": "Botanical and zoological gardens and nature reserves activities"
  },
  {
      "id": "91.04Z",
      "label": "Botanical and zoological gardens and nature reserves activities"
  },
  {
      "id": "92",
      "label": "Gambling and betting activities"
  },
  {
      "id": "92.0",
      "label": "Gambling and betting activities"
  },
  {
      "id": "92.00",
      "label": "Gambling and betting activities"
  },
  {
      "id": "92.00Z",
      "label": "Gambling and betting activities"
  },
  {
      "id": "93",
      "label": "Sports activities and amusement and recreation activities"
  },
  {
      "id": "93.1",
      "label": "Sports activities"
  },
  {
      "id": "93.11",
      "label": "Operation of sports facilities"
  },
  {
      "id": "93.11Z",
      "label": "Operation of sports facilities"
  },
  {
      "id": "93.12",
      "label": "Activities of sport clubs"
  },
  {
      "id": "93.12Z",
      "label": "Activities of sport clubs"
  },
  {
      "id": "93.13",
      "label": "Fitness facilities"
  },
  {
      "id": "93.13Z",
      "label": "Fitness facilities"
  },
  {
      "id": "93.19",
      "label": "Other sports activities"
  },
  {
      "id": "93.19Z",
      "label": "Other sports activities"
  },
  {
      "id": "93.2",
      "label": "Amusement and recreation activities"
  },
  {
      "id": "93.21",
      "label": "Activities of amusement parks and theme parks"
  },
  {
      "id": "93.21Z",
      "label": "Activities of amusement parks and theme parks"
  },
  {
      "id": "93.29",
      "label": "Other amusement and recreation activities"
  },
  {
      "id": "93.29Z",
      "label": "Other amusement and recreation activities"
  },
  {
      "id": "94",
      "label": "Activities of membership organisations"
  },
  {
      "id": "94.1",
      "label": "Activities of business, employers and professional membership organisations"
  },
  {
      "id": "94.11",
      "label": "Activities of business and employers membership organisations"
  },
  {
      "id": "94.11Z",
      "label": "Activities of business and employers membership organisations"
  },
  {
      "id": "94.12",
      "label": "Activities of professional membership organisations"
  },
  {
      "id": "94.12Z",
      "label": "Activities of professional membership organisations"
  },
  {
      "id": "94.2",
      "label": "Activities of trade unions"
  },
  {
      "id": "94.20",
      "label": "Activities of trade unions"
  },
  {
      "id": "94.20Z",
      "label": "Activities of trade unions"
  },
  {
      "id": "94.9",
      "label": "Activities of other membership organisations"
  },
  {
      "id": "94.91",
      "label": "Activities of religious organisations"
  },
  {
      "id": "94.91Z",
      "label": "Activities of religious organisations"
  },
  {
      "id": "94.92",
      "label": "Activities of political organisations"
  },
  {
      "id": "94.92Z",
      "label": "Activities of political organisations"
  },
  {
      "id": "94.99",
      "label": "Activities of other membership organisations n.e.c."
  },
  {
      "id": "94.99Z",
      "label": "Other membership organisations based on voluntary membership"
  },
  {
      "id": "95",
      "label": "Repair of computers and personal and household goods"
  },
  {
      "id": "95.1",
      "label": "Repair of computers and communication equipment"
  },
  {
      "id": "95.11",
      "label": "Repair of computers and peripheral equipment"
  },
  {
      "id": "95.11Z",
      "label": "Repair of computers and peripheral equipment"
  },
  {
      "id": "95.12",
      "label": "Repair of communication equipment"
  },
  {
      "id": "95.12Z",
      "label": "Repair of communication equipment"
  },
  {
      "id": "95.2",
      "label": "Repair of personal and household goods"
  },
  {
      "id": "95.21",
      "label": "Repair of consumer electronics"
  },
  {
      "id": "95.21Z",
      "label": "Repair of consumer electronics"
  },
  {
      "id": "95.22",
      "label": "Repair of household appliances and home and garden equipment"
  },
  {
      "id": "95.22Z",
      "label": "Repair of household appliances and home and garden equipment"
  },
  {
      "id": "95.23",
      "label": "Repair of footwear and leather goods"
  },
  {
      "id": "95.23Z",
      "label": "Repair of footwear and leather goods"
  },
  {
      "id": "95.24",
      "label": "Repair of furniture and home furnishings"
  },
  {
      "id": "95.24Z",
      "label": "Repair of furniture and home furnishings"
  },
  {
      "id": "95.25",
      "label": "Repair of watches, clocks and jewellery"
  },
  {
      "id": "95.25Z",
      "label": "Repair of watches, clocks and jewellery"
  },
  {
      "id": "95.29",
      "label": "Repair of other personal and household goods"
  },
  {
      "id": "95.29Z",
      "label": "Repair of other personal and household goods"
  },
  {
      "id": "96",
      "label": "Other personal service activities"
  },
  {
      "id": "96.0",
      "label": "Other personal service activities"
  },
  {
      "id": "96.01",
      "label": "Washing and (dry-)cleaning of textile and fur products"
  },
  {
      "id": "96.01A",
      "label": "Washing and dry cleaning general services"
  },
  {
      "id": "96.01B",
      "label": "Washing and dry cleaning household services"
  },
  {
      "id": "96.02",
      "label": "Hairdressing and other beauty treatment"
  },
  {
      "id": "96.02A",
      "label": "Hairdressing"
  },
  {
      "id": "96.02B",
      "label": "Beauty treatment"
  },
  {
      "id": "96.03",
      "label": "Funeral and related activities"
  },
  {
      "id": "96.03Z",
      "label": "Funeral and related activities"
  },
  {
      "id": "96.04",
      "label": "Physical well-being activities"
  },
  {
      "id": "96.04Z",
      "label": "Physical well-being activities"
  },
  {
      "id": "96.09",
      "label": "Other personal service activities n.e.c."
  },
  {
      "id": "96.09Z",
      "label": "Other personal service activities n.e.c."
  },
  {
      "id": "97",
      "label": "Activities of households as employers of domestic personnel"
  },
  {
      "id": "97.0",
      "label": "Activities of households as employers of domestic personnel"
  },
  {
      "id": "97.00",
      "label": "Activities of households as employers of domestic personnel"
  },
  {
      "id": "97.00Z",
      "label": "Activities of households as employers of domestic personnel"
  },
  {
      "id": "98",
      "label": "Undifferentiated goods- and services-producing activities of private households for own use"
  },
  {
      "id": "98.1",
      "label": "Undifferentiated goods-producing activities of private households for own use"
  },
  {
      "id": "98.10",
      "label": "Undifferentiated goods-producing activities of private households for own use"
  },
  {
      "id": "98.10Z",
      "label": "Undifferentiated goods-producing activities of private households for own use"
  },
  {
      "id": "98.2",
      "label": "Undifferentiated service-producing activities of private households for own use"
  },
  {
      "id": "98.20",
      "label": "Undifferentiated service-producing activities of private households for own use"
  },
  {
      "id": "98.20Z",
      "label": "Undifferentiated service-producing activities of private households for own use"
  },
  {
      "id": "99",
      "label": "Activities of extraterritorial organisations and bodies"
  },
  {
      "id": "99.0",
      "label": "Activities of extraterritorial organisations and bodies"
  },
  {
      "id": "99.00",
      "label": "Activities of extraterritorial organisations and bodies"
  },
  {
      "id": "99.00Z",
      "label": "Activities of extraterritorial organisations and bodies"
  }
]

export default formatInfos;