import React from 'react';
import { useTranslation } from 'react-i18next';

const RangeSlider = ({ minValue, maxValue, value, onSliderChange, isEmployees = false }) => {

  const { t, i18n } = useTranslation();

  function formatNumber(value) {
    if (value >= 1_000_000_000) {
        return `${(value / 1_000_000_000)} ${isEmployees ? "salariés" : "Md €"}`; 
    } else if (value >= 1_000_000) {
        return `${(value / 1_000_000)} ${isEmployees ? "salariés" : "M €"}`; 
    } else if (value >= 1_000) {
        return `${(value / 1_000)} ${isEmployees ? "salariés" : "k €"}`; 
    } else {
        return `${value} ${isEmployees ? "salariés" : "€"}`; 
    }
  }

  function formatValue(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  

  return (
    <div className="slider-div slider-div-k" style={{ display: 'flex' }}>
      <span className="slider-min">{formatNumber(minValue)}</span>
      <div className="slider-wrap" style={{ flexGrow: 1, margin: '0 10px' }}>
  <div className="bubble text-[#1e4690]" style={{ left: `${(value - minValue) / (maxValue - minValue) * 100}%` }}>
    {isEmployees ? t('rangeSlider.effectif_company') : t("rangeSlider.ca_company") }<br />
    <b style={{ fontSize: '20px', color: '#006acc' }}>{!isEmployees ? `${formatValue(value)} €` : `${Math.ceil(value)} ${t("rangeSlider.employees")}`}</b>
  </div>
  <input 
    type="range"
    className="slider-input"
    min={minValue}
    max={maxValue}
    value={value}
    onChange={onSliderChange}
    style={{
      background: `linear-gradient(to right, #006acc ${((Math.ceil(value) - minValue) / (maxValue - minValue) * 100)}%, #dbdbdb 0%)`
    }}
  />
</div>

      <span className="slider-max">{formatNumber(maxValue)}</span>
    </div>
  );
};

export default RangeSlider;
