
import { useLocation } from "react-router-dom";
import MainRecapWorkspace from "../components/recapWorkspace/MainRecapWorkspace";
import SideBar from "../components/recapWorkspace/SideBar";
import React, { useEffect, useState } from 'react';
import DisclaimerModal from "../components/recapWorkspace/DisclaimerModal";

function RecapWorkspace() {
    const location = useLocation();
    const [result, setResult] = useState(location.state?.result || {});
    const raisonSociale = location.state?.raisonSociale || ''

    useEffect(() => {
        if (!location.state?.result) {
            // Si `result` n'est pas passé via `location.state`, le récupérer depuis localStorage
            const savedResult = localStorage.getItem("result");
            if (savedResult) {
                setResult(JSON.parse(savedResult));
            }
        }
    }, [location.state]);

    console.log("result RecapWorkspace : ", result)

    const organizeData = (data) => {
        const thematicMap = new Map();

        // Organiser les données par thématique et compter les détails
        data.forEach(item => {
            const theme = item.categories[0];
            const detail = item.categories[1];

            // Vérifie si la thématique existe déjà
            if (!thematicMap.has(theme)) {
                thematicMap.set(theme, { title: theme, details: new Map() });
            }

            const themeDetails = thematicMap.get(theme).details;

            // Compter les occurrences des détails
            if (!themeDetails.has(detail)) {
                themeDetails.set(detail, { name: detail, count: 1 });
            } else {
                themeDetails.get(detail).count += 1;
            }
        });
        // Transformer les Maps en array d'objets pour une utilisation plus simple en React
        const organizedData = Array.from(thematicMap, ([title, { details }]) => ({
            title,
            details: Array.from(details, ([name, detailData]) => detailData)
        }));
        console.log(organizedData)

        return organizedData;
    };

    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(true);

    const toggleSidebar = () => setIsOpen(!isOpen);


    return (
        <div className="flex flex-col h-screen bg-gray-100 md:flex-row">
            <SideBar nbObligationsApplicables={result.n_content_ok} nbObligationspotentiels={result.n_content_maybe}
                n_content_ko={result.n_content_ko} raisonSociale={raisonSociale} isMobile={isMobile} isOpen={isOpen} setIsMobile={setIsMobile} setIsOpen={setIsOpen} toggleSidebar={toggleSidebar} />

            <div className="flex flex-col flex-1 overflow-hidden bg-[#E5F0FF]">

                <MainRecapWorkspace nbObligations={result.n_content_ok} thematiques={organizeData(result.ok_contents)} result={result}
                    isMobile={isMobile} isOpen={isOpen} setIsMobile={setIsMobile} setIsOpen={setIsOpen} raisonSociale={raisonSociale}
                    toggleSidebar={toggleSidebar} />
                {/* Nouveau disclaimer */}
                <DisclaimerModal
                    isOpen={isDisclaimerOpen}
                    onClose={() => setIsDisclaimerOpen(false)}
                />
            </div>
        </div>
    );
}

export default RecapWorkspace;