import React, { useState } from 'react';

const NumberInputWithSeparator = ({ setValue }) => {
    const [displayValue, setDisplayValue] = useState('');

    const formatNumber = (num) => {
        return num.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const handleChange = (e) => {
        let value = e.target.value.replace(/\s/g, ''); // Enlève tous les espaces
        if (value === '' || /^\d+$/.test(value)) { // Vérifie si c'est vide ou ne contient que des chiffres
            setDisplayValue(formatNumber(value));
            setValue(value); // Met à jour la valeur dans le state parent
        }
    };

    return (
        <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
            <div className="flex justify-center gap-4">
                <div>
                    <input
                        type="text"
                        className="w-full p-4 text-center font-bold rounded-xl bg-white text-[#006acc]"
                        value={displayValue}
                        onChange={handleChange}
                        inputMode="numeric"
                    />
                </div>
                <div className='text-[#006acc] flex items-center text-3xl'>
                    €
                </div>
            </div>
        </div>
    );
};

export default NumberInputWithSeparator;