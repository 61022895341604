import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { getEnvConfig } from '../../utils/config';


const SiegeLocauxEnCommunX = ({ nbStep, setSiegeLocauxEnCommunX, moveToStep,
    goBack, raisonSociale, isMicroEntreprise, nbCandtionMicroValide,
    isSCI,
    SIRET,
    codeNaf,
    NAFsection,
    isComanyCommercial,
    catJuridiqueNiv1,
    catJuridiqueNiv2,
    catJuridiqueNiv3,

    chiffredAffairesTotalNet, //1
    nbMoyenSalaries, // 2
    regimeTva, //3
    optionImpotSocieteISQ, //4
    proprioBatimentImmoQ, //5
    equipInstallationsSoumisVGPQ, //6
    loyerProprioBailleur, // 7
    locMeubleeNonMeubleeQ, // 8 
    locMeubleeOccasMoins10p, // 9
    locationMeubleDeTourismeQ, // 10
    locHabitPersoProprioQ,
    assujettissementLoyerProComTvaQ,
    surfaceExploitationSup1000mC,
    locBiensImmoUsagePro,
    impositionAssociesQ,
    traitementDonneesPerso,
    sousTraitantDonneesPersonnelles,
    collectionDonnesPerso,
    traitementListeAIPDoblig,
    criteresDesignationDPO,
    etabliRecevantPublicERP,
    prodDetDechets5flux,
    cocontractantPrestationSup5k,
    classementLogementDPE,
    detentionSiteInternetQ,
    utilisationTraceurCookies,
    operateurPlateforme,
    datePremClotureX,
    totalBilanQ,


    dateCreation,
    siegeLocauxEnCommunX
}) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep) : 18) : (nbStep >= 25) ? (nbStep + 1) : 25);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const decomposeCodeNaf = (code) => {
        const parts = code.match(/^(\d{2})\.(\d{2})([A-Z])$/);
        if (!parts) return null;

        return {
            NAFdivision: parts[1],
            NAFgroupe: `${parts[1]}.${parts[2].charAt(0)}`,
            NAFclasse: `${parts[1]}.${parts[2]}`,
            NAFsousClasse: code
        };
    };

    const handleConfirm = async () => {
        if (isMicroEntreprise || isComanyCommercial) {
            siegeLocauxEnCommunX ? moveToStep('dateDomiciliationLocauxCommun') : moveToStep('ContractantPrestationSup');
            return
        }
        setIsLoading(true);
        const postData = {
            scope: {
                types: ["Obligation"],
                categories: [
                    "Compliance", "Comptabilite - Gestion - Finances", "Environnement - Developpement durable",
                    "Fiscalite - Impots - Taxes", "Gouvernance - Engagements", "Ressources humaines",
                    "Santé de l'Homme et de son environnement de travail"
                ],
                laws: ["France", "Europe"],
                modules_only: false,
                module_id: null
            },
            variables: {
                SIRET: SIRET,
                catJuridiqueNiv1: catJuridiqueNiv1,
                catJuridiqueNiv2: catJuridiqueNiv2,
                catJuridiqueNiv3: catJuridiqueNiv3,
                NAFsection: NAFsection,
                ...decomposeCodeNaf(codeNaf),

                resultat_ExerciceN_chiffredAffairesTotalNet: Math.ceil(chiffredAffairesTotalNet), //1
                nbMoyenSalaries_ExerciceN: Math.ceil(nbMoyenSalaries), // 2
                regimeTVANormalSimplifieFranQ: regimeTva, //3
                optionImpotSocieteISQ: optionImpotSocieteISQ, // 4
                proprioBatimentImmoQ: proprioBatimentImmoQ, // 5
                equipInstallationsSoumisVGPQ: equipInstallationsSoumisVGPQ, // 6
                loyerProprioBailleur: loyerProprioBailleur, //7
                locMeubleeNonMeubleeQ: locMeubleeNonMeubleeQ, // 8
                locMeubleeOccasMoins10p: locMeubleeOccasMoins10p, // 9
                locationMeubleDeTourismeQ: locationMeubleDeTourismeQ, // 10
                locHabitPersoProprioQ: locHabitPersoProprioQ, // 11
                assujettissementLoyerProComTvaQ: assujettissementLoyerProComTvaQ, //12
                surfaceExploitationSup1000mC: surfaceExploitationSup1000mC,// 13
                locBiensImmoUsagePro: locBiensImmoUsagePro, //14
                impositionAssociesQ: impositionAssociesQ, // 15
                traitementDonneesPerso: traitementDonneesPerso,//16
                sousTraitantDonneesPersonnelles: sousTraitantDonneesPersonnelles, //17
                collectionDonnesPerso: collectionDonnesPerso, //18
                traitementListeAIPDoblig: traitementListeAIPDoblig, //19
                criteresDesignationDPO: criteresDesignationDPO,//20
                etabliRecevantPublicERP: etabliRecevantPublicERP,//21
                prodDetDechets5flux: prodDetDechets5flux, //22
                cocontractantPrestationSup5k: cocontractantPrestationSup5k, //23
                classementLogementDPE: classementLogementDPE, //24
                detentionSiteInternetQ: detentionSiteInternetQ, //25
                utilisationTraceurCookies: utilisationTraceurCookies, //26
                operateurPlateforme: operateurPlateforme, // 27
                // datePremClotureX: datePremClotureX, // 28
                totalBilanQ: Math.ceil(totalBilanQ), // 29
                SiegeLocauxEnCommunX: siegeLocauxEnCommunX //30
                // dateCreation: dateCreation
            }
        };
        console.log(postData);

        try {
            const { apiUrl } = getEnvConfig();
            console.log("apiUrl: ", apiUrl + 'run_simulator')
            const response = await fetch(apiUrl + 'run_simulator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(postData)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result)
            if (result?.erros) {
                alert("Une erreur s'est produite lors du calcul des obligations.")
                navigate('/siret');
            }
            navigate('/workspace', { state: { result, raisonSociale } });

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            alert("Une erreur s'est produite lors du calcul des obligations.")
            navigate('/siret');
        } finally {
            setIsLoading(false);
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    const Loader = () => (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
    );

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);

    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            {isLoading && <Loader />}
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        <>
                            Le siège de  {raisonSociale} est-il installé dans des locaux en commun avec une ou plusieurs entreprises ?
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            Is {raisonSociale}'s head office located on premises shared with one or more other companies?
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[350px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${siegeLocauxEnCommunX === true ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setSiegeLocauxEnCommunX(true)}
                    >
                        {i18n.language === 'fr' ? "Oui" : "Yes"}
                    </button>
                    <button
                        className={`w-full p-4 text-center font-bold rounded-xl ${siegeLocauxEnCommunX === false ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc]  '}`}
                        onClick={() => setSiegeLocauxEnCommunX(false)}
                    >
                        {i18n.language === 'fr' ? "Non" : "No"}
                    </button>
                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <button className={`bg-[#1e4690] text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300 ${siegeLocauxEnCommunX === null && 'opacity-25'}`} disabled={siegeLocauxEnCommunX === null} onClick={handleConfirm} >
                    {isSCI ? t('revenu.button_save') : t('siret_page.button')}
                </button>
            </div>
        </div>
    );
};

export default SiegeLocauxEnCommunX;
