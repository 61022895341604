import React, { useRef, useState, useEffect } from 'react';
import RangeSlider from './RangeSlider';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { themePopOver, updateProgressColor } from '../../utils/utils';


import { Popover } from "flowbite-react";

export function Component() {
  return (
    <p className="text-gray-500 dark:text-gray-400">
      Due to its central geographic location in Southern Europe,{' '}
      <Popover
        trigger="hover"
        content={
          <div className="w-96 text-sm text-gray-500 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400">
            <div className="grid grid-cols-5">
              <div className="col-span-3 p-3">
                <div className="space-y-2">
                  <h3 className="font-semibold text-gray-900 dark:text-white">About Italy</h3>
                  <p>
                    Italy is located in the middle of the Mediterranean Sea, in Southern Europe it is also considered
                    part of Western Europe. A unitary parliamentary republic with Rome as its capital and largest city.
                  </p>

                </div>
              </div>
            </div>
          </div>
        }
      >
        <a href="#" className="text-blue-600 underline hover:no-underline dark:text-blue-500">
          Italy
        </a>
      </Popover>{' '}
      has historically been home to myriad peoples and cultures. In addition to the various ancient peoples dispersed
      throughout what is now modern-day Italy, the most predominant being the Indo-European Italic peoples who gave the
      peninsula its name, beginning from the classical era, Phoenicians and Carthaginians founded colonies mostly in
      insular Italy
    </p>
  );
}


const RevenueRangeSelector = ({ nbStep, isSCI, moveToStep, nbCandtionMicroValide, goBack, raisonSociale, isMicroEntreprise, setChiffredAffairesTotalNet, chiffredAffairesTotalNet }) => {
  const [showSlider1, setShowSlider1] = useState(false);
  const [showSlider2, setShowSlider2] = useState(false);
  const [showSlider3, setShowSlider3] = useState(false);
  // const [chiffredAffairesTotalNet, setChiffredAffairesTotalNet] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep+1) : 9)  : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18)  : (nbStep >= 25) ? (nbStep+1) : 25);
  const [valueSelection, setValueSelection] = useState('');

  const rangeRef = useRef(null);
  const [divDisplayHover, setDivDisplayHover] = useState({});
  const { t, i18n } = useTranslation();


  const createMarkup = (htmlString) => {
    return { __html: htmlString };
  };

  const handleConfirm = () => {
    setChiffredAffairesTotalNet(chiffredAffairesTotalNet);
    moveToStep("employeesSelection");
  };

  const handleSliderChange = (event) => {
    setChiffredAffairesTotalNet(event.target.value);
  };

  const handleSelectRange = (minValue, maxValue, line) => {
    setDivDisplayHover({});
    switch (line) {
      case 1:
        setShowSlider2(false);
        setShowSlider3(false);
        setShowSlider1(true);
        break;
      case 2:
        setShowSlider1(false);
        setShowSlider3(false);
        setShowSlider2(true);
        break;
      case 3:
        setShowSlider1(false);
        setShowSlider2(false);
        setShowSlider3(true);
        break;

      default:
        break;
    }
    setMaxValue(maxValue);
    setMinValue(minValue);
    setChiffredAffairesTotalNet((minValue + maxValue) / 2);
  };

  console.log("raisonSociale1 : ", raisonSociale);


  useEffect(() => {
    updateProgressColor(nbStep, maxValueQuestion, rangeRef);
  }, []);

  const handleMobileSelect = (event) => {
    setValueSelection(event.target.value);

    if (event.target.value) {
      const [min, max, line] = event.target.value.split('-').map(Number);
      handleSelectRange(min, max, line);
    } else {
      setShowSlider1(false);
      setShowSlider2(false);
      setShowSlider3(false);
    }
  };

  const mobileOptions = [
    { value: "0-50000-1", label: 'revenu.lessThan50K' },
    { value: "50000-100000-1", label: 'revenu.range50To100K' },
    { value: "100000-300000-1", label: 'revenu.range100To300K' },
    { value: "300000-500000-1", label: 'revenu.range300To500K' },
    { value: "500000-1000000-2", label: 'revenu.range500KTo1M' },
    { value: "1000000-3000000-2", label: 'revenu.range1To3M' },
    { value: "3000000-10000000-2", label: 'revenu.range3To10M' },
    { value: "10000000-30000000-2", label: 'revenu.range10To30M' },
    { value: "30000000-100000000-3", label: 'revenu.range30To100M' },
    { value: "100000000-500000000-3", label: 'revenu.range100To500M' },
    { value: "500000000-1000000000-3", label: 'revenu.range500MTo1B' },
    { value: "1000000000-60000000000-3", label: 'revenu.moreThan1B' },
  ];
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  useEffect(() => {
    updateProgressColor(nbStep, maxValueQuestion, rangeRef);
  }, []);



  return (
    <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>

      <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


      <div className='max-w-[800px] -mt-2'>

        <h1 className='text-2xl text-[#1e4690] mb-6'>
          {i18n.language === 'fr' &&
            <>
              <p className="text-gray-500 dark:text-gray-400">
                <Popover theme={themePopOver}
                  trigger="hover"
                  aria-labelledby="default-popover"
                  content={
                    <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                      <div className="px-3 py-2">
                        <p>Le chiffre d'affaires représente le montant des affaires (hors taxes) réalisées par une unité statistique (entreprise, unité légale) avec les tiers dans l'exercice de son activité professionnelle normale et courante.</p>
                      </div>
                    </div>
                  }
                >
                  <a href="#"
                    className="text-[#006acc] hover:text-[#006acc] focus:text-[#006acc]"
                  >
                    Chiffre d'affaires
                  </a>
                </Popover>{' '}

                (CA) annuel HT de {raisonSociale} lors du dernier exercice clos (ou le CA prévisionnel si l’entreprise est en devenir) :
              </p>
              {/* <a href='#' className='text-[#006acc] hover:border-b-2 hover:border-[#006acc]'>
              </a> */}
            </>
          }
          {i18n.language === 'en' &&
            <>
              {raisonSociale}'s total{' '}
              <Popover theme={themePopOver}
                trigger="hover"
                aria-labelledby="default-popover"
                content={
                  <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
                    <div className="px-3 py-2">
                      <p>Sales represent the amount of business (excluding tax) done by a statistical unit (company, legal unit) with third parties in the course of its normal and ordinary business activity.</p>
                    </div>
                  </div>
                }
              >
                <a href="#" className="text-[#006acc] hover:text-[#006acc] focus:text-[#006acc]">
                  business turnover
                </a>
              </Popover>{' '}
              excluding tax in last fiscal year (or the projected turnover if company's not yet created):
            </>
          }
        </h1>
        <p className='text-[#aaaaaa]'>{t('revenu.title1')}</p>
      </div>

      {isMobile ? (
        <select
          className="w-full p-3 my-4 text-[#1e4690] border border-gray-300 rounded-md"
          onChange={handleMobileSelect}
        >
          <option value="">{t('rangeSlider.selectNbEmployees')}</option>
          {mobileOptions.map(({ value, label }) => (
            <option key={value} value={value} dangerouslySetInnerHTML={createMarkup(t(label))}></option>
          ))}
        </select>
      ) : (
        <div className="buttons">

          <div className="btn-row text-[#1e4690]">
            <div className={`btn-ca text-sm ${divDisplayHover[0] ? "border-2 border-[#1e4690] bg-[#e9ecef]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(0, 50000, 1); setDivDisplayHover({ 0: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('revenu.lessThan50K'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[1] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(50000, 100000, 1); setDivDisplayHover({ 1: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('revenu.range50To100K'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[2] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(100000, 300000, 1); setDivDisplayHover({ 2: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('revenu.range100To300K'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[3] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(300000, 500000, 1); setDivDisplayHover({ 3: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('revenu.range300To500K'))}></div>
          </div>

          {showSlider1 && (
            <RangeSlider
              minValue={minValue}
              maxValue={maxValue}
              value={chiffredAffairesTotalNet}
              onSliderChange={handleSliderChange}
            />
          )}


          <div className="btn-row text-[#1e4690]">
            <div className={`btn-ca text-sm ${divDisplayHover[4] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(500000, 1000000, 2); setDivDisplayHover({ 4: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('revenu.range500KTo1M'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[5] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(1000000, 3000000, 2); setDivDisplayHover({ 5: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('revenu.range1To3M'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[6] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(3000000, 10000000, 2); setDivDisplayHover({ 6: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('revenu.range3To10M'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[7] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(10000000, 30000000, 2); setDivDisplayHover({ 7: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('revenu.range10To30M'))}></div>
          </div>

          {showSlider2 && (
            <RangeSlider
              minValue={minValue}
              maxValue={maxValue}
              value={chiffredAffairesTotalNet}
              onSliderChange={handleSliderChange}
            />
          )}

          <div className="btn-row text-[#1e4690]">
            <div className={`btn-ca text-sm ${divDisplayHover[8] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(30000000, 100000000, 3); setDivDisplayHover({ 8: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('revenu.range30To100M'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[9] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(100000000, 500000000, 3); setDivDisplayHover({ 9: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('revenu.range100To500M'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[10] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(500000000, 1000000000, 3); setDivDisplayHover({ 10: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('revenu.range500MTo1B'))}></div>
            <div className={`btn-ca text-sm ${divDisplayHover[11] ? "border-2 border-[#1e4690] bg-[#ddd]" : "bg-[#e9ecef] hover:border-[#1e4690] hover:bg-[#ddd]"}`}
              onClick={() => { handleSelectRange(1000000000, 60000000000, 3); setDivDisplayHover({ 11: true }); }}
              dangerouslySetInnerHTML={createMarkup(t('revenu.moreThan1B'))}></div>
          </div>


          {showSlider3 && (
            <RangeSlider
              minValue={minValue}
              maxValue={maxValue}
              value={chiffredAffairesTotalNet}
              onSliderChange={handleSliderChange}
            />
          )}
        </div>
      )}

      {showSlider1 && isMobile && (
        <RangeSlider
          minValue={minValue}
          maxValue={maxValue}
          value={chiffredAffairesTotalNet}
          onSliderChange={handleSliderChange}
        />
      )}

      {showSlider2 && isMobile && (
        <RangeSlider
          minValue={minValue}
          maxValue={maxValue}
          value={chiffredAffairesTotalNet}
          onSliderChange={handleSliderChange}
        />
      )}

      {showSlider3 && isMobile && (
        <RangeSlider
          minValue={minValue}
          maxValue={maxValue}
          value={chiffredAffairesTotalNet}
          onSliderChange={handleSliderChange}
        />
      )}

      <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-4'>
        <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack}>
          {t('revenu.button_back')}
        </button>
        <button className={`bg-[#1e4690] text-white px-12 py-3 rounded-full  ${(chiffredAffairesTotalNet === null || (isMobile && valueSelection === '')) ? 'opacity-25' : 'hover:bg-[#1e469094] transition duration-300'}`} disabled={(chiffredAffairesTotalNet === null || (isMobile && valueSelection === ''))} onClick={handleConfirm}>
          {t('revenu.button_save')}
        </button>
      </div>
    </div>
  );
};

export default RevenueRangeSelector;
